<!-- EXISTING REGULATION -->
<ul class="nav nav-tabs mb-3" id="regulationData-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="existing" data-mdb-toggle="tab" data-mdb-target="#existingtab" type="button" role="tab" aria-controls="commontab" aria-selected="true" >
      Existing Regulations</button>
  </li>
</ul>

<!-- EXISTING REGULATION - CONTENT -->
<mat-card>
  <div class="tab-content" id="regulationData-content">
    <div class="tab-pane fade show active" id="existingtab" role="tabpanel" aria-labelledby="existing">
      <div [formGroup]="searchForm" class="form-group d-flex flex-row align-items-center" style="margin:1em 0;">
          <label for="search" class="me-2 fw-bold">Search:</label>
          <input formControlName="keyword" id="search" name="search" (keyup)="search($event)" class="form-control"/>
          <button class="btn btn-outline-dark ms-2" (click)="refreshList()" title="refresh"><i class="fas fa-redo"></i></button>
      </div>

      <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
      <table mat-table class="table table-hover table-responsive" id="matTable"  [dataSource]="agencyRegulationsDataSource">
        <ng-container matColumnDef="regulations">
          <th mat-header-cell *matHeaderCellDef class="p-2">
            <h6 class="text-center fw-bold report-title theme-default-text"></h6>
          </th>
          <td mat-cell *matCellDef="let item" style="padding:2em">
            <div>
              <a [routerLink]="[]" (click)="goToRegulation(item.id)" title="Go to {{item.title ? item.title : item.subject ? item.subject : ''}} regulation">
                <h6 class="fw-bold report-title theme-arta-text" [ngClass]="item.title || item.subject ? '' : 'red-text'">
                  {{item.title ? item.title : item.subject ? item.subject : 'No Title'}}
                </h6>
              </a>
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <span *ngIf="item.displayDate" class="mx-2"><span class="fw-bold">Date Issued:</span> {{ item.displayDate }}</span>
                <span *ngIf="item.effectiveDate" class="mx-2"><span class="fw-bold">Date Effectivity:</span> {{ item.effectiveDate }}</span>
                <span *ngIf="item.amendDate" class="mx-2"><span class="fw-bold">Date Amended/Repealed:</span> {{ item.amendDate }}</span>
              </div>
              <div class="d-flex flex-column">
                <span *ngIf="item.sector" class="mx-2"><span class="fw-bold">Sector:</span> {{ item.sector }}</span>
                <span *ngIf="item.division" class="mx-2"><span class="fw-bold">Division:</span> {{ item.division }}</span>
                <span *ngIf="item.sob" class="mx-2"><span class="fw-bold">Stage of Business:</span> {{ item.sob }}</span>
                <span *ngIf="item.case" class="mx-2"><span class="fw-bold">Case Use:</span> {{ item.case }}</span>
                <span *ngIf="item.sol" class="mx-2"><span class="fw-bold">Stage of Life:</span> {{ item.sol }}</span>
                <span *ngIf="item.le" class="mx-2"><span class="fw-bold">Life Event:</span> {{ item.le }}</span>
              </div>
            </div>
          </td>
          <ng-container *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
            <td mat-footer-cell *matFooterCellDef style="padding:2em" class="fw-bold d-flex justify-content-center">
              No posted Regulation yet.
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns;"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        <ng-container *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
          <tr  mat-footer-row *matFooterRowDef="columns"></tr>
        </ng-container>
      </table>
      <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</mat-card>