<div class="inboxStart">
  
  <!--Page Title -->
  <div class="row" style="background-color:#f8f6ed;">
    <nav aria-label="breadcrumb" class="col-6 py-2">
      <ol class="breadcrumb my-1 ms-4">
          <!--<li class="breadcrumb-item active fw-bold" style="color:#595959;" aria-current="page" *ngIf="currentFormPage == 'preface'">CREATE A PROPOSED REGULATIONS</li>-->
          <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page">MY WORKLIST<i class="fas fa-chevron-right mx-2"></i>PROPOSED</li>
      </ol>
    </nav> 
    <div class="col-6">
      <button class="my-2 me-4 btn btn-dark btn-sm float-end" (click)="showOnInboxViewport()">Back To Inbox</button>
    </div>
  </div>

  <div class="content padded">
    <!-- title -->
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-12">
            <!-- <h2 class="{{style_theme}}-text report-title fw-bold">[LONG TITLE OF REGULATION HERE]</h2> -->
            <h6 class=" report-title fw-bold my-3">
              <!-- {{ reg_long_name }} -->
              <ng-container *ngIf="hasDris && regulationsData['DRIS'] && regulationsData['DRIS'].data && regulationsData['DRIS'].data.reg_long_name; else initialDataShow">
                {{ regulationsData['DRIS'].data.reg_long_name }}
              </ng-container>
              <ng-template #initialDataShow>
                <ng-container *ngIf="regulationsData['INITIAL'] && regulationsData['INITIAL'].data && regulationsData['INITIAL'].data.reg_long_name">
                  {{ regulationsData['INITIAL'].data.reg_long_name }}
                </ng-container>
              </ng-template>
            </h6>
          </div>
        </div>
        
        <!-- <div class="row mt-1">
          <div class="col">
            <strong class="text-danger">{{ reg_section_name }} Section Name</strong> of the
            <strong class="text-danger">{{ reg_subject }} Regulation Short Title</strong> issued on
            <strong class="text-danger">{{ reg_date | date: "MM/dd/yyyy" }} Date</strong>
          </div>
        </div> -->

      </div>
    </div>

    <!-- Sidenav -->
    <div class="row my-5">
      <div class="col-lg-3 col-sm-12">

        <!-- Nav-pills Buttons -->
        <div class="col sidebar-custom mb-3" *ngIf="isSidebarVisible">
          <div class="text-center" >
            <div class="col-12 {{ style_theme }} py-2" style="border-top:3px solid #FCD116">
              <h6 class="fw-bold m-2">{{ reg_status }}</h6> 
              <span style="font-size:14px;" *ngIf="rulestage">{{rulestage.stage}} {{rulestage.rulemakingStage}}</span>
            </div>
          </div>

          <!-- Menu -->
          <ul class="nav nav-pills nav flex-column py-2" id="pills-tab" role="tablist" *ngIf="regulationsData['INITIAL'].data || regulationsData['CRIS'].data || regulationsData['DRIS'].data">

            <li class="nav-item" style="cursor: pointer;" *ngIf="reg_status === enum_regulation_status.FOR_POSTING "> 
              <!-- (click)="onPrePostingView()"  -->
              <a class="nav-link" [ngClass]="showViews.prePostingView? 'active':''" (click)="onView('prePostingView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-finalReg" aria-selected="false">For Posting</a>
            </li>

            <!-- <li class="nav-item" style="cursor: pointer;" *ngIf="(( baseCode == 'INITIAL' && reg_has_draft_text) || baseCode == 'DRIS')">
              <a class="nav-link" [ngClass]="textRegView? 'active':''" (click)="onTextRegView()"   data-toggle="pill"  role="tab" aria-controls="pills-textReg" aria-selected="false">INITIAL Text Regulations</a>
            </li> -->

            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onPhase2View()" -->
              <a class="nav-link" [ngClass]="showViews.phase2View? 'active':''" (click)="onView('phase2View')" 
                data-toggle="pill"  role="tab" aria-controls="pills-textReg" aria-selected="false">Initial Regulation Details</a>
            </li>

            <!-- <li class="nav-item" style="cursor: pointer;"> -->
              <!-- (click)="onPiaView()"  -->
              <!-- <a class="nav-link" [ngClass]="showViews.piaView? 'active':''" (click)="onView('piaView')"
                data-toggle="pill"  role="tab" aria-controls="pills-pia" aria-selected="false">Initial PIA</a>
            </li> -->

            <li class="nav-item" style="cursor: pointer;" *ngIf="regulationsData['INITIAL'].data.reg_has_draft_text">
              <!-- (click)="onTextRegView()"  --> 
              <a class="nav-link" [ngClass]="showViews.textRegView? 'active':''" (click)="onView('textRegView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-textReg" aria-selected="false">Initial Text Regulations</a>
            </li>

            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onSummaryView()" -->
              <a class="nav-link" [ngClass]="showViews.summaryView? 'active':''" (click)="onView('summaryView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-summary" aria-selected="false">Proposal Summary</a>
            </li>
  
            <!-- For review of ARTA -->
            <li class="nav-item" style="cursor: pointer" style="cursor: pointer;border-top:1px solid rgba(0,0,0,0.1);padding-top:10px;margin-top:10px;"
              *ngIf="
                reg_status === enum_regulation_status.FOR_RIA ||
                reg_status === enum_regulation_status.DRAFT_CRIS ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG ||
                reg_status === enum_regulation_status.DRAFT_DRIS ||
                reg_status === enum_regulation_status.DRIS_FOR_VERIFICATION ||
                reg_status === enum_regulation_status.DRIS_FOR_REVISION ||
                reg_status === enum_regulation_status.DRIS_FOR_APPROVAL ||
                reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW ||
                reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC ||
                reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR ||
                reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG ||
                reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG ||
                reg_status === enum_regulation_status.DRIS_FOR_ASSESMENT ||
                (reg_status === enum_regulation_status.FOR_POSTING && hasCris) ||
                reg_status === enum_regulation_status.WITHDRAWN
              "
            >
              <a class="nav-link" [ngClass]="showViews.pisAssessmentSummaryView? 'active':''" (click)="onView('pisAssessmentSummaryView')" 
                data-toggle="pill" role="tab" aria-controls="pills-pis-assessment-summary" aria-selected="false">PIS Assessment Summary</a>
            </li>

            <!-- View Comments"  -->
            <li class="nav-item" style="cursor: pointer;border-top:1px solid rgba(0,0,0,0.1);padding-top:10px;margin-top:10px;"
                *ngIf="
                  reg_status == enum_regulation_status.DRAFT || reg_status == enum_regulation_status.FOR_REVISION || 
                  reg_status == enum_regulation_status.FOR_MODIFICATION || reg_status === enum_regulation_status.FOR_VERIFICATION || 
                  reg_status === enum_regulation_status.FOR_APPROVAL  || reg_status === enum_regulation_status.RESUBMITTED">
              <a class="nav-link" [ngClass]="showViews.viewComments? 'active':''" (click)="onView('viewComments')"
                data-toggle="pill"  role="tab" aria-controls="pills-overview" aria-selected="false">
                <i class="fas fa-comments me-2"></i>View Comments
              </a>
            </li>
          </ul>
        </div>

        <!-- Action Buttons -->
        <div class="col-12 sidebar-custom my-4" *ngIf="actionButtonAllowed">
          <div class="align-left" >
            <nav aria-label="breadcrumb" class="breadcrumb-custom my-2">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">ACTION:</li>
              </ol>
            </nav>
          </div>

          <div class="row p-3 text-center">
            <!--Add Comment-->
            <div class="my-1"
              *ngIf="
                reg_status == enum_regulation_status.DRAFT || reg_status == enum_regulation_status.FOR_REVISION || 
                reg_status == enum_regulation_status.FOR_MODIFICATION || reg_status === enum_regulation_status.FOR_VERIFICATION || 
                reg_status === enum_regulation_status.FOR_APPROVAL  || reg_status === enum_regulation_status.RESUBMITTED">
              <button disabled class="btn btn-dark btn-custom w-100" *ngIf="commentButtonsEnabled" aria-label="Comment on regulation details" (click)="goToComments(hasDris ? 'DRIS' : hasCris ? 'CRIS' : 'INITIAL','regpia')">
                <i class="fas fa-comment-medical fa-lg me-2"></i>add comment
              </button>
            </div>

            <!-- For review of ARTA -->
            <div class="my-1" *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG ||
                reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG">
              <button class="btn btn-info btn-custom w-100" [ngClass]="showViews.pisAssessmentView? 'active':''" (click)="onView('pisAssessmentView')" 
                  data-toggle="pill" role="tab" aria-controls="pills-pis-assessment" aria-selected="false">
                <i class="fas fa-user-edit me-2"></i>Create PIS Assessment
              </button>
            </div>

            <!-- Agency Encoder-->
            <div class="my-1" *ngIf="reg_status == enum_regulation_status.DRAFT || reg_status == enum_regulation_status.FOR_REVISION || reg_status == enum_regulation_status.FOR_MODIFICATION">
              <button (click)="editRegulation()" class="btn btn-dark btn-custom w-100">
                <i class="fas fa-edit me-2"></i>EDIT
              </button>
            </div>

            <!-- For RIA-->
            <div *ngIf="reg_status == enum_regulation_status.FOR_RIA">
              <div class="my-1">
                <button (click)="draftCris()" class="btn btn-dark btn-custom w-100">
                  <i class="fas fa-edit me-2"></i>DRAFT CONSULTATION RIS
                </button>
              </div>
              
              <div class="my-1">
                <button cl (click)="withdrawRegulation()" class="btn btn-danger btn-custom w-100">
                  <i class="fas fa-trash-alt me-2"></i>WITHDRAW REGULATION
                </button>
              </div>
            </div>
            
            <div class="my-1" *ngIf="reg_status == enum_regulation_status.DRAFT_CRIS">
              <button class="btn btn-dark btn-custom w-100" (click)="editCris()">
                <i class="fas fa-edit me-2"></i>CONTINUE DRAFT CRIS
              </button>
            </div>

            <div *ngIf="reg_status == enum_regulation_status.SUBMITTED_CRIS">
              <div class="my-1">
                <button class="btn btn-dark btn-custom w-100" (click)="editCris()">
                  <i class="fas fa-edit me-2"></i>EDIT CRIS
                </button>
              </div>

              <div class="my-1" (click)="draftDris()" *ngIf="allConsultationsCompleted">
                <button class="btn btn-dark btn-custom w-100">
                  <i class="fas fa-edit me-2"></i>DRAFT DECISION RIS
                </button>
              </div>
            </div>

            <div class="my-1" *ngIf="reg_status == enum_regulation_status.DRAFT_DRIS">
              <button class="btn btn-dark btn-custom w-100" (click)="editDris()">
                <i class="fas fa-edit me-2"></i>CONTINUE DRAFT DRIS
              </button>
            </div>

            <div class="my-1" *ngIf="(reg_status == enum_regulation_status.DRIS_FOR_VERIFICATION || reg_status === enum_regulation_status.DRIS_FOR_REVISION) && hasDris">
              <button class="btn btn-dark btn-custom w-100" (click)="editDris()">
                <i class="fas fa-edit me-2"></i>EDIT DRIS
              </button>
            </div>

            <!-- Action Buttons -->
            <div *ngIf="actionButtonAllowed">
              <!-- for revision -->
              <!-- <div *ngIf="reg_status !== enum_regulation_status.DRAFT" class="row"> -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION || reg_status === enum_regulation_status.FOR_APPROVAL  || reg_status === enum_regulation_status.RESUBMITTED" class="row">
                <div class="col-12 my-1">
                  <button class="btn btn-primary btn-custom w-100" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)">
                    <i class="fas fa-undo-alt me-2"></i>Return For Revision
                  </button>
                </div>
              </div>

              <!-- submit draft -->
              <div *ngIf="reg_status === enum_regulation_status.DRAFT">
                <div class="col-12 my-1">
                  <button class="btn btn-warning btn-custom w-100" [disabled]="!true" (click)="submit(enum_action_flag.SUBMIT_DRAFT)">
                    <i class="fas fa-share-square me-2"></i>Submit
                  </button>
                </div>
              </div>

              <!-- for verification -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION">
                <div class="col-12 my-1">
                  <button class="btn btn-warning btn-custom w-100" [disabled]="!true" (click)="submit(enum_action_flag.SUBMIT_TO_AGENCY_APPR)">
                    <i class="fas fa-share-square me-2"></i>Submit For Approval
                  </button>
                </div>
              </div>

              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_VERIFICATION || reg_status === enum_regulation_status.DRIS_FOR_REVISION">
                <div class="col-12 my-1">
                  <button [disabled]="!true" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_AGENCY_APPR)" class="btn btn-warning btn-custom w-100">
                    <i class="fas fa-share-square me-2"></i>Submit Decision For Approval
                  </button>
                </div>
              </div>

              <!-- resubmit for approval agency/arta -->
              <!-- Resubmissions must now go through verifier first -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_REVISION || reg_status === enum_regulation_status.FOR_MODIFICATION">
                <div class="col-12 my-1">
                  <button class="btn btn-success btn-custom w-100" (click)="submit(enum_action_flag.SUBMIT_DRAFT)">
                    <i class="fas fa-undo-alt me-2"></i>Re-Submit For Verification
                  </button>
                </div>
              </div>

              <!-- approve / submit to arta -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_APPROVAL || reg_status === enum_regulation_status.RESUBMITTED">
                <div class="col-12 my-1">
                  <button class="btn btn-warning btn-custom w-100" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA)">
                    <i class="fas fa-thumbs-up me-2"></i>Approve
                  </button>
                </div>
              </div>

              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_APPROVAL">
                <div class="col-12 my-1">
                  <button [disabled]="!true" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_ARTA)" class="btn btn-warning btn-custom w-100">
                    <i class="fas fa-thumbs-up me-2"></i>Approve Decision
                  </button>
                </div>
              </div>

              <!-- posting -->
              <!-- <div class="row"
                *ngIf="reg_status === enum_regulation_status.FOR_POSTING">
                <div class="col-6"></div>
                <div class="col-6">
                  <button class="btn btn-primary m-1"
                    (click)="submit(enum_action_flag.POST_REGULATION)">
                    POST REGULATION
                  </button>
                </div>
              </div> -->

              <!-- decs for approval arta/bro dc/dir -->
              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC">
                <div class="col-12 my-1">
                  <button class="btn btn-warning btn-custom w-100" (click)="submit((reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW)
                      ? enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DC : enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DIR)">
                      <i class="fas fa-share-square me-2"></i>Decision For Approval (Next Level)
                  </button>
                </div>
              </div>

              <!-- for post regulatio for arta dir -->
              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
                <div class="col-12 my-1">
                  <button class="btn btn-info btn-custom w-100" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_ARTA_DDG)">
                    <i class="fas fa-share-square me-2"></i>Escalate to DDG
                  </button>
                </div>
                <div class="col-12 my-1">
                  <button class="btn btn-primary btn-custom w-100" (click)="submit(enum_action_flag.DRIS_SUBMIT_FOR_ASSESSMENT)">
                    <i class="fas fa-undo-alt me-2"></i>Return to PO for Assessment
                  </button>
                </div>
              </div>

              <!-- for post regulation for arta ddg -->
              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG">
                <div class="col-12 my-1">
                  <button class="btn btn-info btn-custom w-100" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_ARTA_DG)">
                    <i class="fas fa-share-square me-2"></i>Escalate to DG
                  </button>
                </div>
                <div class="col-12 my-1">
                  <button class="btn btn-primary btn-custom w-100" (click)="submit(enum_action_flag.DRIS_SUBMIT_FOR_ASSESSMENT)">
                    <i class="fas fa-undo-alt me-2"></i>Return to PO for Assessment
                  </button>
                </div>
              </div>

              <!-- for post regulation  -->
              <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
                <div class="col-12 my-1">
                  <button class="btn btn-primary btn-custom w-100" (click)="submit(enum_action_flag.DRIS_SUBMIT_FOR_ASSESSMENT)">
                    <i class="fas fa-undo-alt me-2"></i>Return to PO for Assessment
                  </button>
                </div>
              </div>

              <!-- PO final assessment before returning to Agency -->
              <!-- TODO: "REVISE DECISION" (new, return DRIS to agency at the DRIS level for smaller revisions to the DRIS) -->
              <!-- "RE-CONSULT" (new, return DRIS to agency back to the CRIS level for additional consultations) -->
              <div  *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ASSESMENT">
                <div class="col-12 my-1">
                  <button class="btn btn-success btn-custom w-100" (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
                    <i class="fas fa-check-circle me-2"></i>For Posting
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- CRIS & DRIS -->
        <div class="col sidebar-custom mb-3">
          <!-- CRIS -->
          <ul class="nav nav-pills nav flex-column py-2" id="pills-tab2" role="tablist" *ngIf="hasCris" 
            style="cursor: pointer;border-top:1px solid rgba(0,0,0,0.1);padding-top:10px;margin-top:10px;">

            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onCrisTextRegView()"  -->
              <a class="nav-link" [ngClass]="showViews.crisTextRegView? 'active':''" (click)="onView('crisTextRegView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-cris-textReg" aria-selected="false">CRIS Text Regulations</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onCrisView()" -->
              <a class="nav-link" [ngClass]="showViews.crisView? 'active':''" (click)="onView('crisView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-cris" aria-selected="false">CRIS Details</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onDrisSummaryView()"  -->
              <a class="nav-link" [ngClass]="showViews.crisSummaryView? 'active':''" (click)="onView('crisSummaryView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-cris-summary" aria-selected="false">CRIS Proposal Summary</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" [ngClass]="showViews.crisConsultations? 'active':''" (click)="onView('crisConsultations')" 
                data-toggle="pill"  role="tab" aria-controls="pills-cris-consultations" aria-selected="false">Consultations</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onCrisUploadsView()" -->
              <a class="nav-link" [ngClass]="showViews.crisUploadsView? 'active':''" (click)="onView('crisUploadsView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-crisUploads" aria-selected="false">CRIS Uploaded Documents</a>
            </li>
          </ul>

          <!-- DRIS -->
          <ul class="nav nav-pills nav flex-column py-2" id="pills-tab3" role="tablist" *ngIf="hasDris"
            style="cursor: pointer;border-top:1px solid rgba(0,0,0,0.1);padding-top:10px;margin-top:10px;">

            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onDrisTextRegView()" -->
              <a class="nav-link" [ngClass]="showViews.drisTextRegView? 'active':''" (click)="onView('drisTextRegView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-dris-textReg" aria-selected="false">DRIS Text Regulations</a>
            </li>
            <!-- <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" [ngClass]="phase2View? 'active':''" (click)="onPhase2View()" 
                data-toggle="pill"  role="tab" aria-controls="pills-phase2" aria-selected="false">{{baseCode}} Details</a>
            </li> -->
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onDrisPhase2View()" -->
              <a class="nav-link" [ngClass]="showViews.drisPhase2View? 'active':''" (click)="onView('drisPhase2View')" 
                data-toggle="pill"  role="tab" aria-controls="pills-dris-textReg" aria-selected="false">DRIS Details</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onDrisSummaryView()"  -->
              <a class="nav-link" [ngClass]="showViews.drisSummaryView? 'active':''" (click)="onView('drisSummaryView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-dris-summary" aria-selected="false">DRIS Proposal Summary</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <!-- (click)="onDrisUploadsView()"  -->
              <a class="nav-link" [ngClass]="showViews.drisUploadsView? 'active':''" (click)="onView('drisUploadsView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-drisUploads" aria-selected="false">DRIS Uploaded Documents</a>
            </li>
            <li class="nav-item" style="cursor: pointer;" *ngIf="ris_allow_view || ris_allow_agency">
              <!-- (click)="onRisAssessmentView()" -->
              <a class="nav-link" [ngClass]="showViews.risAssessmentView? 'active':''" (click)="onView('risAssessmentView')" 
                data-toggle="pill"  role="tab" aria-controls="pills-ris" aria-selected="false">RIS Assessment Summary</a>
            </li>
          </ul>
        </div>
      </div>

      


      <!-- content -->
      <div class="col-lg-9 col-sm-12">

        <!-- final touch before posting -->
        <div *ngIf="showViews.prePostingView && prePostLoaded">
          <!--  && hasDris -->
          <app-regulation-view-proposed-pre-posting
            (ForRIA)="submit(enum_action_flag.MARK_FOR_RIA)"
            (ReturnDRIS)="submit(enum_action_flag.RETURN_DRIS)"
            (PostRegulation)="toPostRegulation($event)"
            [actionButtonAllowed]="actionButtonAllowed"
            [objectDataInitial]="regulationsData['INITIAL'].data"
            [objectDataDRIS]="regulationsData['DRIS'].data"
          ></app-regulation-view-proposed-pre-posting>
        </div>

        <!-- Initial Text Regulations -->
        <div *ngIf="showViews.textRegView">
          <app-proposed-text-regulation-view codeBase="INITIAL" [textRegulation]="this.regulationsData['INITIAL'].data.regTextData" [paramFromInboxList]="paramFromInboxList"  [commentButtonsEnabled]="commentButtonsEnabled"></app-proposed-text-regulation-view>
        </div>

        <!-- Initial Regulation details -->
        <div *ngIf="showViews.phase2View">
          <app-proposed-regulations-details-view [objectData]="regulationsData['INITIAL'].data" baseCode="INITIAL"></app-proposed-regulations-details-view>
        </div>

        <!-- Initial Proposal summary -->
        <div *ngIf="showViews.summaryView">
          <app-proposed-summary-view baseCode="INITIAL" [objectData]="regulationsData['INITIAL'].data"></app-proposed-summary-view>
        </div>

        <!-- viewComments-->
        <div *ngIf="showViews.viewComments">
          <div class="content col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="card padded">

                  <div class="card-header info-header">
                      <div class="row">
                          <div class="ms-3">
                              <h6 class="fw-bold">Comments</h6>
                          </div>
                      </div>
                  </div>

                  <div class="card-body">
                    <div class="col">
                      <div class="row">

                        <!-- PIA Comments -->
                        <div class="row table-responsive">
                          <table class="table table-striped table-bordered">
                            <thead class="info-subheader">
                                <tr></tr>
                            </thead>

                            <tbody class="info-subheader">

                              <tr>
                                <th><h6>PRELIMINARY IMPACT ASSESSMENT (PIA)</h6></th>
                              </tr>
                              <tr>
                                <td>{{ reg_comment_pia }}
                                  <span *ngIf="reg_comment_pia.length === 0">none</span>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                        <!-- Tags Comments -->
                        <div class="row table-responsive">
                          <table class="table table-striped table-bordered">
                            <thead class="info-subheader">
                                <tr></tr>
                            </thead>

                            <tbody class="info-subheader">
                              
                              <tr>
                                <th><h6>REVIEW OF TAGS</h6></th>
                              </tr>
                              <tr>
                                <td>{{ reg_comment_details }}
                                  <span *ngIf="reg_comment_details.length === 0">none</span>
                                  </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                        <!-- Text Reg Comments 3 -->
                        <div class="row table-responsive">
                          <table class="table table-striped table-bordered">
                            <thead class="info-subheader">
                                <tr></tr>
                            </thead>

                            <tbody class="info-subheader">

                              <tr>
                                <th><h6>TEXT OF THE REGULATION</h6></th>
                              </tr>
                              <tr>
                                <td>{{ reg_comment_regtext }}
                                  <span *ngIf="reg_comment_regtext.length === 0">none</span>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                        <!-- Agency Self-Assessment Comments 4 -->
                        <div class="row table-responsive">
                          <table class="table table-striped table-bordered">
                            <thead class="info-subheader">
                                <tr></tr>
                            </thead>

                            <tbody class="info-subheader">

                              <tr>
                                <th><h6>AGENCY SELF-ASSESSMENT</h6></th>
                              </tr>
                              <tr>
                                <td>{{ reg_comment_self_assessment }}
                                  <span *ngIf="reg_comment_self_assessment.length === 0">none</span>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>  

        <!-- pia -->
        <div *ngIf="showViews.piaView">
          <div class="content col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="card padded">

                  <div class="card-header info-header">
                      <div class="row">
                          <div class="ms-3">
                              <h6 class="fw-bold">PRELIMINARY IMPACT ASSESSMENT (PIA) 
                                <!-- <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on PIA" (click)="goToComments(hasDris ? 'DRIS' : hasCris ? 'CRIS' : 'INITIAL','regpia')">
                                  <mat-icon>edit</mat-icon>
                                </button> -->
                              </h6>
                          </div>
                      </div>
                  </div>

                  <app-worklist-regulations-pia-view *ngIf="regulationsData['INITIAL'].data" [id]="regulationsData['INITIAL'].id" [currentRegulation]="regulationsData['INITIAL'].data"></app-worklist-regulations-pia-view>
                  <div class="d-flex justify-content-center" style="padding:1em;" *ngIf="current_regulation.id == null || current_regulation.id == undefined">
                      <div class="row" style="padding:0.5em;">
                          <label class="form-input-header fw-bold" for="feedback_remark">
                            <h6><b>No PIA Data</b></h6>
                          </label>
                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>  

        <!-- PIS Assessment -->
        <div *ngIf="showViews.pisAssessmentView">
          <app-pis-assessment-view
            *ngIf="current_regulation && pis_assessment && flagged_sections"
            [id]="current_regulation.id"
            [pis_assessment]="pis_assessment"
            [flagged_sections]="flagged_sections"
            [flaggedSectionIndexes]="flaggedSectionIndexes"
            [actionButtonAllowed]="actionButtonAllowed"
          ></app-pis-assessment-view>
          <div
            class="d-flex justify-content-center"
            style="padding: 1em"
            *ngIf="!current_regulation"
          >
            <!-- *ngIf="current_regulation.id == null || current_regulation.id == undefined" -->
            <div class="row" style="padding: 0.5em">
              <label class="form-input-header fw-bold" for="feedback_remark"
                ><h6><b>No PIA Data</b></h6></label
              >
            </div>
          </div>
        </div>

        <!-- PIS Assessment Summary -->
        <div *ngIf="showViews.pisAssessmentSummaryView">
          <app-pis-assessment-summary-view
            *ngIf="pis_assessment"
            [pis_assessment]="pis_assessment"
            [flagged_sections]="flagged_sections">
          </app-pis-assessment-summary-view>
          <!-- [upload_folders]="pis_assessment" -->
        </div>

        <!-- Comments -->
        <div *ngIf="showViews.crisCommentsView" >
          <h5 class="info-header" style="background-color: orange">
            Comments on Regulation Details
          </h5>
          <p>
            {{ regulationsData['INITIAL'].data.reg_comment_details }}
          </p>

          <h5 class="info-header" style="background-color: orange">
            Comments on Regulation Text
          </h5>
          <p>
            {{ regulationsData['INITIAL'].data.reg_comment_regtext }}
          </p>

          <h5 class="info-header" style="background-color: orange">
            Comments on PIA
          </h5>
          <p>
            {{ regulationsData['INITIAL'].data.reg_comment_pia }}
          </p>
          
          <h5 class="info-header" style="background-color: orange">
            Comments on Agency Self-Assessment
          </h5>
          <p>
            {{ regulationsData['INITIAL'].data.reg_comment_self_assessment }}
          </p>
        </div>

        <!-- Cris Text Regulations -->
        <div *ngIf="showViews.crisTextRegView">
          <app-proposed-text-regulation-view codeBase="CRIS" [textRegulation]="this.regulationsData['CRIS'].data.regTextData" [paramFromInboxList]="paramFromInboxList"  [commentButtonsEnabled]="commentButtonsEnabled"></app-proposed-text-regulation-view>
        </div>

        <!-- Cris Regulation details -->
        <div *ngIf="showViews.crisView">
          <!-- <app-regulations-cris-view [crisData]="crisData" [consultationsData]="reg_completed_consultations"></app-regulations-cris-view> -->
          <!-- <app-proposed-regulations-details-view [objectData]="this.regulationsData['CRIS'].crisData2" baseCode="CRIS"></app-proposed-regulations-details-view> -->
          <app-proposed-regulations-details-view [objectData]="this.regulationsData['CRIS'].data" baseCode="CRIS"></app-proposed-regulations-details-view>
        </div>

        <!-- Cris Proposal Summary -->
        <div *ngIf="showViews.crisSummaryView">
          <app-proposed-summary-view baseCode="CRIS" [objectData]="regulationsData['CRIS'].data"></app-proposed-summary-view>
        </div>

        <!-- Cris Consulations -->
        <div *ngIf="showViews.crisConsultations">
          <app-proposed-cris-consultations-view [objectData]="regulationsData['CRIS'].data" baseCode="CRIS"></app-proposed-cris-consultations-view>
        </div>

        <!-- Cris Uploads -->
        <div *ngIf="showViews.crisUploadsView">
          <app-uploaded-documents-view [objectData]="this.regulationsData['CRIS'].uploads" baseCode="CRIS"></app-uploaded-documents-view>
        </div>

        <!-- Dris Text Regulations -->
        <div *ngIf="showViews.drisTextRegView">
          <app-proposed-text-regulation-view codeBase="DRIS" [textRegulation]="this.regulationsData['DRIS'].data.regTextData" [paramFromInboxList]="paramFromInboxList"  [commentButtonsEnabled]="commentButtonsEnabled"></app-proposed-text-regulation-view>
        </div>
        
        <!-- Dris Regulation details -->
        <div *ngIf="showViews.drisPhase2View">
          <app-proposed-regulations-details-view [objectData]="regulationsData['DRIS'].data" baseCode="DRIS"></app-proposed-regulations-details-view>
        </div>

        <!-- Dris Proposal Summary -->
        <div *ngIf="showViews.drisSummaryView">
          <app-proposed-summary-view baseCode="DRIS" [objectData]="regulationsData['DRIS'].data"></app-proposed-summary-view>
        </div>

        <!-- Dris uploads -->
        <div *ngIf="showViews.drisUploadsView">
          <app-uploaded-documents-view [objectData]="this.regulationsData['DRIS'].uploads" baseCode="DRIS"></app-uploaded-documents-view>
        </div>

        <!-- Ris Assessment -->
        <div *ngIf="showViews.risAssessmentView">

          <div class="content col-md-12">
            <div class="container-fluid">
              <div class="row">
                <div class="card padded">

                  <div class="card-header info-header">
                      <div class="row">
                          <div class="ms-3">
                              <h6 class="fw-bold">Summary of RIS Assessment</h6>
                          </div>
                      </div>
                  </div>

                  <div class="card-body">
                    <div class="row">

                      <table mat-table [dataSource]="ris_scoring_test.controls"  class="mat-elevation-z4 demo-table w-100">
                        <ng-container matColumnDef="section">
                          <th style="width:35%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                            <h6>Section</h6>
                          </th>
                          <td mat-cell *matCellDef="let element" [class.theme-default]="element.value.overall_score_flag">
                            <ng-container *ngIf="element.value.url; else noURL">
                              <a [href]="element.value.url" target="_blank">
                                {{ element.value.section }}
                              </a>
                            </ng-container>
                            <ng-template #noURL>
                              {{ element.value.section }}
                            </ng-template>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="rating">
                          <th style="width:35%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                            <h6>Rating</h6>
                          </th>
                          <td mat-cell *matCellDef="let element; let ratingIndex = index">
                            <div class="d-flex justify-content-center">
                              <ng-container *ngIf="actionButtonAllowed && ris_allow_action; else noActionAllowed">
                                <ng-container *ngIf="element.value.optionsType == 'assesmentType1Options'"  [formGroup]="element.controls.score">
                                    <select class="form-select" [id]="element.value.control" [name]="element.value.control" (change)="universalSelectNumber(ratingIndex,getElement(element.value.control))">
                                      <option readonly disabled selected value="0">Choose Rating</option>
                                      <option *ngFor="let item of getOption(element.value.optionsType)" [selected]="getOptionDefault('assesmentType1Options',item.value,element.value.score.rating)" [value]="item.value" class="option-item"  [ngClass]="item.color">{{item.title}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="element.value.optionsType == 'assesmentOverallRatingOptions'">
                                  <span style="font-weight:bold" [ngClass]="getColor(element.value.computedRatingText)">{{element.value.rating_text}}</span>
                                </ng-container>
                                <ng-container *ngIf="element.value.optionsType == 'assesmentType2Options'">
                                  <select class="form-select" [id]="element.value.control" [name]="element.value.control" (change)="universalSelectNumber(ratingIndex,getElement(element.value.control))">
                                    <option readonly disabled selected value="0">Choose Rating</option>
                                    <option *ngFor="let item of getOption(element.value.optionsType)" [selected]="getOptionDefault('assesmentType2Options',item.title,element.value.score.rating)" [value]="item.title" class="option-item"  [ngClass]="item.color">{{item.title}}</option>
                                  </select>
                                </ng-container>
                                <ng-container *ngIf="element.value.optionsType == 'assesmentType3Options'">
                                  <select class="form-select" [id]="element.value.control" [name]="element.value.control" (change)="universalSelectNumber(ratingIndex,getElement(element.value.control))">
                                    <option readonly disabled selected value="0">Choose Rating</option>
                                    <option *ngFor="let item of getOption(element.value.optionsType)" [selected]="getOptionDefault('assesmentType3Options',item.title,element.value.score.rating)" [value]="item.title" class="option-item"  [ngClass]="item.color">{{item.title}}</option>
                                  </select>
                                </ng-container>
                              </ng-container>
                              <ng-template #noActionAllowed>
                                <span [ngClass]="getColor2(element.value)" style="font-weight:bold">{{getSelectionItem(element.value)}}</span>
                              </ng-template>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="remarks">
                          <th style="width:30%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                            <h6>Remarks for Agency</h6>
                          </th>
                          <td mat-cell *matCellDef="let element" >
                            <!-- <div class="d-flex justify-content-center"> -->

                              <ng-container *ngIf="actionButtonAllowed && ris_allow_action; else noRemarksActionAllowed">
                                <button class="btn btn-custom" (click)="checkRemarks(element)"
                                  [ngClass]="(element.value.score.remarks != '' && element.value.score.remarks != null && element.value.score.remarks != undefined) ? 'btn-outline-danger' : 'btn-outline-primary'">
                                  ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})
                                  <i class="far fa-comments"></i> 
                                  <span *ngIf="(element.value.score.remarks != '' && element.value.score.remarks != null && element.value.score.remarks != undefined)">
                                    New
                                  </span>
                                  Remarks
                                  <span *ngIf="(element.value.score.remarks != '' && element.value.score.remarks != null && element.value.score.remarks != undefined)">
                                    Added
                                  </span>
                                </button>
                              </ng-container>
                              <ng-template #noRemarksActionAllowed>
                                <button class="btn btn-outline-primary btn-custom" (click)="checkRemarks(element)">
                                  ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})
                                  <i class="far fa-comments"></i> Remarks
                                </button>
                              </ng-template>

                              <!-- <ng-container *ngIf="actionButtonAllowed && ris_allow_action; else noRemarksActionAllowed">
                                <div *ngIf="actionButtonAllowed && ris_allow_action" [formGroup]="element.controls.score" class="input-group">
                                  <span class="input-group-addon btn btn-outline-primary" (click)="checkRemarks(element)">
                                    <i class="far fa-comments"></i> ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})
                                  </span>
                                                            <div class="input-group-prepend">
                                                              <button class="btn btn-outline-primary" type="button" (click)="checkRemarks(element)"><i class="far fa-comments"></i> ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})</button>
                                                            </div>
                                  <textarea style="max-height: 6em;" oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text" class="form-control" formControlName="remarks" placeholder="No Remarks. . . "></textarea>
                                </div>
                              </ng-container>
                              <ng-template #noRemarksActionAllowed>
                                <button class="btn btn-outline-primary" (click)="checkRemarks(element)"><i class="far fa-comments"></i> Remarks ({{element.value.score && element.value.score.previous_remarks ? element.value.score.previous_remarks.length : '0'}})</button>
                              </ng-template> -->
                            <!-- </div> -->
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                      </table>

                    </div>
                  </div>
                  
                  <div class="row my-4" *ngIf="ris_final_assessment && show_for_reconsideration">
                    <div class="col ms-5 m-5">
                      <span>
                        <input type="checkbox"
                          class="form-check-input"
                          id="for_reconsideration"
                          [(ngModel)]="risAssessmentForReconsideration">
                        <label class="form-check-label" for="for_reconsideration"
                          >This RIS is For Reconsideration.</label>
                      </span>
                    </div>
                  </div>
                  
                  <!-- Uploads go here -->
                  <!-- Move this to Proposed RIS RWL 20220207 -->
                  <div class="card-body">
                    <div class="row my-2">
                      <div class="col">

                        <div class="card-header info-header">
                          <div class="row">
                              <div class="ms-3">
                                  <h6 class="fw-bold">Upload Assessment Documents</h6>
                              </div>
                          </div>
                        </div>
                        <div class="form-group p-3" *ngIf="!ris_final_assessment">
                          <p>Please upload the RIS Assessment in document format. Kindly assure that all sections have been fully accomplished.</p>
                        </div>
    
                        <!-- upload files goes here -->
                        <div class="form-group" *ngIf="ris_documents_test.length == 0">
                          <div class="row">
                            <div class="col-4">
                              <b> RIS Assessment Document </b>
                            </div>
                            <div class="col-8">
                              <!-- <input type="file" (change)="saveFileInformation('RIS Assessment Document',$event)"
                                class="form-control"
                                ng-required="true"
                                style="display: none"
                                #ris_assessment_document_file_input
                              />
                              <div class="file-input p-3">
                                <label>Drop files here or</label>
                                <button (click)="ris_assessment_document_file_input.click()" class="btn btn-light">
                                  Browse
                                </button>
                              </div>
                              {{ display_ris_assessment_document_file_name }} -->
                              
                              <div class="form-group file-uploader-wrapper">
                                <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'RIS Assessment Document')">
                                  <button type="button" class="btn btn-link dropzone-btn" (click)="risAssessment.click()">
                                    <div *ngIf="display_ris_assessment_document_file_name">
                                      {{ display_ris_assessment_document_file_name }}
                                    </div>
                                    <div *ngFor="let formValid of checkRisDocumentsTest.all">
                                      <div class="error" *ngIf="hasError('ris_documents_new','risAssessment', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                                        {{formValid.message}}  
                                      </div>
                                    </div>
                                    <span><strong>Drop file here to upload or click to choose file</strong></span>
                                    <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                                  </button>
                                  <input #risAssessment id="risAssessment"  type="file" multiple (change)="buttonUpload($event,'RIS Assessment Document')"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="form-helper text-danger"
                            *ngIf="showRisAssessmentDocumentFileUploadErrors">
                            Invalid file type
                          </div> -->
              
                          <div class="row">
                            <div class="col-4">
                              <b> Cover Letter </b>
                            </div>
                            <div class="col-8">
                              <!-- <input type="file" (change)="saveFileInformation('Cover Letter', $event)" class="form-control"
                                ng-required="true" style="display: none" #cover_letter_file_input/>
                              <div class="file-input p-3">
                                <label>Drop files here or</label>
                                <button (click)="cover_letter_file_input.click()" class="btn btn-light">
                                  Browse
                                </button>
                              </div>
                              {{ display_cover_letter_file_name }} -->
                              <div class="form-group file-uploader-wrapper">
                                <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'Cover Letter')">
                                  <button type="button" class="btn btn-link dropzone-btn" (click)="coverLetter.click()">
                                    <div *ngIf="display_cover_letter_file_name">
                                      {{ display_cover_letter_file_name }}
                                    </div>
                                    <div *ngFor="let formValid of checkRisDocumentsTest.all">
                                      <div class="error" *ngIf="hasError('ris_documents_new','coverLetter', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                                        {{formValid.message}}  
                                      </div>
                                    </div>
                                    <span><strong>Drop file here to upload or click to choose file</strong></span>
                                    <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                                  </button>
                                  <input #coverLetter id="coverLetter"  type="file" multiple (change)="buttonUpload($event,'Cover Letter')"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="form-helper text-danger" *ngIf="showCoverLetterFileUploadErrors">
                            Invalid file type
                          </div> -->
                        </div>
              
                        <div class="row" *ngIf="ris_documents_test.length > 0">
                          <table mat-table [dataSource]="ris_documents_test.controls" class="mat-elevation-z4 demo-table">
                            <ng-container matColumnDef="docname">
                              <th style="width:30%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h6>Assessment Documents</h6>
                              </th>
                              <td mat-cell *matCellDef="let element">
                                <a [href]="element.value.url" class="" target="_blank">
                                  {{ element.value.doc_name }}
                                </a>
                              </td>
                            </ng-container>
              
                            <ng-container matColumnDef="action">
                              <th style="width:40%;" class="theme-default" mat-header-cell *matHeaderCellDef [hidden]="ris_final_assessment">
                                <h6>Action</h6>
                              </th>
                              <td mat-cell *matCellDef="let element; let $i = index" [hidden]="ris_final_assessment">
                                <!-- <input type="file" (change)="saveFileInformation(element.value.doc_name, $event)" class="form-control"
                                  ng-required="true" style="display: none" #document_file_input/>
                                <button *ngIf="ris_allow_action && actionButtonAllowed" class="btn btn-dark" (click)="document_file_input.click()">
                                  Replace
                                </button>
                                <div *ngIf="ris_allow_action && actionButtonAllowed && element.value.new_file">
                                  {{ element.value.new_file }}
                                </div> -->
                                <div class="form-group file-uploader-wrapper relative" *ngIf="ris_allow_action && actionButtonAllowed">
                                  <span class="absolute upper-right"><a [routerLink]="[]" (click)="resetNewUpload(element.value.doc_code)"><i class="fas fa-redo" *ngIf="ris_allow_action && actionButtonAllowed"></i></a></span>
                                  <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, element.value.doc_name)">
                                    <button type="button" class="btn btn-link dropzone-btn" (click)="element.value.doc_code.click()">
                                      <div *ngIf="ris_allow_action && actionButtonAllowed && element.value.new_file">
                                        {{ element.value.new_file }}
                                      </div>
                                      <div *ngFor="let formValid of checkRisDocumentsTest.all">
                                        <div class="error" *ngIf="hasError('ris_documents_test',element.value.doc_code, formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                                          {{formValid.message}}  
                                        </div>
                                      </div>
                                      <span><strong>Drop file here or click to choose file to replace</strong></span>
                                      <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                                    </button>
                                    <input #{{element.value.doc_code}} [id]="element.value.doc_code"  type="file" multiple (change)="buttonUpload($event,element.value.doc_name)"/>
                                  </div>
                                </div>
                              </td>
                            </ng-container>
              
                            <ng-container matColumnDef="remarks">
                              <th style="width:25%;" class="theme-default" mat-header-cell *matHeaderCellDef>
                                <h6>Remarks</h6>
                              </th>
                              <td mat-cell *matCellDef="let element">
                                <div class="d-flex justify-content-center" >
                                  <ng-container *ngIf="actionButtonAllowed && ris_allow_action; else noDocumentRemarksActionAllowed">
                                    <button class="btn btn-custom" (click)="checkDocumentRemarks(element)"
                                      [ngClass]="(element.value.remarksDetails.remarks != '' && element.value.remarksDetails.remarks != null && element.value.remarksDetails.remarks != undefined) ? 'btn-outline-danger' : 'btn-outline-primary'">
                                      ({{element.value.remarksDetails && element.value.remarksDetails.previous_remarks ? element.value.remarksDetails.previous_remarks.length : '0'}})
                                      <i class="far fa-comments"></i> 
                                      <span *ngIf="(element.value.remarksDetails.remarks != '' && element.value.remarksDetails.remarks != null && element.value.remarksDetails.remarks != undefined)">
                                        New
                                      </span>
                                      Remarks
                                      <span *ngIf="(element.value.remarksDetails.remarks != '' && element.value.remarksDetails.remarks != null && element.value.remarksDetails.remarks != undefined)">
                                        Added
                                      </span>
                                    </button>
                                    <!-- <div *ngIf="actionButtonAllowed && ris_allow_action" [formGroup]="element.controls.remarksDetails" class="input-group">
                                      <span class="input-group-addon btn btn-outline-primary" (click)="checkDocumentRemarks(element)">
                                        <i class="far fa-comments"></i> ({{element.value.remarksDetails.previous_remarks ? element.value.remarksDetails.previous_remarks.length : '0'}})
                                      </span>
                                      <textarea style="max-height: 6em;" oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text" class="form-control" formControlName="remarks" placeholder="No Remarks. . . "></textarea>
                                    </div> -->
                                  </ng-container>
                                  <ng-template #noDocumentRemarksActionAllowed>
                                    <button class="btn btn-outline-primary btn-custom" (click)="checkDocumentRemarks(element)">({{element.value.remarksDetails.previous_remarks ? element.value.remarksDetails.previous_remarks.length : 0}}) <i class="far fa-comments"></i>Remarks</button>
                                  </ng-template>
                                </div>
                              </td>
                            </ng-container>
              
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsRisDocs"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsRisDocs"></tr>
                          </table>
                        </div>
                        <!-- <div class="form-group" *ngIf="!ris_final_assessment">
                          <label
                            >Valid file types include: doc, docx, pdf. Each file cannot exceed
                            10MB.</label
                          >
                        </div> -->

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        
        </div>

      </div>
    </div>


    <!-- Footer Buttons -->
    <div class="row g-3" *ngIf="actionButtonAllowed">
      <div class="d-flex flex-row justify-content-evenly">
        <div class="card-footer text-center col-md-3 mt-2">
          <button (click)="showOnInboxViewport()" class="m-2 btn btn-dark float-start">Back to Inbox</button>
        </div>

        <div class="card-footer text-center col-md-9 mt-2">
          <!-- for revision -->
          <!-- <div *ngIf="reg_status !== enum_regulation_status.DRAFT" class="row"> -->
          <div *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION 
              || reg_status === enum_regulation_status.FOR_APPROVAL  
              || reg_status === enum_regulation_status.RESUBMITTED">
            <button (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" class="m-2 btn btn-primary float-end">
              <i class="fas fa-undo-alt me-2"></i>Return For Revision
            </button>
          </div>
      
          <!-- submit draft -->
          <div *ngIf="reg_status === enum_regulation_status.DRAFT">
            <button [disabled]="!true" (click)="submit(enum_action_flag.SUBMIT_DRAFT)" class="m-2 btn btn-warning float-end">
              <i class="fas fa-share-square me-2"></i>Submit
            </button>
          </div>
      
          <!-- for verification -->
          <div *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION">
            <button [disabled]="!true" (click)="submit(enum_action_flag.SUBMIT_TO_AGENCY_APPR)" class="m-2 btn btn-warning float-end">
              <i class="fas fa-share-square me-2"></i>Submit For Approval
            </button>
          </div>
      
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_VERIFICATION || reg_status === enum_regulation_status.DRIS_FOR_REVISION">
            <button [disabled]="!true" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_AGENCY_APPR)" class="m-2 btn btn-warning float-end">
              <i class="fas fa-share-square me-2"></i>Submit Decision For Approval
            </button>
          </div>
      
          <!-- resubmit for approval agency/arta -->
          <!-- Resubmissions must now go through verifier first -->
          <div *ngIf="reg_status === enum_regulation_status.FOR_REVISION || reg_status === enum_regulation_status.FOR_MODIFICATION" >
            <button class="m-2 btn btn-success float-end" (click)="submit(enum_action_flag.SUBMIT_DRAFT)">
              <i class="fas fa-undo-alt me-2"></i>Re-Submit For Verification
            </button>
          </div>
      
          <!-- approve / submit to arta -->
          <div *ngIf="reg_status === enum_regulation_status.FOR_APPROVAL || reg_status === enum_regulation_status.RESUBMITTED">
            <button class="m-2 btn btn-warning float-end" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA)">
              <i class="fas fa-thumbs-up me-2"></i>Approve
            </button>
          </div>
      
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_APPROVAL">
            <button [disabled]="!true" (click)="submit(enum_action_flag.DRIS_SUBMIT_TO_ARTA)" class="m-2 btn btn-warning float-end">
              <i class="fas fa-thumbs-up me-2"></i>Approve Decision
            </button>
          </div>
      
          <!-- posting -->
          <!-- <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_POSTING">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit(enum_action_flag.POST_REGULATION)">
              POST REGULATION
            </button>
          </div>
          -->
      
          <!-- decs for approval arta/bro dc/dir -->
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC">
            <button class="m-2 btn btn-warning float-end" (click)="submit((reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW)
                ? enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DC : enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DIR)">
              <i class="fas fa-thumbs-up me-2"></i>Decision For Approval (Next Level)
            </button>
          </div>
      
          <!-- for post regulatio for arta dir -->
          <div  *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
            <button class="m-2 btn btn-info float-end" (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR)
                ? enum_action_flag.SUBMIT_TO_ARTA_DDG : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DDG)">
              <i class="fas fa-share-square me-2"></i>Escalate to DDG
            </button>
          </div>
      
          <!-- <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
              || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
            <button class="btn btn-primary m-1 w-100"
            (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              For Posting
            </button>
          </div> -->
      
          <!-- for post regulation for arta ddg -->
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG">
            <button class="m-2 btn btn-info float-end" (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG)
                  ? enum_action_flag.SUBMIT_TO_ARTA_DG : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DG)">
              <i class="fas fa-share-square me-2"></i>Escalate to DG
            </button>
          </div>
      
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG ||
              reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
            <button class="m-2 btn btn-primary float-end" (click)="submit(enum_action_flag.DRIS_SUBMIT_FOR_ASSESSMENT)">
              <i class="fas fa-undo-alt me-2"></i>Return to PO for Assessment
            </button>
          </div>

          <!-- for post regulation  -->
          <!-- <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              For Posting
            </button>
          </div> -->

          <!-- PO final assessment before returning to Agency -->
          <div *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ASSESMENT">
            <button class="btn btn-success float-end" (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              <i class="fas fa-check-circle me-2"></i>For Posting
            </button>
          </div>

          <!-- ARTA-BRO non-DRIS action buttons moved to PIS Assessment Summary -->
          <div *ngIf="actionButtonAllowed && pisAssessmentIsValid">
            <div class="col-md-12">
              <!-- ria -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG">
                <button (click)="submit(enum_action_flag.MARK_FOR_RIA)" class="m-2 btn btn-info float-end">
                  <i class="fas fa-tasks me-2"></i>For RIA
                </button>
              </div>

              <!-- for approval arta/bro dc/dir -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW || reg_status === enum_regulation_status.FOR_REAPPROVAL || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC">
                <button class="m-2 btn btn-warning float-end"
                    (click)="submit(reg_status === enum_regulation_status.FOR_ARTA_REVIEW || reg_status === enum_regulation_status.FOR_REAPPROVAL
                        ? enum_action_flag.SUBMIT_TO_ARTA_BRO_DC : enum_action_flag.SUBMIT_TO_ARTA_BRO_DIR)">
                  <i class="fas fa-share-square me-2"></i>For Approval (Next Level)
                </button>
              </div>
    
              <!-- for post regulatio for arta dir -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR">
                <button class="m-2 btn btn-info float-end" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA_DDG)">
                    <i class="fas fa-share-square me-2"></i>Escalate to DDG
                </button>
              </div>
    
              <!-- for post regulation for arta ddg -->
              <div *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG">
                <button class="m-2 btn btn-info float-end" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA_DG)">
                  <i class="fas fa-share-square me-2"></i>Escalate to DG
                </button>
              </div>
    
              <div *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG">
                <button class="m-2 btn btn-success float-end" (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
                  <i class="fas fa-check-circle me-2"></i>For Posting
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

  
  <ng-template #showRestricted>
    <h1>This is a Restricted Page</h1>
  </ng-template>
