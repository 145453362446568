<app-navbar [currentPage]="'Management'"></app-navbar>

<!--Page Title -->
<mat-toolbar class="auxiliary-nav">
  <span class="ms-3">
    <span class="active fw-bold" style="color:#595959;font-size:12px" aria-current="page">UPLOAD EXISTING REGULATION</span>
  </span>
  <span class="auxiliary-nav-spacer"></span>
  <div class="auxiliary-nav-button-row">
      <a mat-icon-button routerLink="/pbris/regulatory-management">
        <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulatory Management">arrow_back_ios</mat-icon>
      </a>
  </div>
</mat-toolbar>

<div class="content padded">
  <ng-container *ngIf="checkPageLoader(); else notYetLoaded">
    <div class="container-fluid">

      <div *ngIf="!commentMode" class="card-text mt-2 p-3">
        <p>Pursuant to Section 5 of RA 11032, all proposed regulations of government agencies under Section 3 of the same Act shall undergo regulatory 
          impact assessment to establish if the proposed regulation does not add undue regulatory burden and cost to these agencies and the applicants or requesting parties.
        </p>
        <p>To facilitate and implement the RIA process, rules on exemptions and proportionality, the rulemaking process will follow four 
          stages and one optional procedure.
        </p>
      </div>

      <div *ngIf="commentMode" class="alert alert-danger my-4" role="alert">
        <i class="fas fa-exclamation-circle me-2"></i>You can add your comment below this form.
      </div>

      <div class="card mb-4" *ngIf="isPhase1" style="border-top:1px solid #D9D9D9;">
        <div class="card-body form-container">
          <!-- <div class="card-header card-header-custom">
            <div class="row">
              <div class="ms-3">
                <h6 class="fw-bold">UPLOAD EXISTING REGULATION</h6>
              </div>
            </div>
          </div> -->

  
          <!-- stepper -->
          <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper" role="tab" aria-selected="true">

            <!-- regulation details -->
            <mat-step [stepControl]="phase1form">
              <form [formGroup]="phase1form"><!-- *ngIf="currentFormPage == 'regulationtext'" -->
                <ng-template matStepLabel>REGULATION DETAILS</ng-template>

                <div class="row mt-4">
                  <div>
                    <p class="float-start fw-bold">
                      Complete all fields below. Asterisks (<span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>) indicate required fields.
                    </p>
                  </div>
                </div>

                <!-- title -->
                <div class="row g-3 mt-3">
                  <div class="form-group col-md-6">
                    <label class="form-input-header fw-bold" for="title">Regulation Title <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    <textarea class="form-control" formControlName="title"></textarea>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'title')">Input Required</mat-hint>
                    <ng-container *ngFor="let item of checkPhaseForm.title">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','title',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                  </div>

                  <div class="form-group col-md-6">
                    <label class="form-input-header" for="subject">Regulation Short Title <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    <textarea class="form-control" formControlName="subject"></textarea>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'subject')">Input Required</mat-hint>
                    <ng-container *ngFor="let item of checkPhaseForm.subject">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','subject',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                  </div>
                </div>
               
                <!-- reg intrument / issuing / reg number -->
                <div class="form-inline">
                  <div class="row g-3 mt-3">
                    <div class="form-inline col-md-4">
                      <label class="form-input-header" for="reg_instrument">Regulation Instrument <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <select class="form-control" formControlName="reg_instrument" (change)="instrumentOnChange($event)">
                        <option value="" disabled selected>--</option>
                        <option *ngFor="let x of instruments" [value]="x['id']">
                          {{ x["name"] }}
                        </option>
                      </select>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_instrument')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.reg_instrument">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_instrument',item.type)">* {{item.message}}</mat-error>
                      </ng-container>
                    </div>

                    <div class="form-inline col-md-4">
                      <label class="form-input-header" for="subject">Issuing Agency <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <mat-select *ngIf="!multipleIssuingAgency" class="form-control" formControlName="reg_agency">
                        <mat-option *ngFor="let x of agencies" [value]="x['id']">
                          {{ x["name"] }}
                        </mat-option>
                      </mat-select>
                      <mat-select *ngIf="multipleIssuingAgency" class="form-control" formControlName="reg_agency" multiple>
                        <mat-option *ngFor="let x of agencies" [value]="x['id']">
                          {{ x["name"] }}
                        </mat-option>
                      </mat-select>
                      <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(phase1form, 'reg_agency')">Input Required</mat-hint> -->
                      <ng-container *ngFor="let item of checkPhaseForm.reg_agency">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_agency',item.type)">* {{item.message}}</mat-error>
                      </ng-container> 
                    </div>

                    <div class="form-inline col-md-4">
                      <label class="form-input-header" for="subject">Regulation Number</label>
                      <input type="text" class="form-control" formControlName="reg_number" />
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_number')">Input Optional</mat-hint>
                    </div>
                  </div>
      
                  <!-- significance -->
                  <div class="row g-3 mt-3">
                    <div class="form-inline">
                      <label class="form-input-header">Significance</label>
                      <div>
                        <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(phase1form, 'reg_significance')">Input Required</mat-hint> -->
                        <!-- <ng-container *ngFor="let item of checkPhaseForm.reg_significance">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_significance',item.type)">* {{item.message}}</mat-error>
                        </ng-container>  -->
                      </div>
                      
                      <mat-radio-group formControlName="reg_significance" class="example-radio-group" [disabled]=true>
                        <div class="form-group row">
                          <div class="col-md-2">
                            <mat-radio-button checked value="Major" class="example-radio-button fw-bold">Major</mat-radio-button>
                          </div>
                          <p class="col-md-10">
                            Regulations that meet the ARTA’s Proportionality Rule will
                            require a full regulatory impact assessment and must undergo
                            ARTA’s review.
                          </p>
                        </div>
        
                        <div class="form-group row">
                          <div class="col-md-2">
                            <mat-radio-button value="Minor" class="example-radio-button fw-bold">Minor</mat-radio-button>
                          </div>
                          <p class="col-md-10">
                            Regulations that have no expected significant socio-economic
                            impacts. They do not need to undergo full regulatory impact
                            assessment.
                          </p>
                        </div>
        
                        <div class="form-group row">
                          <div class="col-md-2">
                            <mat-radio-button value="Exempted" class="example-radio-button fw-bold">Exempted</mat-radio-button>
                          </div>
                          <p class="col-md-10">
                            Regulations that are identified to be outside the scope of
                            the RIA Process, or exempted due to emergency reasons.
                          </p>
                        </div>

                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <!-- main tag -->
                <div class="card-body mt-5" style="background-color:#FAFAFA;">
                  <nav aria-label="breadcrumb" class="breadcrumb-custom-2">
                    <ol class="breadcrumb p-2">
                      <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page"><i class="fas fa-tag me-3"></i>MAIN TAG</li>
                    </ol>
                  </nav>

                  <div class="row g-3 mt-3 form-group">
                    <div class="col-md-2">
                      <label class="form-input-header">Classification <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    </div>
                    <div class="form-check col-md-10">
                      <mat-radio-group formControlName="reg_classification" class="example-radio-group d-flex justify-content-start flex-row w-100 m-0">
                        <mat-radio-button checked value="Business" class="example-radio-button my-0 mx-4">Business</mat-radio-button>
                        <mat-radio-button value="Non-Business" class="example-radio-button my-0 mx-4">Non-Business</mat-radio-button>
                        <mat-radio-button value="Government" class="example-radio-button my-0 mx-4" [disabled]=true>Government</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <!-- <div>
                      <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(phase1form, 'reg_classification')">Input Required</mat-hint>
                    </div> -->
                  </div>
      
                  <div *ngIf="this.phase1form.get('reg_classification')?.value == 'Business'">
                    <div class="row g-3 mt-3">
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Sector <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_sector" (change)="sectorOnChange($event)">
                          <option value="" disabled selected></option>
                          <option *ngFor="let x of sectors" [value]="x['id']">
                            {{ x["name"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_sector')">Input Required</mat-hint>
                        <ng-container *ngFor="let item of checkPhaseForm.reg_sector">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_sector',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
        
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Division <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_division">
                          <option value="" disabled selected>--</option>
                          <option *ngFor="let x of divisions_select" [value]="x['id']">
                            {{ x["name"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_division')">Input Required</mat-hint>
                      </div>
                    </div>
        
                    <div class="row g-3 mt-3">
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Stage of Business <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_business" (change)="sobOnChange($event)">
                          <option value="" disabled selected>--</option>
                          <option *ngFor="let x of sobs" [value]="x['id']">
                            {{ x["name"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_business')">Input Required</mat-hint>
                        <ng-container *ngFor="let item of checkPhaseForm.reg_business">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_business',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
        
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Case Use <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_case">
                          <option value="" disabled selected>--</option>
                          <option *ngFor="let x of cases_select" [value]="x['id']">
                            {{ x["name"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_case')">Input Required</mat-hint>
                        <ng-container *ngFor="let item of checkPhaseForm.reg_case">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_case',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="this.phase1form.get('reg_classification')?.value == 'Non-Business'">
                    <div class="row g-3 mt-3">
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Stage of Life <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_stageoflife" name="reg_stageoflife" id="reg_stageoflife" (change)="solOnChange($event)">
                          <option value="" disabled selected>--</option>
                          <option *ngFor="let x of sols" [value]="x['id']">
                            {{ x["stage"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_stageoflife')">Input Required</mat-hint>
                        <ng-container *ngFor="let item of checkPhaseForm.reg_stageoflife">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_stageoflife',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
        
                      <div class="form-inline col-md-6">
                        <label class="form-input-header" for="subject">Life Event <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                        <select class="form-select" formControlName="reg_lifeevent">
                          <option value="" disabled selected>--</option>
                          <option *ngFor="let x of lifeevents_select" [value]="x['id']">
                            {{ x["event"] }}
                          </option>
                        </select>
                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_lifeevent')">Input Required</mat-hint>
                        <ng-container *ngFor="let item of checkPhaseForm.reg_lifeevent">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_lifeevent',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="row g-3 mt-3">
                    <div class="form-inline col-md-6">
                      <label class="form-input-header" for="subject">Jurisdiction <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <select class="form-select" formControlName="reg_juris">
                        <option value="" disabled selected>--</option>
                        <option *ngFor="let x of jurisdictions" [value]="x['id']">
                          {{ x["name"] }}
                        </option>
                      </select>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_juris')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.reg_juris">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','reg_juris',item.type)">* {{item.message}}</mat-error>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!-- sub tag -->
                <div class="card-body mt-3" style="background-color:#FAFAFA;">
                  <nav aria-label="breadcrumb" class="breadcrumb-custom-2">
                    <ol class="breadcrumb p-2">
                      <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page"><i class="fas fa-tags me-3"></i>SUB TAG</li>
                    </ol>
                  </nav>

                  <div class="row g-3 m-3 form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="has_sub_tags" formControlName="has_sub_tags"/>
                      <label for="has_sub_tags" class="form-check-label fw-bold">Check if there are additional taggings</label>
                    </div>
                  </div>

                  <form [formGroup]="phase1form" *ngIf="this.phase1form.get('has_sub_tags')?.value === true">
                    <div>
                      <ng-container formArrayName="sub_tags">
                        <ng-container *ngFor="let row of getFormsArray('sub_tags'); let i = index; let c = count;">
                          <div class="p-2 form-group" [formGroup]="row">
                            
                            <div class="row g-3 mt-3">
                              <div class="col-md-2 d-flex flex-row">
                                <label class="form-input-header">Classification <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                              </div>
                              <div class="col-md-8">
                                <mat-radio-group formControlName="reg_classification" class="example-radio-group d-flex justify-content-start flex-row w-100 m-0">
                                  <mat-radio-button value="Business" class="example-radio-button my-0 mx-4">Business</mat-radio-button>
                                  <mat-radio-button value="Non-Business" class="example-radio-button my-0 mx-4">Non-Business</mat-radio-button>
                                  <mat-radio-button value="Government" class="example-radio-button my-0 mx-4" [disabled]=true>Government</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div class="col-md-2">
                                <button class="btn btn-danger d-flex align-items-center float-end btn-custom" type="button" (click)="removeSubTag('sub_tags',i)"
                                  matTooltipPosition="above" matTooltip="Remove">
                                  <i class="fas fa-times-circle fa-lg"></i>
                                </button>
                              </div>
                            </div>

                            <ng-conainer [ngSwitch]="row.value.reg_classification">
                              <!-- Business -->
                              <ng-container *ngSwitchCase="'Business'">
                                <!-- sector -->
                                <div class="row g-3 mt-3">
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_sector">Sector <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_sector">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of sectors" [value]="x['id']">
                                        {{ x["name"] }}
                                      </option>
                                    </select>
                                    <!--  (change)="sectorOnChange($event)" -->
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_sector')">Input Required</mat-hint>
                                    <ng-container *ngFor="let item of checkPhaseForm.reg_sector">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('sub_tags', 'reg_sector',item.type,undefined,row)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
          
                                  <!-- div -->
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_division">Division <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_division">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of filterSelection('reg_division',row.value.reg_sector)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_division)" [value]="x['id']">
                                        {{ x["name"] }}
                                      </option>
                                    </select>
                                    <!--  (change)="sectorOnChange($event)" -->
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_division')">Input Required</mat-hint>
                                  </div>
                                </div>

                                <!-- sob -->
                                <div class="row g-3 mt-3">
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_business">Stage of Business <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_business">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of sobs" [value]="x['id']">
                                        {{ x["name"] }}
                                      </option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_business')">Input Required</mat-hint>
                                    <ng-container *ngFor="let item of checkPhaseForm.reg_business">
                                      <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('sub_tags', 'reg_business',item.type,undefined,row)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
            
                                  <!-- case use -->
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_case">Case Use <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_case">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of filterSelection('reg_case',row.value.reg_business)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_case)" [value]="x['id']">
                                        {{ x["name"] }}
                                      </option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_case')">Input Required</mat-hint>
                                    <ng-container *ngFor="let item of checkPhaseForm.reg_case">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('sub_tags', 'reg_case',item.type,undefined,row)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
                                </div>
                              </ng-container>

                              <!-- Non-Business -->
                              <ng-container *ngSwitchCase="'Non-Business'">
                                <!-- sol -->
                                <div class="row g-3 mt-3">
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_stageoflife">Stage of Life <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_stageoflife">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of sols" [value]="x['id']">
                                        {{ x["stage"] }}
                                      </option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_stageoflife')">Input Required</mat-hint>
                                    <ng-container *ngFor="let item of checkPhaseForm.reg_stageoflife">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('sub_tags', 'reg_stageoflife',item.type,undefined,row)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>

                                  <!-- le -->
                                  <div class="form-inline col-md-6">
                                    <label class="form-input-header" for="reg_lifeevent">Life event <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                                    <select class="form-select" formControlName="reg_lifeevent">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of filterSelection('reg_lifeevent',row.value.reg_stageoflife)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_lifeevent)" [value]="x['id']">
                                        {{ x["event"] }}
                                      </option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_lifeevent')">Input Required</mat-hint>
                                    <ng-container *ngFor="let item of checkPhaseForm.reg_lifeevent">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('sub_tags', 'reg_lifeevent',item.type,undefined,row)">* {{item.message}}</mat-error>
                                    </ng-container>
                                  </div>
                                </div>

                              </ng-container>
                            </ng-conainer>

                          </div>
                          <hr class="divider my-5" *ngIf="i < (c-1)">
                        </ng-container>
                      </ng-container>
                    </div>
          
                    <div class="my-5 d-flex justify-content-center">
                      <button class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addSubtag('sub_tags')">
                        <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD
                      </button>
                    </div>
                  </form>
                </div>

                <!-- legal bases & international agreements -->
                <div class="form-inline">
                  <div class="row g-3 mt-5">
                    <label class="form-input-header" for="subject">Legal Bases <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    <!-- <div>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'reg_legal')">Legal Bases Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.reg_legal">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form', 'reg_legal',item.type)">* {{item.message}}</mat-error>
                      </ng-container>
                    </div>-->

                    <div formArrayName="reg_legal">
                      <mat-list role="list">
                        <mat-list-item *ngFor="let text of reg_legal_val?.controls; let $i = index" role="listitem">
                          <div>
                            <mat-hint class="m-1" style="color:red;font-size:12px;" *ngIf="!arrayIsTouched(text)">Required</mat-hint>
                            <ng-container *ngFor="let item of checkPhaseForm.reg_legal_item">
                                <mat-error class="m-1" style="color:red;font-size:12px;" *ngIf="arrayHasErrors(text, item.type)">{{item.message}}</mat-error>
                            </ng-container>
                          </div>

                          <button *ngIf="!commentMode" class="btn btn-danger d-flex align-items-center float-end ms-2 btn-custom" type="button" (click)="removeLegalBase($i)" 
                            matTooltipPosition="above" matTooltip="Remove">
                              <i class="fas fa-times-circle fa-lg"></i>
                          </button>

                          <div mat-line>
                            <input type="text" class="form-control" formControlName="{{ $i }}" [matAutocomplete]="auto_reg_legal"/>
                            <mat-autocomplete #auto_reg_legal="matAutocomplete">
                              <mat-option *ngFor="let option of legalbasesobs[$i] | async" [value]="option.name">
                                {{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                          </div>

                        </mat-list-item>
                      </mat-list>
                    </div>

                    <div class="mt-3 mb-5 d-flex justify-content-center" *ngIf="!commentMode">
                      <button class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addLegalBase()">
                        <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD
                      </button>
                    </div>
                  </div>
                </div>
      
                <div class="form-inline">
                  <div class="row g-3">
                    <label class="form-input-header" for="subject">International Agreements</label>
                    <div formArrayName="reg_intl">
                      <mat-list role="list">
                        <mat-list-item *ngFor="let text of reg_intl_val?.controls; let $i = index" role="listitem">

                          <button *ngIf="!commentMode" class="btn btn-danger d-flex align-items-center float-end ms-2 btn-custom" type="button" (click)="removeInternationalAgreement($i)"
                            matTooltipPosition="above" matTooltip="Remove">
                            <i class="fas fa-times-circle fa-lg"></i>
                          </button>

                          <div mat-line>
                            <input type="text" class="form-control" formControlName="{{ $i }}" [matAutocomplete]="auto_reg_intl"/>
                            <mat-autocomplete #auto_reg_intl="matAutocomplete">
                              <mat-option *ngFor="let option of intlagreementsobs[$i] | async" [value]="option.name">{{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                          </div>

                        </mat-list-item>
                      </mat-list>
                    </div>

                    <div class="mt-3 mb-5 d-flex justify-content-center" *ngIf="!commentMode">
                      <button class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addInternationalAgreement()">
                        <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD
                      </button>
                    </div>
                  </div>
                </div>

                <!-- checkbox -->
                <div class="card-body mt-5" style="background-color:#FAFAFA;">
                  <div class="form-group m-3">
                    <div class="form-check fw-bold">
                      <input type="checkbox" class="form-check-input" id="regulation_lapse" formControlName="regulation_lapse"/>
                      <label class="form-check-label" for="regulation_lapse">Check if the regulation has been repealed or has lapsed</label>
                    </div>

                    <div class="form-inline mt-4" *ngIf="this.phase1form?.get('regulation_lapse')?.value === true" style="background-color:#f1f1f1;padding:20px;">
                      <div class="col-md-6">
                        <label class="form-input-header" for="date_repeal">Date Repealed / Lapsed</label>
                        <input type="date" placeholder="mm/dd/yyyy" class="form-control" id="date_repeal" formControlName="date_repeal"/>
                      </div>

                      <div class="col-md-12 mt-4">
                        <label class="form-input-header">Repealing Regulations</label>
                        <div formArrayName="reg_lapse">
                          <mat-list role="list">
                            <mat-list-item *ngFor="let obj of reg_lapse_val?.controls; let $i = index" role="listitem">
                              
                              <button *ngIf="!commentMode" class="btn btn-danger d-flex align-items-center float-end ms-2 btn-custom" (click)="removeRepealingRegulation($i)"
                                matTooltipPosition="above" matTooltip="Remove">
                                <i class="fas fa-times-circle fa-lg"></i>
                              </button>

                              <div mat-line>
                                <input type="text" class="form-control" formControlName="{{ $i }}" [matAutocomplete]="auto_reg_lapse"/>
                                <mat-autocomplete #auto_reg_lapse="matAutocomplete" [displayWith]="displayReg">
                                  <mat-option *ngFor="let option of reg_lapse_obs[$i] | async" [value]="option">
                                    {{ option.name }}
                                  </mat-option>
                                </mat-autocomplete>
                              </div>

                            </mat-list-item>
                          </mat-list>
                        </div>
                      </div>

                      <div *ngIf="!commentMode" class="mt-3 mb-5 d-flex justify-content-center">
                        <button class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addRepealingRegulation()">
                          <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD
                        </button>
                      </div>
                    </div>
                  </div>
        
                  <div class="form-group m-3">
                    <div class="form-check fw-bold">
                      <input class="form-check-input" type="checkbox" id="regulation_repeal" formControlName="regulation_repeal"/>
                      <label for="regulation_repeal" class="form-check-label">Check if the regulation repeals another regulation</label>
                    </div>
        
                    <div class="form-inline mt-4" *ngIf="this.phase1form?.get('regulation_repeal')?.value === true" style="background-color:#f1f1f1;padding:20px;">
                      <label class="form-input-header">Regulation/s Repealed</label>
                      <div formArrayName="reg_repeal">
                        <mat-list role="list">
                          <mat-list-item *ngFor="let obj of reg_repeal_val?.controls; let $i = index" role="listitem">

                            <button *ngIf="!commentMode" class="btn btn-danger d-flex align-items-center float-end ms-2 btn-custom" type="button" (click)="removeRegulationRepealed($i)"
                              matTooltipPosition="above" matTooltip="Remove">
                              <i class="fas fa-times-circle fa-lg"></i>
                            </button>

                            <div mat-line>
                              <input type="text"  class="form-control" formControlName="{{ $i }}" [matAutocomplete]="auto_reg_repeal"/>
                              <mat-autocomplete #auto_reg_repeal="matAutocomplete" [displayWith]="displayReg">
                                <mat-option *ngFor="let option of reg_repeal_obs[$i] | async" [value]="option">
                                  {{ option.name }}
                                </mat-option>
                              </mat-autocomplete>
                            </div>

                          </mat-list-item>
                        </mat-list>
                      </div>

                      <div *ngIf="!commentMode" class="mt-3 mb-5 d-flex justify-content-center">
                        <button class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addRegulationRepealed()">
                          <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Date -->
                <div class="row g-3 mt-5">
                  <div class="form-group col-md-6">
                    <label class="form-input-header" for="doc_date">Issue Date <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    <input type="date" placeholder="mm/dd/yyyy" class="form-control" formControlName="doc_date"/>
                    <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(phase1form, 'doc_date')">Input Required</mat-hint> -->
                    <ng-container *ngFor="let item of checkPhaseForm.doc_date">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','doc_date',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                  </div>
      
                  <div class="form-group col-md-6">
                    <label class="form-input-header" for="date_published_onar">Date Filed to ONAR</label>
                    <input type="date" placeholder="mm/dd/yyyy" class="form-control" formControlName="date_published_onar"/>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'date_published_onar')">Input Optional</mat-hint>
                  </div>
                </div>
      
                <div class="row g-3 mt-3">
                  <div class="form-group col-md-6">
                    <label class="form-input-header" for="date_published_gazette">Date Published to Official Gazette / Newspaper</label>
                    <input type="date" placeholder="mm/dd/yyyy" class="form-control" formControlName="date_published_gazette"/>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'date_published_gazette')">Input Optional</mat-hint>
                  </div>
      
                  <div class="form-group col-md-6">
                    <label class="form-input-header" for="date_effective">Date of Effectivity</label>
                    <input type="date" placeholder="mm/dd/yyyy" class="form-control" formControlName="date_effective"/>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'date_effective')">Input Optional</mat-hint>
                  </div>
                </div>
      
                <div class="row g-3 mt-3">
                  <div class="form-inline col-md-6">
                    <label class="form-input-header" for="effectivity_type" >Effectivity Type <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                    <select class="form-control" formControlName="effectivity_type">
                      <option value="In Effect">In Effect</option>
                      <option value="Repealed/Lapsed">Repealed/Lapsed</option>
                      <option value="Amended">Amended</option>
                    </select>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(phase1form, 'effectivity_type')">Input Required</mat-hint>
                    <ng-container *ngFor="let item of checkPhaseForm.effectivity_type">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('phase1form','effectivity_type',item.type)">* {{item.message}}</mat-error>
                    </ng-container>
                  </div>
      
                  <div class="form-group col-md-6" *ngIf="!commentMode">
                    <label class="form-input-header" for="file_file_name">Upload File <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>
                      <span style="font-size:12px;" matTooltipPosition="above" matTooltip="Reset Upload"><a [routerLink]="[]" (click)="resetNewUpload('documentFile')"><i class="fas fa-sync-alt fa-lg mx-3"></i></a></span>
                    </label>
                    <!-- <input
                      type="file"
                      class="form-control"
                      (change)="buttonUpload('documentFile', $event)"
                    /> -->
                      
                    <div class="d-flex justify-content-center file-uploader-wrapper border-0">
                      <div appFileUploader class="col-md-12 dropzone" (filesChangeEmiter)="dropzone('documentFile',$event)">
                        <button type="button" class="btn btn-link dropzone-btn file-uploader-wrapper" (click)="documentFile.click()">
                          <div *ngIf="uploadInputs.get('documentFile')?.display_file_name">
                            <span class="fw-bold" style="font-size:16px;">{{ uploadInputs.get("documentFile")?.display_file_name }}</span>
                          </div>
                          <div *ngFor="let formValid of checkUploadedDocs.all">
                            <div class="error" *ngIf="hasError('uploadsForm','documentFile', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                              <span class="fw-bold" style="color:red;font-size:16px;">{{formValid.message}}</span>
                            </div>
                          </div>
                          <span>Drop file here to upload or click to choose file</span>
                          <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                        </button>
                        <input #documentFile id="documentFile" type="file" multiple (change)="buttonUpload('documentFile',$event)"/>
                      </div>
                    </div>
                  </div>
                  <!-- <ng-container *ngFor="let item of checkUploadedDocs.all">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('uploadsForm','documentFile', item.type == 'maxSize' ? 'max' : item.type, item.type == 'maxSize' ? true : false)">* {{item.message}}</mat-error>
                  </ng-container> -->
                  <!-- <div class="row my-4" *ngIf="download_phase_01_filepath">
                    <div class="col-6">
                      <a [download]="phase_01_file_name" target="_blank" [href]="download_phase_01_filepath" class="btn btn-lg btn-primary float-start">Download Document - {{ phase_01_file_name }}</a>
                    </div>
                  </div> -->
                  <!-- <div *ngFor="let formValid of checkUploadedDocs.all">
                    <div class="error" *ngIf="hasError('uploadsForm','documentFile', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                      {{formValid.message}}  
                    </div>
                  </div> -->
                
                </div>
      
                
                <div class="row">
                  <div style="margin-top:250px;">
                    <!-- Add Comment of Verifier/HOA -->
                    <div *ngIf="commentMode" class="my-5 p-4" style="border-top: 1px dashed #d9d9d9;">
                      <label class="form-input-header" for="vision">
                        <h6 class="fw-bold"><i class="fas fa-comments me-2" style="color:#FCD116;"></i>Add comment here:</h6>
                      </label>
                      <mat-form-field style="width: 100%;" hideRequiredMarker floatLabel="never">
                        <textarea matInput placeholder="Input" type="text" [formControl]="comment_details"></textarea>
                      </mat-form-field>
                    </div>
                    <p class="float-end fst-italic fw-bold">
                      Asterisks (<span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>) indicate required fields - must be filled in order to Submit the form.
                    </p>
                  </div>
                </div>
              
                <!-- Footer Bottons -->
                <div class="row g-3" *ngIf="!commentMode">
                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-start" role="group">
                      <button id="btnCancelRegulation" type="button" class="btn btn-dark dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">CANCEL</button>
                      <ul class="dropdown-menu" aria-labelledby="btnCancelRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-dark rounded-3" (click)="toCancel()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>

                      <button id="btnDraftRegulation" type="button" class="btn btn-info dropdown-toggle" *ngIf="!commentMode" [disabled]="loading"
                        data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-save me-2"></i>SAVE AS DRAFT
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="btnDraftRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-info rounded-3" (click)="saveAsDraft()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-footer text-center col-md-6 my-3">
                    <button class="btn btn-primary float-end" [disabled]="phase1form.invalid || loading" matStepperNext>NEXT <i class="fas fa-arrow-circle-right ms-2"></i></button>
                  </div>
                </div>

                <!-- Buttons for comment modes -->
                <div class="row g-3" *ngIf="commentMode">
                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-start" role="group">
                      <button id="btnCancelRegulation" type="button" class="btn btn-dark dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">CANCEL</button>
                      <ul class="dropdown-menu" aria-labelledby="btnCancelRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-dark rounded-3" (click)="toCancel()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-end" role="group">
                      <button class="btn btn-primary float-end" [disabled]="phase1form.invalid || loading" matStepperNext>NEXT <i class="fas fa-arrow-circle-right ms-2"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-step>
  

            <!-- text regulation -->
            <mat-step [stepControl]="TextRegFormGroup">
              <form [formGroup]="phase1form"><!-- *ngIf="currentFormPage == 'regulationtext'" -->
                <ng-template matStepLabel>TEXT OF THE REGULATION</ng-template>

                <div class="card-header mt-3">
                  <div class="row">
                    <div class="col-md-9 text-center">
                      <h6><b>SECTION / SUBSECTION <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></b></h6>
                    </div>
                    <div class="col-md-3 text-center" *ngIf="!commentMode">
                      <h6><b>ACTION</b></h6>
                    </div>
                  </div>
                </div>
      
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree mt-3">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node *matTreeNodeDef="let node; when: childlessSection" matTreeNodeToggle class="border-bottom mt-2">
                    <div class="mat-tree-node d-flex justify-content-between childless-section" style="background-color:#E2E2E2;" [class.bg-info]="node.uuid === activeRegulationSectionUuid">
                      <div class="d-flex justify-content-start align-content-stretch flex-grow-1">
                        <div class="tree-select d-flex align-self-center"> <!-- (click)="getRegulationSectionInfo(node)" -->
                          <!-- {{ node.name }} -->
                        <div class="d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                          {{ node.name ? node.name : '* Section Title is required' }}
                        </div>
                        <div *ngIf="!commentMode">
                          <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">
                            * Invalid: 
                            <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                              <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                            </ng-container>
                          </mat-error>
                        </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="btn-group btn-custom justify-content-end mx-3" role="group">
                      <button *ngIf="!commentMode" class="btn-custom-action btn-light rounded-end rounded-9" type="button" (click)="addNewRegulationSubsection(node)">
                        <div class="btn-custom-action-div">
                          <i class="fas fa-plus-circle me-1 btn-custom-action-icon"></i>
                        </div>
                        <div class="text" style="color:#000000;">SUBSECTION</div>
                      </button>
                      <button *ngIf="!commentMode" class="btn-custom-action btn-primary" type="button" (click)="getRegulationSectionInfo(node)">
                        <div class="btn-custom-action-div">
                          <i class="fas fa-edit me-1 btn-custom-action-icon"></i>
                        </div>
                        <div class="text">EDIT</div>
                      </button>
                      <button *ngIf="!commentMode" class="btn-custom-action btn-danger rounded-start rounded-9" type="button" (click)="removeRegulationSection(node.uuid)">
                        <div class="btn-custom-action-div">
                          <i class="fas fa-trash-alt me-1 btn-custom-action-icon"></i>
                        </div>
                        <div class="text">DELETE</div>
                      </button>
                    </div> -->

                    <div class="btn-group btn-custom justify-content-end mx-3" role="group" aria-label="Button group with nested dropdown">
                      <button *ngIf="!commentMode" class="btn badge-light btn-sm rounded-end rounded-9" type="button" (click)="addNewRegulationSubsection(node)">
                        <i class="fas fa-plus-circle me-2" style="color:#FCD116;"></i>Subsection
                      </button>
                      <button *ngIf="!commentMode" class="btn badge-primary btn-sm" type="button" (click)="getRegulationSectionInfo(node)">
                        <i class="fas fa-edit me-2"></i>Edit
                      </button>

                      <div class="btn-group" role="group">
                        <button *ngIf="!commentMode" id="btnRemoveSection" type="button" class="btn badge-danger btn-sm rounded-start rounded-9 dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                          <i class="fas fa-trash-alt me-2"></i>Delete
                        </button>
                        <ul class="dropdown-menu rounded-9" aria-labelledby="btnRemoveSection">
                          <li matTooltipPosition="above" matTooltip="Yes">
                            <a class="dropdown-item d-flex justify-content-center badge-danger rounded-9" (click)="removeRegulationSection(node.uuid)" style="font-size:.75rem;color:#000000;">
                              <i class="fas fa-check"></i>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <button *ngIf="commentMode" class="btn badge-primary btn-sm d-flex align-items-center" type="button" (click)="getRegulationSectionInfo(node)">
                        <i class="fas fa-eye me-2"></i>View
                      </button>
                    </div>
                  </mat-tree-node>
      
                  <mat-tree-node *matTreeNodeDef="let node; when: childlessSubsection" matTreeNodeToggle class="border-bottom">
                    <div class="mat-tree-node d-flex justify-content-between px-3" [class.bg-info]="node.uuid === activeRegulationSubsectionUuid">
                      <div class="d-flex justify-content-start align-content-stretch flex-grow-1">
                        <div class="tree-select d-flex align-self-center"> <!-- (click)="getRegulationSubsectionInfo(node.parentUuid, node.uuid)" -->
                          <!-- {{ node.name }} -->
                          <div class="d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                            <i class="fas fa-circle fa-xs me-3 d-flex align-items-center" style="font-size:6px;"></i>
                            {{ node.name ? node.name : '* Subsection is required' }}
                          </div>
                          <div *ngIf="!commentMode">
                            <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0" class="color-red">
                              * Invalid: 
                              <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                                <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                              </ng-container>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="btn-group btn-custom justify-content-end mx-3" role="group">
                      <button *ngIf="!commentMode" class="btn-custom-action btn-primary rounded-end rounded-9" type="button" (click)="getRegulationSubsectionInfo(node.parentUuid, node.uuid)">
                        <div class="btn-custom-action-div">
                          <i class="fas fa-edit me-1 btn-custom-action-icon"></i>
                        </div>
                        <div class="text">EDIT</div>
                      </button>
                      <button *ngIf="!commentMode" class="btn-custom-action btn-danger rounded-start rounded-9" type="button" (click)="removeRegulationSubsection(node.parentUuid, node.uuid)">
                        <div class="btn-custom-action-div">
                          <i class="fas fa-trash-alt me-1 btn-custom-action-icon"></i>
                        </div>
                        <div class="text">DELETE</div>
                      </button>
                    </div> -->

                    <div class="btn-group btn-custom justify-content-end mx-3" role="group" aria-label="Button group with nested dropdown">
                      <button *ngIf="!commentMode" class="btn badge-primary btn-sm rounded-end rounded-9" type="button" (click)="getRegulationSubsectionInfo(node.parentUuid, node.uuid)">
                        <i class="fas fa-edit me-2"></i>Edit
                      </button>

                      <div class="btn-group" role="group">
                        <button *ngIf="!commentMode" id="btnRemoveSub" type="button" class="btn badge-danger btn-sm rounded-start rounded-9 dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                          <i class="fas fa-trash-alt me-2"></i>Delete
                        </button>
                        <ul class="dropdown-menu rounded-9" aria-labelledby="btnRemoveSub">
                          <li matTooltipPosition="above" matTooltip="Yes">
                            <a class="dropdown-item d-flex justify-content-center badge-danger rounded-9" (click)="removeRegulationSubsection(node.parentUuid, node.uuid)" style="font-size:.75rem;color:#000000;">
                              <i class="fas fa-check"></i>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <button *ngIf="commentMode" class="btn badge-primary btn-sm d-flex align-items-center" type="button" (click)="getRegulationSubsectionInfo(node.parentUuid, node.uuid)">
                        <i class="fas fa-eye me-2"></i>View
                      </button>
                    </div>
                  </mat-tree-node>
      
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <div class="mat-tree-node d-flex justify-content-between border-bottom mt-2">
                      <div class="d-flex justify-content-start flex-grow-1 p-1" style="background-color:#E2E2E2;" [class.bg-info]="node.uuid === activeRegulationSectionUuid">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{
                              treeControl.isExpanded(node)
                                ? "expand_more"
                                : "chevron_right"
                            }}
                          </mat-icon>
                        </button>
                        <div class="tree-select d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                          <!-- {{ node.name }} -->
                          {{ node.name ? node.name : '* Section Title is required' }}
                          <div *ngIf="!commentMode">
                            <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0" class="color-red">
                              * Invalid: 
                              <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                                <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                              </ng-container>
                            </mat-error>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="btn-group btn-custom justify-content-end mx-3" role="group">
                        <button *ngIf="!commentMode" class="btn-custom-action btn-light rounded-end rounded-9" type="button" (click)="addNewRegulationSubsection(node)">
                          <div class="btn-custom-action-div">
                            <i class="fas fa-plus-circle me-1 btn-custom-action-icon"></i>
                          </div>
                          <div class="text" style="color:#000000;">SUBSECTION</div>
                        </button>
                        <button *ngIf="!commentMode" class="btn-custom-action btn-primary d-flex align-items-center" type="button" (click)="getRegulationSectionInfo(node)">
                          <div class="btn-custom-action-div">
                            <i class="fas fa-edit me-1 btn-custom-action-icon"></i>
                          </div>
                          <div class="text">EDIT</div>
                        </button>
                        <button *ngIf="!commentMode" class="btn-custom-action btn-danger rounded-start rounded-9" type="button" (click)="removeRegulationSection(node.uuid)">
                          <div class="btn-custom-action-div">
                            <i class="fas fa-trash-alt me-1 btn-custom-action-icon"></i>
                          </div>
                          <div class="text">DELETE</div>
                        </button>
                      </div> -->

                      <div class="btn-group btn-custom justify-content-end mx-3" role="group" aria-label="Button group with nested dropdown">
                        <button *ngIf="!commentMode" class="btn badge-light btn-sm rounded-end rounded-9" type="button" (click)="addNewRegulationSubsection(node)">
                          <i class="fas fa-plus-circle me-2" style="color:#FCD116;"></i>Subsection
                        </button>
                        <button *ngIf="!commentMode" class="btn badge-primary btn-sm d-flex align-items-center" type="button" (click)="getRegulationSectionInfo(node)">
                          <i class="fas fa-edit me-2"></i>Edit
                        </button>

                        <div class="btn-group" role="group">
                          <button *ngIf="!commentMode" id="btnRemoveSecTree" type="button" class="btn badge-danger btn-sm rounded-start rounded-9 dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-trash-alt me-2"></i>Delete
                          </button>
                          <ul class="dropdown-menu rounded-9" aria-labelledby="btnGroupDrop1">
                            <li matTooltipPosition="above" matTooltip="Yes">
                              <a class="dropdown-item d-flex justify-content-center badge-danger rounded-9" (click)="removeRegulationSection(node.uuid)" style="font-size:.75rem;color:#000000;">
                                <i class="fas fa-check"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <button *ngIf="commentMode" class="btn badge-primary btn-sm d-flex align-items-center" type="button" (click)="getRegulationSectionInfo(node)">
                          <i class="fas fa-edit me-2"></i>View
                        </button>
                      </div>
      
                    </div>

                    <!-- There is inline padding applied to this div using styles.
                      This padding value depends on the mat-icon-button width.  -->
                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                      <ng-container matTreeNodeOutlet></ng-container>
                    </div>
                  </mat-nested-tree-node>
                </mat-tree>
      
                <div class="form-group my-5 mb-5 d-flex justify-content-center">
                  <button *ngIf="!commentMode" class="col-md-6 btn btn-link dropzone-btn file-uploader-wrapper" type="button" (click)="addNewRegulationSection()">
                    <i class="fas fa-plus-circle fa-lg me-2" style="color:#FCD116;"></i>ADD ANOTHER SECTION
                  </button>
                </div>
      
                <!-- Section Details -->
                <div *ngIf="activeRegulationSection" class="card-body border-top border-warning border-2 mt-5" style="background-color:#FAFAFA;">
                  <div class="row">
                    <div>
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>
                  
                  <form [formGroup]="activeRegulationSection.info">

                    <nav aria-label="breadcrumb" class="breadcrumb-custom-2">
                      <ol class="breadcrumb p-2">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">
                          <i class="fas fa-th-list me-2" style="color:red;"></i>SECTION DETAILS
                      </ol>
                    </nav>

                    <div class="form-group mt-5">
                      <label class="form-input-header" for="title">Section Title <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <input type="text" class="form-control" formControlName="section_title" (keyup)="updateTreeSectionName($event)"
                        (change)="checkRegulationTextFormIsInvalid()" />
                      <ng-container *ngFor="let item of checkPhaseForm.activeRegulationSection.section_title">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('activeRegulationSection','section_title',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                      </ng-container> 
                    </div>
        
                    <div class="form-inline mt-3">
                      <label class="form-input-header" for="subject">Section Type <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <!-- <input type="text" class="form-control" formControlName="section_type" /> -->
                      <mat-select class="form-control" multiple formControlName="section_type" (selectionChange)="checkRegulationTextFormIsInvalid()">
                        <mat-option value="Background">Background</mat-option>
                        <mat-option value="Overview">Overview</mat-option>
                        <mat-option value="Legal Basis">Legal Basis</mat-option>
                        <mat-option value="Objectives">Objectives</mat-option>
                        <mat-option value="Scope/Coverage">Scope/Coverage</mat-option>
                        <mat-option value="Definition of Terms">Definition of Terms</mat-option>
                        <mat-option value="Effectivity">Effectivity</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSection.info, 'section_type')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.activeRegulationSection.section_type">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('activeRegulationSection', 'section_type',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                      </ng-container> 
                    </div>
        
                    <div class="form-group mt-3">
                      <label class="form-input-header fw-bold" for="title">Section Text <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <editor
                        [disabled]="commentMode"
                        formControlName="section_text"
                        (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                    
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSection.info, 'section_text')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.activeRegulationSection.section_text">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('activeRegulationSection','section_text',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                      </ng-container> 
                    </div>
                  </form>
                </div>
        
                <!-- Subsection Details -->
                <div *ngIf="activeRegulationSubsection" class="card-body border-top border-danger border-2 mt-5" style="background-color:#FAFAFA;">
                  <div class="row">
                    <div>
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>

                  <form [formGroup]="activeRegulationSubsection">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom-2">
                      <ol class="breadcrumb p-2">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">
                          <i class="fas fa-info-circle me-2" style="color:red;"></i>SUBSECTION DETAILS
                        </li>
                      </ol>
                    </nav>

                    <div class="form-group mt-5">
                      <label class="form-input-header" for="title">Subsection Title <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <input type="text" class="form-control" formControlName="subsection_title" (keyup)="updateTreeSubsectionName($event)" (change)="checkRegulationTextFormIsInvalid()"/>
                      <ng-container *ngFor="let item of checkPhaseForm.activeRegulationSection.subsection_title">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('activeRegulationSubsection','subsection_title',item.type,false,activeRegulationSubsection)">* {{item.message}}</mat-error>
                      </ng-container>
                    </div>
        
                    <div class="form-group mt-3">
                      <label class="form-input-header" for="title">Subsection Text <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span></label>
                      <editor
                        [disabled]="commentMode"
                        formControlName="subsection_text"
                        (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                    
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>
                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSubsection, 'subsection_text')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkPhaseForm.activeRegulationSection.subsection_text">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasError('activeRegulationSubsection','subsection_text',item.type,false,activeRegulationSubsection)">* {{item.message}}</mat-error>
                      </ng-container>
        
                      <div class="form-group">
                        <label *ngIf="!commentMode" class="form-input-header" for="uacs_organization_code">Add Image</label>
                        <input *ngIf="!commentMode" type="file" accept="image/*" class="form-control" (change)="saveImageInformation($event)" />
                        <div class="col-3">
                          <img [src]="previewImg" class="img-fluid img-thumbnail" *ngIf="previewImg" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <hr class="divider mt-5">

                <!-- Annexes -->
                <div class="my-3">
                  <input class="form-check-input" type="checkbox" id="has_annex_files" formControlName="has_annex_files" />
                  <label for="has_annex_files" class="form-check-label">
                    Check if annexes to the regulation are available
                  </label>
                </div>
      
                <div *ngIf="phase1form.get('has_annex_files')?.value === true">
                  <!-- upload files goes here -->
                  <div class="form-group text-center mt-3 mb-5" *ngIf="!commentMode">
                    <!-- <label class="form-input-header fw-bold" for="title"
                      >*Upload Text-Readable/Searchable documents:</label
                    >
                    <input
                      type="file"
                      (change)="saveFileInformation('regulationTextAnnex', $event)"
                      class="form-control"
                      ng-required="true"
                      style="display: none"
                      #regulation_text_annex_file_input
                    />
                    <div class="file-input p-3">
                      <label>Drop files here or</label>
                      <button
                        (click)="regulation_text_annex_file_input.click()"
                        class="btn btn-light"
                      >
                        Browse
                      </button>
                    </div>
                    {{ display_regulation_text_annex_file_name }} -->
                    <label class="form-input-header fw-bold" for="title">
                      Upload Text-Readable / Searchable documents <span class="asterisk" matTooltipPosition="above" matTooltip="Required">*</span>
                      <span style="font-size:12px;" matTooltipPosition="above" matTooltip="Reset Upload"><a [routerLink]="[]" (click)="resetNewUpload('regulationTextAnnex')">
                        <i class="fas fa-sync-alt fa-lg mx-2"></i></a>
                      </span>
                    </label>

                    <div class="d-flex justify-content-center file-uploader-wrapper border-0">
                      <div appFileUploader class="col-md-6 dropzone" (filesChangeEmiter)="dropzone('regulationTextAnnex',$event)">
                        <button type="button" class="btn btn-link dropzone-btn file-uploader-wrapper" (click)="regulationTextAnnex.click()">
                          <div *ngIf="uploadInputs.get('regulationTextAnnex')?.display_file_name">
                            <span class="fw-bold" style="font-size:16px;">{{ uploadInputs.get("regulationTextAnnex")?.display_file_name }}</span>
                          </div>
                          <!-- <div *ngFor="let formValid of checkUploadedDocs.all">
                            <div class="error" *ngIf="hasError('uploadsForm','regulationTextAnnex', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                              <span class="fw-bold" style="color:red;font-size:16px;">{{ formValid.message }}</span> 
                            </div>
                          </div> -->
                          <span>Drop file here to upload or click to choose file</span>
                          <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                        </button>
                        <input #regulationTextAnnex id="regulationTextAnnex"  type="file" multiple (change)="buttonUpload('regulationTextAnnex',$event)"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div style="margin-top:250px;">
                    <!-- Add Comment of Verifier/HOA -->
                    <div *ngIf="commentMode" class="my-5 p-4" style="border-top: 1px dashed #d9d9d9;">
                      <label class="form-input-header" for="vision">
                        <h6 class="fw-bold"><i class="fas fa-comments me-2" style="color:#FCD116;"></i>Add comment here:</h6>
                      </label>
                      <mat-form-field style="width: 100%;" hideRequiredMarker floatLabel="never">
                        <textarea matInput placeholder="Input" type="text" [formControl]="comment_regtext"></textarea>
                      </mat-form-field>
                    </div>
                    <p class="float-end fst-italic fw-bold">
                      Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                    </p>
                  </div>
                </div>
              
                <!-- Footer Bottons -->
                <div class="row g-3" *ngIf="!commentMode">
                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-start" role="group">
                      <button id="btnCancelRegulation" type="button" class="btn btn-dark dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">CANCEL</button>
                      <ul class="dropdown-menu" aria-labelledby="btnCancelRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-dark rounded-3" (click)="toCancel()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>

                      <button id="btnDraftRegulation" type="button" class="btn btn-info dropdown-toggle" *ngIf="!commentMode" [disabled]="loading"
                        data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-save me-2"></i>SAVE AS DRAFT
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="btnDraftRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-info rounded-3" (click)="saveAsDraft()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-end" role="group">
                      <button class="btn btn-primary" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>BACK</button>
                      <button id="btnSubmitRegulation" type="button" class="btn btn-danger dropdown-toggle" 
                        [disabled]="phase1form.invalid || loading || regulationTextFormIsInvalid" data-mdb-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-share-square me-2"></i>SUBMIT <span class="spinner-border spinner-border-sm mx-3" *ngIf="loading" [disabled]="loading"></span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="btnSubmitRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-danger rounded-3" (click)="submitRegulation()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Buttons for comment modes -->
                <div class="row g-3" *ngIf="commentMode">
                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-start" role="group">
                      <button id="btnCancelRegulation" type="button" class="btn btn-dark dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">CANCEL</button>
                      <ul class="dropdown-menu" aria-labelledby="btnCancelRegulation">
                        <li matTooltipPosition="above" matTooltip="Yes">
                          <a class="dropdown-item d-flex justify-content-center bg-dark rounded-3" (click)="toCancel()" style="font-size:.75rem;color:#FFFFFF;">
                            <i class="fas fa-check"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-footer text-center col-md-6 my-3">
                    <div class="btn-group float-end" role="group">
                      <button class="btn btn-primary" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>BACK</button>
                      <button *ngIf="commentButtonsEnabled" class="btn btn btn-danger float-end" (click)="submitCommentsAndReturn()" id="bt" 
                        [disabled]="(comment_details.invalid && comment_regtext.invalid) || loading">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                        Submit Comments and Return<i class="fas fa-share-square ms-2"></i>
                      </button>
                      <!-- <mat-list>
                        <mat-list-item> <strong>Name:</strong> {{ this.phase1form.value.title }}</mat-list-item>
                      </mat-list> -->
                    </div>
                  </div>
                </div>

              </form>
            </mat-step>

          </mat-horizontal-stepper>

        </div>
      </div>
    </div>
  </ng-container>

  <!-- Loader -->
  <ng-template #notYetLoaded>
    <div class="d-flex justify-content-center align-items-center loader-wrapper">
      <div class="loader">
        <div class="box box-1">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-2">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-3">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-4">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
