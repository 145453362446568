<div class="container mt-4">
    <table class="table">
        <thead>
            <tr>
                <th class="fw-bold">Sector</th>
                <th class="fw-bold">Legal Basis</th>
                <th class="fw-bold">Role of Agency in the Regulated Sector</th>
                <th class="fw-bold text-end">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sector of sectors; let i = index">
                <td>
                    <!-- Input with datalist for selecting or typing a sector -->
                    <input list="sectorOptions" type="text" class="form-control" placeholder="Select or type sector"
                        [(ngModel)]="sector.name" />
                    <datalist id="sectorOptions">
                        <option *ngFor="let option of availableSectors" [value]="option"></option>
                    </datalist>
                </td>
                <td>
                    <input type="text" class="form-control" placeholder="Enter legal basis"
                        [(ngModel)]="sector.legalBasis" />
                </td>
                <td>
                    <input type="text" class="form-control" placeholder="Enter role of agency"
                        [(ngModel)]="sector.role" />
                </td>
                <td class="text-end">
                    <button class="btn btn-light" (click)="deleteSector(i)">
                        <i class="fas fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>

            <!-- Always show "Add new sector" row -->
            <tr>
                <td>
                    <input list="sectorOptions" type="text" class="form-control" placeholder="Select or type sector"
                        (change)="addNewSector($event)" />
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>

    <!-- Datalist options -->
    <datalist id="sectorOptions">
        <option *ngFor="let option of availableSectors" [value]="option"></option>
    </datalist>
</div>