
<div class="row">
    <div class="col-6">
      <label for=""><strong>Client Step</strong></label>
      
    </div>
    <div class="col-6 float-end">
      <label class="me-5"><strong>Agency Action</strong></label>
      <button
      (click)="addAgencyAction()"
      mat-raised-button
      color="primary"
    >
    <span><i class="fas fa-plus"></i> Add Agency Action</span> 
    </button>
    </div>
</div>
<mat-dialog-content>
 
 <div class="container-fluid">
  <form [formGroup]="client_step_form">
    <div class="row">
      <!--Client Step-->
      <div class="form-group" formGroupName="info" class="col-6 pe-3">
        <div class="form-group row">
          <section class="example-section">
            <div class="col">
              <div class="mt-1">
                <div *ngIf="is_situational_step" class="form-check form-check-inline">
                  <input
                  
                    type="hidden"
                    class="form-check-input"
                    formControlName="is_situational_step"
                    [(ngModel)]="is_situational_step"
                    (change)="situationalStepChange()"
                    id="situationalStep"
                  /> 
                  <!--
                    <label class="form-check-label" for="situationalStep"
                  ><strong>With Situational Step</strong></label>
                 
                  -->
                </div>
              </div>
            </div>
          </section>
        </div>
  
        <div *ngIf="is_situational_step" class="form-group row">
          <mat-form-field appearance="outline">
            <mat-label>Situation</mat-label>
            <input 
            formControlName="situation" 
            matInput width="100%" 
            placeholder="Situation"
            [required]="is_situational_step" />
          </mat-form-field>
        </div>

        <input type="hidden" formControlName="step_Id">
        <div class=" row">
          <mat-form-field appearance="outline">
            <mat-label>{{client_step_index}}.Client Step</mat-label>
            <textarea formControlName="title" required matInput></textarea>
            <mat-error
                      style="color: red"
                      *ngIf="client_step_form.get('title')?.errors"
                      >Client step title is required</mat-error>
          </mat-form-field>
        </div>
  
        <div class=" row mt-1">
          <label class="fw-bold">Location*</label>
            <mat-form-field style="width: 100%"  floatLabel="never">
              <input matInput hidden/>
              <editor
                formControlName="location"
                [init]="{
                  base_url: '/tinymce',
                  suffix: '.min',                    
                  height: 150,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
                }"
              ></editor>
              <mat-hint class="text-danger" *ngIf="infoGroup.get('location')?.errors ">Location is required!</mat-hint>
            </mat-form-field>
          <!--- <mat-form-field appearance="outline">
            
           -<input formControlName="location" matInput width="100%" placeholder="location" required />
      
            </mat-form-field> -->

        </div>

        <div class="row mt-1">
          <label class="fw-bold">Notes / Instruction</label>
          <mat-form-field style="width: 100%"  floatLabel="never">
            <input required matInput hidden/>
            <editor
              formControlName="notes"
              [init]="{
                base_url: '/tinymce',
                suffix: '.min',                    
                height: 150,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                                  alignleft aligncenter alignright alignjustify | \
                                  bullist numlist outdent indent | removeformat | help'
              }"
            ></editor>
          </mat-form-field>
        </div>
  
        <div class=" row">
          <section class="example-section">
            <div class="col">
              <div class="">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    onchange="checkboxChange($event)"
                    formControlName="client_step_payment_option"
                    [matMenuTriggerFor]="iswithFee"
                    [(ngModel)]="iswithoutFees"
                  />
                  <label [matMenuTriggerFor]="iswithFee" class="form-check-label" for="type_g2c"
                    ><strong>No Fees to be Paid</strong></label>
  
                            <mat-menu #iswithFee="matMenu">
                            <div class="container-fluid">
                              <div class="row">
                                Are you sure? All currently encoded fees will be removed by ticking this box!
                              </div>
                              <button (click)="removeAllFees()" mat-button color="warn">Proceed</button>
                              <button (click)="cancelRemoveFees()" mat-button >Cancel</button>
                            
                            </div>
                            </mat-menu>
                </div>
              </div>
            </div>
          </section>
        </div>
  
       
  
        <div class="form-group row">
          <section class="example-section">
            <div class="col-12">
              <div class="mt-1">
                <div class="form-check form-check-inline">
                  <input 
                   (change)="checkboxStandardFeeChange($event)"
                    type="checkbox"
                    class="form-check-input"
                    id="standardfees"
                    formControlName="standard_fees"
                 
                  />
                  <label class="form-check-label" for="standardfees"
                    ><strong>Standard Fees</strong></label>
  
                  <div *ngIf="iswithStandardFees">
                    <div formArrayName="standard_fees_arr" class="form-group row mt-1">
                      <div *ngFor="let fee of standardFees.controls; let x = index" class="row">
  
                        <div class="row" [formGroupName]="x">
                          <div  class="col">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Fee Type</mat-label>
                                <input
                                  matInput
                             
                                  formControlName="fee_type"
                                  placeholder="Enter fee type"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div  class="col-2">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Currency</mat-label>
                                <input
                                  matInput
                                  oninput="this. value = this. value. toUpperCase()"
                                  formControlName="currency"
                                  placeholder="Enter Currency"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Amount</mat-label>
                                <input
                                  matInput
                                  
                                  formControlName="amount"
                                  type="number"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-1">
                            <div class="example-button-container">
                              <button
                              (click)="removeStandardFee(x)"
                              [disabled]="standardFees.controls.length == 1 "
                                mat-button
                                color="warn"
                                aria-label="Example icon button with a menu icon"
                              >
                              <mat-icon  >delete</mat-icon>
 
                              </button>
                            </div>
                          </div>
                        </div>
  
                      </div>
                      
  
                      <div class="col-3 mb-2">
                        <button (click)="addStandardFee()"  mat-button >
                          <span><i class="fas fa-plus-circle"></i> Add More Fee</span>
                        </button>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="form-group row">
          <section class="example-section">
            <div class="col-12">
              <div class="mt-1">
                <div class="form-check form-check-inline">
                  <input
                    (change)="checkboxPossibleFeeChange($event)"
                    type="checkbox"
                    class="form-check-input"
                    id="possible_fees"
                    formControlName="list_of_possible_fees"
                  />
                  <label class="form-check-label" for="possible_fees"
                    ><strong>List of Possible Fees</strong></label
                  >
                  <div *ngIf="iswithPossibleFee">
                    <div formArrayName="list_of_possible_fees_arr" class="form-group row mt-1">
                      <div *ngFor="let fee of PossibleFees.controls; let v = index" class="row">
  
                        <div class="row" [formGroupName]="v">
                          <div  class="col">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Fee Type</mat-label>
                                <input
                                  matInput
                               
                                  formControlName="fee_type"
                                  placeholder="Enter fee type"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div  class="col-2">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Currency</mat-label>
                                <input
                                  matInput
                                  oninput="this. value = this. value. toUpperCase()"
                                  formControlName="currency"
                                  placeholder="Enter Currency"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group row">
                              <mat-form-field appearance="outline">
                                <mat-label>Amount</mat-label>
                                <input
                                  matInput
                               
                                  formControlName="amount"
                                  type="number"
                                  required
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-1">
                            <div class="example-button-container">
                              <button
                              [disabled]="PossibleFees.controls.length == 1 "
                              (click)="removePossibleFee(v)"
                                mat-button
                                color="warn"
                                aria-label="Example icon button with a menu icon"
                              >
                              <mat-icon >delete</mat-icon>

                              </button>
                            </div>
                          </div>
                        </div>
  
                      </div>
                      
  
                      <div class="col-3 mb-2">
                        <button (click)="adPossibleFee()"  mat-button >
                          <span><i class="fas fa-plus-circle"></i> Add More Fee</span>
                        </button>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="form-group row">
          <section class="example-section">
            <div class="col-12">
              <div class="mt-1">
                <div class="form-check form-check-inline">
                  <input
                    (change)="checkboxFormulaFeeChange($event)"
                    type="checkbox"
                    class="form-check-input"
                    id="formula_fees"
                    value="false"
                    formControlName="list_of_formula_fees"
                   
                  />
                  <label class="form-check-label" for="formula_fees"
                    ><strong>Formula/Schedule of Fees</strong></label
                  >
                  <div *ngIf="iswithFormulaFee">
                    <div formArrayName="list_of_formula_fees_arr" class="form-group row mt-1">
                      <div *ngFor="let fee of FormulaFees.controls; let y = index" class="row">
  
                        <div  [formGroupName]="y">
                          <div class="row">
                            <div  class="col-11">
                              <div class="form-group row">
                                <mat-form-field appearance="outline">
                                  <mat-label>Fee Type</mat-label>
                                  <input
                                    matInput
                                 
                                    formControlName="fee_type"
                                    placeholder="Enter fee type"
                                    required
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-1">
                              <div class="example-button-container">
                                <button
                                (click)="removeFormulaFee(y)"
                                [disabled]="FormulaFees.controls.length == 1 "
                                  mat-button
                                  color="warn"
                                  aria-label="Example icon button with a menu icon"
                                >
                                <mat-icon  >delete</mat-icon>

                                </button>
                              </div>
                            </div>
                          </div>
  
                          <div class="form-group row">
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                            >
                              <input matInput hidden />
                              <editor
                                formControlName="amount"
                                [init]="{
                                  base_url: '/tinymce',
                                  suffix: '.min',
                                  height: 250,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                                      alignleft aligncenter alignright alignjustify | \
                                                      bullist numlist outdent indent | removeformat | help'
                                }"
                              ></editor>
                            </mat-form-field>
                          </div>
                        </div>
  
                      </div>
                      
  
                      <div class="col-3 mb-2">
                        <button (click)="addFormulaFee()"  mat-button >
                          <span><i class="fas fa-plus-circle"></i> Add More Fee</span>
                        </button>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!--Client Step-->
  
      <!--Agency Action-->
      <div  formArrayName="agency_actions" class="col-6 ps-3" style="background-color: snow">
        <div class="px-2">
          <div class="mt-2" >
            <mat-accordion>
              <div >
                <div class="row">
                  <button
                  *ngIf="agencyAction().controls.length > 1"
                  (click)="addAgencyAction(null,true,0 )"
                  mat-button
                  color="primary"
                >
                <span><i class="fas fa-plus"></i> Insert Agency Action</span> 
                </button>
                </div>
                <div *ngFor="let actionForm of agencyAction().controls; let i = index;let c= count">
                 
                  <div class="mb-1" formGroupName="{{i}}">
                    <input type="hidden" formControlName="action_Id">
                    <mat-expansion-panel class="px-3" expanded="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title matTooltip = "{{getActionTitle(i)}}"
                          >{{client_step_index }}.{{ i + 1 }} {{getActionTitle(i) ? (getActionTitle(i).length > 50 ) ? (getActionTitle(i) | slice:0:50) + '...' : getActionTitle(i)  : 'Agency Action' }}
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isAgencyActionComplete(i)">
                          <span class="text-danger">Incomplete or Invalid Input/s!</span>
                       </mat-panel-description>
                      </mat-expansion-panel-header>
  
                      <div class="form-group row mt-1">
                        <mat-form-field appearance="outline">
                          <mat-label>Agency Action</mat-label>
                          <textarea  formControlName="title" required matInput></textarea>
                        </mat-form-field>
                      </div>
  
                      <label class="text-bold">Person Responsible</label>
  
                      <div formArrayName="persons_responsible" class="form-group row mt-1">
                        <div *ngFor="let person of persons_responsible(i).controls; let j = index; let c = count" class="row">

                          <div class="row person-responsible ms-2 p-1" [formGroupName]="j">
                          
                      
                              <div class="form-group row">
                                <mat-form-field appearance="outline">
                                  <mat-label>Designation</mat-label>
                                  <input
                                    matInput
                                 
                                    formControlName="designation"
                                    placeholder="Designation"
                                    required
                                  />
                                  <mat-error 
                                   ><span *ngIf="hasError(person,'duplicate')">Already Exists!</span>
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div class="form-group ">
                                <div class="col-sm-12">
                                  <div class="row">
                                    
                                    <mat-form-field class="mt-3" appearance="outline">
                                      <mat-label>Office / Division</mat-label>
                                      <mat-select formControlName="division_group" required>
                                        <mat-option
                                        value="Any of requesting office / division"
                                        >
                                        Any of requesting office / division
                                        </mat-option>
                                        <div *ngFor="let office of allOffices">
                                          <ng-container *ngIf="office?.name">
                                            <mat-optgroup  [label]="office.name"
                                            [disabled]="office.disabled">
                                            <mat-option [value]="office">
                                              {{office.name}}
                                            </mat-option>
                                            <mat-option *ngFor="let suboffice of office.offices" [value]="suboffice">
                                              {{suboffice.name}}
                                            </mat-option>
                                          </mat-optgroup>

                                          </ng-container>

                                          <ng-contaier *ngIf="!office?.name">
                                            <mat-option  [value]="office">{{ office?.name ? office.name : office }}</mat-option
                                          >
                                          </ng-contaier>

                                        </div>

                                       
                                       
                                        
                                      </mat-select>
                                    
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                         
                           
                              <!--
                                From normal input to selectable inputs
                                <div class="form-group row">
                                <mat-form-field appearance="outline">
                                  <mat-label>Division</mat-label>
                                  <input
                                    matInput
                                
                                    formControlName="division_group"
                                    placeholder="Division"
                                    required
                                  />
                                  <mat-error 
                                   ><span *ngIf="hasError(person,'duplicate')"></span>
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              -->
                              
                            <div >
                              <div class="">
                                <button [disabled]="c <= 1"
                                  mat-button
                                  color="warn"
                                  aria-label="Example icon button with a menu icon"
                                  (click)="removePersonResponsible(i,j)" 
                                >
                                <mat-icon >delete</mat-icon> <span>Remove</span></button>

                                
                              </div>
                            </div>
                          </div>
  
                        </div>
                        
  
                        <div class="col-3 mb-2">
                          <button (click)="addPersonResponsible(i)"  mat-button >
                            <span><i class="fas fa-plus-circle"></i> Add Person Responsible</span>
                          </button>
                        </div>
                      </div>
  
                      <label class="text-bold">Processing Time</label>
  
  
                      <div class="form-group row mt-1">
                        <div class="col-4">
                          <div class="form-group row">
                            <mat-form-field appearance="outline">
                              <mat-label>Days</mat-label>
                              <input
                                formControlName="processing_time_days"
                                matInput
                                type="text"
                                width="100%" 
                                placeholder="0"
                                required
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group row">
                            <mat-form-field appearance="outline">
                              <mat-label>Hours (0-{{maxHours}})</mat-label>
                              <input
                              formControlName="processing_time_hours"
                                matInput
                                type="text"
                                width="100%"
                                placeholder="0"
                                [max]="maxHours"
                                required
                              />
                            </mat-form-field>
                          </div>
                        </div>
  
                        <div class="col-4">
                          <div class="form-group row">
                            <mat-form-field appearance="outline">
                              <mat-label>Minutes (0-59)</mat-label>
                              <input
                              formControlName="processing_time_minutes"
                                matInput
                                width="100%"
                                type="text"
                                placeholder="0"
                                max="59"
                                required
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <input type="hidden"  formControlName="total_processing_time" >
  
                      <p ngClass="{{ invalidProcessingTime?  'text-danger' : '' }}">Format: {{getAgencyActionProcessingTime(i)}} {{ invalidProcessingTime ? ' *Processing time must not be zero': ''}}</p>
                      <mat-card-actions align="end">
                        <button (click)="removeAgencyAction(i)" [disabled]="agencyAction().controls.length == 1" mat-button color="warn" >
                          <mat-icon>delete</mat-icon> <span>Remove Agency Action</span>
                        </button>

                      </mat-card-actions>
                    </mat-expansion-panel>

                    <div class="row">
                      <button
                      *ngIf="i !== (c - 1)"
                      (click)="addAgencyAction(null,true,i + 1)"
                      mat-button
                      color="primary"
                    >
                    <span><i class="fas fa-plus"></i> Insert Agency Action</span> 
                    </button>
                    </div>
                  </div>
                </div>
              </div>
  
            </mat-accordion>
          </div>
        </div>
      </div>
      <!--Agency Action-->
    </div>
    
   </form>
  
 </div>
</mat-dialog-content>
<mat-dialog-actions>
  <p class="mx-2"><strong>Total Fees:</strong> <li *ngFor="let fee of calculateStandardFees()">{{fee.amount}} {{fee.currency}}</li></p>

</mat-dialog-actions>
<mat-dialog-actions>
 
  <button (click)="cancel()" mat-flat-button color="warn" mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="saveClientStep()"
  >
    Save Client Step and Agency Action
  </button>
</mat-dialog-actions>
