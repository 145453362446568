import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PbrisDashboardService } from 'src/app/service/pbris-dashboard.service';
@Component({
  selector: 'app-pbris-regulatory-dashboard',
  templateUrl: './pbris-regulatory-dashboard.component.html',
  styleUrls: ['./pbris-regulatory-dashboard.component.scss']
})
export class PbrisRegulatoryDashboardComponent implements OnInit {

  counts: { [key: string]: number } = {};
  countsExisting: { [key: string]: number } = {};
  userType: any = '';
  userSubtype: any = sessionStorage.getItem("account_subtype");

  constructor(private afs: AngularFirestore,
    private pbrisDashboard: PbrisDashboardService) { }

  ngOnInit(): void {
    this.loadCounts();
    this.userType = sessionStorage.getItem("user_type");
  }

  async loadCounts(): Promise<void> {
    try {
      this.counts = await this.pbrisDashboard.getAllCounts('Worklist-Proposed Regulations');
      this.countsExisting = await this.pbrisDashboard.getAllCounts('Worklist-Existing Regulations');
      console.log(this.counts);
    } catch (error) {
      console.log('error');
    }
  }


}
