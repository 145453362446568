import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { doc, getDoc } from 'firebase/firestore';
import { firstValueFrom, map, Observable } from 'rxjs';
import { PBRIS, ARTEMIS } from 'src/app/entities/constant';
import { SubAgency } from 'src/app/entities/subagency';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-organization-subagency',
  templateUrl: './organization-subagency.component.html',
  styleUrls: ['./organization-subagency.component.scss']
})

export class OrganizationSubagencyComponent implements OnInit {

  currentSystem: any = sessionStorage.getItem('currentSystem');
  agencyId = this.route.snapshot.params.agencyId;
  isAgencyAdmin: boolean = false;
  isAdmin: boolean = false;
  currentUser: any;
  currentUserId: any;
  agencyTitle: any = "";
  currentAgency: any;
  loader: boolean = false;
  pbris = PBRIS
  artemis = ARTEMIS

  documentId: string = ''; // Track the document ID if it exists

  subAgency: SubAgency[] = [];

  constructor(
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackbarNotifService
  ) { }

  ngOnInit(): void {
    this.checkUser();
    // this.loadAgency(this.agencyId)
    // this.getSubAgencyByAgencyId(this.agencyId)
    // this.checkForExistingAgency();
    this.getAgencyData(this.agencyId);
    console.log(this.agencyId)
    // Initialize with a root node
    // this.subAgency.push({ id: 1, subagency: '', children: [] });
  }



  // Add a child node to the specified parent
  addSubAgency(parentId: number | null): void {
    const newId = this.generateUniqueId();
    const newNode: SubAgency = {
      id: this.generateUniqueId(),
      agencyId: this.agencyId,
      subagency: '',
      structure: [],
    };

    if (parentId === null) {
      // Add as a root node
      this.subAgency.push(newNode);
    } else {
      // Add to the correct parent node
      const parentNode = this.findNodeById(parentId, this.subAgency);
      if (parentNode) {
        parentNode.structure.push(newNode);
      } else {
        console.error('Parent node not found:', parentId);
      }
    }
    console.log(this.subAgency);
  }

  // Find a node by its ID recursively
  findNodeById(id: number, nodes: any[]): any {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      const foundChild = this.findNodeById(id, node.structure);
      if (foundChild) {
        return foundChild;
      }
    }
    return null;
  }

  // Remove a node by its ID recursively
  removeSubAgency(id: number): void {
    this.subAgency = this.deleteNodeById(id, this.subAgency);
  }

  deleteNodeById(id: number, nodes: any[]): any[] {
    return nodes.filter(node => {
      if (node.id === id) {
        return false; // Remove the node
      }
      node.structure = this.deleteNodeById(id, node.structure); // Recurse for children
      return true;
    });
  }

  // Generate a unique ID for each node
  private generateUniqueId(): number {
    return Math.floor(Math.random() * 100000); // Simplified for this example
  }

  async checkUser() {
    if (await this.auth.user) {
      switch (sessionStorage.getItem("user_type")) {
        case 'arta': {
          this.isAdmin = sessionStorage.getItem("arta_admin") === "true" ? true : false
          this.auth.onAuthStateChanged((user: any) => {
            if (user.uid) {
              firstValueFrom(this.afs.collection('Users').doc(user.uid).get())
                .then((data) => {
                  if (data.exists) {
                    const item: any = data.data()
                    item.id = user.uid
                    this.currentUser = item
                    this.currentUserId = user.uid
                  }
                })
            }
          })
          break;
        }
        case 'agency': {
          this.auth.onAuthStateChanged((user: any) => {
            if (user.uid) {
              firstValueFrom(this.afs.collection('Users').doc(user.uid).get())
                .then((data) => {
                  if (data.exists) {
                    const item: any = data.data()
                    item.id = user.uid
                    this.currentUser = item
                    this.currentUserId = user.uid
                    if (item.government.agency !== this.agencyId) {
                      this.router.navigate([''])
                      return;
                    }
                    if (item.credentials.account_subtype.trim().toLowerCase().includes("government")
                      && item.credentials.account_subtype.trim().toLowerCase().includes("it/admin")
                      && item.government.agency === this.agencyId) {
                      this.isAgencyAdmin = true
                    }
                    else {
                      this.router.navigate(['/artemis/agency/view/' + this.agencyId])
                    }
                  }
                })
            }
          })
          break
        }
        default: this.toHome()
      }
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  loadAgency(agencyId:any){
    this.loader = true
    this.afs.collection('Agency').doc(agencyId).snapshotChanges()
    .subscribe(info=>{
      const agency:any = info.payload.data()
      agency.id = info.payload.id;
      this.agencyTitle = 
        (agency.agencyDetails) 
          ? ((agency.agencyDetails.agencyName) 
              ?(agency.agencyDetails.agencyName) 
              : ((agency.name) ? (agency.name) : "") ) 
          : ((agency.name) ? (agency.name) : "")
      this.currentAgency = agency
    })
  }

  toHome() {
    this.router.navigate(['/'])
  }
  // constructor(private afs: AngularFirestore) { }

  // ngOnInit(): void {
  // }

  // subAgency: { agency: string, parentDocId: string, subagency: string }[] = [{ agency: '', parentDocId: '', subagency: '' }];


  // addSubAgency() {
  //   this.subAgency.push({ agency: '', parentDocId: '', subagency: '' });
  // }
  // removeSubAgency(index: number) {
  //   this.subAgency.splice(index, 1);
  // }

  async uploadSubAgency() {
    // var agencyId = this.agencyId;
    var docId = this.documentId;
    console.log(docId);
    if (docId == '') {
      try {
        const subagencyCollection = await this.afs.collection('PBRIS-Agency Structure').add({
          agencyId: this.agencyId,
          structure: this.subAgency
        });

        console.log(`Added Sub-agency with Document ID: ${subagencyCollection.id}`);
        this.router.navigate(['/pbris/regulatory-management/']);
        this.snackBarService.openPlainNotif("Agency Structure Saved Successfully", "Close");
      } catch (error) {
        console.error('Error adding document: ', error);
        this.snackBarService.openPlainNotif("Error Saving Agency Structure", "Close");
      }
    }
    else {
      //update
      console.log('this is the doc id:' + docId);
      this.afs
        .collection('PBRIS-Agency Structure')
        .doc(docId)
        .update({
          agencyId: this.agencyId,
          structure: this.subAgency
        })
        .then(() => {
          this.router.navigate(['/pbris/regulatory-management/'])
          this.snackBarService.openPlainNotif("Organizational Structure Saved Successfully", "Close");
        })
        .catch(error => {
          console.error('Error updating document: ', error);
          this.snackBarService.openPlainNotif("Error Saving Agency Structure", "Close");
        });
    }

  }

  getAgencyData(agencyId: string): void {
    this.afs
      .collection('PBRIS-Agency Structure', ref => ref.where('agencyId', '==', agencyId))
      .get()
      .subscribe(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]; // Retrieve the first matching document
          this.documentId = doc.id;
          const data = doc.data() as { structure: SubAgency[] };
          console.log('Retrieved data:', data);

          // Check if structure exists and assign it
          if (data.structure && Array.isArray(data.structure)) {
            this.subAgency = data.structure; // Populate subAgency with the retrieved data
          } else {
            console.warn('structure is missing or not an array. Setting to an empty array.');
            this.subAgency = []; // Fallback to an empty array
          }
        } else {
          console.log('No matching document found for agencyId:', agencyId);
          this.subAgency = []; // If no document is found, initialize to an empty array
        }
      });
  }


  async checkForExistingAgency(): Promise<void> {
    try {
      const snapshot = await firstValueFrom(
        this.afs.collection('PBRIS-Agency Structure', (ref) =>
          ref.where('agencyId', '==', this.agencyId)
        ).get()
      );

      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        this.documentId = doc.id;
        console.log(doc.data());
        this.subAgency = (doc.data() as { subAgency: SubAgency[] }).subAgency || [];
        // this.subAgency = doc.data();
        console.log('Doc ID', this.documentId);
        console.log('Existing agency data loaded:', this.subAgency);
      } else {
        console.log('No existing agency found. Initializing new structure.');
        // Initialize with a root node if no data exists
        this.subAgency.push({
          id: this.generateUniqueId(),
          agencyId: this.agencyId,
          subagency: 'Root Agency',
          structure: [],
        });
      }
    } catch (error) {
      console.error('Error checking for existing agency:', error);
    }
  }

  getSubAgencyByAgencyId(agencyId: string): Observable<any> {
    return this.afs
      .collection('PBRIS-Agency Structure', ref => ref.where('agencyId', '==', agencyId))
      .valueChanges({ idField: 'docId' }) // Include document ID if needed
      .pipe(
        map(docs => {
          if (docs.length > 0) {
            console.log('it works')
            return docs[0]; // Return the first match
          } else {
            console.error('No document found with agencyId:', agencyId);
            return null;
          }
        })
      );
  }

  toRegulatoryManagement() {
    this.router.navigate(['/pbris/regulatory-management'])
  }
}
