import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { first } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-regulatory-management',
  animations: [
    // bugs out /delays sidenav-content width
    trigger('collapsed',[
      state('open', style({width: '235px'})),
      state('close', style({width: '50px'})),
      // state('auto1', style({width: '100%'})),
      // state('auto2', style({width: '100%'})),
      state('auto1', style({width: '*'})),
      state('auto2', style({width: '*'})),
      // transition('open => close',[
      //   animate('0.2s ease')
      // ]),
      // transition('close => open',[
      //   animate('0.2s ease')
      // ]),
      // transition('auto1 => auto2',[
      //   animate('0.2s ease')
      // ]),
      // transition('auto2 => auto1',[
      //   animate('0.2s ease')
      // ]),
    ])
  ],
  templateUrl: './regulatory-management.component.html',
  styleUrls: ['./regulatory-management.component.scss'],
})
export class RegulatoryManagementComponent implements OnInit {
  isAgency : boolean = false;
  isAdmin : boolean = false;
  isAgencyAdmin :boolean =false;
  
  collapse = false;
  dashboardView = false;
  profile:boolean = false;
  accountprofileView: boolean =false;
  accountverificationView : boolean = false;
  systemsettingsView : boolean =false;
  inboxView:boolean = false;
  inboxMode:string = '';
  account_management_link :any = "";
  agency_structure :any ="";
  agency_id = sessionStorage.getItem('agency_id')

  inboxExpander = false;
  myworklistExpander = false;
  ticketExpander = false;
  regulatorystockEpander = false;
  // in-line components in the page
  regulatory_stock_view: boolean = false;
  regulation_view:boolean = false;
  
  regulatory_inbox_view:boolean = false;
  sub_inbox:boolean = false;
  sub_inbox_existing:boolean = false;
  sub_inbox_proposed:boolean = false;

  notification_view:boolean = false;

  ticket_root:boolean = false;
  sel_ticket:boolean = false;
  create_ticket: boolean = false;
  regulation_proposed_notification_view: boolean = false;
  feedback_form: boolean = false;
  feedback_list: boolean = false;
  comment_list: boolean = false;

  existing_regulation_collection_name = 'regulations-existing-phase-02';
  existing_regulation_worklist_collection_name = 'Worklist-Existing Regulations';
  proposed_regulation_collection_name = 'regulations-proposed-phase-02';
  proposed_regulation_worklist_collection_name = 'Worklist-Proposed Regulations';

  proposed_regulation_notification_name ='regulation-proposed-notifications';

  activateChild:boolean = false;
  // activateSelection:string = ''
  activateObject:any;
  activateTrigger:boolean = false;

  //notification related
  notification_collection_name= 'Notifications';

  // global settings
  userType: any = sessionStorage.getItem("user_type");
  agencyName: any = sessionStorage.getItem("agency_name");
  userSubtype: any = sessionStorage.getItem("account_subtype");
  user:any = {};
  userLoading:boolean = true


  constructor(private router: Router, private afs: AngularFirestore, public auth: AngularFireAuth, private route: ActivatedRoute) {
  switch(sessionStorage.getItem('account_subtype') || '') {
      case GovernmentAccountSubtype.AGENCY_ADMIN:
        this.isAgencyAdmin = true;
        break;
      case GovernmentAccountSubtype.ARTA_ADMIN:
        this.isAdmin = true;
        this.isAgency =false;
        break;
      case GovernmentAccountSubtype.AGENCY_ENC:
      case GovernmentAccountSubtype.AGENCY_VER:
        this.inboxMode = 'agency';
        this.isAdmin = false;
        this.isAgency = true;
        break;
      case GovernmentAccountSubtype.AGENCY_DIR:
        this.inboxMode = 'agency-head';
        this.isAgency = true;
        this.isAdmin = false;
     
        break;


  }
    //console.log('subtype',this.userSubtype)
    
    auth.user
    .pipe(first())
    .subscribe({
      next:(res)=>{
        // const id = res?.uid
        console.log('res',res)
        this.user.auth = {}
        this.user.auth.displayName = res?.displayName
        this.user.auth.uid = res?.uid
        this.user.auth.email = res?.email
        // console.log('auth',this.user)
        if(this.user.auth.uid) this.getUserData(this.user.auth.uid)
        else this.userLoading = false
      },
      error:(err)=>{
        console.warn('User not authenticated',err)
        this.userLoading = false
      }
    })
  }

  ngOnInit(): void {
    this.account_management_link = "/agency/view/" + this.agency_id + "/members"
    this.agency_structure = "/pbris/view/agency/"+ this.agency_id +"/subagency"
    let regulatorySelection = sessionStorage.getItem('regulatoryActive')
    if(regulatorySelection != undefined && regulatorySelection != null &&regulatorySelection != ''){
      // console.log(regulatorySelection)
      let removeList:any[] = []
      if(regulatorySelection == 'phase 2 existing'){
        this.activateChild = true
        this.activateObject = {
          selection: sessionStorage.getItem('regulatoryActive'),
          worklistId: sessionStorage.getItem('worklistId'),
          regulationId: sessionStorage.getItem('regulationId')
        }
        this.openWorklistExisting2()
        removeList.push('regulatoryActive')   
        removeList.push('worklistId')
        removeList.push('regulationId')
      }
      else if(regulatorySelection == 'phase 2 proposed'){
        //add worklist id and regulation in pbris-upload-proposed-regulations
        // this.activateChild = true
        // this.activateObject = {
        //   selection: sessionStorage.getItem('regulatoryActive'),
        //   worklistId: sessionStorage.getItem('worklistId'),
        //   regulationId: sessionStorage.getItem('regulationId')
        // }
        this.openWorklistProposed2()
        removeList.push('regulatoryActive')
        // removeList.push('worklistId')
        // removeList.push('regulationId')
      }
      else if(regulatorySelection == 'regulatoryStock'){
        this.openRegulatoryStock()
        removeList.push('regulatoryActive')
      }
      this.rersetActiveSelection(removeList)
    }
  }

  getUserData(id:any){
    this.afs.collection('Users').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe({
      next:(res)=>{
        if(res.payload.exists){
          let data = res.payload.data()
          this.user.data = data
          if(this.user.data.government && this.user.data.government.agency) this.getAgencyDataCollector(this.user.data.government.agency)
          else this.userLoading = false
          // this.userLoading = false
          console.log('data',this.user)
        }
        else
          this.userLoading = false
      },
      error:(err)=>{
        console.warn('User no data: ',err)
        this.userLoading = false
      }
    })
  }

  async getAgencyDataCollector(value:any){
    const agencyData1: any = await this.getAgencyData('id',value)
    if(agencyData1){
      console.log('agencyData1 good',agencyData1)
      this.user.agency = agencyData1
      this.userLoading = false
    }else{
      console.warn('agencyData1 bad')
      const agencyData2: any = await this.getAgencyData('name',value,'agencyDetails.agencyName')
      if(agencyData2.length > 0){
        console.log('agencyData2 good',agencyData2[0])
        this.user.agency = agencyData2
        this.userLoading = false
      }
      else{
        console.warn('agencyData2 bad')
        const agencyData3: any = await this.getAgencyData('name',value,'name')
        if(agencyData3.length > 0){
          console.log('agencyData3 good',agencyData3[0])
          this.user.agency = agencyData3
          this.userLoading = false
        }else{
          console.warn('agencyData3 bad')
          this.userLoading = false
        }
      }
    }
  }

  getAgencyData(code:any,value:any,lookFor?:any){
    return new Promise((res,rej)=>{
      if(code == 'id'){
        this.afs.collection('Agency').doc(value).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let user:any = result.payload.data()
            user.id = result.payload.id
            return res(user)
          },
          error:(err)=>{
            return res(false)
          }
        })
      }
      else if(code == 'name'){
        this.afs.collection('Agency',filter=>filter.where(lookFor,'==',value)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let tempArr:any = []
            result.map((item)=>{
              let agency:any = item.payload.doc.data()
              agency.id = item.payload.doc.id
              if(!tempArr.map((info:any)=>info.id).includes(agency.id)) tempArr.push(agency)
              return res(tempArr)
            })
          },
          error:(err)=>{
            console.error('getAgencyData : '+code+' | '+lookFor,err)
            return res(false)
          }
        })
      }
      else return res(false)
    })
  }

  getAcronym(str:any){
    let matches = str.match(/\b(\w)/g);
    let acronym = matches.join('');
    return acronym;
  }


  rersetActiveSelection(list:any[]){
    list.forEach((item:any)=>{
      sessionStorage.removeItem(item)
    })
  }

  resetActivatedValues(){
    this.activateChild = false
    this.activateObject = {}
  }

  toggleSideNav(){
    this.collapse = !this.collapse
  }

  toggleExpander(value:any){
    if(value == 'inbox'){
      this.inboxExpander = !this.inboxExpander
    }
    else if(value == 'myworklist'){
      this.myworklistExpander = !this.myworklistExpander
    }
    else if(value == 'ticket'){
      this.ticketExpander = !this.ticketExpander
    }
  }

  resetFlags(){
    this.dashboardView =false;
    this.profile =false;
    this.accountprofileView = false;
    this.accountverificationView = false;
    this.systemsettingsView = false;

    this.regulatory_stock_view = false;
    this.regulatory_inbox_view = false;
    this.regulation_view = false;
    
    this.regulation_proposed_notification_view = false;

    this.sub_inbox = false;
    this.sub_inbox_existing = false;
    this.sub_inbox_proposed = false;

    this.notification_view = false;

    this.ticket_root = false;
    this.sel_ticket = false;
    this.create_ticket = false;
    this.feedback_form = false;
    this.feedback_list = false;
    this.comment_list = false;

    if(!this.activateTrigger) this.activateTrigger = true
    else this.resetActivatedValues()
  }

  resetTicketFlags(){
    this.ticket_root = false;
    this.sel_ticket = false;
    this.create_ticket = false;
  }

  opendashboardView(){
    this.resetFlags();
    this.dashboardView = true;
  }

  openagencypageView(){
    this.resetFlags();
    this.profile = true;
  }

  openaccountprofileView(){
    this.resetFlags();
    this.accountprofileView = true;
  }

  openaccountverificationView(){
    this.resetFlags();
    this.accountverificationView = true;
  }

  opensystemsettingsView(){
    this.resetFlags();
    this.systemsettingsView = true;
  }

  openRegulatoryStock(){
    this.resetFlags();
    this.regulatory_stock_view = true;
  }

  openRegulatoryPlanning(){
    this.resetFlags();
  }

  openRegulatoryInbox(){
    this.resetFlags();
    this.sub_inbox = true;
  }

  openNotifications(){
    this.resetFlags();
    this.notification_view = true;
  }
  
  openWorklistExisting(){
    if(this.sub_inbox) {
      this.resetTicketFlags();
      this.regulatory_inbox_view = true;
      this.sub_inbox_existing = true;
      this.sub_inbox_proposed = false;
    }
  }

  openWorklistExisting2(){
    this.resetFlags();
    this.regulatory_inbox_view = true;
    this.sub_inbox_existing = true;
    this.sub_inbox_proposed = false;
    this.comment_list = false;
  } 

  openWorklistProposed(){
    if(this.sub_inbox) {
      this.resetTicketFlags();
      this.regulatory_inbox_view = true;
      this.sub_inbox_existing = false;
      this.sub_inbox_proposed = true;
    }
  }

  openWorklistProposed2(){
    this.resetFlags();
    this.regulatory_inbox_view = true;
    this.sub_inbox_existing = false;
    this.sub_inbox_proposed = true;
    this.comment_list = false;
  }

  openRegulationView(){
    this.resetFlags();
    this.regulation_view = true; 
  }
  
  openRegulationProposedNotificationView(){
    this.resetFlags();
    this.regulation_proposed_notification_view = true; 
  }

  openTickets(){
    if(this.sub_inbox) {
      this.regulatory_inbox_view = false;
      this.sub_inbox_existing = false;
      this.sub_inbox_proposed = false;
      this.create_ticket = false;
      this.sel_ticket = true;
      this.ticket_root = true;
    }
  }

  openTickets2(){
    this.resetFlags()
    this.regulatory_inbox_view = false;
    this.sub_inbox_existing = false;
    this.sub_inbox_proposed = false;
    this.create_ticket = false;
    this.sel_ticket = true;
    this.ticket_root = true;
    this.comment_list = false;
  }

  createTickets(){
    if(this.sub_inbox && this.ticket_root) {
      this.regulatory_inbox_view = false;
      this.create_ticket = true;
      this.sel_ticket = false;
    }
  }

  createTickets2(){
    this.resetFlags()
    this.regulatory_inbox_view = false;
    this.create_ticket = true;
    this.sel_ticket = false;
    this.comment_list = false;
  }

  feedbackForm(){
    this.resetFlags();
    this.feedback_form = true;
  }

  feedbackList(){
    this.resetFlags();
    this.feedback_list = true;
  }

  commentList(){
    this.resetFlags();
    this.comment_list = true;
  }

}
