import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {Location} from '@angular/common'
import { ArtemisCitizensCharterViewComponent } from '../../artemis-citizens-charter-view/artemis-citizens-charter-view.component';

@Component({
  selector: 'app-charter-reports',
  templateUrl: './charter-reports.component.html',
  styleUrls: ['./charter-reports.component.scss']
})
export class CharterReportsComponent implements OnInit {
  @ViewChild(ArtemisCitizensCharterViewComponent) aCCViewComponent:ArtemisCitizensCharterViewComponent

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];

  // cc_id:any = this.route.snapshot.params.id
  // agency_id:any = this.route.snapshot.queryParams.agency
  cc_id:any;
  agency_id:any;
  currentCC:any;
  currentCCReportURL:any;
  agencies:any[]= []
  agencyName:any;
  currentAgency:any;
  headOfAgency:any[]= [];
  chairPerson:any[]= [];
  viceChairPerson:any[]= [];
  member:any[]= [];
  // services:any[] = []
  services:any = {
    internal:[],
    external:[]
  }

  disabler:boolean=true;
  ng_if:boolean=false;

  loader:boolean = true;
  loaderGenerateDoc: boolean = false;
  loaderCheckFile:boolean = false;

  currentUser:any;
  
  isAgencyHead:boolean = false;
  isArta:boolean = false;
  isEncoder:boolean = false;
  isVerifier:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private http: HttpClient,
    public auth: AngularFireAuth,
    private store: AngularFireStorage,
    private _location: Location,
  ) {
    //console.log('CharterReportsComponent 1')
    this.checkUser();
  }

  ngOnInit(): void {
    // console.log('CharterReportsComponent 2')
    this.loader =true;
    this.route.queryParams.subscribe({
      next:(result)=>{
        //console.log('Edition: ',result.edition)
        if(result.cc_id){
          this.cc_id = result.cc_id
        }
        else if(result.agency){
          this.agency_id = result.agency
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  updateCCLogo(){
    this.afs.collection('citizens-charter-phase-02').doc(this.cc_id)
    .snapshotChanges()
    .pipe(first())
    .subscribe()
  }

  checkUser(){
    //console.log('checkUser')
    if(sessionStorage.getItem('user_type')){
      const userType = sessionStorage.getItem('user_type')
      //console.log('userType: ',userType)
      switch(userType){
        case 'agency':{
          //console.log('account_subtype: ',sessionStorage.getItem('account_subtype'))
          if(sessionStorage.getItem('account_subtype')){
            const accountSubtype = sessionStorage.getItem('account_subtype')
            switch(accountSubtype){
              case GovernmentAccountSubtype.AGENCY_DIR:{
                this.isAgencyHead = true
                break
              }
              case GovernmentAccountSubtype.AGENCY_VER:{
                this.isVerifier = true
                break
              }
              case GovernmentAccountSubtype.AGENCY_ENC:{
                this.isEncoder = true
                break
              }
            }
          }
          break;
        }
        case 'arta':{
          this.isArta = true
          break;
        }
        default:{
          // this.router.navigate(['/artemis/citizen-charter/view/',this.cc_id])
          // this.router.navigate(['/artemis/citizen-charter/view/'],{
          //   queryParams:{cc_id: this.cc_id}
          // })
          //this.goToCC()
          break;
        }
      }
    } else{
      // this.router.navigate(['/artemis/citizen-charter/view/',this.cc_id])
      //this.goToCC()
    }
  }
  
  transform(n: number, year: string, keepNumber: boolean = true) {
    if(n != null){
      let v = n % 100;
      return year  + " "+  (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) + " Edition";
    }
    return 'Edition'
  }

  //file generation
  generateReport(){
    // console.log('generateReport')
    this.loader = true
    this.loaderGenerateDoc = true
    if(this.currentCC && this.currentAgency){
      this.generatePDFFile(this.currentCC)
    }
    else{
      // console.log("can't generate report")
      this.loaderGenerateDoc = false
      this.loader = false
    }
  }

  checkReport(){
    this.loader = true
    this.loaderCheckFile = true
    if(this.currentCC && this.currentAgency){
      this.checkDocumentExist(this.currentCC)
      // if (this.currentCC.reportURL){
      //   const signedURLValid:boolean = this.extractDateFromUrl(this.currentCC.reportURL)
      //   if(signedURLValid){
      //     this.checkDocumentExist(this.currentCC,true)
      //   }
      //   else{
      //     this.checkDocumentExist(this.currentCC,false)
      //   }
      // }
      // else {
      //   this.checkDocumentExist(this.currentCC,false)
      //   // alert("No PDF file to check, Generate a report first")
      // }
    }
    else{
      //console.log("can't check report")
      alert("No PDF file to check, Invalid Charter and Agency")
      this.loaderCheckFile = false
      this.loader = false
    }
  }

  private async checkDocumentExist(ccDoc:any){
    // console.log("checkDocumentExist")
    // const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/checkDocExist"; //cloudfunc /to check document existence /no checking
    const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReportV2/api/v1/checkDocumentExist";
    let  serviceData ={
      generationFor: "ccAgencyReports",
      id: this.cc_id,
      agency: {agencyCode: this.currentAgency.id}
    };
    //console.log('serviceData: ',serviceData)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    this.http.post<any> (apiUrl,serviceData,httpOptions)
    .subscribe({
      next:(apiResponse:any)=>{
        // { message: string, exist: boolean  }
        //console.log('apiResponse: ',apiResponse)
        if(apiResponse){
          if(apiResponse.exist == true && ccDoc.reportURL){
            window.open(ccDoc.reportURL)
            this.loaderCheckFile = false
            this.loader = false
          }else{
            alert("No PDF file to check, Generate a report first")
            this.loaderCheckFile = false
            this.loader = false
          }
        }else{
          alert("Checking Report failed")
          this.loaderCheckFile = false
          this.loader = false
        }


        // if(apiResponse.exist && urlValid){
        //   window.open(ccDoc.reportURL)
        //   this.loader = false
        // }
        // else if(apiResponse.exist && !urlValid){
        //   this.signUrl(ccDoc)
        // }
        // else if(!apiResponse.exist){
        //   // this.generatePDFFile(ccDoc)
        //   alert("No PDF file to check, Generate a report first")
        //   this.loader = false
        // }
        // else{
        //   alert("Checking Report failed")
        //   this.loader = false
        // }
      },
      error:(err:any)=>{
        console.error("Error checking document: ",err)
        this.loader = false
      }
    })
  }

  private async generatePDFFile(ccDoc:any){
    // console.log('generatePDFFile')
    const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReportV2/api/v1/generateDoc"; //cloudfunc endpoint / to generate /no checking
    

    let external:any[] = []
    let internal:any[] = []

    this.services.map((service: any)=>{
      switch(service.serviceDetails.service_category){
        case 'External Service':external.push(service);break;
        case 'Internal Service':internal.push(service);break;
      }
    })
    
    const ccData:any = {
      generationFor:"ccAgencyReports",
      fullEditionStr:"Citizen's Charter "+this.transform(this.currentCC?.edition ? this.currentCC.edition : null, this.currentCC.date_posted.toDate().getFullYear()),
      id:this.cc_id,
      agency:{
        agencyLogoURL: this.currentAgency?.agencyDetails?.logoURL ? this.currentAgency.agencyDetails.logoURL : "",
        // agencyName: this.currentAgency.agencyDetails.agencyName ? this.currentAgency.agencyDetails.agencyName : this.currentAgency.name ? this.currentAgency.name : "",
        agencyName: this.currentCC.fromAgencyName,
        agencyCode: this.currentCC.fromAgencyId,
        agency_info:{
          // mission: this.currentAgency.agencyInfo.mission ? this.currentAgency.agencyInfo.mission : this.currentAgency.mission ? this.currentAgency.mission : "",
          // vision: this.currentAgency.agencyInfo.vision ? this.currentAgency.agencyInfo.vision : this.currentAgency.vision ? this.currentAgency.vision : "",
          // mandate: this.currentAgency.agencyInfo.mandate ? this.currentAgency.agencyInfo.mandate : this.currentAgency.mandate ? this.currentAgency.mandate : "",
          // pledges: this.currentAgency.agencyInfo.pledges ? this.currentAgency.agencyInfo.pledges : this.currentAgency.pledges ? this.currentAgency.pledges : "",
          mission: this.currentCC?.agency_profile?.mission ? this.currentCC.agency_profile.mission : "",
          vision: this.currentCC?.agency_profile?.vision ? this.currentCC.agency_profile.vision : "",
          mandate: this.currentCC?.agency_profile?.mandate ? this.currentCC.agency_profile.mandate : "",
          pledges: this.currentCC?.agency_profile?.service_pledge ? this.currentCC.agency_profile.service_pledge : "",
        }
      },
      cartDetails:{
        titles:{
          headOfAgencyTitle: this.currentAgency?.cartDetails?.titles?.headOfAgencyTitle ? this.currentAgency?.cartDetails?.titles?.headOfAgencyTitle : 'Agency Head',
          chairPersonTitle: this.currentAgency?.cartDetails?.titles?.chairPersonTitle ? this.currentAgency?.cartDetails?.titles?.chairPersonTitle : 'Chairpersons',
          viceChairPersonTitle: this.currentAgency?.cartDetails?.titles?.viceChairPersonTitle ? this.currentAgency?.cartDetails?.titles?.viceChairPersonTitle : 'Vice-Chairpersons',
          membersTitle: this.currentAgency?.cartDetails?.titles?.membersTitle ? this.currentAgency?.cartDetails?.titles?.membersTitle : 'Members',
        }
      },
      services: {
        external:external,
        internal:internal
      },
      hOA: this.headOfAgency,
      cP:this.chairPerson,
      vCP:this.viceChairPerson,
      memb:this.member
    };

    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    
    // console.log('generate report: ',ccData)
    this.http.post<any> (apiUrl,ccData,httpOptions)
    .subscribe(
      (apiResponse:any)=>{
        //console.log('apiResponse: ',apiResponse)
        if(apiResponse && apiResponse.processDone && apiResponse.result){
          // this.signUrl(ccDoc)
          this.toGetDownloadURL(ccDoc)
        }
        else{
          alert("generating pdf failed, try again")
          this.loaderGenerateDoc = false
          this.loader = false
        }
      },
      (error:any)=>{
        console.error("Error generating file ")
        this.loaderGenerateDoc = false
        this.loader = false
      }
    )
  }

  private async toGetDownloadURL(ccDoc:any){
    // console.log('toGetDownloadURL')
    // const publicBucket = 'artemis_services_public_documents'//functions.config().storagebucket.publicreportsurl;
    // const publicStorage = this.store.storage.app.storage(publicBucket)
    
    const privateBucket = 'artemis_services_documents'//functions.config().storagebucket.publicreportsurl;
    const privateStorage = this.store.storage.app.storage(privateBucket)

    const storagePath = "reports/agency/"+this.currentCC.fromAgencyId+"/citizenscharter/"+this.cc_id+"/"
    const fileName = this.cc_id+"_agency_cc.pdf"
    const filePath = storagePath+fileName
    privateStorage.ref(filePath).getDownloadURL()
    .then((url)=>{
      //console.log('URL: ',url)
      if(url){
        this.updateFirebaseReportLink(ccDoc,url);
        window.open(url)
        // this.loaderGenerateDoc = false
        // this.loader = false
      }else{
        alert('Failed to generate URL, try again')
        this.loaderGenerateDoc = false
        this.loader = false
      }
    })
    .catch((err)=>{
      console.error('Error: ',err)
      alert('Failed to generate URL, try again')
      this.loaderGenerateDoc = false
      this.loader = false
    })
  }

  //added logo url in CC model
  updateFirebaseReportLink(ccDoc:any,link:any){
    ccDoc.reportURL = link
    
    this.afs.collection('citizens-charter-phase-02').doc(ccDoc.id).update({
      reportURL: ccDoc.reportURL,
      logoURL: this.currentAgency.agencyDetails.logoURL
    })
    .then(()=>{
      this.currentCC.reportURL = link
      this.aCCViewComponent.updateLinkTrigger(link)
      this.currentCCReportURL = link
      this.loaderGenerateDoc = false
      this.loader = false
    })
    .catch(err=>{
      this.loaderGenerateDoc = false
      this.loader = false
      console.error("Error updating CC firebase ")
    })
  }
  //

  changeReceiver($event:any){
    // console.log('updating receiver: ',$event)
    switch($event.code){
      case 'loader':this.loader = $event.value;break;
      case 'ccId':{
        if(!this.cc_id){
          this.cc_id = $event.value
        }
        break;
      }
      case 'currentCC':{
        if(!this.currentCC)
          this.currentCC = $event.value;
        break;
      }
      case 'currentAgency':this.currentAgency = $event.value;break;
      case 'services':this.services = $event.value;break;
      case 'cart':{
        this.headOfAgency = $event.value.headOfAgency,
        this.chairPerson = $event.value.chairPerson,
        this.viceChairPerson = $event.value.viceChairPerson,
        this.member = $event.value.member
        break;
      }
    }
  }

  goToCC(){
    this.router.navigate(['/artemis/citizen-charter/view'],{
      queryParams:{
        cc_id: this.cc_id
      }
    })
  }

  returnToAgencyProfile(){
      this._location.back();
  }
}
