<div class="content padded">
  <div class="container-fluid">
    <div class="row">

        <!-- Quick Status (Style 1)-->
        <div class="row mt-3 mb-4">
          <div *ngIf="countDraft != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-gray rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#000000;margin-left:auto;margin-right:auto;">{{ countDraft }}</h4>
              </div>
              <div class="col-8 p-2 borrounded-start rounded-6" style="background-color:#fff">
                <div class="card-body">
                  <div class="card-title mb-0">DRAFTED<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Encoder
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countVerification != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-primary rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFF;margin-left:auto;margin-right:auto;">{{ countVerification }}</h4>
              </div>
              <div class="col-8 badge-primary p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR VERIFICATION<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Verifier
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countConsolidation != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-dark rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFF;margin-left:auto;margin-right:auto;">{{ countConsolidation }}</h4>
              </div>
              <div class="col-8 badge-light p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR REVISION<br><!-- For Comments Consolidation -->
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Encoder
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countApproval != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-warning rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFFFF;margin-left:auto;margin-right:auto;">{{ countApproval }}</h4>
              </div>
              <div class="col-8 badge-warning p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR APPROVAL<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Head of Agency
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countPosting != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-success rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFFFF;margin-left:auto;margin-right:auto;">{{ countPosting }}</h4>
              </div>
              <div class="col-8 badge-success p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR POSTING<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Head of Agency
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countAgencyReview != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-info rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFFFF;margin-left:auto;margin-right:auto;">{{ countAgencyReview }}</h4>
              </div>
              <div class="col-8 badge-info p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR AGENCY REVIEW<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>Agency
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="countARTAReview != 0" class="col-lg-3 col-md-3 col-sm-12">
            <div class="row my-2">
              <div class="col-3 bg-danger rounded-end rounded-6 d-flex align-items-center">
                <h4 class="card-text fw-bold" style="display:block;color:#FFFFFFFF;margin-left:auto;margin-right:auto;">{{ countARTAReview }}</h4>
              </div>
              <div class="col-8 badge-danger p-2 rounded-start rounded-6">
                <div class="card-body">
                  <div class="card-title mb-0">FOR ARTA REVIEW<br>
                    <span style="font-size:13px;">
                      <i class="fas fa-user-edit me-2"></i>ARTA
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="card mb-5" style="border-top:1px solid #DDDFDF;">
          <div class="card-body">
            <div class="card-header"><h6 class="fw-bold">MY WORKLIST
              <button type="button" class="btn btn-link p-0 ms-4" data-mdb-ripple-color="dark" (click)="refreshList()" matTooltipPosition="above" matTooltip="Refresh">
                <i class="fas fa-redo fa-bold fa-lg" alt="Refresh" style="color:#000000;"></i></button></h6>
            </div>
          
            <!-- Quick Status (Style 2)-->
            <!--<div class="row text-center">
              <div  class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-dark" style="color:#FFFFFF;">Drafted</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countDraft }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>

              <div  class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-primary" style="color:#FFFFFF;">For Verification</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countVerification }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>

              <div class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-warning">For Comments Consolidation</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countConsolidation }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>

              <div  class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-success" style="color:#FFFFFF;">For Approval</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countApproval }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>

              <div  class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-info" style="color:#FFFFFF;">For Agency Review</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countAgencyReview }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>

              <div  class="col-2 mt-3">
                <div class="card">
                  <div class="card-header bg-danger" style="color:#FFFFFF;">For ARTA Review</div>
                  <div class="card-body">
                    <h5 class="card-title">{{ countARTAReview }}</h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>
            </div> -->

              <div class="inboxStart">
                
                <!-- Quick Status (Style 3)-->
                <!-- <div class="row my-3">
                  <div class="col-auto" style="background-color: orange">
                    <div class="container my-auto">
                      <h3>Quick Stats</h3>
                    </div>
                  </div>
                  <div class="text-center">
                    <table class="table table-lg">
                      <tr>
                        <th *ngIf="countDraft != 0" class="col-2 bg-dark" style="color:#FFFFFF;font-weight:700;padding:5px;border:1px solid #FFFFFF;">Drafted</th>
                        <th *ngIf="countVerification != 0" class="col-2 bg-primary" style="color:#FFFFFF;font-weight:700;padding:5px;border:1px solid #FFFFFF;">For Verification</th>
                        <th *ngIf="countConsolidation != 0" class="col-2 bg-warning" style="font-weight:700;padding:5px;border:1px solid #FFFFFF;">For Comments Consolidation</th>
                        <th *ngIf="countApproval != 0" class="col-2 bg-success" style="color:#FFFFFF;font-weight:700;padding:5px;border:1px solid #FFFFFF;">For Approval</th>
                        <th *ngIf="countAgencyReview != 0" class="col-2 bg-info" style="font-weight:700;padding:5px;border:1px solid #FFFFFF;">For Agency Review</th>
                        <th *ngIf="countARTAReview != 0" class="col-2 bg-danger" style="color:#FFFFFF;font-weight:700;padding:5px;border:1px solid #FFFFFF;">For ARTA Review</th>
                      </tr>

                      <tr>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countDraft != 0">
                          {{ countDraft }}
                        </td>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countVerification != 0">
                          {{ countVerification }}
                        </td>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countConsolidation != 0">
                          {{ countConsolidation }}
                        </td>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countApproval != 0">
                          {{ countApproval }}
                        </td>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countAgencyReview != 0">
                          {{ countAgencyReview }}
                        </td>
                        <td class="col-2 h5" style="background-color:#f1f2f4;padding:10px;border:1px solid #FFFFFF;box-shadow:0 1px 5px rgba(0, 0, 0, 0.1)" *ngIf="countARTAReview != 0">
                          {{ countARTAReview }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div> -->

                <div *ngIf="auth.user | async as user; else showRestricted" style="overflow-x:auto;padding:10px;">
                  
                  <!-- <table class="table">
                    <thead style="background-color: orange">
                      <tr>
                        <th>Date Generated</th>
                        <th>From</th>
                        <th>Officer</th>
                        <th>Status</th>
                        <th>Date Submitted</th>
                        <th>Regulation Short Title</th>
                        <th>Action Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let worklist of results">
                        <td>
                          {{
                            worklist.dateGenerated?.toDate() | date: "MMMM dd, yyyy hh:mm:ss a"
                          }}
                        </td>
                        <td>
                          {{ worklist.fromAgency }}
                        </td>
                        <td>
                          {{ worklist.fromOfficer }}
                        </td>
                        <td>
                          {{ worklist.status }}
                        </td>
                        <td>
                          {{
                            worklist.dateSubmitted?.toDate() | date: "MMMM dd, yyyy hh:mm:ss a"
                          }}
                        </td>
                        <td>
                          <u
                            class="regulation-link"
                            (click)="showOnInboxViewport(worklist.documentId, worklist.id)"
                            >{{
                              worklist.documentTitle ? worklist.documentTitle : "NO TITLE"
                            }}</u
                          >
                        </td>
                        <td>
                          {{ worklist.dueDate?.toDate() | date: "MMMM dd, yyyy hh:mm:ss a" }}
                        </td>
                      </tr>
                    </tbody>
                  </table> -->
                  
                  <div [formGroup]="searchForm" class="form-group d-flex flex-row-reverse align-items-center fw-bold my-4">
                    <label for="search" style="margin-left:1em;text-align:center;">Search</label>
                      <div class="col-md-5"><input formControlName="keyword" id="search" name="search" (keyup)="search($event)" class="form-control"/></div>
                    <!-- <button class="btn btn-outline-dark" style="margin-left:1em;" (click)="refreshList()"><i class="fas fa-redo"></i></button> -->
                  </div>
                    
                  <table mat-table class="table table-bordered table-striped table-hover table-responsive"
                      id="matTable"
                      [dataSource]="worklistDataSource"
                      matSort
                      (matSortChange)="sortChange($event)">
                      
                      <ng-container matColumnDef="dateGenerated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">DATE CREATED</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="font-size:13px;padding:1em;text-align:center;">
                          {{ worklist.dateGenerated?.toDate() | date: "MMMM dd, yyyy" }} <br/>
                          {{ worklist.dateGenerated?.toDate() | date: "hh:mm:ss a" }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="fromAgency">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">CREATED BY</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="font-size:13px;padding:1em;text-align:center;">
                          {{ worklist.fromOfficer }}
                        </td>
                      </ng-container>

                      <!-- <ng-container matColumnDef="fromOfficer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#6C757D;color:#FFFFFF;padding:1em;"><h6>Officer</h6></th>
                        <td mat-cell *matCellDef="let worklist" style="padding:2em">
                          {{ worklist.fromOfficer }}
                        </td>
                      </ng-container> -->

                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">STATUS</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="padding:1em;text-align:center;">
                          <div [ngSwitch]="worklist.status" >

                            <!-- Agency -->
                            <div *ngSwitchCase="'DRAFT'">
                              <h6><span class="badge rounded-pill bg-light text-dark border">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR VERIFICATION'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-primary">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR REVISION'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-dark">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'RESUBMITTED'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-primary">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR MODIFICATION'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-primary">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR APPROVAL'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-warning">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR RE-APPROVAL'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-warning">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR POSTING'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-success">{{worklist.status}}</span></h6>
                            </div>

                            <!-- Agency RIA-DRIS-Consultation -->
                            <div *ngSwitchCase="'FOR RIA'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-info">
                                <i class="fas fa-university me-1"></i>AGENCY
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR REVISION'">
                              <h6><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR VERIFICATION'">
                              <h6><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR APPROVAL'">
                              <h6><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'UPLOADED RIS - FOR CONSULTATION'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-info">FOR CONSULTATION</span></h6>
                              <h6><span class="badge rounded-pill badge-info">
                                <i class="fas fa-university me-1"></i>AGENCY
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'WITH DRAFT CRIS'">
                              <h6><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                            </div>

                            <div *ngSwitchCase="'WITH DRAFT DRIS'">
                              <h6><span class="badge rounded-pill bg-info">{{worklist.status}}</span></h6>
                            </div>

                            <!-- ARTA -->
                            <div *ngSwitchCase="'FOR ARTA REVIEW - PO'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR REVIEW</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-PROGRAM OFFICER
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR ARTA REVIEW - DC'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR REVIEW</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-DIVISION CHIEF
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR ARTA REVIEW - DIRECTOR'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR REVIEW</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-DIRECTOR
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR ARTA REVIEW - DDG'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR REVIEW</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>DEPUTY DIRECTOR GENERAL
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'FOR ARTA REVIEW - DG'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR REVIEW</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>DIRECTOR GENERAL
                              </span></h6>
                            </div>

                            <!-- ARTA - DRIS -->
                            <div *ngSwitchCase="'DECISION FOR ARTA REVIEW - PO'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-PROGRAM OFFICER
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR ARTA REVIEW - DC'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-DIVISION CHIEF
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR ARTA REVIEW - DIRECTOR'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-DIRECTOR
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR ARTA REVIEW - DDG'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>DEPUTY DIRECTOR GENERAL
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR ARTA REVIEW - DG'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>DIRECTOR GENERAL
                              </span></h6>
                            </div>

                            <div *ngSwitchCase="'DECISION FOR ARTA ASSESSMENT - PO'">
                              <h6 style="font-size:15px;"><span class="badge rounded-pill bg-danger">DECISION FOR DRIS</span></h6>
                              <h6 style="font-size:15px;"><span class="badge rounded-pill badge-danger">
                                <i class="fas fa-user-edit me-1"></i>BRO-PROGRAM OFFICER
                              </span></h6>
                            </div>

                            <!--Default -->
                            <div *ngSwitchDefault>
                                <h6><span class="badge rounded-pill bg-secondary">{{worklist.status}}</span></h6>
                            </div>

                          </div>
                        </td>
                      </ng-container>

                      <!-- <ng-container matColumnDef="dateSubmitted">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:#6C757D;color:#FFFFFF;padding:1em;"><h6>Date Submitted</h6></th>
                        <td mat-cell *matCellDef="let worklist" style="padding:2em">
                          {{ worklist.dateSubmitted?.toDate() | date: "MMMM dd, yyyy hh:mm:ss a" }}
                        </td>
                      </ng-container> -->

                      <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">REGULATION SHORT TITLE</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="font-size:13px;padding:1em;text-align:center;">
                          {{ worklist.documentTitle ? worklist.documentTitle : "NO TITLE" }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="due-date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">DUE DATE</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="font-size:13px;padding:1em;text-align:center;">
                          {{ worklist.dueDate?.toDate() | date: "MMMM dd, yyyy" }} <br/>
                          {{ worklist.dueDate?.toDate() | date: "hh:mm:ss a" }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="p-2" style="background-color:#343A40;">
                          <h6 style="font-size:13px;color:#FFFFFF;">ACTION</h6>
                        </th>
                        <td mat-cell *matCellDef="let worklist" style="font-size:13px;padding:1em;text-align:center;">
                          <u class="btn btn-custom btn-rounded btn-sm" (click)="showOnInboxViewport(worklist.documentId, worklist.id)">
                            View
                          </u>
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="worklist_columns; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: worklist_columns;"></tr>
                  </table>
                  <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
                </div>

                <ng-template #showRestricted>
                  <h1>This is a Restricted Page</h1>
                </ng-template>
              </div>

          </div>
        </div>

    </div>
  </div>
</div>