import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { take, tap, first } from 'rxjs/operators';
import { EmailAppCode, EmailTemplateCode } from '../entities/emailTemplates';
import { GovernmentAccountSubtype, WorklistStatusPBRISRegulation, WorklistActionFlagPBRISRegulation } from 'src/app/entities/worklist';
import { accountRole } from '../agency-control/agency-register-member/agency-register-member.component';
import { NotificationsEmailer } from './notifications-emailer.service';

@Injectable({
  providedIn: 'root'
})
export class PBRISEmailer {

  constructor(
    private afs: AngularFirestore,
    private Ne: NotificationsEmailer,
  ) { }

  public footerPBRIS: string = "<p>Best,</p><p>Philippine Business Regulations Information System</p><p>For inquiries and other concern, you may reach us through our <a href='https://regulations.gov.ph/pbris/contact-us'>PBRIS Website</a> or <a href='https://arta.gov.ph/contact-us/'>ARTA Website</a></p><p>This is an automated message please <strong>DO NOT REPLY</strong>.</p>"

  public sendEmailNotification(email: any, EmailAppCode: EmailAppCode, EmailTemplateCode: EmailTemplateCode, variables: any, footer: string = this.footerPBRIS) {
    this.Ne.emailServiceHandler(email, EmailAppCode, EmailTemplateCode, variables, footer);
  }

  async getEmails(userAgency: string, govAccSubType: GovernmentAccountSubtype, access_pbris: boolean = true) {

    return new Promise((res, rej) => {
      this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', govAccSubType).where('credentials.access_pbris', '==', access_pbris).where('government.agency', '==', userAgency)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next: (result) => {
            let tempArr: any = [];
            //console.log('Number of emails: ', result.length)
            result.map(data => {
              let user: any = data.payload.doc.data()
              let email: string = user.credentials.email // Extract email from user object
              if (!tempArr.includes(email)) tempArr.push(email)
            })
            return res(tempArr)
          },
          error: (err) => {
            console.error('getEmails', err)
            return res(false)
          }
        })
    })
  }

  async getAdminEmails() {

    return new Promise((res, rej) => {
      this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', GovernmentAccountSubtype.ARTA_ADMIN)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next: (result) => {
            let tempArr: any = [];
            //console.log('Number of emails: ', result.length)
            result.map(data => {
              let user: any = data.payload.doc.data()
              let email: string = user.credentials.email // Extract email from user object
              if (!tempArr.includes(email)) tempArr.push(email)
            })
            return res(tempArr)
          },
          error: (err) => {
            console.error('getEmails', err)
            return res(false)
          }
        })
    })
  }


  firebaseEmailer(emails: any[], subject: any, htmlBody: any) {
    this.afs.collection('mail').add({
      to: emails,
      message: {
        subject: subject,
        html: htmlBody
      },
    }).then(() => {
      console.log("email sent")
    })
  }

  async emailNotificationbyAction(userAgency: string, userEmail: any[], data: any, actionFlag: WorklistActionFlagPBRISRegulation, reg_status: any = '') {
    var agencyHead: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_DIR);
    var agencyEncoders: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_ENC);
    var agencyVerifiers: any = await this.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_VER);

    var footer: string = this.footerPBRIS;

    //test
    switch (actionFlag) {
      case WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT: //for revision na isusubmit
        await this.Ne.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_FORWARD_REGULATION_TO_VERIFIER, data, footer);
        await this.Ne.emailServiceHandler(agencyVerifiers, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_RECIEVES_REGULATION_FOR_VERIFICATION, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR: //submit to agency head for approval
        if (reg_status == "FOR REVISION") {

        }
        await this.Ne.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_SUBMITS_REGULATION_FOR_APPROVAL, data, footer);
        await this.Ne.emailServiceHandler(agencyHead, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_RECEIVES_REGULATION_FOR_APPROVAL, data, footer);

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA:

        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR:

        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA:
        //self
        await this.Ne.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_SUBMITS_TO_ARTA, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR: //for revision
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA:
        //self
        await this.Ne.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VALIDATOR_INDICATED_REGULATION_REVISION, data, footer);
        //encoders
        await this.Ne.emailServiceHandler(agencyEncoders, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_RECEIVES_REGULATION_FOR_REVISION, data, footer);
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG:

        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING:

        break;
      case WorklistActionFlagPBRISRegulation.POST_REGULATION:
        //self
        await this.Ne.emailServiceHandler(userEmail, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_POSTS_REG, data, footer);
        //verifier
        await this.Ne.emailServiceHandler(agencyVerifiers, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_POSTS_REG, data, footer);
        // encoder
        await this.Ne.emailServiceHandler(agencyEncoders, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_HEAD_POSTS_REG, data, footer);
        break;
    }
  }

}
