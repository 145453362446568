<!-- <app-wel-navbar *ngIf="currentSystem == pbris"></app-wel-navbar> -->

<div class="row">
    <div class="col">
        <h2 mat-dialog-title>Add Agency</h2>
    </div>

    <div class="col-1 float-end">
        <mat-dialog-action align="end">
            <button mat-button [matMenuTriggerFor]="menu" #menuTrigger>Close</button>
            <mat-menu #menu="matMenu">
                <div class="matmenu">
                    <div class="row">
                        <button class="text-center" mat-flat-button color="warn" (click)="cancel()">Leave Form</button>
                    </div>

                    <div class="row">
                        <button class="text-center " mat-flat-button (click)="onSubmit()">Save Changes</button>
                    </div>

                </div>
            </mat-menu>
        </mat-dialog-action>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <mat-vertical-stepper #stepper (click)="onStepChange(stepper)">

        <!-- Agency Details -->
        <mat-step [stepControl]="agencyForm" errorMessage="Agency Details required">
            <form [formGroup]="agencyForm">
                <ng-template matStepLabel>Agency Details</ng-template>

                <div class="form-group mt-1">

                    <div class="row">



                        <div class="col">
                            <!--mother agency-->
                            <div class="row minispace">
                                <div class="col minispace">
                                    <label class="form-input-header">Mother Agency</label>
                                    <div class="row">
                                        <div class="col d-flex" style="flex-direction:column; margin-top:0.5em">
                                            <select class="form-select" #select_mother
                                                (change)="selectChange('motherAgencyID',select_mother.value)">
                                                <option selected disabled value="">Select Mother Agency</option>
                                                <ng-container *ngFor="let item of motheragencies">
                                                    <option [value]="item.id">{{item.motheragency}}</option>
                                                </ng-container>
                                            </select>
                                            <ng-container *ngFor="let item of checkAgencyForm.motherAgencyID">
                                                <mat-error style="color:red"
                                                    *ngIf="hasError('motheragency','motherAgencyID',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- abbvr -->
                            <div class="row minispace">
                                <!--
                                    <div class="col-4">
                                    <label class="form-input-header">Agency Abbreviation</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Input" type="text"
                                            formControlName="agencyAbbreviation">
                                        <mat-hint style="color:rgb(45, 108, 233)"
                                            *ngIf="!isTouched('agency','agencyAbbreviation')">Input Required</mat-hint>
                                    </mat-form-field>
                                    <ng-container *ngFor="let item of checkAgencyForm.agencyAbbreviation">
                                        <mat-error style="color:red"
                                            *ngIf="hasError('agency','agencyAbbreviation',item.type)">*
                                            {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                                -->
                                <div class="col">
                                    <label class="form-input-header">Agency Name</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Input" type="text" formControlName="agencyName">
                                        <mat-hint style="color:rgb(45, 108, 233)"
                                            *ngIf="!isTouched('agency','agencyName')">Input Required</mat-hint>
                                    </mat-form-field>
                                    <ng-container *ngFor="let item of checkAgencyForm.agencyName">
                                        <mat-error style="color:red" *ngIf="hasError('agency','agencyName',item.type)">*
                                            {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- contact nums -->
                            <!-- <div class="row minispace">
                            
                            phone num
                            <div class="col">
                                <label class="form-input-header">Phone Number</label>
                                <div style="padding: 0 !important;" class="row">
                                    <div class="col-1 col-3-sm col-3-md">
                                        <div class="center">
                                            <mat-form-fiel appearance="fill">
                                                <input readonly disabled matInput type="text" value="+63">
                                            </mat-form-fiel>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput appPhoneMask placeholder="Input" type="text" maxlength="12" formControlName="phoneNumber">
                                            <mat-hint style="padding:0;" style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','phoneNumber')">Input Required</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <ng-container *ngFor="let item of checkAgencyForm.phoneNumber">
                                        <mat-error style="color:red" *ngIf="hasError('agency','phoneNumber',item.type)">* {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                            </div>
    
                            tel num
                            <div class="col">
                                <label class="form-input-header">Telephone Number</label>
                                <mat-form-field>
                                    <input matInput appTelephoneMask placeholder="Input" type="text" formControlName="telephoneNumber"> 
                                    maxlength="12"
                                    <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','telephoneNumber')">Input Required</mat-hint>
                                </mat-form-field>
                                <ng-container *ngFor="let item of checkAgencyForm.telephoneNumber">
                                    <mat-error style="color:red" *ngIf="hasError('agency','telephoneNumber',item.type)">* {{item.message}}</mat-error>
                                </ng-container> 
                            </div>
                        </div> -->

                            <!-- category -->
                            <div class="row minispace">
                                <div class="col-4">
                                    <label class="form-input-header">Agency Abbreviation</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Input" type="text"
                                            formControlName="agencyAbbreviation">
                                        <mat-hint style="color:rgb(45, 108, 233)"
                                            *ngIf="!isTouched('agency','agencyAbbreviation')">Input Required</mat-hint>
                                    </mat-form-field>
                                    <ng-container *ngFor="let item of checkAgencyForm.agencyAbbreviation">
                                        <mat-error style="color:red"
                                            *ngIf="hasError('agency','agencyAbbreviation',item.type)">*
                                            {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                                <div class="col ">
                                    <label class="form-input-header">Agency Category</label>
                                    <div class="row">
                                        <div class="col d-flex" style="flex-direction:column; margin-top:0.5em">
                                            <select class="form-select" #select_agencyCat
                                                (change)="selectChange('agencyCategoryID',select_agencyCat.value)">
                                                <option selected disabled value="">Agency Category</option>
                                                <ng-container *ngFor="let item of agencyCategories">
                                                    <option [value]="item.id">({{item.abbreviation}}) {{item.name}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                            <ng-container *ngFor="let item of checkAgencyForm.agencyCategoryID">
                                                <mat-error style="color:red"
                                                    *ngIf="hasError('agency','agencyCategoryID',item.type)">*
                                                    {{item.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- net -->
                            <div class="row minispace">

                                <!-- email -->
                                <!-- <div class="col">
                                <label class="form-input-header">Email</label>
                                <mat-form-field>
                                    <input matInput placeholder="Input" type="text" formControlName="email">
                                    <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','email')">Input Required</mat-hint>
                                </mat-form-field>
                                <ng-container *ngFor="let item of checkAgencyForm.email">
                                    <mat-error style="color:red" *ngIf="hasError('agency','email',item.type)">* {{item.message}}</mat-error>
                                </ng-container> 
                            </div> -->

                                <!-- url -->
                                <!-- <div class="col">
                                <label class="form-input-header">Website URL</label>
                                <div class="row">
                                    <div class="col-3 d-flex" style="flex-direction:column; margin-top:0.5em">
                                        <select class="form-select" #website_protocol (change)="selectChange('website',website_protocol.value)">
                                            <option selected disabled value="">Protocol</option>
                                            <option  value="http:">http://</option>
                                            <option value="https:">https://</option>
                                        </select>
                                        <ng-container *ngFor="let item of checkAgencyForm.protocol">
                                            <mat-error style="color:red" *ngIf="hasError('agency','protocol',item.type)">* {{item.message}}</mat-error>
                                        </ng-container>  
                                    </div>
                                    <div class="col d-flex" style="flex-direction:column">
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text" formControlName="websiteLink">
                                            <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','websiteLink')">Input Required</mat-hint>
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkAgencyForm.websiteLink">
                                            <mat-error style="color:red" *ngIf="hasError('agency','websiteLink',item.type)">* {{item.message}}</mat-error>
                                        </ng-container>  
                                    </div>
                                </div>
                            </div> -->


                                <!-- url -->
                                <div class="col">
                                    <label class="form-input-header">Website URL</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Input" type="text" formControlName="websiteLink">
                                        <mat-hint style="color:rgb(45, 108, 233)"
                                            *ngIf="!isTouched('agency','websiteLink')">Input Required</mat-hint>
                                    </mat-form-field>
                                    <ng-container *ngFor="let item of checkAgencyForm.websiteLink">
                                        <mat-error style="color:red"
                                            *ngIf="hasError('agency','websiteLink',item.type)">*
                                            {{item.message}}</mat-error>
                                    </ng-container>
                                </div>


                            </div>

                            <div class="row minispace">
                                <!-- phone num -->
                                <div class="col">
                                    <label class="form-input-header">Phone Number</label>
                                    <div style="padding: 0 !important;" class="row">
                                        <div class="col-1 col-3-sm col-3-md">
                                            <div class="center">
                                                <mat-form-fiel appearance="fill">
                                                    <input readonly disabled matInput type="text" value="+63">
                                                </mat-form-fiel>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <mat-form-field>
                                                <input matInput appPhoneMask placeholder="Input" type="text"
                                                    maxlength="12" formControlName="phoneNumber">
                                                <!-- <mat-hint style="padding:0;" style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','phoneNumber')">Input Required</mat-hint> -->
                                            </mat-form-field>
                                        </div>
                                        <ng-container *ngFor="let item of checkAgencyForm.phoneNumber">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('agency','phoneNumber',item.type)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <!-- contact nums -->
                            <div class="row minispace">

                                <!-- phone num -->
                                <!-- <div class="col">
                                <label class="form-input-header">Phone Number</label>
                                <div style="padding: 0 !important;" class="row">
                                    <div class="col-1 col-3-sm col-3-md">
                                        <div class="center">
                                            <mat-form-fiel appearance="fill">
                                                <input readonly disabled matInput type="text" value="+63">
                                            </mat-form-fiel>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <mat-form-field>
                                            <input matInput appPhoneMask placeholder="Input" type="text" maxlength="12" formControlName="phoneNumber">
                                            <mat-hint style="padding:0;" style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','phoneNumber')">Input Required</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <ng-container *ngFor="let item of checkAgencyForm.phoneNumber">
                                        <mat-error style="color:red" *ngIf="hasError('agency','phoneNumber',item.type)">* {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                            </div> -->
                                <!-- email -->
                                <div class="col">
                                    <label class="form-input-header">Email</label>
                                    <ng-container formArrayName="emailArray">
                                        <ng-container
                                            *ngFor="let row of getFormsArray('email'); let i = index; let c = count;">
                                            <div class="row" [formGroup]="row">
                                                <!--<div *ngIf="getNumberOfElements('email') > 1"
                                                    class="col-1 d-flex align-items-center justify-content-center">
                                                    <button mat-stroked-button type="button"
                                                        (click)="removeCartFormRow('email',i) " class="btn btn-danger">
                                                        <span class="material-icons"> remove_circle_outline
                                                        </span></button>
                                                </div>-->
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput placeholder="Input" type="text"
                                                            formControlName="email">
                                                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','email')">Input Required</mat-hint> -->
                                                    </mat-form-field>
                                                    <ng-container *ngFor="let item of checkAgencyForm.email">
                                                        <mat-error style="color:red"
                                                            *ngIf="hasError('emailArray','email',item.type,i)">*
                                                            {{item.message}}</mat-error>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="getNumberOfElements('email') > 1"
                                                    class="col-1 d-flex align-items-center justify-content-center">
                                                    <button mat-stroked-button type="button"
                                                        (click)="removeCartFormRow('email',i) " class="btn btn-danger">
                                                        <span class="material-icons"> remove_circle_outline
                                                        </span></button>
                                                </div>
                                            </div>
                                            <hr *ngIf="getNumberOfElements('email') > 1 && (c-1) > i">
                                        </ng-container>
                                    </ng-container>
                                    <div class="d-flex justify-content-center">
                                        <button mat-stroked-button type="button" (click)="addNewRow('email') "
                                            class="btn btn-info">
                                            <span class="material-icons"> add_circle_outline </span>Add Email
                                            Address</button>
                                    </div>
                                    <!-- <mat-form-field>
                                    <input matInput placeholder="Input" type="text" formControlName="email">
                                    <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','email')">Input Required</mat-hint>
                                </mat-form-field>
                                <ng-container *ngFor="let item of checkAgencyForm.email">
                                    <mat-error style="color:red" *ngIf="hasError('agency','email',item.type)">* {{item.message}}</mat-error>
                                </ng-container>  -->
                                </div>


                            </div>

                            <div class="row  minispace">
                                <!-- tel num -->
                                <div class="col">

                                    <label class="form-input-header">Telephone Number</label>
                                    <ng-container formArrayName="telephoneArray">
                                        <ng-container
                                            *ngFor="let row of getFormsArray('telephone'); let i = index; let c = count">
                                            <div class="row" [formGroup]="row">
                                                <div
                                                    class="col-2 form-check d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <input type="checkbox" class="form-check-input"
                                                            formControlName="hasTrunkline" id="hasTrunkline-{{i}}">
                                                        <label class="form-check-label"
                                                            for="hasTrunkline-{{i}}">trunkline</label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <mat-form-field>
                                                        <input matInput appTelephoneMask placeholder="Input"
                                                            maxlength="15" type="text"
                                                            formControlName="telephoneNumber">
                                                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','telephoneNumber')">Input Required</mat-hint> -->
                                                    </mat-form-field>
                                                    <!-- <ng-container *ngFor="let item of checkAgencyForm.telephoneNumber">
                                                    <mat-error style="color:red" *ngIf="hasError('agency','telephoneNumber',item.type)">* {{item.message}}</mat-error>
                                                </ng-container> -->
                                                    <ng-container *ngFor="let item of checkAgencyForm.telephoneNumber">
                                                        <mat-error style="color:red"
                                                            *ngIf="hasError('telephoneArray','telephoneNumber',item.type,i)">*
                                                            {{item.message}}</mat-error>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="showTrunkline(i)" class="col">
                                                    <mat-form-field>
                                                        <mat-label for="trunkline">Trunkline</mat-label>
                                                        <input matInput appTrunklineMask placeholder="Input" type="text"
                                                            id="trunkline" formControlName="trunkline">
                                                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','telephoneNumber')">Input Required</mat-hint> -->
                                                    </mat-form-field>
                                                    <ng-container *ngFor="let item of checkAgencyForm.trunkline">
                                                        <mat-error style="color:red"
                                                            *ngIf="hasError('telephoneArray','trunkline',item.type,i)">*
                                                            {{item.message}}</mat-error>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="getNumberOfElements('telephone') > 1"
                                                    class="col-1  d-flex align-items-center justify-content-center">
                                                    <button mat-stroked-button type="button"
                                                        (click)="removeCartFormRow('telephone',i) "
                                                        class="btn btn-danger">
                                                        <span class="material-icons"> remove_circle_outline
                                                        </span></button>
                                                </div>
                                            </div>
                                            <hr *ngIf="getNumberOfElements('telephone') > 1 && (c-1) > i">
                                        </ng-container>
                                        <div class="d-flex justify-content-center">
                                            <button mat-stroked-button type="button" (click)="addNewRow('telephone') "
                                                class="btn btn-info">
                                                <span class="material-icons"> add_circle_outline </span>Add Telephone
                                                Number</button>
                                        </div>
                                    </ng-container>
                                    <!-- <mat-form-field>
                                    <input matInput appTelephoneMask placeholder="Input" type="text" formControlName="telephoneNumber">
                                    <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('agency','telephoneNumber')">Input Required</mat-hint>
                                </mat-form-field>
                                <ng-container *ngFor="let item of checkAgencyForm.telephoneNumber">
                                    <mat-error style="color:red" *ngIf="hasError('agency','telephoneNumber',item.type)">* {{item.message}}</mat-error>
                                </ng-container>  -->
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <!-- logo -->
                            <form [formGroup]="uploadForm">
                                <div class="row">
                                    <div class="col center-logo">
                                        <h4>Logo Upload</h4>
                                        <div class=" card as-column">

                                            <!-- <i style="font-size: 300px;" class="fa-solid fa-file-arrow-up"></i> -->
                                            <img *ngIf="imgSrc" [src]="imgSrc" class="card"
                                                style="width:300px;height:300px; margin-bottom:1em;">
                                            <img *ngIf="!imgSrc" src="assets/default_upload_logo.png" class="card"
                                                style="width:300px;height:300px; margin-bottom:1em;">
                                            <div class="row w-100" *ngIf="showLogoUploadProgress">
                                                <div class="fileProgress">
                                                    <p class="logoDetails">{{logoName}}</p>
                                                    <!-- <p> Total size: {{logoSize}}</p> -->
                                                    <mat-progress-bar mode="determinate"
                                                        [value]="currentLogoUploadProgress"></mat-progress-bar>
                                                    <span class="float-right">{{currentLogoUploadProgress}}% /
                                                        100%</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <input type="file" class="form-control"
                                                    [readonly]="disablerWhileLoadingLogo" id="logoFile"
                                                    formControlName="logoFile" (change)="onFileChange($event)"
                                                    placeholder="JPG, JPEG or PNG images only">
                                                <button type="button" (click)="resetLogo()"
                                                    class="form-control btn btn-warning"><i
                                                        class="fa-solid fa-arrow-rotate-right">Refresh Logo</i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>
                <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <!--<button class="btn btn-danger mat-focus-indicator" type="button"
                                (click)="cancel()">Cancel</button>-->
                            <button mat-button class="btn btn-primary" matStepperNext>Next</button>
                            <!-- <button mat-button class="btn btn-primary" type="button" (click)="onTest()">Submit</button> -->

                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!-- Address -->
        <mat-step [stepControl]="addressForm" errorMessage="Address Details required">
            <form [formGroup]="addressForm">
                <ng-template matStepLabel>Agency Address</ng-template>

                <div class="form-group mt-5">
                    <div class="row minispace">
                        <div class="col-8">
                            <label class="form-input-header">Address Line: House no / Building no. - Street name /
                                Avenue name - Subdivision</label>
                            <mat-form-field>
                                <input matInput placeholder="Input" type="text" formControlName="addressLine">
                                <mat-hint style="color:rgb(45, 108, 233)"
                                    *ngIf="!isTouched('address','addressLine')">Input Required</mat-hint>
                            </mat-form-field>
                            <ng-container *ngFor="let item of checkAddressForm.addressLine">
                                <mat-error style="color:red" *ngIf="hasError('address','addressLine',item.type)">*
                                    {{item.message}}</mat-error>
                            </ng-container>
                        </div>
                        <div class="col-4">
                            <label class="form-input-header">Barangay</label>
                            <mat-form-field>
                                <input matInput placeholder="Input" type="text" formControlName="barangay">
                                <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('address','barangay')">Input
                                    Required</mat-hint>
                            </mat-form-field>
                            <ng-container *ngFor="let item of checkAddressForm.barangay">
                                <mat-error style="color:red" *ngIf="hasError('address','barangay',item.type)">*
                                    {{item.message}}</mat-error>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row minispace">
                        <div class="col">
                            <label class="form-input-header">City / Municipality</label>
                            <mat-form-field>
                                <input matInput placeholder="Input" type="text" formControlName="city">
                                <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('address','city')">Input
                                    Required</mat-hint>
                            </mat-form-field>
                            <ng-container *ngFor="let item of checkAddressForm.city">
                                <mat-error style="color:red" *ngIf="hasError('address','city',item.type)">*
                                    {{item.message}}</mat-error>
                            </ng-container>
                        </div>
                        <div class="col">
                            <label class="form-input-header">Province - Region</label>
                            <mat-form-field>
                                <input matInput placeholder="Input" type="text" formControlName="province">
                                <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('address','province')">Input
                                    Required</mat-hint>
                            </mat-form-field>
                            <ng-container *ngFor="let item of checkAddressForm.province">
                                <mat-error style="color:red" *ngIf="hasError('address','province',item.type)">*
                                    {{item.message}}</mat-error>
                            </ng-container>
                        </div>
                        <div class="col-2">
                            <label class="form-input-header">Postal code / Zip code</label>
                            <mat-form-field>
                                <input matInput placeholder="Input" type="text" formControlName="postal">
                                <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('address','postal')">Input
                                    Required</mat-hint>
                            </mat-form-field>
                            <ng-container *ngFor="let item of checkAddressForm.postal">
                                <mat-error style="color:red" *ngIf="hasError('address','postal',item.type)">*
                                    {{item.message}}</mat-error>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <!--<button class="btn btn-danger mat-focus-indicator" type="button"
                                (click)="cancel()">Cancel</button>-->
                            <button mat-button class="btn btn-warning" matStepperPrevious>Back</button>
                            <button mat-button class="btn btn-primary" matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!-- Profile -->
        <mat-step [stepControl]="profileForm" errorMessage="Agency Profile required">
            <form [formGroup]="profileForm">
                <ng-template matStepLabel>Agency Profile</ng-template>
                <!--change to rtf
              <div class="form-group mt-5 minispace">
                <label class="form-input-header">Mandate</label>

                <mat-form-field>
                    <textarea matInput placeholder="Input"
                        oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text"
                        formControlName="mandate"></textarea>
                    <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('profile','mandate')">Input
                        Required</mat-hint>
                </mat-form-field>

                <ng-container *ngFor="let item of checkProfileForm.mandate">
                    <mat-error style="color:red" *ngIf="hasError('profile','mandate',item.type)">*
                        {{item.message}}</mat-error>
                </ng-container>
            </div>

            change to rtf-->

                <!--new mandate rtf-->
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">Mandate</label>
                    <!--start  here-->
                    <mat-form-field style="width: 100%" floatLabel="never">
                        <!--< old mandate textarea type="text" class="form-control" formControlName="mandate" id="mandate" placeholder="Input Mandate here" appTextareaAutoresize></textarea>-->
                        <input matInput hidden />
                        <editor formControlName="mandate" appTextareaAutoresize id="mandate" [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',                    
                    height: 250,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help'
                  }"></editor>
                        <!--<textarea matInput placeholder="Input" oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text" formControlName="mandate"></textarea-->
                        <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('profile','mandate')">Input
                            Required</mat-hint>
                    </mat-form-field>

                    <ng-container *ngFor="let item of checkProfileForm.mandate">
                        <mat-error style="color:red" *ngIf="hasError('profile','mandate',item.type)">*
                            {{item.message}}</mat-error>
                    </ng-container>
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">Mission</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text"
                            formControlName="mission"></textarea>
                        <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('profile','mission')">Input
                            Required</mat-hint>
                    </mat-form-field>

                    <ng-container *ngFor="let item of checkProfileForm.mission">
                        <mat-error style="color:red" *ngIf="hasError('profile','mission',item.type)">*
                            {{item.message}}</mat-error>
                    </ng-container>
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">Vision</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text"
                            formControlName="vision"></textarea>
                        <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('profile','vision')">Input
                            Required</mat-hint>
                    </mat-form-field>

                    <ng-container *ngFor="let item of checkProfileForm.vision">
                        <mat-error style="color:red" *ngIf="hasError('profile','vision',item.type)">*
                            {{item.message}}</mat-error>
                    </ng-container>
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">Service Pledge</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'" type="text"
                            formControlName="pledges"></textarea>
                        <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('profile','pledges')">Input
                            Required</mat-hint>
                    </mat-form-field>

                    <ng-container *ngFor="let item of checkProfileForm.pledges">
                        <mat-error style="color:red" *ngIf="hasError('profile','pledges',item.type)">*
                            {{item.message}}</mat-error>
                    </ng-container>
                </div>
                <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <!--<button class="btn btn-danger mat-focus-indicator" type="button"
                                (click)="cancel()">Cancel</button>-->
                            <button mat-button class="btn btn-warning" matStepperPrevious>Back</button>
                            <button mat-button class="btn btn-primary" matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!-- Committee -->
        <mat-step [stepControl]="cartForm" errorMessage="Committee required">
            <form [formGroup]="cartForm">
                <ng-template matStepLabel>Committee on Anti-Red Tape</ng-template>

                <!-- Agency head -->
                <div class="form-group mt-5">
                    <!-- <label class="form-input-header">
                        <h4>Head of Agency</h4>
                    </label> -->
                    <form [formGroup]="cartForm" class="form-input-header">
                        <h4>
                            <mat-form-field>
                                <mat-label class="form-input-header">Default Title: Agency Head</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="headOfAgencyTitle">
                            </mat-form-field>
                        </h4>
                    </form>

                    <form [formGroup]="cartForm">
                        <ng-container formArrayName="headOfAgencyForm">
                            <ng-container *ngFor="let row of getFormsArray('hOA'); let i = index">
                                <div class="row" [formGroup]="row">

                                    <div class="col">
                                        <label class="form-input-header">Name</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text" formControlName="name">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('carthead','name',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.name">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('carthead','name',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Designation</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text"
                                                formControlName="designation">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('carthead','designation',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.designation">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('carthead','designation',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Email</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="email" formControlName="email">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('carthead','email')">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.email">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('carthead','email',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <!-- <div class="col suppress-width" *ngIf="i > 0">
                                        <button mat-stroked-button type="button" (click)="removeCartFormRow('hOA',i)" class="btn committee">
                                            <span class="material-icons"> delete </span> Remove row </button>
                                    </div> -->

                                </div>
                            </ng-container>
                        </ng-container>
                    </form>

                    <!-- <div class="full-width">
                        <button mat-stroked-button type="button" (click)="addNewRow('hOA') " class="btn committee">
                                <span class="material-icons"> add_circle_outline  </span> Add row </button>
                    </div> -->
                </div>

                <!-- Chairperson -->
                <div class="form-group mt-5">
                    <!-- <label class="form-input-header">
                        <h4>Chairperson</h4>
                    </label> -->
                    <form [formGroup]="cartForm">
                        <h4>
                            <mat-form-field>
                                <mat-label class="form-input-header">Default Title: Chairpersons</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="chairPersonTitle">
                            </mat-form-field>
                        </h4>
                    </form>

                    <form [formGroup]="cartForm">
                        <ng-container formArrayName="chairPersonForm">
                            <ng-container *ngFor="let row of getFormsArray('cP'); let i = index">
                                <div class="row" [formGroup]="row">

                                    <div class="col">
                                        <label class="form-input-header">Name</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text" formControlName="name">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartchair','name',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.name">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartchair','name',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Designation</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text"
                                                formControlName="designation">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartchair','designation',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.designation">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartchair','designation',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Email</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="email" formControlName="email">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartchair','email',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.email">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartchair','email',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col suppress-width" *ngIf="i > 0">
                                        <button mat-stroked-button type="button" (click)="removeCartFormRow('cP',i)"
                                            class="btn committee">
                                            <span class="material-icons"> delete </span> Remove row </button>
                                    </div>

                                </div>
                            </ng-container>
                        </ng-container>
                    </form>

                    <div class="full-width">
                        <button mat-stroked-button type="button" (click)="addNewRow('cP') " class="btn committee">
                            <span class="material-icons"> add_circle_outline </span> Add row </button>
                    </div>
                </div>

                <!-- Vice Chair -->
                <div class="form-group mt-5">
                    <!-- <label class="form-input-header">
                        <h4>Vice-Chairperson</h4>
                    </label> -->
                    <form [formGroup]="cartForm">
                        <h4>
                            <mat-form-field>
                                <mat-label class="form-input-header">Default Title: Vice-Chairpersons</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="viceChairPersonTitle">
                            </mat-form-field>
                        </h4>
                    </form>

                    <form [formGroup]="cartForm">
                        <ng-container formArrayName="viceChairPersonForm">
                            <ng-container *ngFor="let row of getFormsArray('vCP'); let i = index">
                                <div class="row" [formGroup]="row">

                                    <div class="col">
                                        <label class="form-input-header">Name</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text" formControlName="name">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartvchair','name',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.name">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartvchair','name',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Designation</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text"
                                                formControlName="designation">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartvchair','designation',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.designation">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartvchair','designation',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Email</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="email" formControlName="email">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartvchair','email',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.email">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartvchair','email',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col suppress-width" *ngIf="i > 0">
                                        <button mat-stroked-button type="button" (click)="removeCartFormRow('vCP',i)"
                                            class="btn committee">
                                            <span class="material-icons"> delete </span> Remove row </button>
                                    </div>

                                </div>
                            </ng-container>
                        </ng-container>
                    </form>
                    <div class="full-width">
                        <button mat-stroked-button type="button" (click)="addNewRow('vCP') " class="btn committee">
                            <span class="material-icons"> add_circle_outline </span> Add row </button>
                    </div>
                </div>

                <!-- Members -->
                <div class="form-group mt-5">
                    <!-- <label class="form-input-header">
                        <h4>Members</h4>
                    </label> -->
                    <form [formGroup]="cartForm">
                        <h4>
                            <mat-form-field>
                                <mat-label class="form-input-header">Default Title: Members</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="membersTitle">
                            </mat-form-field>
                        </h4>
                    </form>

                    <form [formGroup]="cartForm">
                        <ng-container formArrayName="membersForm">
                            <ng-container *ngFor="let row of getFormsArray('memb'); let i = index">
                                <div class="row" [formGroup]="row">

                                    <div class="col">
                                        <label class="form-input-header">Name</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text" formControlName="name">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartmemb','name',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.name">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartmemb','name',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Designation</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="text"
                                                formControlName="designation">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartmemb','designation',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.designation">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartmemb','designation',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col">
                                        <label class="form-input-header">Email</label>
                                        <mat-form-field>
                                            <input matInput placeholder="Input" type="email" formControlName="email">
                                            <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('cartmemb','email',i)">Input Required</mat-hint> -->
                                        </mat-form-field>
                                        <ng-container *ngFor="let item of checkCartForm.email">
                                            <mat-error style="color:red"
                                                *ngIf="hasError('cartmemb','email',item.type,i)">*
                                                {{item.message}}</mat-error>
                                        </ng-container>
                                    </div>

                                    <div class="col suppress-width" *ngIf="i > 0">
                                        <button mat-stroked-button type="button" (click)="removeCartFormRow('memb',i)"
                                            class="btn committee">
                                            <span class="material-icons"> delete </span> Remove row </button>
                                    </div>

                                </div>
                            </ng-container>
                        </ng-container>
                    </form>

                    <div class="full-width">
                        <button mat-stroked-button type="button" (click)="addNewRow('memb') " class="btn committee">
                            <span class="material-icons"> add_circle_outline </span> Add row </button>
                    </div>
                </div>

                <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <!--<button class="btn btn-danger mat-focus-indicator" type="button"
                                (click)="cancel()">Cancel</button>-->
                            <button mat-button class="btn btn-warning" matStepperPrevious>Back</button>
                            <button mat-button class="btn btn-primary" matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!-- Feedback -->
        <mat-step [stepControl]="feedbackForm" errorMessage="Feedback Measure required">
            <form [formGroup]="feedbackForm">
                <ng-template matStepLabel>Feedback Measures</ng-template>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">How to send feedback</label>

                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
                            formControlName="howToSendFeedback"></textarea>
                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('feedback','howToSendFeedback')">Input Required</mat-hint> -->
                    </mat-form-field>
                    <!-- <ng-container *ngFor="let item of checkFeedbackForm.howToSendFeedback">
                        <mat-error style="color:red" *ngIf="hasError('feedback','howToSendFeedback',item.type)">* {{item.message}}</mat-error>
                    </ng-container>  -->
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">How feedback is processed</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
                            formControlName="howFeedbackProcess"></textarea>
                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('feedback','howFeedbackProcess')">Input Required</mat-hint> -->
                    </mat-form-field>
                    <!-- <ng-container *ngFor="let item of checkFeedbackForm.howFeedbackProcess">
                        <mat-error style="color:red" *ngIf="hasError('feedback','howFeedbackProcess',item.type)">* {{item.message}}</mat-error>
                    </ng-container> -->
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">How to file complaint</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
                            formControlName="howTofileComplaint"></textarea>
                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('feedback','howTofileComplaint')">Input Required</mat-hint> -->
                    </mat-form-field>
                    <!-- <ng-container *ngFor="let item of checkFeedbackForm.howTofileComplaint">
                        <mat-error style="color:red" *ngIf="hasError('feedback','howTofileComplaint',item.type)">* {{item.message}}</mat-error>
                    </ng-container> -->
                </div>
                <div class="form-group mt-5 minispace">
                    <label class="form-input-header">How complaints are processed</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Input"
                            oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"
                            formControlName="howComplaintsProcess"></textarea>
                        <!-- <mat-hint style="color:rgb(45, 108, 233)" *ngIf="!isTouched('feedback','howComplaintsProcess')">Input Required</mat-hint> -->
                    </mat-form-field>
                    <!-- <ng-container *ngFor="let item of checkFeedbackForm.howComplaintsProcess">
                        <mat-error style="color:red" *ngIf="hasError('feedback','howComplaintsProcess',item.type)">* {{item.message}}</mat-error>
                    </ng-container> -->
                </div>
                <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <!--<button class="btn btn-danger mat-focus-indicator" type="button"
                                (click)="cancel()">Cancel</button>-->
                            <button mat-button class="btn btn-warning" matStepperPrevious>Back</button>
                            <button mat-button class="btn btn-primary" matStepperNext>Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!-- Submission Details -->
        <mat-step>
            <!-- <form [formGroup]="submissionForm"> -->
            <ng-template matStepLabel>Submission Details</ng-template>
            <!-- Agency Details -->
            <div class="form-group mt-5">

                <div class="row">
                    <div class="col center-logo">
                        <div class="center-logo as-column">
                            <img *ngIf="imgSrc" [src]="imgSrc" class="card"
                                style="width:300px;height:300px; margin-bottom:1em;">
                            <img *ngIf="!imgSrc" src="assets/GOP_Logo.png" class="card"
                                style="width:300px;height:300px; margin-bottom:1em;">
                        </div>
                    </div>
                </div>

                <label>
                    <h2>Agency Details</h2>
                </label>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Agency Abbreviation</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="agencyForm.controls.agencyAbbreviation.value"> -->
                        <!-- <p>{{agencyForm.agencyAbbreviation}}</p> -->
                        <p>{{agencyForm.controls.agencyAbbreviation.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Agency Name</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="agencyForm.controls.agencyName.value"> -->
                        <p>{{agencyForm.controls.agencyName.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Mother Agency</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <p>{{showMotherAgency}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Phone Number</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="agencyForm.controls.phoneNumber.value"> -->
                        <p>{{agencyForm.controls.phoneNumber.value}}</p>
                    </div>
                </div>
                <!-- <div class="row">
                        <div class="col-2">
                            <label class="form-input-header">Telephone Number</label>
                        </div>
                        <div class="col">
                            <p>:</p>
                        </div>
                        <div class="col-9"> -->
                <!-- <p>{{agencyForm.controls.telephoneNumber.value}}</p> -->
                <!-- <p>under construction</p>
                        </div>
                    </div> -->
                <!-- <div class="row">
                        <div class="col-2">
                            <label class="form-input-header">Email</label>
                        </div>
                        <div class="col">
                            <p>:</p>
                        </div>
                        <div class="col-9"> -->
                <!-- <input matInput class="full-width" readonly type="email" [ngModel]="agencyForm.controls.email.value"> -->
                <!-- <p>{{agencyForm.controls.email.value}}</p> -->
                <!-- <p>under construction</p>
                        </div>
                    </div> -->
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Website Link</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="agencyForm.controls.websiteLink.value"> -->
                        <!-- needs http/s:// to work -->
                        <a href="{{agencyForm.value.protocol+'//'+agencyForm.value.websiteLink}}"
                            target="_blank">{{(agencyForm.value.protocol && agencyForm.value.websiteLink) ?
                            agencyForm.value.protocol+'//'+agencyForm.value.websiteLink : ""}}</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Agency Category</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <p>{{showAgencyCategory}}</p>
                    </div>
                </div>
                <!-- <div class="row">
                        <div class="col-6 row">
                            <div class="col">1st</div>
                        </div>
                        <div class="col-6 row">
                            <div class="col">2nd</div>
                        </div>
                    </div> -->
                <div class="row">
                    <table class="table">
                        <thead>
                            <tr>
                                <td>
                                    Emails :
                                </td>
                                <td>
                                    Telephone Number :
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="w-50">
                                    <ng-container *ngFor="let emailItem of agencyForm.controls.emailArray.value">
                                        <div class="row">
                                            <div class="col">
                                                <p>{{emailItem.email}}</p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </td>
                                <td class="w-50">
                                    <ng-container
                                        *ngFor="let telephoneItem of agencyForm.controls.telephoneArray.value">
                                        <div class="row">
                                            <div class="col">
                                                <p>{{telephoneItem.telephoneNumber}} <span
                                                        *ngIf="telephoneItem.hasTrunkline == true">:
                                                        {{telephoneItem.trunkline}}</span></p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Address Details -->
            <div class="form-group mt-5">
                <label>
                    <h2>Agency Address</h2>
                </label>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Address Line: House no / Building no. - Street name / Avenue
                            name - Subdivision</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="addressForm.controls.addressLine.value"> -->
                        <p>{{addressForm.controls.addressLine.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Barangay</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="addressForm.controls.barangay.value"> -->
                        <p>{{addressForm.controls.barangay.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">City / Municipality</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="addressForm.controls.city.value"> -->
                        <p>{{addressForm.controls.city.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Province - Region</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="text" [ngModel]="addressForm.controls.province.value"> -->
                        <p>{{addressForm.controls.province.value}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Postal code / Zip code</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <input matInput class="full-width" readonly type="email" [ngModel]="addressForm.controls.postal.value"> -->
                        <p>{{addressForm.controls.postal.value}}</p>
                    </div>
                </div>
            </div>

            <!-- Agency Profile -->
            <div class="form-group mt-5">
                <label>
                    <h2>Agency Profile</h2>
                </label>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Mandate</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="profileForm.controls.mandate.value"></textarea> -->
                        <!-- <p>{{profileForm.controls.mandate.value}}</p> -->
                        <p [innerHtml]="profileForm.controls.mandate.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Mission</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="profileForm.controls.mission.value"></textarea> -->
                        <!-- <p>{{profileForm.controls.mission.value}}</p> -->
                        <p [innerHtml]="profileForm.controls.mission.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Vision</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="profileForm.controls.vision.value"></textarea> -->
                        <!-- <p>{{profileForm.controls.vision.value}}</p> -->
                        <p [innerHtml]="profileForm.controls.vision.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">Service Pledge</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="profileForm.controls.pledges.value"></textarea> -->
                        <!-- <p>{{profileForm.controls.pledges.value}}</p> -->
                        <p [innerHtml]="profileForm.controls.pledges.value | linebreaks"></p>
                    </div>
                </div>
            </div>

            <!-- Committee -->
            <div class="form-group mt-5">
                <label>
                    <h2>Committee on Anti-Red Tape</h2>
                </label>

                <div class="form-group mt-5">
                    <!-- <label>
                        <h3>Agency Head/s</h3>
                    </label> -->
                    <h3>
                        <input matInput class="full-width" readonly type="text" [ngModel]="cartForm.value.headOfAgencyTitle ? cartForm.value.headOfAgencyTitle : 'Agency Head'">
                    </h3>
                    <table>
                        <tr class="row-headers">
                            <th><label class="form-input-header">Name</label></th>
                            <th><label class="form-input-header">Designation</label></th>
                            <th><label class="form-input-header">Email</label></th>
                        </tr>
                        <ng-container *ngFor="let agencyHead of cartForm.controls.headOfAgencyForm.value">
                            <tr>
                                <td><input matInput class="full-width" readonly type="text" [ngModel]="agencyHead.name">
                                </td>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="agencyHead.designation"></td>
                                <td><input matInput class="full-width" readonly type="email"
                                        [ngModel]="agencyHead.email"></td>
                            </tr>
                        </ng-container>
                        <!-- <ng-container  *ngFor="let agencyHead of getObjectArray('headOfAgencyForm')">
                                <tr>
                                    <td><input class="full-width" readonly type="text" [ngModel]="agencyHead.name"></td>
                                    <td><input class="full-width" readonly type="text" [ngModel]="agencyHead.designation"></td>
                                    <td><input class="full-width" readonly type="email" [ngModel]="agencyHead.email"></td>
                                </tr>
                            </ng-container> -->
                    </table>
                </div>

                <div class="form-group mt-5">
                    <!-- <label>
                        <h3>Chairperson/s</h3>
                    </label> -->
                    <h3>
                        <input matInput class="full-width" readonly type="text" [ngModel]="cartForm.value.chairPersonTitle ? cartForm.value.chairPersonTitle : 'Chairpersons'">
                    </h3>
                    <table>
                        <tr class="row-headers">
                            <th><label class="form-input-header">Name</label></th>
                            <th><label class="form-input-header">Designation</label></th>
                            <th><label class="form-input-header">Email</label></th>
                        </tr>
                        <ng-container *ngFor="let chairPerson of cartForm.controls.chairPersonForm.value">
                            <tr>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="chairPerson.name"></td>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="chairPerson.designation"></td>
                                <td><input matInput class="full-width" readonly type="email"
                                        [ngModel]="chairPerson.email"></td>
                            </tr>
                        </ng-container>
                    </table>
                </div>

                <div class="form-group mt-5">
                    <!-- <label>
                        <h3>Vice-Chairperson/s</h3>
                    </label> -->
                    <h3>
                        <input matInput class="full-width" readonly type="text" [ngModel]="cartForm.value.viceChairPersonTitle ? cartForm.value.viceChairPersonTitle : 'Vice-Chairpersons'">
                    </h3>
                    <table>
                        <tr class="row-headers">
                            <th><label class="form-input-header">Name</label></th>
                            <th><label class="form-input-header">Designation</label></th>
                            <th><label class="form-input-header">Email</label></th>
                        </tr>
                        <ng-container *ngFor="let viceChairPerson of cartForm.controls.viceChairPersonForm.value">
                            <tr>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="viceChairPerson.name"></td>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="viceChairPerson.designation"></td>
                                <td><input matInput class="full-width" readonly type="email"
                                        [ngModel]="viceChairPerson.email"></td>
                            </tr>
                        </ng-container>
                    </table>
                </div>

                <div class="form-group mt-5">
                    <!-- <label>
                        <h3>Members</h3>
                    </label> -->
                    <h3>
                        <input matInput class="full-width" readonly type="text" [ngModel]="cartForm.value.membersTitle ? cartForm.value.membersTitle : 'Members'">
                    </h3>
                    <table>
                        <tr class="row-headers">
                            <th><label class="form-input-header">Name</label></th>
                            <th><label class="form-input-header">Designation</label></th>
                            <th><label class="form-input-header">Email</label></th>
                        </tr>
                        <ng-container *ngFor="let member of cartForm.controls.membersForm.value">
                            <tr>
                                <td><input matInput class="full-width" readonly type="text" [ngModel]="member.name">
                                </td>
                                <td><input matInput class="full-width" readonly type="text"
                                        [ngModel]="member.designation"></td>
                                <td><input matInput class="full-width" readonly type="email" [ngModel]="member.email">
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>

            </div>

            <!-- Feedback -->
            <div class="form-group mt-5">
                <label>
                    <h2>Feedback Measures</h2>
                </label>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">How send a Feedback</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="feedbackForm.controls.howToSendFeedback.value"></textarea> -->
                        <!-- <p>{{feedbackForm.controls.howToSendFeedback.value}}</p> -->
                        <p [innerHtml]="feedbackForm.controls.howToSendFeedback.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">How feedback is processed</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="feedbackForm.controls.howFeedbackProcess.value"></textarea> -->
                        <!-- <p>{{feedbackForm.controls.howFeedbackProcess.value}}</p> -->
                        <p [innerHtml]="feedbackForm.controls.howFeedbackProcess.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">How to file complaint</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="feedbackForm.controls.howTofileComplaint.value"></textarea> -->
                        <!-- <p>{{feedbackForm.controls.howTofileComplaint.value}}</p> -->
                        <p [innerHtml]="feedbackForm.controls.howTofileComplaint.value | linebreaks"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-input-header">How complaints are processed</label>
                    </div>
                    <div class="col">
                        <p>:</p>
                    </div>
                    <div class="col-9">
                        <!-- <textarea matInput class="full-width unresizable-ta" readonly type="text" [ngModel]="feedbackForm.controls.howComplaintsProcess.value"></textarea> -->
                        <!-- <p>{{feedbackForm.controls.howComplaintsProcess.value}}</p> -->
                        <p [innerHtml]="feedbackForm.controls.howComplaintsProcess.value | linebreaks"></p>
                    </div>
                </div>
            </div>
            <!--
                    <div class="button-div">
                    <div class="row">
                        <div class="btn-control">
                            <button class="btn btn-danger mat-focus-indicator" type="button" (click)="cancel()" >Cancel</button>
                            <button mat-button class="btn btn-warning" (click)="goBack(stepper)">Back</button>
                            <button mat-button class="btn btn-primary" type="button" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>                                
                </div>
                -->

            <!-- </form> -->
        </mat-step>

    </mat-vertical-stepper>
</mat-dialog-content>

<mat-dialog-actions align="end">


    <button mat-stroked-button [disabled]="!hasBackStepper" (click)="goBack(stepper)">Back</button>
    <button mat-stroked-button [disabled]="!hasNextStepper" (click)="goNext(stepper)">Next</button>
    <button mat-flat-button color="primary" [disabled]="!isLastStepper" type="button"
        (click)="onSubmit()">Submit</button>
</mat-dialog-actions>