import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  ValidatorFn,
  ValidationErrors,
  AsyncValidator
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RegulationTextNode, RegulationTextNodeType } from 'src/app/entities/regulation-text-section';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { GovernmentAccountSubtype, WorklistStatusPBRISRegulation } from 'src/app/entities/worklist';
import {
  atLeastOne,
  hasSubTagValidator,
  regulationRequiredByClassification,
} from 'src/app/validators';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DecimalPipe } from '@angular/common';
import { format } from 'date-fns';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { startWith, debounceTime, flatMap, map, take, tap, first } from 'rxjs/operators';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PBRISEmailer } from 'src/app/service/pbris-emailer.service';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';

enum EntityBuilderIndex {
  PIA_SECTION_5_ENTRY = 3,
  PIA_SECTION_5_PARTICIPANT_ENTRY = 4,
  PIA_SECTION_3_AND_4_ENTRY = 6,
  TAG = 7,
  TAG_BUSINESS = 8,
  TAG_NON_BUSINESS = 9,
}

interface ParentRnfVersion {
  id: string;
  title: string;
}

@Component({
  selector: 'app-upload-rnf',
  templateUrl: './upload-rnf.component.html',
  styleUrls: ['./upload-rnf.component.scss']
})

export class UploadRnfComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  isPhase1: boolean = true;
  isLinear = false;
  PIAFormGroup: UntypedFormGroup;
  TagsFormGroup: UntypedFormGroup;
  TextFormGroup: UntypedFormGroup;
  AgencyFormGroup: UntypedFormGroup;
  PISSummaryFormGroup: UntypedFormGroup;
  parent_rnf_version: Observable<ParentRnfVersion[]>;
  parent_rnf_id: ParentRnfVersion | null = null;
  showPIA = false;

  // Accordion
  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  agencies: any[] = [];
  sectors: any[] = [];
  org_codes: any[] = [];
  location_codes: any[] = [];
  psic_codes: any[] = [];
  sobs: any[] = [];
  divisions: any[] = [];
  divisions_select: any[] = [];
  jurisdictions: any[] = [];
  cases: any[] = [];
  cases_select: any[] = [];
  locations: any[] = [];
  documents: any[] = [];
  instruments: any[] = [];
  sols: any[] = [];
  lifeevents: any[] = [];
  lifeevents_select: any[] = [];
  loader: boolean = true;

  legalbases: Observable<any[]>;
  legalbasesobs: Observable<any[]>[] = [];
  intlagreements: Observable<any[]>;
  intlagreementsobs: Observable<any[]>[] = [];
  regulations: Observable<any[]>;
  reg_repeal_obs: Observable<any[]>[] = [];

  //user info
  currenUser: any;

  multipleIssuingAgency: boolean = false;

  public showCostComplianceFileUploadErrors: boolean = false;
  display_cost_compliance_file_name: string = '';
  public showPolicyProblemFileUploadErrors: boolean = false;
  display_policy_problem_file_name: string = '';
  public showRegulationTextAnnexFileUploadErrors: boolean = false;
  display_regulation_text_annex_file_name: string = '';
  public showConsultationAndRecommendationFileUploadErrors: boolean = false;
  display_consultation_and_recommendation_file_name: string = '';

  checkProposedRegulationForm = {
    part_of_reg_plan: [
      { type: "required", message: "Regulatory Planning is required" },
    ],
    reg_action_classification: [
      { type: "atLeastOne", message: "At least one regulatory action is required" },
    ],
    title: [
      { type: "required", message: "Regulation Title is required" },
    ],
    subject: [
      { type: "required", message: "Regulation Short Title is required" },
    ],
    reg_agency: [
      { type: "required", message: "Issuing agency/ies is required" },
    ],
    reg_instrument: [
      { type: "required", message: "Regulation Instrument is required" },
    ],
    has_consultation_comments: [
      { type: "required", message: "Subjected to stakeholder consultation and comments is required" },
    ],
    concudted_consultations: {
      activity_title: [
        { type: "required", message: "Activity title is required" },
      ],
      start_date: [
        { type: "required", message: "Start date invalid" },
      ],
      end_date: [
        { type: "required", message: "End date invalid" },
      ],
    },
    pia_summary: [
      { type: "required", message: "Summary of the regulatory proposal is required" },
    ],
    policy_problem_desc: [
      { type: "required", message: "Explanation on identification of the policy problem is required" },
    ],
    pia_section_2: [
      { type: "required", message: "Objectives of government action is required" },
    ],
    pia_section_3: {
      policy_option: [
        { type: "required", message: "Policy Option Title is required" },
      ],
      policy_option_type: [
        { type: "required", message: "Policy Option Type is required" },
      ],
      policy_option_desc: [
        { type: "required", message: "Policy Option Description is required" },
      ],
    },
    pia_section_4: {
      policy_option: [
        { type: "required", message: "Policy Option Title is required" },
      ],
      impacts: [
        { type: "atLeastOne", message: "Policy Impacts is required" },
      ],
      benefits_desc: [
        { type: "required", message: "Narrative of Benefits is required" },
      ],
      costs_desc: [
        { type: "required", message: "Narrative of Costs is required" },
      ],
      impacts_desc: [
        { type: "required", message: "Narrative of Impacts is required" },
      ],
    },
    pia_section_5: {
      consultation_title: [
        { type: "required", message: "Consultation title is required" },
      ],
      methods: [
        { type: "atLeastOne", message: "At least one consultation method is required" },
      ],
      date_conducted: [
        { type: "required", message: "Date conducted invalid" },
      ],
      has_agency_consulted: [
        { type: "required", message: "Agency conducted a consultation on this policy is required" },
      ],
    },
    pia_sections_6: {
      recommended_policy_option: [
        { type: "required", message: "Recommended Policy Option is required" },
      ],
    },
    tags: {
      reg_classification: [
        { type: "required", message: "Classification is required" },
      ],
      reg_sector: [
        { type: "required", message: "Sector is required" },
      ],
      reg_division: [
        { type: "required", message: "Division is required" },
      ],
      reg_business: [
        { type: "required", message: "Stage of Business is required" },
      ],
      reg_case: [
        { type: "required", message: "Case Use is required" },
      ],
      reg_stageoflife: [
        { type: "required", message: "Stage of life is required" },
      ],
      reg_lifeevent: [
        { type: "required", message: "Life event is required" },
      ],
      reg_juris: [
        { type: "required", message: "Jurisdiction is required" },
      ],
      tags: [
        { type: "regulationRequiredByClassification", message: "Classification is required" },
      ],
      reg_legal: [
        { type: "required", message: "Legal Bases required" },
      ],
      reg_legal_item: [
        { type: "required", message: "Legal Bases required" },
      ],
    },
    has_draft_text: [
      { type: "required", message: "Text regulation is required" },
    ],
    // has_annex_files:[
    //   { type: "required", message: "Annex files is required" },
    // ],
    activeRegulationSection: {
      section_title: [
        { type: "required", message: "Section Title is required" },
      ],
      section_type: [
        { type: "required", message: "Section Type is required" },
      ],
      section_text: [
        { type: "required", message: "Section Text is required" },
      ],
      subsection_title: [
        { type: "required", message: "Subsection Title is required" },
      ],
      subsection_text: [
        { type: "required", message: "Subsection Text is required" },
      ],
    },
    reg_significance_comments: [
      { type: "required", message: "Agency Self-Assessment Narrative/Justification is required" },
    ],
    reg_lenses_comments: [
      { type: "required", message: "Lenses Narrative/Justification is required" },
    ],
  }

  checkUploadedDocs = {
    all: [
      { type: 'min', message: 'No Uploaded file' },
      { type: 'max', message: 'Invalid multiple file upload' },
      { type: 'type', message: 'Incorrect uploaded file type' },
      { type: 'maxSize', message: 'File size exceeded allowed limit' },
    ],
  }

  proposedregulationform = new UntypedFormGroup({
    // true or false, but needs to be answered so initialize as null
    // Initialize as false first until plan collection is accomplished
    part_of_reg_plan: new UntypedFormControl(false, Validators.required),
    // Not to be mistaken with reg_classification
    reg_action_classification: new UntypedFormGroup(
      {
        reg_action_new: new UntypedFormControl(true),
        reg_action_modification: new UntypedFormControl(false),
        reg_action_repeal: new UntypedFormControl(false),
        reg_action_ex_post: new UntypedFormControl(false),
        reg_action_completed: new UntypedFormControl(false),
      },
      { validators: [atLeastOne(Validators.requiredTrue)] }
    ),

    //date

    // Regulatory Notification Form Details
    acc_officer: new UntypedFormControl('', Validators.required),
    acc_officer_designation: new UntypedFormControl('', Validators.required),
    acc_officer_phone: new UntypedFormControl('', Validators.required),
    acc_officer_email: new UntypedFormControl('', Validators.required),
    contact_officer: new UntypedFormControl('', Validators.required),
    contact_officer_designation: new UntypedFormControl('', Validators.required),
    contact_officer_phone: new UntypedFormControl('', Validators.required),
    contact_officer_email: new UntypedFormControl('', Validators.required),
    
    title: new UntypedFormControl('', Validators.required), // Full Title
    subject: new UntypedFormControl('', Validators.required), // Short Title
    reg_life_cycle_stage:new UntypedFormControl('', Validators.required),
    reg_instrument: new UntypedFormControl('', Validators.required),
    reg_agency: new UntypedFormControl((sessionStorage.getItem("agency_id") || ''), Validators.required),
    //reg_action_classification: new UntypedFormControl('', Validators.required),
    reg_action_modification_value: new UntypedFormControl('None'),
    reg_action_modification_section: new UntypedFormControl('None'),
    reg_action_repeal_value: new UntypedFormControl('None'),
    
    has_consultation_comments: new UntypedFormControl('No'),

    summary_regulation: new UntypedFormControl('None'),
    objective_reg_action: new UntypedFormControl('None'),

    conduct_pia_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
    consultation_pis_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
    submission_pis_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
    conduct_ria_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
    consultation_ris_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
    submission_ris_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),

    // PIA
    pia_summary: new UntypedFormControl('None'),
    pia_section_1: new UntypedFormGroup({
      policy_problem_desc: new UntypedFormControl('None'),
      policy_problem_file_name: new UntypedFormControl(false),
      policy_problem_file_folder: new UntypedFormControl(false),
    }),
    pia_section_2: new UntypedFormControl('None'),
    pia_section_3_and_4: new UntypedFormArray([]),
    pia_section_4_file: new UntypedFormGroup({
      compliance_file_name: new UntypedFormControl(false),
      compliance_file_folder: new UntypedFormControl(false),
    }),
    pia_section_5: new UntypedFormArray([]),
    pia_section_6: new UntypedFormGroup({
      recommended_policy_option: new UntypedFormControl('Policy Option #1'),
      impact_summary_desc: new UntypedFormControl('None'),
      compliance_desc: new UntypedFormControl('None'),
      consultation_and_recommendation_file_name: new UntypedFormControl(false),
      consultation_and_recommendation_file_folder: new UntypedFormControl(false),
    }),

    tags: new UntypedFormGroup(
      {
        reg_classification: new UntypedFormControl('Business'),

        // Business only
        reg_sector: new UntypedFormControl('', [Validators.required]),
        reg_business: new UntypedFormControl('', [Validators.required]),
        reg_division: new UntypedFormControl(''),
        reg_case: new UntypedFormControl(''),
        reg_case_text: new UntypedFormControl(''),
        // Non-Business only
        reg_stageoflife: new UntypedFormControl(''),
        reg_lifeevent: new UntypedFormControl(''),

        reg_juris: new UntypedFormControl(''),
        reg_legal: new UntypedFormArray([]),
        reg_intl: new UntypedFormArray([]),
        // regulation repealed has a value if regulation_repeal is true
        regulation_repeal: new UntypedFormControl(false),
        reg_repeal: new UntypedFormArray([]),
      },
      {
        validators: [regulationRequiredByClassification()],
      }
    ),

    has_sub_tags: new UntypedFormControl(false),
    sub_tags: new UntypedFormArray([]),

    has_draft_text: new UntypedFormControl(false),
    has_annex_files: new UntypedFormControl(false),
    regulation_text_annex_file_name: new UntypedFormControl(false),
    regulation_text_annex_file_folder: new UntypedFormControl(false),

    // Self-assessment
    reg_significance: new UntypedFormControl('Major'),
    reg_significance_comments: new UntypedFormControl(''),
    reg_lenses: new UntypedFormGroup(
      {
        lense_competition: new UntypedFormControl(true),
        lense_environmental: new UntypedFormControl(false),
        lense_inclusive: new UntypedFormControl(false),
        lense_disaster: new UntypedFormControl(false),
      }
    ),
    reg_lenses_comments: new UntypedFormControl(''),
  }, { validators: [hasSubTagValidator()], });

  uploadsForm = this._fB.group({
    // policyProblem: new FormGroup({

    // })
  })
  oneMBinByte = 1000000
  acceptedFileType: any = [
    'application/pdf', 'application/msword',
    // 'application/octet-stream',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]
  og_uploads: any = {
    costCompliance: {
      count: 0,
      name: '',
      newFile: false,
      size: 0,
      type: '',
    },
    policyProblem: {
      count: 0,
      name: '',
      newFile: false,
      size: 0,
      type: '',
    },
    regulationTextAnnex: {
      count: 0,
      name: '',
      newFile: false,
      size: 0,
      type: '',
    },
    consultationAndRecommendation: {
      count: 0,
      name: '',
      newFile: false,
      size: 0,
      type: '',
    },
  }

  active_pia_section_3_and_4_form: any;
  active_pia_section_3_and_4_index: number = -1;
  active_pia_section_5_form: any;
  active_pia_section_5_index: number = -1;

  phase_01_filepath: any;
  pia_section_1_filepath: any;
  pia_section_4_filepath: any;
  regulation_text_annex_filepath: any;
  pia_section_6_filepath: any;

  // PIA tables

  get pia_section_3_and_4_val() {
    return this.proposedregulationform.get('pia_section_3_and_4') as UntypedFormArray;
  }

  get pia_section_5_val() {
    return this.proposedregulationform.get('pia_section_5') as UntypedFormArray;
  }

  get reg_action_classification_val() {
    return this.proposedregulationform.get(
      'reg_action_classification'
    ) as UntypedFormGroup;
  }

  pia_section_5_datasource: MatTableDataSource<AbstractControl>;

  piaSection5DisplayedColumns: string[] = [
    'stakeholder',
    'commenting_as',
    'gender',
    'age',
    'income_class',
    'size_of_firm',
    'issues_and_concerns',
    'actions_taken',
    'delete',
  ];

  // form
  currentFormPage: string = '';
  previewImg: any = null;

  edit_regulation_id: any;
  edit_worklist_id: any;

  // Tree selector START
  treeControl = new NestedTreeControl<RegulationTextNode>(
    (node) => node.children
  );
  dataSource = new MatTreeNestedDataSource<RegulationTextNode>();
  hasChild = (_: number, node: RegulationTextNode) =>
    !!node.children && node.children.length > 0;
  childlessSection = (_: number, node: RegulationTextNode) => !!node.children && node.children.length == 0 && node.nodeType == RegulationTextNodeType.SECTION;
  childlessSubsection = (_: number, node: RegulationTextNode) => node.nodeType == RegulationTextNodeType.SUBSECTION;
  regulation_sections_form: Map<string, any>;
  activeRegulationSection: any = null;
  activeRegulationSectionUuid: string = '';
  activeRegulationSubsection: any = null;
  activeRegulationSubsectionUuid: string = '';
  parentOfActiveRegulationSubsectionUuid: string = '';
  // Default false in Proposed because regulation text requires has_draft_text to be true
  private _regulationTextFormIsInvalid: boolean = false;
  // Tree selector END

  commentMode: boolean = false;
  commentButtonsEnabled: boolean = false;
  commentPage: string;
  comment_pia = new UntypedFormControl('');
  comment_details = new UntypedFormControl('');
  comment_regtext = new UntypedFormControl('');
  comment_self_assessment = new UntypedFormControl('');

  private currentRegulation: any;

  private subscriptions: Subscription[] = [];

  proposedRegulationFile = [
    { title: 'Regulatory Notification Form (RNF)', code: 'rnf1', path: 'regulationdetails' },
    { title: 'Stakeholders and/or Sectors Affected', code: 'reviewTags', path: 'pia' },
  ]

  dataLoaded: any = {
    agency: false,
    sector: false,
    division: false,
    sob: false,
    case: false,
    sol: false,
    le: false,
    juris: false,
  }

  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    public decimalPipe: DecimalPipe,
    public auth: AngularFireAuth,
    public worklistService: WorklistService,
    private nE: NotificationsEmailer,
    private pEmail: PBRISEmailer,
    private _fB: UntypedFormBuilder,
  ) {


    // Initial Regulation Section form
    this.regulation_sections_form = new Map();

    if (this.route.snapshot.params.id) {
      if (this.route.snapshot.url[3].path === 'comment') {
        this.commentMode = true;
        var lowest_level_users = [
          GovernmentAccountSubtype.AGENCY_ENC.valueOf(),
        ];
        this.commentButtonsEnabled = lowest_level_users.indexOf(sessionStorage.getItem('account_subtype') || '') < 0;
      }

      this.edit_regulation_id = this.route.snapshot.params.id;
      this.loadRegulationInformation();
    }
    else {
      // Initialize value changers because this.proposedregulationform won't be reinitialized
      this.valueChangeChecker1('reg_classification');
      this.setValueChanger();

      this.loadFormDependentReferences();

      const initialRegulationSectionUuid = uuidv4();

      // Initial Regulation Section selection tree
      this.dataSource.data = [
        {
          name: 'New Section',
          uuid: initialRegulationSectionUuid,
          children: [],
          nodeType: RegulationTextNodeType.SECTION
        },
      ];

      let newSectionInfo = new UntypedFormGroup({
        section_title: new UntypedFormControl('New Section'),
        section_type: new UntypedFormControl(''),
        section_text: new UntypedFormControl(''),
      });

      let newRegulationSection = {
        info: newSectionInfo,
        subsections: new Map(),
      };

      this.regulation_sections_form.set(
        initialRegulationSectionUuid,
        newRegulationSection
      );

      this.pia_section_3_and_4_val.push(
        this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_3_AND_4_ENTRY)
      );

      let newSection5Entry = this.buildNewEntry(
        EntityBuilderIndex.PIA_SECTION_5_ENTRY
      );
      this.pia_section_5_val.push(newSection5Entry);
      this.pia_section_5_datasource = new MatTableDataSource();

      // First page when encoding
      this.currentFormPage = 'preface';

      // Disable reg_agency when encoding because default reg_instrument value does not contain "joint"
      this.proposedregulationform.controls.reg_agency.disable();
    }
  }

  ngOnInit(): void {
    this.loadFormIndependentReferences();

    this.auth.user.subscribe(data => {
      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
        .subscribe({
          next: (res) => {
            let user: any = res.payload.data()
            this.currenUser = user
            console.log('user: ', user)
          }
        })
    });
    // Force scroll to top so user can read the preface for PIS
    // Consider creating a service or routing listener for this
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    console.log('proposedregulationform: ', this.proposedregulationform.value)
  }

  checkPageLoader() {
    let loaded;
    for (let check of Object.keys(this.dataLoaded)) {
      if (loaded == undefined || loaded == null) loaded = this.dataLoaded[check]
      else loaded = loaded && this.dataLoaded[check]
    }
    return loaded
  }

  ngOnDestroy(): void {
    // not being used currently
    this.subscriptions.map((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

  valueChangeChecker1(control2: any) {
    this.valueChangeChecker2('tags', control2)
  }

  valueChangeChecker2(control1: any, control2: any) {
    ((this.proposedregulationform.controls[control1] as UntypedFormGroup).controls[control2] as UntypedFormControl).valueChanges
      .subscribe({
        next: (value) => {
          let tempArr: any[] = [];
          if (control2 == 'reg_sector') tempArr = this.sectors
          if (control2 == 'reg_division') tempArr = this.divisions_select
          if (control2 == 'reg_business') tempArr = this.sobs
          if (control2 == 'reg_case') tempArr = this.cases_select

          if (control2 == 'reg_stageoflife') tempArr = this.sols
          if (control2 == 'reg_lifeevent') tempArr = this.lifeevents_select

          if (control2 == 'reg_juris') tempArr = this.jurisdictions

          if (tempArr.length > 0 && control2 != 'reg_classification') {

          } else if (control2 == 'reg_classification') {
            this.proposedregulationform.controls[control1].patchValue({
              reg_sector: '',
              reg_business: '',
              reg_division: '',
              reg_case: '',
              reg_stageoflife: '',
              reg_lifeevent: '',
            });
            switch (value) {
              case 'Business':
                // Empty Non-Business fields
                this.businessValidators('tags')
                break;
              case 'Non-Business':
                // Empty Business fields
                this.nonBusinessValidators('tags')
                break;
            }
          }
        }
      });
  }

  setValueChanger() {
    this.proposedregulationform.controls['has_annex_files'].valueChanges.subscribe({
      next: (value) => {
        if (value == true) {
          if (!this.uploadsForm.contains('regulationTextAnnex')) {
            this.uploadsForm.addControl('regulationTextAnnex', new UntypedFormGroup({
              name: new UntypedFormControl(''),
              count: new UntypedFormControl(0, [Validators.min(1), Validators.max(1)]),
              type: new UntypedFormControl('', this.checkUploadType()),
              size: new UntypedFormControl(0, Validators.max(this.oneMBinByte * 10)),
              newFile: new UntypedFormControl(false)
            }))
          }
          this.uploadsForm.controls['regulationTextAnnex'].markAllAsTouched();
          this.uploadsForm.controls['regulationTextAnnex'].markAsDirty();
        }
        else if (value == false) {
          if (this.uploadsForm.contains('regulationTextAnnex')) {
            this.resetNewUpload('regulationTextAnnex')
          }
        }
      }
    })
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    // function must return false i guess if user proceeds to pia?
    return true
  }

  async loadRegulationInformation() {
    const params = await firstValueFrom(this.route.queryParams.pipe(first()));

    if (params.worklistId) {
      this.edit_worklist_id = params.worklistId;
    }

    if (this.commentMode) {
      // First page when commenting on regulation
      this.proposedregulationform.disable();

      this.commentPage = params.type;
      switch (params.type) {
        case 'regtext':
          this.currentFormPage = 'regulationtext';
          break;
        case 'regpia':
          this.currentFormPage = 'pia';
          break;
        case 'regdetails':
          // Proposed 'regulationdetails' refers to RNF, to be split off from Proposed form
          this.currentFormPage = 'tagging';
          break;
        default:
          this.currentFormPage = 'tagging';
          break;
      }
    } else {
      // First page when editing regulation
      this.currentFormPage = 'regulationdetails';
    }

    // TODO: Actual editing of proposed regualtion, this one's a doozy

    let regulation_ref = this.afs.collection(`regulations-proposed-phase-02`).doc(this.edit_regulation_id);

    regulation_ref.snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            let pageinfo = data.payload.data();
            this.currentRegulation = pageinfo
            this.currentRegulation.id = data.payload.id

            console.log('pageinfo: ', pageinfo)

            // TODO: rnf and pia FormArrays contain FormGroups, not FormControls
            // Slightly different order because Regulation editing uses reinitialization of the proposedregulationform FormGroup

            // pia FormArrays
            let pia_section_3_and_4 = new UntypedFormArray([]);
            for (let x in pageinfo.pia_section_3) {
              // build combined PIA_SECTION_3_AND_4 array from pageinfo.pia_section_3 and pageinfo.pia_section_4
              // assumes that pia_section_3 and pia_section_4 will have the exact same length from now on, which may affect older test data
              let item = {
                policy_option: pageinfo.pia_section_3[x]?.policy_option,
                policy_option_type: pageinfo.pia_section_3[x]?.policy_option_type,
                policy_option_desc: pageinfo.pia_section_3[x]?.policy_option_desc,
                impacts: pageinfo.pia_section_4[x]?.impacts,
                benefits_desc: pageinfo.pia_section_4[x]?.benefits_desc,
                costs_desc: pageinfo.pia_section_4[x]?.costs_desc,
                impacts_desc: pageinfo.pia_section_4[x]?.impacts_desc,
              };

              pia_section_3_and_4.push(this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_3_AND_4_ENTRY, item));
            }

            let pia_section_5 = new UntypedFormArray([]);
            for (let item of pageinfo.pia_section_5) {
              pia_section_5.push(this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_5_ENTRY, item));
            }

            // tags FormArrays
            let reg_legal = new UntypedFormArray([]);
            for (let item of pageinfo.tags.reg_legal) {
              reg_legal.push(new UntypedFormControl(item));
            }

            let reg_intl = new UntypedFormArray([]);
            for (let item of pageinfo.tags.reg_intl) {
              reg_intl.push(new UntypedFormControl(item));
            }

            let reg_repeal = new UntypedFormArray([]);
            for (let item of pageinfo.tags.reg_repeal) {
              reg_repeal.push(new UntypedFormControl(this.buildNewRegEntry(item)));
            }

            this.proposedregulationform = new UntypedFormGroup({
              // true or false, but needs to be answered so initialize as null
              // Initialize as false first until plan collection is accomplished
              part_of_reg_plan: new UntypedFormControl(pageinfo.part_of_reg_plan, Validators.required),
              // Not to be mistaken with reg_classification
              reg_action_classification: new UntypedFormGroup(
                {
                  reg_action_new: new UntypedFormControl(pageinfo.reg_action_classification.reg_action_new),
                  reg_action_modification: new UntypedFormControl(pageinfo.reg_action_classification.reg_action_modification),
                  reg_action_repeal: new UntypedFormControl(pageinfo.reg_action_classification.reg_action_repeal),
                  reg_action_ex_post: new UntypedFormControl(pageinfo.reg_action_classification.reg_action_ex_post),
                  reg_action_completed: new UntypedFormControl(pageinfo.reg_action_classification.reg_action_completed),
                },
                { validators: [atLeastOne(Validators.requiredTrue)] }
              ),

              // Regulatory Notification Form Details
              acc_officer: new UntypedFormControl(pageinfo.acc_officer, Validators.required),
              acc_officer_designation: new UntypedFormControl(pageinfo.acc_officer_designation, Validators.required),
              acc_officer_phone: new UntypedFormControl(pageinfo.acc_officer_phone, Validators.required),
              acc_officer_email: new UntypedFormControl(pageinfo.acc_officer_email, Validators.required),
              contact_officer: new UntypedFormControl(pageinfo.contact_officer, Validators.required),
              contact_officer_designation: new UntypedFormControl(pageinfo.contact_officer_designation, Validators.required),
              contact_officer_phone: new UntypedFormControl(pageinfo.contact_officer_phone, Validators.required),
              contact_officer_email: new UntypedFormControl(pageinfo.contact_officer_email, Validators.required),
              
              title: new UntypedFormControl(pageinfo.subject, Validators.required), // Full Title
              subject: new UntypedFormControl(pageinfo.title, Validators.required), // Short Title
              reg_life_cycle_stage:new UntypedFormControl(pageinfo.reg_life_cycle_stage, Validators.required),
              reg_instrument: new UntypedFormControl(pageinfo.reg_instrument, Validators.required),
              reg_agency: new UntypedFormControl(pageinfo.reg_agency, Validators.required),
              
              has_consultation_comments: new UntypedFormControl(pageinfo.has_consultation_comments),

              summary_regulation: new UntypedFormControl(pageinfo.summary_regulation),
              objective_reg_action: new UntypedFormControl(pageinfo.objective_reg_action),

              conduct_pia_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
              consultation_pis_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
              submission_pis_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
              conduct_ria_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
              consultation_ris_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
              submission_ris_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),

              //subject: new UntypedFormControl(pageinfo.subject, Validators.required),
              //title: new UntypedFormControl(pageinfo.title, Validators.required),
              //reg_agency: new UntypedFormControl(pageinfo.reg_agency, Validators.required),
              //reg_instrument: new UntypedFormControl(pageinfo.reg_instrument, Validators.required),
              //has_consultation_comments: new UntypedFormControl(pageinfo.has_consultation_comments, Validators.required),

              // PIA
              pia_summary: new UntypedFormControl(pageinfo.pia_summary),
              pia_section_1: new UntypedFormGroup({
                policy_problem_desc: new UntypedFormControl(pageinfo.pia_section_1.policy_problem_desc),
                policy_problem_file_name: new UntypedFormControl(pageinfo.pia_section_1.policy_problem_file_name),
                policy_problem_file_folder: new UntypedFormControl(pageinfo.pia_section_1.policy_problem_file_folder),
              }),
              pia_section_2: new UntypedFormControl(pageinfo.pia_section_2),
              pia_section_3_and_4: pia_section_3_and_4,
              // New property, so null check for older test data
              pia_section_4_file: new UntypedFormGroup({
                compliance_file_name: new UntypedFormControl(pageinfo.pia_section_4_file?.compliance_file_name),
                compliance_file_folder: new UntypedFormControl(pageinfo.pia_section_4_file?.compliance_file_folder),
              }),

              pia_section_5: pia_section_5,
              pia_section_6: new UntypedFormGroup({
                recommended_policy_option: new UntypedFormControl(pageinfo.pia_section_6.recommended_policy_option),
                impact_summary_desc: new UntypedFormControl(pageinfo.pia_section_6.impact_summary_desc),
                compliance_desc: new UntypedFormControl(pageinfo.pia_section_6.compliance_desc),
                consultation_and_recommendation_file_name: new UntypedFormControl(pageinfo.pia_section_6.consultation_and_recommendation_file_name ? pageinfo.pia_section_6.consultation_and_recommendation_file_name : ''),
                consultation_and_recommendation_file_folder: new UntypedFormControl(pageinfo.pia_section_6.consultation_and_recommendation_file_folder ? pageinfo.pia_section_6.consultation_and_recommendation_file_folder : ''),
              }),

              tags: new UntypedFormGroup(
                {
                  reg_classification: new UntypedFormControl(pageinfo.tags.reg_classification),

                  // Business only
                  reg_sector: new UntypedFormControl(pageinfo.tags.reg_sector),
                  reg_business: new UntypedFormControl(pageinfo.tags.reg_business),
                  reg_division: new UntypedFormControl(pageinfo.tags.reg_division),
                  reg_case: new UntypedFormControl(pageinfo.tags.reg_case),
                  // Non-Business only
                  reg_stageoflife: new UntypedFormControl(pageinfo.tags.reg_stageoflife),
                  reg_lifeevent: new UntypedFormControl(pageinfo.tags.reg_lifeevent),

                  reg_juris: new UntypedFormControl(pageinfo.tags.reg_juris),
                  reg_legal: reg_legal,
                  reg_intl: reg_intl,
                  // regulation repealed has a value if regulation_repeal is true
                  regulation_repeal: new UntypedFormControl(pageinfo.tags.regulation_repeal),
                  reg_repeal: reg_repeal,
                },
                {
                  validators: [regulationRequiredByClassification()],
                }
              ),

              has_sub_tags: new UntypedFormControl(pageinfo.has_sub_tags),
              sub_tags: new UntypedFormArray([]),

              has_draft_text: new UntypedFormControl(pageinfo.has_draft_text),
              has_annex_files: new UntypedFormControl(pageinfo.has_annex_files),
              regulation_text_annex_file_name: new UntypedFormControl(pageinfo.regulation_text_annex_file_name),
              regulation_text_annex_file_folder: new UntypedFormControl(pageinfo.regulation_text_annex_file_folder),

              // Self-assessment
              reg_significance: new UntypedFormControl(pageinfo.reg_significance),
              reg_significance_comments: new UntypedFormControl(pageinfo.reg_significance_comments),
              reg_lenses: new UntypedFormGroup(
                {
                  lense_competition: new UntypedFormControl(pageinfo.reg_lenses.lense_competition),
                  lense_environmental: new UntypedFormControl(pageinfo.reg_lenses.lense_environmental),
                  lense_inclusive: new UntypedFormControl(pageinfo.reg_lenses.lense_inclusive),
                  lense_disaster: new UntypedFormControl(pageinfo.reg_lenses.lense_disaster),
                }
              ),
              reg_lenses_comments: new UntypedFormControl(pageinfo.reg_lenses_comments),
            }, { validators: [hasSubTagValidator()], });

            if (pageinfo.has_sub_tags && pageinfo.sub_tags) {
              pageinfo.sub_tags.map((sub_tag: any) => {
                this.addSubtag('sub_tags', sub_tag)
              })
            }

            console.log('proposedregulationform: ', this.proposedregulationform.value)

            // data set for document uploads
            if (this.proposedregulationform.value.pia_section_1.policy_problem_file_folder && this.proposedregulationform.value.pia_section_1.policy_problem_file_name) {
              this.handleLoadForEditUploads('policyProblem')
            }

            if (this.proposedregulationform.value.pia_section_4_file.compliance_file_folder && this.proposedregulationform.value.pia_section_4_file.compliance_file_name) {
              this.handleLoadForEditUploads('costCompliance')
            }

            if (this.proposedregulationform.value.pia_section_6.consultation_and_recommendation_file_folder && this.proposedregulationform.value.pia_section_6.consultation_and_recommendation_file_name) {
              this.handleLoadForEditUploads('consultationAndRecommendation')
            }

            if (this.proposedregulationform.value.regulation_text_annex_file_folder && this.proposedregulationform.value.regulation_text_annex_file_name) {
              this.handleLoadForEditUploads('regulationTextAnnex')
            }
            // !data set for document uploads
            if (this.proposedregulationform.value.tags.reg_classification == 'Business') this.businessValidators('tags');
            else if (this.proposedregulationform.value.tags.reg_classification == 'Non-Business') this.nonBusinessValidators('tags');

            // Initialize value changers here because this.proposedregulationform was reinitialized
            this.valueChangeChecker1('reg_classification');
            this.setValueChanger();
            this.loadFormDependentReferences();

            if (this.commentMode) {
              this.proposedregulationform.disable();
            }

            if (pageinfo.has_draft_text) {
              this.fillUpRegulationText(pageinfo.regulation_text);
            }
          }
        ),
        take(1)
      )
      .subscribe();
  }

  handleLoadForEditUploads(controlCode: string) {
    let uploadName = ''
    switch (controlCode) {
      case 'policyProblem': {
        uploadName = this.proposedregulationform.value.pia_section_1.policy_problem_file_name;
        break;
      }
      case 'costCompliance': {
        uploadName = this.proposedregulationform.value.pia_section_4_file.compliance_file_name;
        break;
      }
      case 'consultationAndRecommendation': {
        uploadName = this.proposedregulationform.value.pia_section_6.consultation_and_recommendation_file_name;
        break;
      }
      case 'regulationTextAnnex': {
        uploadName = this.proposedregulationform.value.regulation_text_annex_file_name;
        break;
      }
    }
    this.og_uploads[controlCode].name = uploadName;
    this.og_uploads[controlCode].count = 1;
    this.og_uploads[controlCode].newFile = false;

    let filePath = '';

    switch (controlCode) {
      case 'policyProblem': {
        filePath = this.proposedregulationform.value.pia_section_1.policy_problem_file_folder + '/' + this.proposedregulationform.value.pia_section_1.policy_problem_file_name;
        break;
      }
      case 'costCompliance': {
        filePath = this.proposedregulationform.value.pia_section_4_file.compliance_file_folder + '/' + this.proposedregulationform.value.pia_section_4_file.compliance_file_name;
        break;
      }
      case 'consultationAndRecommendation': {
        filePath = this.proposedregulationform.value.pia_section_6.consultation_and_recommendation_file_folder + '/' + this.proposedregulationform.value.pia_section_6.consultation_and_recommendation_file_name;
        break;
      }
      case 'regulationTextAnnex': {
        filePath = this.proposedregulationform.value.regulation_text_annex_file_folder + '/' + this.proposedregulationform.value.regulation_text_annex_file_name;
        break;
      }
    }
    // console.log('filePath: ',filePath)
    this.store.ref(filePath).getMetadata()
      .subscribe({
        next: (metadata) => {
          // console.log('metadata: ',metadata)
          this.og_uploads[controlCode].type = metadata.contentType
          this.og_uploads[controlCode].size = metadata.size
          this.saveFileInformation([{ name: this.og_uploads[controlCode].name, size: this.og_uploads[controlCode].size, type: this.og_uploads[controlCode].type }], controlCode, false)
        },
        error: (err) => {
          console.error('Error: ', err, 'path: ' + filePath)
          this.saveFileInformation([{ name: this.og_uploads[controlCode].name, size: 0, type: '' }], controlCode, false)
        }
      })
  }

  /**
   *  ________________________
   * |                        |
   * |        Reminder        |
   * |________________________|
   *
   * Please move all firestore references
   * to 'References'=>'common/PBRIS/ARTEMIS'=>...
   *
   */

  loadFormIndependentReferences() {
    this.loadAgencies();
    this.loadSectors();
    this.loadSobs();
    this.loadJuris();
    this.loadSols();
    this.loadLegalBases();
    this.loadInternationalAgreements();
    this.loadRegulations();
  }

  loadFormDependentReferences() {
    this.loadInstruments();
    this.loadDivisions();
    this.loadCases();
    this.loadLifeEvents();
  }

  loadAgencies() {
    this.afs
      .collection(`Agency`)
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          this.agencies = [];
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.agencies.includes(item) === false) {
              this.agencies.push(item);
            }
          });
          this.dataLoaded.agency = true
        },
        error: (err) => this.dataLoaded.agency = true
      });
  }

  loadSectors() {
    this.afs
      .collection(`Sector`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.sectors = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.sectors.includes(item) === false) {
              this.sectors.push(item);
            }
          });

          this.dataLoaded.sector = true
        },
        error: (err) => this.dataLoaded.sector = true
      });
  }

  loadSobs() {
    this.afs
      .collection(`Stage of Business`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.sobs = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.sobs.includes(item) === false) {
              this.sobs.push(item);
            }
          });
          this.dataLoaded.sob = true
        },
        error: (err) => this.dataLoaded.sob = true
      });
  }

  loadSols() {
    this.afs
      .collection(`Stage of Life`)
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.sols = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.sols.includes(item) === false) {
              this.sols.push(item);
            }
          });
          this.dataLoaded.sol = true
        },
        error: (err) => this.dataLoaded.sol = true
      });
  }

  loadLifeEvents() {
    this.afs
      .collection(`Life Event`)
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.lifeevents = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.lifeevents.includes(item) === false) {
              this.lifeevents.push(item);
            }
          });

          // Now form-dependent on reg_stageoflife
          if (this.proposedregulationform.value.reg_stageoflife) {
            let stageOfLife = this.sols.find((option) => option.id === this.proposedregulationform.value.reg_stageoflife).stage.toLowerCase();
            this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
          }
          this.dataLoaded.le = true
        },
        error: (err) => this.dataLoaded.le = true
      });
  }

  loadDivisions() {
    this.afs
      .collection(`Division`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.divisions = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.divisions.includes(item) === false) {
              this.divisions.push(item);
            }
          });

          // Now form-dependent on reg_sector
          if (this.proposedregulationform.value.reg_sector) {
            let section = this.sectors.find((option) => option.id === this.proposedregulationform.value.reg_sector).section.toLowerCase();
            this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
          }
          this.dataLoaded.division = true
        },
        error: (err) => this.dataLoaded.division = true
      });
  }

  loadJuris() {
    this.afs
      .collection(`Jurisdiction`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.jurisdictions = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.jurisdictions.includes(item) === false) {
              this.jurisdictions.push(item);
            }
          });
          this.dataLoaded.juris = true
        },
        error: (err) => this.dataLoaded.juris = true
      });
  }

  loadCases() {
    this.afs
      .collection(`Case Use`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next: (data: any) => {
          this.cases = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (item.ref) delete item.ref
            if (item._createdBy) delete item._createdBy
            if (item._updatedBy) delete item._updatedBy

            if (this.cases.includes(item) === false) {
              this.cases.push(item);
            }
          });

          // Now form-dependent on reg_business
          if (this.proposedregulationform.value.reg_business) {
            let section = this.sobs.find((option) => option.id === this.proposedregulationform.value.reg_business).section.toLowerCase();
            this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
          }
          this.dataLoaded.case = true
        },
        error: (err) => this.dataLoaded.case = true
      });
  }

  loadInstruments() {
    this.afs
      .collection(`Instrument`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.instruments = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.instruments.includes(item) === false) {
            this.instruments.push(item);
          }
        });

        // Set multipleIssuingAgency during loading
        // Now a "form-dependent reference data call, so put it inside the subscribe"
        this.updateIssuingAgency(this.proposedregulationform.value.reg_instrument);
      });
  }

  loadLegalBases() {
    this.legalbases = this.afs.collection(`Legal Bases`, filter => filter.orderBy('name')).valueChanges();
    // Insert default legal base input because it is required, but check for edit_regulation_id to ensure no race condition when editing a regulation
    if (!this.edit_regulation_id) {
      this.addLegalBase();
    }
  }

  loadInternationalAgreements() {
    this.intlagreements = this.afs.collection(`International Agreements`, filter => filter.orderBy('name')).valueChanges();
  }

  loadRegulations() {
    let batches = [];

    batches.push(
      this.afs.collection('PBRIS 1 Regulations', filter => filter.orderBy('short_title')).snapshotChanges()
    );

    batches.push(
      this.afs.collection('regulations-existing-phase-02', filter => filter.where('is_posted', '==', true)).snapshotChanges()
    );

    combineLatest(batches)
      .pipe(
        tap(
          (results) => {
            let index = 0;
            let reg_results: any[] = [];

            results.forEach(content => {
              content.forEach(async (results: any) => {
                let item: any = results.payload.doc.data();
                item.id = results.payload.doc.id;

                let resultObj = this.buildNewRegEntry(index == 0 ? item.short_title : item.subject);
                reg_results.push(resultObj);
              });
              index++;
            });

            reg_results.sort((a: any, b: any) =>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );
            this.regulations = of(reg_results);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  private _filter(value: string, autocompletecode: string): Observable<any[]> {
    const filterValue = value.toLowerCase();

    switch (autocompletecode) {
      case "legal":
        return this.legalbases.pipe(
          map(legalbases => {
            return legalbases.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "intl":
        return this.intlagreements.pipe(
          map(intlagreements => {
            return intlagreements.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "reg":
        return this.regulations.pipe(
          map(regulations => {
            return regulations.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      default:
        return of([]);
    }
  }

  get reg_legal_val() {
    return this.proposedregulationform.get('tags')?.get('reg_legal') as UntypedFormArray;
  }

  get reg_intl_val() {
    return this.proposedregulationform.get('tags')?.get('reg_intl') as UntypedFormArray;
  }

  get reg_repeal_val() {
    return this.proposedregulationform.get('tags')?.get('reg_repeal') as UntypedFormArray;
  }

  addLegalBase() {
    let newControl = new UntypedFormControl('');
    this.legalbasesobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "legal"))
      )
    );
    this.reg_legal_val.push(newControl);
  }

  removeLegalBase(index: number) {
    this.reg_legal_val.removeAt(index);
    this.legalbasesobs.splice(index, 1);
  }

  addInternationalAgreement() {
    let newControl = new UntypedFormControl('');
    this.intlagreementsobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "intl"))
      )
    )
    this.reg_intl_val.push(newControl);
  }

  removeInternationalAgreement(index: number) {
    this.reg_intl_val.removeAt(index);
    this.intlagreementsobs.splice(index, 1);
  }

  addRegulationRepealed() {
    let newControl = new UntypedFormControl('');
    this.reg_repeal_obs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(typeof value === 'string' ? value : value.name, "reg"))
      )
    )
    this.reg_repeal_val.push(newControl);
  }

  removeRegulationRepealed(index: number) {
    this.reg_repeal_val.removeAt(index);
    this.reg_repeal_obs.splice(index, 1);
  }

  displayReg(reg: any): string {
    return reg && reg.name ? reg.name : '';
  }

  private buildNewRegEntry(name: string): any {
    return {
      name: name
    }
  }

  instrumentOnChange(event: any) {
    this.updateIssuingAgency(event.target.value);
  }

  private updateIssuingAgency(instrumentVal: string) {
    // For copying to other pages
    const useForm = this.proposedregulationform;

    // Handle blank reg_instrument
    if (!instrumentVal) {
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      this.baseSelectOnChange1('reg_agency')
      return;
    }

    let instrument = this.instruments.find((option) => option.id === instrumentVal).name.toLowerCase();
    this.multipleIssuingAgency = instrument.includes('joint');
    // Force reg_agency values after switching reg_instrument value
    this.baseSelectOnChange1('reg_instrument')
    if (this.multipleIssuingAgency) {
      // joint: reg_agency is an array
      useForm.controls.reg_agency.enable();
      useForm.patchValue({
        reg_agency: [sessionStorage.getItem("agency_id") || '']
      });
      this.baseSelectToUntouchOnChange1('reg_agency')
    }
    else {
      // non-joint: reg_agency is a string
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      this.baseSelectOnChange1('reg_agency')
    }
  }

  sectorOnChange(event: any) {
    let section = this.sectors.find((option) => option.id === event.target.value).section.toLowerCase();
    this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
    this.selectOnChange1('reg_sector');
    this.selectToUntouchOnChange1('reg_division');
  }

  sobOnChange(event: any) {
    let section = this.sobs.find((option) => option.id === event.target.value).section.toLowerCase();
    this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
    this.selectOnChange1('reg_business');
    this.selectToUntouchOnChange1('reg_case');
  }

  solOnChange(event: any) {
    let stageOfLife = this.sols.find((option) => option.id === event.target.value).stage.toLowerCase();
    this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
    this.selectOnChange1('reg_stageoflife');
    this.selectToUntouchOnChange1('reg_lifeevent');
  }

  selectOnChange1(formControl: string) {
    this.selectOnChange2('tags', formControl);
  }

  selectOnChange2(controlGroup: string, formControl: string) {
    if (this.proposedregulationform && (this.proposedregulationform.controls[controlGroup] as UntypedFormGroup) && ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl)) {
      ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).markAsTouched();
      ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).updateValueAndValidity();
    }
  }

  selectToUntouchOnChange1(formControl: string) {
    this.selectToUntouchOnChange2('tags', formControl);
  }

  selectToUntouchOnChange2(controlGroup: string, formControl: string) {
    if (this.proposedregulationform && (this.proposedregulationform.controls[controlGroup] as UntypedFormGroup) && ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl)) {
      ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).markAsUntouched();
      ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).updateValueAndValidity();
    }
  }

  baseSelectOnChange1(formControl: string) {
    (this.proposedregulationform.controls[formControl] as UntypedFormControl).markAsTouched();
    (this.proposedregulationform.controls[formControl] as UntypedFormControl).updateValueAndValidity();
  }

  baseSelectToUntouchOnChange1(formControl: string) {
    (this.proposedregulationform.controls[formControl] as UntypedFormControl).markAsUntouched();
    (this.proposedregulationform.controls[formControl] as UntypedFormControl).updateValueAndValidity();
  }

  togglePreview() {
    if (this.isPhase1) {
      this.isPhase1 = false;
    } else {
      this.isPhase1 = true;
    }
  }

  // file handlers
  buttonUpload(event: any, controlCode: string) {
    this.saveFileInformation(event.target.files, controlCode)
  }

  dropzone(files: File[], controlCode: string) {
    this.saveFileInformation(files, controlCode)
  }

  saveFileInformation(files: any, controlCode: any, newFile?: boolean) {
    // console.log('files: ',files)
    // console.log('controlCode: ',controlCode)
    let file
    if (files.length > 0) file = files[0]
    this.saveFileInformationSetControl(controlCode, file, files.length, newFile)

    switch (controlCode) {
      case 'costCompliance': {
        this.showCostComplianceFileUploadErrors = this.uploadsForm.controls[controlCode].invalid;
        this.display_cost_compliance_file_name = this.uploadsForm.controls[controlCode].value.name;
        this.proposedregulationform.get('pia_section_4_file')?.get('compliance_file_name')?.setValue(this.uploadsForm.controls[controlCode].value.name);
      } break;
      case 'policyProblem': {
        this.showPolicyProblemFileUploadErrors = this.uploadsForm.controls[controlCode].invalid;
        this.display_policy_problem_file_name = this.uploadsForm.controls[controlCode].value.name
        this.proposedregulationform.get('pia_section_1')?.get('policy_problem_file_name')?.setValue(this.uploadsForm.controls[controlCode].value.name);
      } break;
      case 'regulationTextAnnex': {
        this.showRegulationTextAnnexFileUploadErrors = this.uploadsForm.controls[controlCode].invalid;
        this.display_regulation_text_annex_file_name = this.uploadsForm.controls[controlCode].value.name
        this.proposedregulationform.get('regulation_text_annex_file_name')?.setValue(this.uploadsForm.controls[controlCode].value.name);
      } break;
      case 'consultationAndRecommendation': {
        this.showConsultationAndRecommendationFileUploadErrors = this.uploadsForm.controls[controlCode].invalid;
        this.display_consultation_and_recommendation_file_name = this.uploadsForm.controls[controlCode].value.name
        this.proposedregulationform.get('pia_section_6')?.get('consultation_and_recommendation_file_name')?.setValue(this.uploadsForm.controls[controlCode].value.name);
      } break;
    }

    if (controlCode == 'costCompliance') this.pia_section_4_filepath = file;
    if (controlCode == 'policyProblem') this.pia_section_1_filepath = file;
    if (controlCode == 'regulationTextAnnex') this.regulation_text_annex_filepath = file;
    if (controlCode == 'consultationAndRecommendation') this.pia_section_6_filepath = file;

  }

  saveFileInformationSetControl(controlName: string, file: any, fileL: number, newFile?: boolean) {
    const bool = newFile == false ? newFile : true
    if (!this.uploadsForm.contains(controlName)) {
      let countValidators = [Validators.max(1)]
      if (controlName == 'regulationTextAnnex') countValidators.push(Validators.min(1))
      this.uploadsForm.addControl(controlName, new UntypedFormGroup({
        name: new UntypedFormControl(''),
        count: new UntypedFormControl(0, countValidators),
        type: new UntypedFormControl('', this.checkUploadType()),
        size: new UntypedFormControl(0, Validators.max(this.oneMBinByte * 10)),
        newFile: new UntypedFormControl(bool)
      }))
      this.uploadsForm.controls[controlName].markAllAsTouched();
      this.uploadsForm.controls[controlName].markAsDirty();
    }
    if (fileL > 0) {
      if (fileL == 1) {
        this.uploadsForm.controls[controlName].patchValue({
          name: file.name,
          count: 1,
          type: file.type,
          size: file.size,
          newFile: bool,
        });
      }
      else {
        this.uploadsForm.controls[controlName].patchValue({
          name: file.name + ', ...',
          count: fileL,
          type: '',
          size: 0,
          newFile: bool,
        });
      }
    } else {
      this.uploadsForm.controls[controlName].patchValue({
        name: '',
        count: fileL,
        type: '',
        size: 0,
        newFile: bool,
      });
    }
  }

  resetNewUpload(controlCode: string) {
    if (this.uploadsForm.contains(controlCode)) {
      this.uploadsForm.controls[controlCode].patchValue({
        name: this.og_uploads[controlCode].name,
        count: this.og_uploads[controlCode].count,
        size: this.og_uploads[controlCode].size,
        type: this.og_uploads[controlCode].type,
        newFile: this.og_uploads[controlCode].newFile,
      })

      switch (controlCode) {
        case 'costCompliance': {
          this.showCostComplianceFileUploadErrors = false;
          this.display_cost_compliance_file_name = this.og_uploads[controlCode].name;
          this.proposedregulationform.get('pia_section_4_file')?.get('compliance_file_name')?.setValue(this.og_uploads[controlCode].name);
        } break;
        case 'policyProblem': {
          this.showPolicyProblemFileUploadErrors = false;
          this.display_policy_problem_file_name = this.og_uploads[controlCode].name
          this.proposedregulationform.get('pia_section_1')?.get('policy_problem_file_name')?.setValue(this.og_uploads[controlCode].name);
        } break;
        case 'regulationTextAnnex': {
          this.showRegulationTextAnnexFileUploadErrors = false;
          this.display_regulation_text_annex_file_name = this.og_uploads[controlCode].name
          this.proposedregulationform.get('regulation_text_annex_file_name')?.setValue(this.og_uploads[controlCode].name);
        } break;
        case 'consultationAndRecommendation': {
          this.showConsultationAndRecommendationFileUploadErrors = false;
          this.display_consultation_and_recommendation_file_name = this.og_uploads[controlCode].name
          this.proposedregulationform.get('pia_section_6')?.get('consultation_and_recommendation_file_name')?.setValue('');
        } break;
      };

      (document.getElementById(controlCode) as HTMLInputElement).value = "";
    }
  }


  saveImageInformation(event: any) {
    if (this.activeRegulationSubsection) {
      var allowedExtensions = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
      if (!allowedExtensions.exec(event.target.files[0].name)) {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: null,
        });
      } else {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: event.target.files[0],
        });
        this.showSubsectionImagePreview();
      }
    }
  }

  updateTreeSectionName(event: any) {
    if (this.activeRegulationSection && this.activeRegulationSectionUuid) {
      this.dataSource.data.forEach((e) => {
        if (e && e.uuid === this.activeRegulationSectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.name = event.target.value;
          return;
        }
      });
    }
  }

  updateTreeSubsectionName(event: any) {
    if (
      this.activeRegulationSubsection &&
      this.activeRegulationSubsectionUuid
    ) {
      this.dataSource.data.forEach((e) => {
        if (e && e.uuid === this.parentOfActiveRegulationSubsectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.children?.forEach((f) => {
            if (f && f.uuid === this.activeRegulationSubsectionUuid) {
              const subsection: RegulationTextNode = f;
              subsection.name = event.target.value;
              return;
            }
          });
          return;
        }
      });
    }
  }

  showSubsectionImagePreview() {
    if (
      this.activeRegulationSubsection &&
      this.activeRegulationSubsection.value.subsection_image_file
    ) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(
        this.activeRegulationSubsection.value.subsection_image_file
      );
      fileReader.onload = () => {
        this.previewImg = fileReader.result as string;
      };
    } else {
      this.previewImg = null;
    }
  }

  async saveAsDraft() {
    this.saveAndUpload(WorklistStatusPBRISRegulation.DRAFT);
  }

  async submitRegulation() {
    this.saveAndUpload(WorklistStatusPBRISRegulation.FOR_VERIFICATION);
  }

  // Upload is now required and and must be valid
  saveAndUploadCheckUpload(controlName: string) {
    return this.uploadsForm.contains(controlName) && this.uploadsForm.controls[controlName].valid
  }

  async saveAndUpload(status: string) {
    this.loading = true;
    let saveAndUploadPass = true

    const dateUploadedStr = format(new Date(), 'yyyy-MM-dd');

    console.log('proposedregulationform: ', this.proposedregulationform.value)
    console.log('uploadsForm: ', this.uploadsForm.value)

    if (status == WorklistStatusPBRISRegulation.FOR_VERIFICATION) {
      this.validateFields()
      if (this.proposedregulationform.invalid) saveAndUploadPass = false
      if (this.regulationTextFormIsInvalid) saveAndUploadPass = false
    }


    // console.log('saveAndUploadPass: ',saveAndUploadPass)

    if (saveAndUploadPass) {
      // if(false){

      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      this.loading = true;
      let documentID: any;
      //
      let uploadProgress!: Observable<number | undefined>;

      const user = await this.auth.currentUser;
      let fromAgency = sessionStorage.getItem('agency_name');
      let fromOfficer = user?.displayName;

      let regulation_ref: any;
      let worklist_ref: any;
      //regulations-phase-02

      // uncomment -------------------------------
      if (this.edit_regulation_id) {
        regulation_ref = this.afs.firestore
          .collection(`regulations-proposed-phase-02`)
          .doc(this.edit_regulation_id);
      }
      else {
        regulation_ref = this.afs.firestore
          .collection(`regulations-proposed-phase-02`)
          .doc();
      }

      if (this.edit_worklist_id) {
        worklist_ref = this.afs.firestore
          .collection(`Worklist-Proposed Regulations`)
          .doc(this.edit_worklist_id);
      }
      else {
        worklist_ref = this.afs.firestore
          .collection(`Worklist-Proposed Regulations`)
          .doc();
      }
      // !uncomment -------------------------------

      //regulations-phase-02

      let destinationPath = '';

      let regulationJSON = this.proposedregulationform.value;

      if (regulationJSON.has_sub_tags == false) {
        regulationJSON.sub_tags = []
      }

      let pia_section_3: any[] = [];
      let pia_section_4: any[] = [];

      // split pia_section_3_and_4 into the pia_section_3 and pia_section_4 arrays
      this.pia_section_3_and_4_val.value.forEach((e: any) => {
        pia_section_3.push({
          policy_option: e.policy_option,
          policy_option_desc: e.policy_option_desc,
          policy_option_type: e.policy_option_type,
        });

        pia_section_4.push({
          policy_option: e.policy_option,
          impacts: e.impacts,
          impacts_desc: e.impacts_desc,
          benefits_desc: e.benefits_desc,
          costs_desc: e.costs_desc,
        });
      });

      regulationJSON.pia_section_3 = pia_section_3;
      regulationJSON.pia_section_4 = pia_section_4;
      delete regulationJSON.pia_section_3_and_4;

      /* 
        pia_section_5
        Participants is not applicable if has_agency_consulted is false
        So that the user doesn't lose work, force empty participants for each Section 5 entity before saving
        But don't do this when saving as draft

        To test:
        1. Set has_agency_consulted to "Yes" (shows the Participants table)
        2. Fill up Participants table
        3. Set has_agency_consulted to "No" (hides the Participants table)
        
        Save as draft option:
        4a. Save as draft
        5a. Edit the draft
        6a. Set has_agency_consulted to "Yes" (shows the Participants table)

        Submit the regulation:
        4b. Submit the regulation
        5b. Have it FOR REVISION
        6b. Edit the regulation
        7b. Set has_agency_consulted to "Yes" (shows the Participants table) 
      */
      if (status == WorklistStatusPBRISRegulation.FOR_VERIFICATION) {
        regulationJSON.pia_section_5.forEach((sec_5: any) => {
          if (!sec_5.has_agency_consulted) {
            sec_5.participants = [];
          }
        });
      }

      // Gotcha: disabled form controls will not be part of form.value, similar to disabled input tags in a form tag
      // You need to get values from a disabled form control through controls['controlname'].value
      if (this.proposedregulationform.controls.reg_agency.disabled) {
        regulationJSON.reg_agency = this.proposedregulationform.controls.reg_agency.value;
      }

      // For Proposed Regulations, only save regulation text if draft text checkbox is ticked
      if (this.proposedregulationform.get('has_draft_text')?.value === true) {
        // Must be an array
        var regulationText: Array<any> = [];

        /* 
        Because order matters for both regulation sections and subsections,
        regulation text must be stored in Firestore as an ordered array.
        However, the UUIDs generated during encoding are still used for the subsection image paths.
      */
        this.regulation_sections_form.forEach((v: any) => {
          // Do not return the FormGroup objects associated with regulation section and regulation subsections
          // Only return the actual FormGroup value
          let returnRegulationSection = {
            info: v.info.value,
            // Must be an array
            subsections: [] as Array<any>,
          };

          // Must be an array
          let returnRegulationSubsections: Array<any> = [];

          // Keep generated UUIDs as a unique path folder name for every subsection image uploaded
          v.subsections.forEach((v: UntypedFormGroup, k: string) => {
            let regulationSubsection = v.value;

            // Upload subsection images
            // uncomment -------------------------------
            if (regulationSubsection.subsection_image_file) {
              const temp_subsection_image_file =
                regulationSubsection.subsection_image_file;
              // Subdirectory format for uniqueness. doc_date is required so assume it has a value
              const new_subsection_image_file_path = `regulationsProposedPhase02/${dateUploadedStr}/${regulation_ref.id}/subsections/${k}`;

              let subsectionImgDestinationPath = `${new_subsection_image_file_path}/${temp_subsection_image_file.name}`;
              const destinationRef = this.store.ref(subsectionImgDestinationPath);
              const task = this.store.upload(
                subsectionImgDestinationPath,
                temp_subsection_image_file
              );

              // Replace regulationSubsection.subsection_image_file values with string name and folder so it can be saved in Firestore
              regulationSubsection.subsection_image_folder =
                new_subsection_image_file_path;
              regulationSubsection.subsection_image_file =
                temp_subsection_image_file.name;
              // TODO: Handle collective upload percentage
              // uploadProgress = task.percentageChanges();
            }
            // !uncomment -------------------------------

            returnRegulationSubsections.push(regulationSubsection);
          });

          returnRegulationSection.subsections = returnRegulationSubsections;
          regulationText.push(returnRegulationSection);
        });

        regulationJSON.regulation_text = regulationText;

        // console.log('regulation_text_annex_filepath: ',this.regulation_text_annex_filepath)
        // Upload regulation text annex files
        if (this.regulation_text_annex_filepath) {
          if (this.uploadsForm.value['costCompliance'].newFile == true) {
            // Subdirectory format for uniqueness. doc_date is required so assume it has a value
            // uncomment -------------------------------
            this.proposedregulationform.patchValue({
              regulation_text_annex_file_folder: `regulationsProposedPhase02/${dateUploadedStr}/${regulation_ref.id}/regulationTextAnnex`,
            });
            // console.log('regulation_text_annex_file_folder: ',this.proposedregulationform.value.regulation_text_annex_file_folder)
            regulationJSON.regulation_text_annex_file_folder = this.proposedregulationform.value.regulation_text_annex_file_folder
            let regTextAnnexDestinationPath = `${this.proposedregulationform.value.regulation_text_annex_file_folder}/${this.regulation_text_annex_filepath.name}`;
            const destinationRef = this.store.ref(regTextAnnexDestinationPath);
            const task = this.store.upload(
              regTextAnnexDestinationPath,
              this.regulation_text_annex_filepath
            );
            // !uncomment -------------------------------
          } else regulationJSON.regulation_text_annex_file_folder = this.proposedregulationform.value.regulation_text_annex_file_folder
        } else {
          // Handling in case the user uploaded an annex file but changed their mind and unticked has_annex_file
          this.proposedregulationform.patchValue({
            regulation_text_annex_file_name: '',
          });
        }
      } else {
        // Handling in case the user uploaded an annex file but changed their mind and unticked has_draft_text
        this.proposedregulationform.patchValue({
          regulation_text_annex_file_name: '',
          regulation_text_annex_file_folder: ''
        });
      }

      let worklistJSON: Record<string, any>

      if (this.edit_regulation_id && this.edit_worklist_id) {
        console.log('updating proposed regulation')
        // https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript
        worklistJSON = {
          fromAgency: fromAgency,
          fromOfficer: fromOfficer,
          status: status,
          documentTitle: this.proposedregulationform.value.subject,
        }

        // this is weird
        if (destinationPath) {
          worklistJSON.documentFile = destinationPath;
        }

        // uncomment -------------------------------
        batch.update(regulation_ref, regulationJSON);
        batch.update(worklist_ref, worklistJSON);
        // !uncomment -------------------------------
      }
      else {
        console.log('creating proposed regulation')
        worklistJSON = {
          dateGenerated: Timestamp.now().toDate(),
          fromAgency: fromAgency,
          fromOfficer: fromOfficer,
          status: status,
          documentFile: destinationPath,
          documentId: regulation_ref.id,
          documentTitle: this.proposedregulationform.value.subject,
          dueDate: Timestamp.now().toDate(),
        };

        batch.set(regulation_ref, regulationJSON);
        batch.set(worklist_ref, worklistJSON);
      }

      // console.log("regulationJSON: ",regulation_ref);
      console.log("worklistJSON: ", worklistJSON);

      const userAgency = this.currenUser.government.agency;
      const agencyVerifiers: any = await this.pEmail.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_VER);
      const email = [this.currenUser.credentials.email];
      await batch.commit().then(() => {
        //email
        var data: any = {};
        data.regulationTitle = this.proposedregulationform.value.subject;
        console.log(agencyVerifiers);

        this.pEmail.sendEmailNotification(agencyVerifiers, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_RECIEVES_REGULATION_FOR_VERIFICATION, data);
        this.pEmail.sendEmailNotification(email, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_FORWARD_REGULATION_TO_VERIFIER, data);

        alert('create successful');
        this.forReturnToRegulatoryManagement(worklist_ref.id, regulation_ref.id) //change to worklist id and regulation id
        this.router.navigate(['/pbris/regulatory-management']);
        this.loading = false;
      })
        .catch((error) => {
          this.loading = false;
          alert(error.message);
        });
      // !uncomment -------------------------------
    }
    else {
      alert('Unable to Submit, Please finish filling up the missing details or correct the invalid details!')
      this.loading = false;
    }
  }

  async gotoForm(selectedForm: any) {
    /**
     * step 1 : regulationdetails
     * step 2 : pia
     * step 3 : tagging
     * step 4 : regulationtext
     * step 5 : selfassessment
     */

    this.currentFormPage = selectedForm;
  }

  getRegulationSectionInfo(node: RegulationTextNode) {
    const uuid = node.uuid;

    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = '';
    this.activeRegulationSection = this.regulation_sections_form.get(uuid);
    this.activeRegulationSectionUuid = uuid;

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  getRegulationSubsectionInfo(regulationSectionUuid: string, uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = '';
    this.parentOfActiveRegulationSubsectionUuid = regulationSectionUuid;
    const parentRegulationSection = this.regulation_sections_form.get(
      regulationSectionUuid
    );
    if (parentRegulationSection) {
      this.activeRegulationSubsection =
        parentRegulationSection.subsections.get(uuid);
      this.activeRegulationSubsectionUuid = uuid;
      this.showSubsectionImagePreview();
    }
  }

  addNewRegulationSection() {
    const newRegulationSectionUuid = uuidv4();

    let newData = this.dataSource.data;
    let newNode = {
      name: 'New Section',
      uuid: newRegulationSectionUuid,
      children: [],
      nodeType: RegulationTextNodeType.SECTION
    };
    newData.push(newNode);

    this.refreshNodeTree();

    let newSectionInfo = new UntypedFormGroup({
      section_title: new UntypedFormControl('New Section'),
      section_type: new UntypedFormControl(''),
      section_text: new UntypedFormControl(''),
    });

    let newRegulationSection = {
      info: newSectionInfo,
      subsections: new Map(),
    };

    this.regulation_sections_form.set(
      newRegulationSectionUuid,
      newRegulationSection
    );

    this.getRegulationSectionInfo(newNode);
  }

  addNewRegulationSubsection(node: RegulationTextNode) {
    const newRegulationSubsectionUuid = uuidv4();
    const regulationSectionUuid = node.uuid;

    this.dataSource.data.forEach((e) => {
      if (e && e.uuid === regulationSectionUuid) {
        const parentRegulationSection: RegulationTextNode = e;

        let newSubsectionNode = {
          name: 'New Subsection',
          uuid: newRegulationSubsectionUuid,
          parentUuid: regulationSectionUuid,
          nodeType: RegulationTextNodeType.SUBSECTION
        };
        parentRegulationSection.children?.push(newSubsectionNode);

        this.refreshNodeTree();

        const parentRegulationSectionForm = this.regulation_sections_form.get(regulationSectionUuid);
        parentRegulationSectionForm.subsections.set(newRegulationSubsectionUuid, new UntypedFormGroup({
          subsection_title: new UntypedFormControl('New Subsection'),
          subsection_text: new UntypedFormControl(''),
          subsection_image_file: new UntypedFormControl(null),
        }));

        // Select the parent section to force it to expand, it will be deselected by getRegulationSubsectionInfo
        this.getRegulationSectionInfo(parentRegulationSection);
        this.getRegulationSubsectionInfo(regulationSectionUuid, newRegulationSubsectionUuid);

        return;
      }
    });

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  removeRegulationSection(uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = '';
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === uuid) {
        this.dataSource.data.splice(i, 1);
        this.refreshNodeTree();
      }
    }
    this.regulation_sections_form.delete(uuid);
  }

  removeRegulationSubsection(RegulationSectionUuid: string, uuid: string) {
    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = '';
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === RegulationSectionUuid) {
        var RegulationSubsections = this.dataSource.data[i].children || [];

        for (var j = 0; j < RegulationSubsections.length; j++) {
          if (RegulationSubsections[j].uuid === uuid) {
            RegulationSubsections.splice(j, 1);
          }
        }

        if (this.regulation_sections_form.get(RegulationSectionUuid)) {
          this.regulation_sections_form
            .get(RegulationSectionUuid)
            .subsections.delete(uuid);
        }
        this.refreshNodeTree();
      }
    }
  }

  private refreshNodeTree() {
    let newData = this.dataSource.data;
    // Workaround for updating nested tree nodes, do not remove
    this.dataSource.data = [];
    this.dataSource.data = newData;
    this.checkRegulationTextFormIsInvalid();
  }

  checkRegulationTextFormIsInvalid() {
    if (this.proposedregulationform.get('has_draft_text')?.value === true) {
      for (let [k, v] of this.regulation_sections_form) {
        if (!v.info.valid) {
          this._regulationTextFormIsInvalid = true;
          return;
        }

        for (let [k, v2] of v.subsections) {
          if (!v2.valid) {
            this._regulationTextFormIsInvalid = true;
            return;
          }
        }
      }
    }
    this._regulationTextFormIsInvalid = false;
  }

  get regulationTextFormIsInvalid(): boolean {
    return this._regulationTextFormIsInvalid;
  }

  addPIASectionEntry(sectionNumber: number) {
    switch (sectionNumber) {
      case 3:
        // 3 & 4 version
        this.pia_section_3_and_4_val.push(
          this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_3_AND_4_ENTRY)
        );

        // Funny little QOL to automatically select the newly inserted row
        // Commented out to see if they want it because this is not yet implemented in regulation text
        // this.active_pia_section_3_index = this.pia_section_3_val.length-1;
        // this.active_pia_section_3_form = this.pia_section_3_val.at(this.active_pia_section_3_index);
        break;
      case 5:
        let newSection5Entry = this.buildNewEntry(
          EntityBuilderIndex.PIA_SECTION_5_ENTRY
        );
        this.pia_section_5_val.push(newSection5Entry);
        break;
    }
  }

  removePIASectionEntry(sectionNumber: number, index: any) {
    switch (sectionNumber) {
      case 3:
        // 3 & 4 version
        this.pia_section_3_and_4_val.removeAt(index);
        if (index === this.active_pia_section_3_and_4_index) {
          this.active_pia_section_3_and_4_form = null;
          this.active_pia_section_3_and_4_index = -1;
        } else {
          // Handle index shifts, object equivalency seems to work for FormGroups
          for (var x in this.pia_section_3_and_4_val.controls) {
            if (
              this.pia_section_3_and_4_val.controls[x] ==
              this.active_pia_section_3_and_4_form
            ) {
              // Unary plus to convert var x in (which is a string) to TS number
              this.active_pia_section_3_and_4_index = +x;
              break;
            }
          }
        }
        break;
      case 5:
        this.pia_section_5_val.removeAt(index);
        if (index === this.active_pia_section_5_index) {
          this.active_pia_section_5_form = null;
          this.active_pia_section_5_index = -1;
        } else {
          // Handle index shifts, object equivalency seems to work for FormGroups
          for (var x in this.pia_section_5_val.controls) {
            if (
              this.pia_section_5_val.controls[x] ==
              this.active_pia_section_5_form
            ) {
              // Unary plus to convert var x in (which is a string) to TS number
              this.active_pia_section_5_index = +x;
              // empty the table datasource
              this.pia_section_5_datasource = new MatTableDataSource();
              break;
            }
          }
        }
        break;
    }
  }

  loadPIASectionInformation(sectionNumber: number, entry: any, index: any) {
    switch (sectionNumber) {
      case 3:
        // 3 & 4 version
        this.active_pia_section_3_and_4_form = entry;
        this.active_pia_section_3_and_4_index = index;
        break;
      case 5:
        this.active_pia_section_5_form = entry;
        this.active_pia_section_5_index = index;

        this.pia_section_5_datasource = new MatTableDataSource(
          this.active_pia_section_5_form.get('participants').controls
        );
        break;
    }
  }

  addPIASection5Participant() {
    this.active_pia_section_5_form
      .get('participants')
      .push(
        this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_5_PARTICIPANT_ENTRY)
      );
    this.pia_section_5_datasource = new MatTableDataSource(
      this.active_pia_section_5_form.get('participants').controls
    );
  }

  removePIASection5Participant(index: any) {
    this.active_pia_section_5_form.get('participants').removeAt(index);
    this.pia_section_5_datasource = new MatTableDataSource(
      this.active_pia_section_5_form.get('participants').controls
    );
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.PIA_SECTION_5_ENTRY:
        let participants = new UntypedFormArray([]);
        // TODO: handle loading of participants sub-array
        if (existing_obj?.participants) {
          for (let item of existing_obj?.participants) {
            participants.push(
              this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_5_PARTICIPANT_ENTRY, item)
            );
          }
        }
        else {
          participants.push(
            this.buildNewEntry(EntityBuilderIndex.PIA_SECTION_5_PARTICIPANT_ENTRY)
          );
        }

        const formGroup = new UntypedFormGroup({
          consultation_title: new UntypedFormControl(existing_obj?.consultation_title || 'New Entry'),
          has_agency_consulted: new UntypedFormControl((existing_obj?.has_agency_consulted || existing_obj?.has_agency_consulted == false) ? existing_obj?.has_agency_consulted : false),
          date_conducted: new UntypedFormControl(existing_obj?.date_conducted || format(new Date(), '2025-01-01')),
          methods: new UntypedFormGroup(
            {
              method_public_commenting: new UntypedFormControl(existing_obj?.methods?.method_public_commenting || true),
              method_forum: new UntypedFormControl(existing_obj?.methods?.method_forum || false),
              method_focus_group: new UntypedFormControl(existing_obj?.methods?.method_focus_group || false),
              method_other: new UntypedFormControl(existing_obj?.methods?.method_other || false),
            },
            { validators: [atLeastOne(Validators.requiredTrue)] }
          ),
          method_other_desc: new UntypedFormControl(existing_obj?.method_other_desc || 'None'),
          consultation_desc: new UntypedFormControl(existing_obj?.consultation_desc || 'None'),
          participants: participants,
        });
        return formGroup
      case EntityBuilderIndex.PIA_SECTION_5_PARTICIPANT_ENTRY:
        let defaultNewParticipant = new UntypedFormGroup({
          stakeholder: new UntypedFormControl(existing_obj?.stakeholder || ''),
          commenting_as: new UntypedFormControl(existing_obj?.commenting_as || ''),
          gender: new UntypedFormControl(existing_obj?.gender || ''),
          age: new UntypedFormControl(existing_obj?.age || ''),
          income_class: new UntypedFormControl(existing_obj?.income_class || ''),
          size_of_firm: new UntypedFormControl(existing_obj?.size_of_firm || ''),
          issues_and_concerns: new UntypedFormControl(existing_obj?.issues_and_concerns || ''),
          actions_taken: new UntypedFormControl(existing_obj?.actions_taken || ''),
        });

        defaultNewParticipant.valueChanges.subscribe((form: any) => {
          if (form.age) {
            defaultNewParticipant.patchValue(
              {
                age: this.decimalPipe.transform(
                  form.age.replace(/\D/g, '').replace(/^0+/, ''),
                  '1.0-2'
                ),
              },
              { emitEvent: false }
            );
          }
          if (form.size_of_firm) {
            defaultNewParticipant.patchValue(
              {
                size_of_firm: this.decimalPipe.transform(
                  form.size_of_firm.replace(/\D/g, '').replace(/^0+/, ''),
                  '1.0-2'
                ),
              },
              { emitEvent: false }
            );
          }
        });
        return defaultNewParticipant;
      case EntityBuilderIndex.PIA_SECTION_3_AND_4_ENTRY:
        const newNumber2 = this.pia_section_3_and_4_val.length + 1;

        // PIA Sections 3 & 4 will be treated as a combined form during encoding, but still saved as separate arrays to minimize refactoring required
        return new UntypedFormGroup({
          // Shared fields (currently just the name of the policy option)
          policy_option: new UntypedFormControl(existing_obj?.policy_option || 'Policy Option #' + newNumber2),

          // PIA Section 3 fields
          policy_option_type: new UntypedFormControl(existing_obj?.policy_option_type || 'Providing Information'),
          policy_option_desc: new UntypedFormControl(existing_obj?.policy_option_desc || 'None'),

          // PIA Section 4 fields
          impacts: new UntypedFormGroup(
            {
              impact_economic: new UntypedFormControl(existing_obj?.impacts?.impact_economic || true),
              impact_social: new UntypedFormControl(existing_obj?.impacts?.impact_social || false),
              impact_disaster_risk: new UntypedFormControl(existing_obj?.impacts?.impact_disaster_risk || false),
              impact_environment: new UntypedFormControl(existing_obj?.impacts?.impact_environment || false),
            },
            { validators: [atLeastOne(Validators.requiredTrue)] }
          ),
          benefits_desc: new UntypedFormControl(existing_obj?.benefits_desc || 'None'),
          costs_desc: new UntypedFormControl(existing_obj?.costs_desc || 'None'),
          impacts_desc: new UntypedFormControl(existing_obj?.impacts_desc || 'None'),
          // PIA Section 4 file upload is now overall
          // removed pia_section_4_filepath, compliance_file_name, compliance_file_folder
        });
      case EntityBuilderIndex.TAG: {
        if (existing_obj && existing_obj.reg_classification) {
          switch (existing_obj.reg_classification) {
            case 'Business': return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS, existing_obj)
            case 'Non-Business': return this.buildNewEntry(EntityBuilderIndex.TAG_NON_BUSINESS, existing_obj)
            default: return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS, existing_obj)
          }
        } else return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS)
      }
      case EntityBuilderIndex.TAG_BUSINESS: {
        let group = new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Business', [Validators.required]),
          reg_sector: new UntypedFormControl(existing_obj?.reg_sector ? existing_obj.reg_sector : '', [Validators.required]),
          reg_business: new UntypedFormControl(existing_obj?.reg_business ? existing_obj.reg_business : '', [Validators.required]),
          reg_division: new UntypedFormControl(''),
          reg_case: new UntypedFormControl(''),
          reg_case_text: new UntypedFormControl(''),
        })

        this.subTagsClassificationChange(group)

        this.subTagControlClear(group, 'reg_sector', 'reg_division')
        this.subTagControlClear(group, 'reg_business', 'reg_case')

        if (existing_obj) {
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_sector)
          this.subTagMarked(group.controls.reg_business)
          this.subTagMarked(group.controls.reg_division)
          this.subTagMarked(group.controls.reg_case)
        }

        group.patchValue({
          reg_division: existing_obj?.reg_division ? existing_obj.reg_division : '',
          reg_case: existing_obj?.reg_case ? existing_obj.reg_case : '',
        })

        return group
      }
      case EntityBuilderIndex.TAG_NON_BUSINESS: {
        // console.log('existing_obj reg_classification: ',existing_obj.reg_classification)
        // console.log('existing_obj reg_stageoflife: ',existing_obj.reg_stageoflife)
        // console.log('existing_obj reg_lifeevent: ',existing_obj.reg_lifeevent)
        let group = new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Non-Business', [Validators.required]),
          reg_stageoflife: new UntypedFormControl(existing_obj?.reg_stageoflife ? existing_obj.reg_stageoflife : ''),
          reg_lifeevent: new UntypedFormControl(''),
        })

        this.subTagsClassificationChange(group)

        this.subTagControlClear(group, 'reg_stageoflife', 'reg_lifeevent')

        if (existing_obj) {
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_stageoflife)
          this.subTagMarked(group.controls.reg_lifeevent)
        }

        group.patchValue({
          reg_lifeevent: existing_obj?.reg_lifeevent ? existing_obj.reg_lifeevent : ''
        })

        return group
      }
      default:
        return new UntypedFormGroup({});
    }
  }

  toCancel() {
    this.uploadsReturnToRegulatoryManagement()
    this.router.navigate(['/pbris/regulatory-management'])
  }

  backToInbox() {
    this.forReturnToRegulatoryManagement(this.edit_worklist_id, this.edit_regulation_id)
    this.router.navigate(['/pbris/regulatory-management']);
  }

  uploadsReturnToRegulatoryManagement() {
    sessionStorage.setItem('regulatoryActive', 'regulatoryStock')
  }

  forReturnToRegulatoryManagement(worklistId: any, regulatiionId: any) {
    sessionStorage.setItem('regulatoryActive', 'phase 2 proposed')
    sessionStorage.setItem('worklistId', worklistId)
    sessionStorage.setItem('regulationId', regulatiionId)
  }

  async fillUpRegulationText(regulationText: any) {
    // Load client steps
    if (regulationText) {
      let newData: RegulationTextNode[] = [];

      for (let regulationSection of regulationText) {
        // Not reused 
        const initialRegulationSectionUuid = uuidv4();

        // Client step agency action
        let newRegulationSectionDataSourceChildren: RegulationTextNode[] = [];
        let newRegulationSectionDataSource: RegulationTextNode = {
          name: regulationSection.info.section_title,
          uuid: initialRegulationSectionUuid,
          children: newRegulationSectionDataSourceChildren,
          nodeType: RegulationTextNodeType.SECTION
        }

        let newSectionInfo = new UntypedFormGroup({
          section_title: new UntypedFormControl({ value: regulationSection.info.section_title, disabled: this.commentMode }),
          section_type: new UntypedFormControl({ value: regulationSection.info.section_type, disabled: this.commentMode }),
          section_text: new UntypedFormControl({ value: regulationSection.info.section_text, disabled: this.commentMode }),
        });

        let newRegulationSection = {
          info: newSectionInfo,
          subsections: new Map()
        };

        for (let regulationSubsection of regulationSection.subsections) {
          let initialRegulationSubsectionUuid = '';
          if (regulationSubsection.subsection_image_folder
            && regulationSubsection.subsection_image_folder.split("/")
            && regulationSubsection.subsection_image_folder.split("/").length == 5) {
            // Reuse UUID previously saved
            // Format is "regulationsExistingPhase02/<doc date>/<regulation id>/subsections/<subsection uuid>", so get the fifth one
            initialRegulationSubsectionUuid = regulationSubsection.subsection_image_folder.split("/")[4];
          }
          else {
            initialRegulationSubsectionUuid = uuidv4();
          }

          // Agency action data source
          const newRegulationSubsectionDataSource: RegulationTextNode = {
            name: regulationSubsection.subsection_title,
            uuid: initialRegulationSubsectionUuid,
            parentUuid: initialRegulationSectionUuid,
            nodeType: RegulationTextNodeType.SUBSECTION
          }
          // Push each agency action to children array of parent client step first
          newRegulationSectionDataSource.children?.push(newRegulationSubsectionDataSource);
          // Agency action form

          let newSubsection = new UntypedFormGroup({
            subsection_title: new UntypedFormControl({ value: regulationSubsection.subsection_title, disabled: this.commentMode }),
            subsection_text: new UntypedFormControl({ value: regulationSubsection.subsection_text, disabled: this.commentMode }),
            // TODO: load actual file object
            subsection_image_file: new UntypedFormControl(null),
          });

          // Load images
          if (regulationSubsection.subsection_image_folder && regulationSubsection.subsection_image_file) {
            firstValueFrom(this.store.ref(`${regulationSubsection.subsection_image_folder}/${regulationSubsection.subsection_image_file}`)
              .getDownloadURL())
              .then(
                (url) => {
                  var xhr = new XMLHttpRequest();
                  xhr.responseType = 'blob';
                  xhr.onload = (event) => {
                    var blob = xhr.response;
                    newSubsection.patchValue({
                      subsection_image_file: new File([blob], regulationSubsection.subsection_image_file, { type: blob.type })
                    })
                    newRegulationSection.subsections.set(initialRegulationSubsectionUuid, newSubsection);
                  };
                  xhr.open('GET', url);
                  xhr.send();
                }
              );
          }
          else {
            newRegulationSection.subsections.set(initialRegulationSubsectionUuid, newSubsection);
          }
        }

        // Push to form
        this.regulation_sections_form.set(
          initialRegulationSectionUuid,
          newRegulationSection
        );

        // Finally, push client step to newData which will be the final dataSource.data
        newData.push(newRegulationSectionDataSource);
      }

      // Initial Client Steps selection tree (loaded)
      this.dataSource.data = newData;
      this.checkRegulationTextFormIsInvalid();

      const params = await firstValueFrom(this.route.queryParams.pipe(first()));
      if (params.type === 'regtext') {
        // Automatically select the regulation section or subsection the user clicked from
        const parentSection = newData[params.sectionId];

        if (parentSection && parentSection.children && params.subsectionId != null) {
          const subsection = parentSection.children[params.subsectionId];
          // Select the parent section to force it to expand, it will be deselected by getRegulationSubsectionInfo
          this.getRegulationSectionInfo(parentSection);
          this.getRegulationSubsectionInfo(parentSection.uuid, subsection.uuid);
        }
        else if (parentSection && params.sectionId != null) {
          this.getRegulationSectionInfo(parentSection);
        }
      }
    }
  }

  async submitCommentsAndReturn() {
    let status = '';
    if (sessionStorage.getItem("user_type") === "arta") {
      status = WorklistStatusPBRISRegulation.FOR_MODIFICATION;
    }
    else {
      status = WorklistStatusPBRISRegulation.FOR_REVISION;
    }

    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    this.loading = true;

    let worklist_ref: any;

    if (this.edit_worklist_id) {
      worklist_ref = this.afs.firestore.collection(`Worklist-Proposed Regulations`).doc(this.edit_worklist_id);

      let worklistJSON: Record<string, any> = await this.worklistService.updateWorklistStatusObj(status);
      worklistJSON.commentDetails = this.comment_details.value;
      worklistJSON.commentRegText = this.comment_regtext.value;
      worklistJSON.commentPIA = this.comment_pia.value;
      worklistJSON.commentSelfAssessment = this.comment_self_assessment.value;
      // worklistJSON.commentSignificanceLenses = this.comment_significance_lenses.value;

      batch.update(worklist_ref, worklistJSON);
    }

    await batch.commit().then(() => {
      alert("Comments updated.");
      // this.getDetailsForEmailer(worklist_ref)
      this.forReturnToRegulatoryManagement(this.edit_worklist_id, this.edit_regulation_id)
      this.router.navigate(['/pbris/regulatory-management']);
      this.loading = false
    }).catch(error => {
      this.loading = false
      alert(error.message)
    });
  }

  // error handlers
  isTouched(form: UntypedFormGroup, controlName: string, controlGroup?: string,) {
    if (controlGroup) {
      return ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched;
    }
    else {
      return (form.get(controlName) as UntypedFormControl).touched;
    }
  }

  hasValue(form: UntypedFormGroup, controlName: string, controlGroup?: string,) {
    if (controlGroup) {
      return (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != ''
        && ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != undefined
        && ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != null);
    }
    else {
      return ((form.get(controlName) as UntypedFormControl).value != ''
        && (form.get(controlName) as UntypedFormControl).value != undefined
        && (form.get(controlName) as UntypedFormControl).value != null);
    }
  }

  validateFields() {
    if (!this.proposedregulationform.valid) {

      for (const control in this.proposedregulationform.controls) {
        if (this.proposedregulationform.controls.hasOwnProperty(control)) {
          this.proposedregulationform.controls[control].markAllAsTouched();
          this.proposedregulationform.controls[control].markAsDirty();
        }
      }
    }
  }

  hasErrors(form: UntypedFormGroup, controlName: string, validType: string, controlGroup?: string, forSize?: boolean) {
    if (controlGroup) {
      if (controlGroup == 'uploadsForm') {
        if (form.contains(controlName)) {
          let formGroup = (form.controls[controlName] as UntypedFormGroup)
          if (forSize == true) return formGroup.controls['size'].hasError(validType) && (formGroup.controls['size'].dirty || formGroup.controls['size'].touched)
          else
            return (formGroup.controls['type'].hasError(validType) && (formGroup.controls['type'].dirty || formGroup.controls['type'].touched))
              || (formGroup.controls['count'].hasError(validType) && (formGroup.controls['count'].dirty || formGroup.controls['count'].touched))
        } else return false
      } else
        return (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).hasError(validType)
          && (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).dirty
            || ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched))
    }
    else {
      if (form.contains(controlName)) {
        return ((form.get(controlName) as UntypedFormControl).hasError(validType)
          && ((form.get(controlName) as UntypedFormControl).dirty
            || (form.get(controlName) as UntypedFormControl).touched))
      } else return false;
    }
  }

  arrayIsTouched(controlName: any) {
    return controlName.touched;
  }

  arrayHasErrors(controlName: any, validType: string) {
    return controlName.hasError(validType)
      && (controlName.dirty
        || controlName.touched)
  }
  // !error handlers

  async getDetailsForEmailer(worklist_id: any) {
    const [worklist, user]: any = await Promise.all([this.getWorklist(worklist_id), this.auth.currentUser])
    // console.log('worklist',worklist)
    if (worklist) {
      const agency: any = await this.getAgency(worklist.fromAgency)
      // console.log('agency',agency)
      if (agency) {
        // const user = await this.auth.currentUser;
        // console.log('user',user)
        if (user && user.email) {
          let userData = {
            regulationTitle: this.currentRegulation.title,
            agencyTitle: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
          }
          // 84
          this.toSendNotifEmail([user.email], EmailTemplateCode.ARTA_RETURNING_REGULATION_WITH_COMMENTS, userData)
        }
      }
    }
  }

  getAgency(agencyCode: any) {
    return new Promise((res, rej) => {
      let batches = []
      let batchCode: any = []
      batches.push(this.afs.collection('Agency').doc(agencyCode).snapshotChanges())
      batchCode.push('id')

      batches.push(this.afs.collection('Agency', filter => filter.where('agencyDetails.agencyName', '==', agencyCode)).snapshotChanges())
      batchCode.push('name')

      batches.push(this.afs.collection('Agency', filter => filter.where('name', '==', agencyCode)).snapshotChanges())
      batchCode.push('name')

      combineLatest(batches)
        .pipe(first())
        .subscribe({
          next: (result) => {
            let ctr = 0
            let agency: any;
            result.every((content: any, ctr: number) => {
              if (batchCode[ctr] == 'id') {
                if (content.payload.exists) {
                  agency = content.payload.data()
                  agency.id = content.payload.id
                  return false
                }
              }
              else if (batchCode[ctr] == 'name') {
                content.every((item: any) => {
                  if (item.payload.doc.exists) {
                    agency = item.payload.doc.data()
                    agency.id = item.payload.doc.id
                    return false
                  }
                  return true
                })
                if (agency) return false
              }
              return true
            })
            if (agency) return res(agency)
            return res(false)
          },
          error: (err) => {
            console.error('getAgency error: ', err)
            return res(false)
          }
        })
    })
  }

  getWorklist(worklist_id: any) {
    return new Promise((res, rej) => {
      this.afs.collection(`Worklist-Proposed Regulations`).doc(worklist_id).snapshotChanges()
        .pipe(first())
        .subscribe({
          next: (result: any) => {
            let worklist: any = result.payload.data()
            worklist.id = result.payload.id
            return res(worklist)
          },
          error: (err: any) => {
            console.error('worklist error: ', err)
            return res(false)
          }
        })
    })
  }

  toSendNotifEmail(currentUsers: any[], templateCode: number, data: any) {
    // console.log('sending email: ',currentUsers, templateCode)
    if (templateCode > 0) {
      this.nE.emailServiceHandler(currentUsers, EmailAppCode.PBRIS, templateCode, data)

      // this.nE.sendEmailNotiftoUser(currentUsers,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('RegulationViewExistingComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('RegulationViewExistingComponent emailer error: ',err)
      //     }
      // })
    } else console.error('RegulationViewExistingComponent emailer error: no email template for account type')
  }

  validateNode(node: any) {
    let errors: any = []
    if (node) {
      if (node.nodeType == 'SECTION') {
        let item: any = this.regulation_sections_form.get(node.uuid);
        if (item && item.info) {
          let nodeParent: UntypedFormGroup = item.info
          if (!nodeParent.valid) errors.push('Section')
          if (item.subsections) {
            if (node.children && node.children.length > 0) {
              node.children.map((nodeChildren: RegulationTextNode) => {
                if (nodeChildren && nodeChildren.uuid) {
                  let nodeChild: UntypedFormGroup = item.subsections.get(nodeChildren.uuid)
                  if (nodeChild && !nodeChild.valid) {
                    if (!errors.includes('Subsection/s')) errors.push('Subsection/s')
                  }
                }
              })
            }
          }
        }
      }
      else if (node.nodeType == 'SUBSECTION') {
        let item: any = this.regulation_sections_form.get(node.parentUuid);
        if (item && item.subsections) {
          let nodeChild: UntypedFormGroup = item.subsections.get(node.uuid);
          if (nodeChild && !nodeChild.valid) {
            if (!errors.includes('Subsection')) errors.push('Subsection')
          }
        }
      }
    } else {
      errors.push('Internal Error')
    }
    return errors
  }

  classificationChange(event: any) {
    if (event) {
      if (event.value == 'Business') {
        this.businessValidators('tags')
      } else if (event.value == 'Non-Business') {
        this.nonBusinessValidators('tags')
      }
    }
  }

  businessValidators(controlGroup: string) {
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).setValidators([Validators.required]);
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).setValidators([Validators.required]);
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).setValidators([Validators.required]);
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).setValidators([Validators.required]);
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).clearValidators();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).clearValidators();

    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  nonBusinessValidators(controlGroup: string) {
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).setValidators([Validators.required]);
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).setValidators([Validators.required]);
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).clearValidators();
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).clearValidators();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).clearValidators();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).clearValidators();

    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    ((this.proposedregulationform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  proposedRegulationChecker(code: any) {
    if (code == 'rnf1') {
      return (
        (this.proposedregulationform.controls.part_of_reg_plan as UntypedFormControl).valid
        && (this.proposedregulationform.controls.title as UntypedFormControl).valid
        && (this.proposedregulationform.controls.subject as UntypedFormControl).valid
        && ((this.proposedregulationform.controls.reg_instrument as UntypedFormControl).valid
          && ((this.multipleIssuingAgency && (this.proposedregulationform.controls.reg_agency as UntypedFormControl).valid)
            || (!this.multipleIssuingAgency)
          )
        )
        && (this.proposedregulationform.controls.reg_action_classification as UntypedFormControl).valid
        && (this.proposedregulationform.controls.has_consultation_comments as UntypedFormControl).valid
      )
    }
    else if (code == 'pia') {
      return (
        (this.proposedregulationform.controls.pia_summary as UntypedFormControl).valid
        && (this.proposedregulationform.controls.pia_section_1 as UntypedFormGroup).valid
        && (this.proposedregulationform.controls.pia_section_2 as UntypedFormControl).valid
        && (this.proposedregulationform.controls.pia_section_3_and_4 as UntypedFormArray).valid
        && (this.proposedregulationform.controls.pia_section_5 as UntypedFormArray).valid
      )
    }
    else if (code == 'reviewTags') {
      return (
        (this.proposedregulationform.controls.tags as UntypedFormGroup).valid
      )
    }
    else if (code == 'subTags') {
      return (this.proposedregulationform.controls.sub_tags as UntypedFormArray).valid
    }
    else if (code == 'textRegulation') {
      return (
        ((this.proposedregulationform.controls.has_draft_text as UntypedFormControl).valid
          && ((this.proposedregulationform.value.has_draft_text == true
            && this.checkRegulationsValidity(this.dataSource.data)
          )
            || (this.proposedregulationform.value.has_draft_text == false)
          )
        )
        && (
          (this.proposedregulationform.controls.has_annex_files as UntypedFormControl).valid
          && (
            this.proposedregulationform.value.has_annex_files == false
            || (
              this.proposedregulationform.value.has_annex_files == true
              && (this.saveAndUploadCheckUpload('regulationTextAnnex'))
            )
          )
        )
      )
      // check text regulation node
    }
    else if (code == 'agencySelfAssessment') {
      return (
        (this.proposedregulationform.controls.reg_significance_comments as UntypedFormControl).valid
        && (this.proposedregulationform.controls.reg_lenses_comments as UntypedFormControl).valid
      )
    }
    return false
  }

  // for (var i = 0; i < this.dataSource.data.length; i++) {
  //   if (this.dataSource.data[i].uuid === RegulationSectionUuid) {
  //     var RegulationSubsections = this.dataSource.data[i].children || [];

  //     for (var j = 0; j < RegulationSubsections.length; j++) {
  //       if (RegulationSubsections[j].uuid === uuid) {
  //         RegulationSubsections.splice(j, 1);
  //       }
  //     }

  //     if (this.regulation_sections_form.get(RegulationSectionUuid)) {
  //       this.regulation_sections_form
  //         .get(RegulationSectionUuid)
  //         .subsections.delete(uuid);
  //     }
  //     this.refreshNodeTree();
  //   }
  // }

  checkRegulationsValidity(node: any) {
    for (var i = 0; i < node.length; i++) {
      let parentErrors = this.validateNode(node[i])
      if (parentErrors.length > 0) return false
    }
    return true
  }

  getFormsArray(listName: string): UntypedFormGroup[] {
    switch (listName) {
      case 'sub_tags': return ((this.proposedregulationform.controls.sub_tags as UntypedFormArray).controls as UntypedFormGroup[])
      default: return []
    }
  }

  // sub tags handler
  addSubtag(listName: string, item?: any) {
    switch (listName) {
      case 'sub_tags': (this.proposedregulationform.controls.sub_tags as UntypedFormArray).push(this.buildNewEntry(EntityBuilderIndex.TAG, item))
    }
  }

  removeSubTag(listName: string, index: number) {
    switch (listName) {
      case 'sub_tags':
        // need to unsubscribe to each control valuechanges before removing whole group
        // reg_classification
        // reg_stageoflife
        // reg_lifeevent
        // reg_sector
        // reg_division
        // reg_business
        // reg_case
        // ((((this.proposedregulationform.controls.sub_tags as FormArray).controls as FormGroup[])[index] as FormGroup).controls.reg_classification as FormControl).valueChanges ;
        (this.proposedregulationform.controls.sub_tags as UntypedFormArray).removeAt(index);

    }
  }

  subTagsClassificationChange(group: UntypedFormGroup) {
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls['reg_classification'].valueChanges.subscribe({
      next: (val) => {
        switch (val) {
          case 'Business': {
            // unsubscribe valuechange before removing
            // reg_stageoflife
            // reg_lifeevent
            if (group.contains('reg_stageoflife')) group.removeControl('reg_stageoflife')
            if (group.contains('reg_lifeevent')) group.removeControl('reg_lifeevent')

            if (!group.contains('reg_sector')) group.addControl('reg_sector', new UntypedFormControl('', [Validators.required]))
            if (!group.contains('reg_division')) group.addControl('reg_division', new UntypedFormControl(''))
            if (!group.contains('reg_business')) group.addControl('reg_business', new UntypedFormControl('', [Validators.required]))
            if (!group.contains('reg_case')) group.addControl('reg_case', new UntypedFormControl(''))
              if (!group.contains('reg_case_text')) group.addControl('reg_case_text', new UntypedFormControl(''))

            this.subTagControlClear(group, 'reg_sector', 'reg_division')
            this.subTagControlClear(group, 'reg_business', 'reg_case')
            break;
          }
          case 'Non-Business': {
            // unsubscribe valuechange before removing
            // reg_sector
            // reg_division
            // reg_business
            // reg_case
            if (group.contains('reg_sector')) group.removeControl('reg_sector')
            if (group.contains('reg_division')) group.removeControl('reg_division')
            if (group.contains('reg_business')) group.removeControl('reg_business')
            if (group.contains('reg_case')) group.removeControl('reg_case')

            if (!group.contains('reg_stageoflife')) group.addControl('reg_stageoflife', new UntypedFormControl(''))
            if (!group.contains('reg_lifeevent')) group.addControl('reg_lifeevent', new UntypedFormControl(''))

            this.subTagControlClear(group, 'reg_stageoflife', 'reg_lifeevent')

            break;
          }
        }
      },
      error: (err) => {
        console.error('Error: ', err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }

  subTagControlClear(group: UntypedFormGroup, mainControl: string, dependentControl: string) {
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls[mainControl].valueChanges.subscribe({
      next: () => {
        group.patchValue({ [dependentControl]: '' })
        console.log('value changer changed -> ' + dependentControl)
      },
      error: (err: any) => {
        console.error('Error: ', err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }

  subTagMarked(control: any) {
    control.markAllAsTouched()
    control.markAsTouched()
    control.updateValueAndValidity()
  }

  filterSelection(controlName: string, value: any): Array<any> {
    if (value != null && value != undefined && value != '') {
      switch (controlName) {
        case 'reg_division': {
          let section = this.sectors.find((option) => option.id === value).section.toLowerCase();
          return this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }
        case 'reg_case': {
          let section = this.sobs.find((option) => option.id === value).section.toLowerCase();
          return this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }
        case 'reg_lifeevent': {
          let stageOfLife = this.sols.find((option) => option.id === value).stage.toLowerCase();
          return this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
        }
        default: return []
      }
    } return []
  }

  subTagOptionSelected(optionValue: any, itemValue: any) {
    return optionValue == itemValue ? true : false
  }
  // !sub tags handler

  // validators
  checkUploadType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control != undefined) {
        const val = control.value ? control.value : null // read '' as any in indexOf
        if (val) {
          let tempbool: boolean = false
          let errors = {
            type: false,
          }
          if (!this.acceptedFileType.includes(val)) {
            tempbool = true
            errors.type = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  // !validators
}

