<div>
    <ng-container [ngSwitch]="currentSystem">
        <app-artemis-navigation-header  *ngSwitchCase="artemis"></app-artemis-navigation-header>
        <app-navbar *ngSwitchCase="pbris"></app-navbar>
        <app-artemis-navigation-header *ngSwitchDefault [currentPage]="''"></app-artemis-navigation-header>
    </ng-container>
</div>

<!-- Auxiliary Navigation -->
<mat-toolbar class="auxiliary-nav">
    <a *ngIf="isAdmin" mat-icon-button class="btn btn-outline-dark" routerLink="/agency/">
        <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to List of Agencies (ARTA ADMIN)">keyboard_backspace</mat-icon>
    </a>
    <ol class="breadcrumb my-1 ms-4">
        <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page">
            ACCOUNT MANAGEMENT<i class="fas fa-chevron-right mx-2"></i>AGENCY MEMBERS
        </li>
    </ol>
    <span class="auxiliary-nav-spacer"></span>
    <div class="auxiliary-nav-button-row">
        <a *ngIf="currentSystem == pbris" mat-icon-button class="btn btn-outline-dark" (click)="toRegulatoryManagement()">
            <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulatory Management">arrow_back_ios</mat-icon>
        </a>
    </div>
</mat-toolbar>

<div class="content">
    <div class="container-fluid p-3">
        <div>
            <mat-card>
                <mat-card-title class="col-md-12">
                    <h6 class="fw-bold report-title theme-default-text"><i class="fas fa-landmark me-3"></i>{{agencyTitle}}
                        <span *ngIf="isAgencyAdmin || isAdmin">
                            <button type="button" class="btn btn-primary btn-sm float-end mx-2" (click)="openRegisterMemberModal()">
                                <i class="fas fa-plus-circle me-2"></i>Create Member
                            </button>
                        </span>
                    </h6>
                </mat-card-title>
            </mat-card>
        </div>
                    
        <div class="row">
            <div class="col-md-7">
                <mat-card class="mt-3 mb-5">
                    <!-- search -->
                    <div class="mb-3">
                        <form [formGroup]="filtersForm">
                            <div class="row form-group">
                                <div class="col" style="display:flex; align-items: center; justify-content:center;">
                                    <label class="fw-bold me-2">Search:</label>
                                    <!-- <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text"> -->
                                    <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text">
                                </div>
                            </div>
                        </form>
                    </div>
                            
                    <!-- table -->
                    <table mat-table matSort
                        class="table table-hover table-responsive table-striped"
                        id="matTable"
                        [dataSource]="memberDataSource"
                        (matSortChange)="sortChange($event)">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <h6 class="fw-bold theme-arta-text">NAME</h6>  
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{(element.personal.first_name || element.personal.last_name) ? (element.personal.first_name+" "+element.personal.last_name) : "N/A"}}</p> 
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <h6 class="fw-bold theme-arta-text">EMAIL ADDRESS</h6>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{element.credentials.email}}</p>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="account_subtype">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <h6 class="fw-bold theme-arta-text">MEMBER ROLE</h6>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex align-items-center" style="padding:0.3em">
                                    <p>{{element.credentials.account_subtype ? element.credentials.account_subtype : "N/A"}}</p>
                                    <p>{{element.credentials.role ? element.credentials.role : 'Basic' }}</p>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="memberDataSourceColumns; sticky: true"></tr>
                        <tr mat-row matRipple (click)="memberChosen(row)" *matRowDef="let row; columns: memberDataSourceColumns;"></tr>
                    </table>
                    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
                </mat-card>
            </div>

            <div class="col">
                <mat-card class="mt-3 mb-5">
                    <!-- chosenMember -->
                    <div class="p-2">
                        <!-- name -->
                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Name</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{ (chosenMember.personal.first_name || chosenMember.personal.last_name) ? (chosenMember.personal.first_name+" "+chosenMember.personal.last_name) : "N/A" }}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Email Address</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{chosenMember.credentials.email}}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Mobile Number</label></div>
                            <div class="col-1">:</div>
                            <div class="col">
                                <ng-container *ngIf="chosenMember">
                                    <p>{{chosenMember.credentials.mobile_number}}</p>
                                </ng-container>
                                <ng-container *ngIf="!chosenMember">
                                    <p>N/A</p>
                                </ng-container>
                            </div>
                        </div>

                        <hr>

                        <div class="mt-3">
                            <form [formGroup]="agencyForm">

                                <!-- subtype -->
                                <div class="row">
                                    <!-- <div class="col-5 fw-bold"><label class="form-input-header fw-bold">Account Subtype</label></div> -->
                                    <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Member Role</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.account_subtype) ? (agencyForm.value.account_subtype) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <select class="form-select" #subtype_account (change)="universalUpdater('govsubtype',subtype_account.value)">
                                                <option selected disabled value="">Account Subtype</option>
                                                <option *ngFor="let item of universalOption('govsubtype')"
                                                    [value]="item"
                                                    [selected]="universalSelector('govsubtype',item)"
                                                    >{{item}}</option>
                                            </select>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- designation -->
                                <div class="row input-row">
                                    <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Official Designation</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.official_designation) ? (agencyForm.value.official_designation) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <input type="text" class="form-control" formControlName="official_designation" placeholder="Official Designation">
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- designation -->
                                <div class="row input-row" *ngIf="currentSystem == artemis">
                                    <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Sub Agency</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.subagency) ? (agencyForm.value.subagency) : "N/A"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <input type="text" class="form-control" formControlName="subagency" placeholder="Sub Agency">
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row input-row">
                                    <div class="col-3 fw-bold"><label class="form-input-header fw-bold">Account Type</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col form-group">
                                        <ng-container *ngIf="agencyEditForm">
                                            <p>{{(agencyForm.value.role) ? (agencyForm.value.role) : "Basic"}}</p>
                                        </ng-container>
                                        <ng-container *ngIf="!agencyEditForm">
                                            <select class="form-select" #role (change)="universalUpdater('role',role.value)">
                                                <option selected disabled value="">Account Role</option>
                                                <option *ngFor="let item of filterRow(agencyForm.value.account_subtype ? agencyForm.value.account_subtype : '' )"
                                                    [value]="item"
                                                    [selected]="universalSelector('role',item)"
                                                    >{{item}}</option>
                                            </select>
                                        </ng-container>
                                    </div>
                                </div>

                                <div *ngIf="isAgencyAdmin || isAdmin" class="d-flex justify-content-around" style="padding: 1em;">
                                    <ng-container *ngIf="agencyEditForm">
                                        <button type="button" [disabled]="!chosenMember || artaCheck()" (click)="updateToggle('agency','edit')" class="btn btn-info">Update</button>
                                    </ng-container>
                                    <ng-container *ngIf="!agencyEditForm">
                                        <button type="button" [disabled]="!chosenMember" (click)="updateToggle('agency','cancel')" class="btn btn-danger">Cancel</button>
                                        <button type="button" [disabled]="!chosenMember || artaCheck()" (click)="submitUpdate('agency')" class="btn btn-primary">Confirm</button>
                                    </ng-container>
                                </div>

                            </form>       
                        </div>

                    </div>
                </mat-card>
            </div>
        </div>

    </div>
</div>
    