
<div class="d-flex flex-layout container-fluid" >
    <div class="d-flex w-100">
        <div class=" agency-details-left relative hover-parent width-layout">
            <!-- MISSION -->
            <mat-card>
                <form class="form-group space p-3" [formGroup]="missionForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span><h5 class="fw-bold">Mission</h5></span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a *ngIf="editMission"
                                (click)="toggleEdit('mission')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit Mission">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span *ngIf="!editMission">
                                <button type="button" (click)="update('mission')" class="btn update-btn btn-primary">
                                    <span class="spinner-border spinner-border-sm me-2" *ngIf ='loading'></span>Update</button>
                                <button type="button" (click)="toggleEdit('mission')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div>
                        <p *ngIf="editMission" [innerHTML]="missionForm.value.mission | linebreaks" class="py-3"></p>
                    </div>

                    <div class="space" *ngIf="!editMission">
                        <quill-editor id="mission" name="quillEditor" formControlName="mission" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="mission" id="mission" placeholder="Input Mission here" appTextareaAutoresize></textarea> -->
                        <ng-container *ngFor="let item of checkProfileForm.mission"> 
                            <mat-error style="color:red" *ngIf="hasErrors('mission','mission',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </form>
            </mat-card>
            
           <!-- VISION -->
            <mat-card class="mt-3">
                <form class="form-group space p-3" [formGroup]="visionForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span><h5 class="fw-bold">Vision</h5></span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editVision"
                                (click)="toggleEdit('vision')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit Vision">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span *ngIf="!editVision">
                                <button type="button"  (click)="update('vision')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm me-2" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('vision')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div>
                        <p *ngIf="editVision" [innerHTML]="visionForm.value.vision | linebreaks" class="py-3"></p>
                    </div>
                    <div class="space" *ngIf="!editVision">
                        <quill-editor id="vision" name="quillEditor" formControlName="vision" ></quill-editor>
                       <!-- <textarea type="text" class="form-control" formControlName="vision" id="vision" placeholder="Input Vision here" appTextareaAutoresize></textarea> -->
                        <ng-container *ngFor="let item of checkProfileForm.vision"> 
                            <mat-error style="color:red" *ngIf="hasErrors('vision','vision',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </form>
            </mat-card>

            <!-- MANDATE -->
            <mat-card class="mt-3">
                <form class="form-group space p-3" [formGroup]="mandateForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h5 class="fw-bold">Mandate</h5>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editMandate"
                                (click)="toggleEdit('mandate')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit Mandate">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span *ngIf="!editMandate">
                                <button type="button" (click)="update('mandate')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm me-2" *ngIf="loading"></span>Update</button>
                                <button type="button" (click)="toggleEdit('mandate')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div>
                        <p *ngIf="editMandate" [innerHTML]="mandateForm.value.mandate | linebreaks" class="py-3"></p>
                    </div>

                   <!--change to RTF-->
                    <div class="space" *ngIf="!editMandate">

                        <quill-editor id="mandate" name="quillEditor" formControlName="mandate" ></quill-editor>
                        <!-- <mat-form-field
                        style="width: 100%"
                        floatLabel="never"
                      >
                        
                        <input matInput hidden/>
                        <editor
                          formControlName="mandate" 
                          appTextareaAutoresize
                          id="mandate"
                          [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',                    
                            height: 250,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help'
                          }"
                        ></editor>
                        <ng-container *ngFor="let item of checkProfileForm.mandate"> 
                            <mat-error style="color:red" *ngIf="hasErrors('mandate','mandate',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </mat-form-field> -->
                        
                    </div>
                </form>
            </mat-card>
            
            <!-- SERVICE PLEDGES -->
            <mat-card class="mt-3">
                <form class="form-group space p-3" [formGroup]="pledgesForm">
                    <div class="profileHeader d-flex justify-content-between">
                        <span>
                            <h5 class="fw-bold">Service Pledge</h5>
                        </span>
                        <span *ngIf="(isAdmin || isAgencyMember)" >
                            <a  *ngIf="editPledges"
                                (click)="toggleEdit('pledges')"
                                style=" z-index: 1000; cursor: pointer;"
                                class="report-title fw-bold"
                                title="Edit Pledges">
                                <i class="fas fa-edit"></i>
                            </a> 
                            <span  *ngIf="!editPledges">
                                <button type="button"  (click)="update('pledges')" class="btn update-btn btn-primary" [disabled]="loading">
                                    <span class="spinner-border spinner-border-sm me-2" *ngIf="loading"></span>Update</button>
                                <button type="button"  (click)="toggleEdit('pledges')" class="btn btn-danger">Cancel</button>
                            </span>
                        </span>
                    </div>
                    <div>
                        <p *ngIf="editPledges" [innerHTML]="pledgesForm.value.pledges | linebreaks" class="py-3"></p>
                    </div>
                    <div class="space" *ngIf="!editPledges">
                        <quill-editor id="pledges" name="quillEditor" formControlName="pledges" ></quill-editor>
                        <!-- <textarea type="text" class="form-control" formControlName="pledges" id="pledges" placeholder="Input Service Pledges here" appTextareaAutoresize></textarea> -->
                        <ng-container *ngFor="let item of checkProfileForm.pledges"> 
                            <mat-error style="color:red" *ngIf="hasErrors('pledges','pledges',item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
    
    <div class="d-flex flex-column align-items-center">
        <mat-card class="contact">
            <!-- AGENCY LOGO -->
            <div class="agency-logo-right w-100 d-flex flex-column align-items-center" >
                <div class="relative hover-parent" style="height: 300px; width: 300px;">
                    <img class='card' style="position:relative;" *ngIf="agencyLogoForm.value.logoURL" [src]="agencyLogoForm.value.logoURL">
                    <img class='card' style="position:relative;" *ngIf="!agencyLogoForm.value.logoURL" [src]="defaultImgSrc">
                    <a *ngIf="isAdmin || isAgencyMember"
                        #menuTrigger
                        [matMenuTriggerFor]="menu"
                        class="absolute hover-1 top-pos rigth-pos"
                        style="z-index:100; cursor: pointer;">
                        <mat-icon>more_vert</mat-icon>
                    </a>
                </div>
                <div class="row w-100" style="margin-top:1em;" *ngIf="uploadToFireStorageProgress | async as progress">
                    <div class="fileProgress">
                        <!-- <p> Total size: {{logoSize}}</p> -->
                        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
                        <span class="float-right">{{progress}}% / 100%</span>
                    </div>
                </div>
            </div>
            
            <div style="margin: auto;">
                <div class=" agency-details-right relative hover-parent " >
                    <!-- <div *ngIf="isAdmin || isAgencyMember" class="absolute top-pos rigth-pos hover-1">
                        <div>
                            <button *ngIf="editContact" type="button" class="edit-btn btn btn-outline-info" (click)="toggleEdit('contact')"><i class="fas fa-edit"></i></button>
                        </div>
                        <div *ngIf="!editContact">
                            <button type="button" (click)="update('contact')" class="btn update-btn btn-primary">Update</button>
                            <button type="button" (click)="toggleEdit('contact')" class="btn btn-danger">Cancel</button>
                        </div>
                    </div> -->
                    <form class="form-group" [formGroup]="contactDetailsForm">
                        <div class="row justify-content-between">
                            <span *ngIf="isAdmin || isAgencyMember">
                                <div class="col-12">
                                    <h5 class="fw-bold">Contact:
                                        <a *ngIf="editContact" style="cursor: pointer;" (click)="toggleEdit('contact')"><i class="fas fa-edit fa-sm"></i></a>
                                    </h5>
                                </div>
                                <div class="col-12 my-3">
                                    <div *ngIf="!editContact" class="d-flex flex-row justify-content-between">
                                        <button type="button" (click)="update('contact')" class="btn update1-btn btn-primary" [disabled]="loading">
                                            <span class="spinner-border spinner-border-sm me-2" *ngIf="loading"></span>Update</button>
                                        <button type="button" (click)="toggleEdit('contact')" class="btn btn-danger">Cancel</button>
                                    </div>
                                </div>
                            </span>
                        </div>
        
                        <!-- EMAIL -->
                        <ng-container formArrayName="emailArray">
                            <span *ngIf="!editContact" class="form-input-header">Email Address:</span>
                            <ng-container *ngFor="let item of getFormsArray('email'); let i = index; let c = count">
                                <div class="row" [formGroup]="item">
                                    <h6>
                                        <div class="d-flex flex-row">
                                            <div class="p-2 centering" style="width: 10%;">
                                                <ng-container *ngIf="editContact"><strong><i class="fas fa-envelope"></i></strong></ng-container>
                                                <div *ngIf="!editContact">
                                                    <!-- mat-stroked-button -->
                                                    <ng-container *ngIf="getNumberOfElements('email') == 1">
                                                        <strong><i class="fas fa-envelope"></i></strong>
                                                    </ng-container>
                                                    <ng-container *ngIf="getNumberOfElements('email') > 1" >
                                                        <button type="button" (click)="removeCartFormRow('email',i) " class="btn btn-danger btn-sm d-flex flex-column align-items-center" style="width: 3em;">
                                                        <span class="material-icons">remove_circle_outline</span></button>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="p-2" style="width: 90%;">
                                                <span *ngIf="editContact"> {{item.value.email}}</span>
                                                <ng-container *ngIf="!editContact">
                                                    <input type="email" class="form-control" formControlName="email" placeholder="Ex. example@email.com">
                                                    <div class="d-flex flex-column">
                                                        <ng-container class="row" *ngFor="let item of checkContactsForm.email"> 
                                                            <mat-error style="color:red" *ngIf="hasErrors('emailArray','email',item.type,i)">* {{item.message}}</mat-error>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </ng-container>
                            <div *ngIf="!editContact" class="d-flex justify-content-center" style="margin-bottom:2em;">
                                <button type="button" (click)="addNewRow('email') " class="btn btn-info btn-sm d-flex align-items-center" >
                                    <span class="material-icons me-2">add_circle_outline</span>Add more
                                </button>
                            </div>
                        </ng-container>
                        
                        <!-- phone number -->
                        <div class="row" *ngIf="!editContact || agency_mobile">
                            <span *ngIf="!editContact" class="form-input-header">Mobilephone Number:</span>
                            <h6> 
                                <div class=" d-flex flex-row">
                                    <div class="p-2 centering" style="width: 10%;">
                                        <strong><i class="fas fa-mobile-alt"></i> </strong>
                                    </div> 
                                    <div class="p-2 centering" *ngIf="editContact">
                                        <span >(+63) {{contactDetailsForm.value.phoneNumber}}</span>
                                    </div>
                                    <div *ngIf="!editContact" class="p-2 centering" style="width: 25%;">
                                        <input disabled type="text" readonly class="form-control" placeholder="+63" value="+63">
                                    </div>
                                    <div *ngIf="!editContact" class="p-2 centering" style="width: 65%;" >
                                        <input appPhoneMask type="text" maxlength="12" class="form-control" formControlName="phoneNumber" placeholder="Ex. 912-345-67890">
                                    </div>
                                </div>
                                <div class="d-flex" style="flex-direction: column; margin-bottom:2em;" *ngIf="!editContact">
                                    <ng-container *ngFor="let item of checkContactsForm.phoneNumber"> 
                                        <mat-error style="color:red" *ngIf="hasErrors('contact','phoneNumber',item.type)">* {{item.message}}</mat-error>
                                    </ng-container>
                                </div>
                            </h6> 
                        </div>
                    
                        <!-- telephone number -->
                        <ng-container formArrayName="telephoneArray">
                            <span *ngIf="!editContact" class="form-input-header">Telephone Number:</span>
                            <ng-container *ngFor="let item of getFormsArray('telephone'); let i = index; let c = count">
                                <div class="row" [formGroup]="item">
                                    <h6>
                                        <div class="d-flex flex-row">
                                            <div class="p-2 centering" style="width: 10%;">
                                                <ng-container *ngIf="editContact"><strong><i class="fas fa-phone-alt"></i> </strong> </ng-container>
                                                <ng-container *ngIf="!editContact">
                                                    <!-- mat-stroked-button -->
                                                    <ng-container *ngIf="getNumberOfElements('telephone') == 1">
                                                        <strong><i class="fas fa-phone-alt"></i></strong>
                                                    </ng-container>
                                                    <ng-container *ngIf="getNumberOfElements('telephone') > 1" >
                                                        <button type="button" (click)="removeCartFormRow('telephone',i) " class="btn btn-danger btn-sm d-flex flex-column align-items-center" style="width: 3em;">
                                                        <span class="material-icons">remove_circle_outline</span></button>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <div class="p-2" style="width: 90%;">
                                                <ng-container *ngIf="editContact">
                                                    <span >{{item.value.telephoneNumber}} <span *ngIf="item.value.hasTrunkline == true">: {{item.value.trunkline}}</span></span>
                                                </ng-container>
                                                <ng-container *ngIf="!editContact">
                                                    <!--removed appTelephoneMask-->
                                                    <div style="margin-bottom:0.5em;">
                                                        <input  type="text" class="form-control" maxlength="15" formControlName="telephoneNumber" placeholder="Ex. (012) 3456-7890">
                                                        <div class="d-flex flex-column">
                                                            <ng-container *ngFor="let item of checkContactsForm.telephoneNumber"> 
                                                                <mat-error style="color:red" *ngIf="hasErrors('telephoneArray','telephoneNumber',item.type,i)">* {{item.message}}</mat-error>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex flex-row align-items-center">
                                                            <input type="checkbox" class="form-check-input" id="hasTrunkline-{{i}}" formControlName="hasTrunkline" (change)="hasTrunklineChange($event,i)">
                                                            <label [hidden]="checkHasNoTrunkline(i)" class="form-check-label" for="hasTrunkline-{{i}}">trunkline</label>
                                                            <input type="text" [hidden]="checkHasTrunkline(i)" formControlName="trunkline" class="form-control" placeholder="Trunkline">
                                                        </div>
                                                        <div class="d-flex flex-column" *ngIf="checkHasNoTrunkline(i)">
                                                            <ng-container *ngFor="let item of checkContactsForm.trunkline"> 
                                                                <mat-error style="color:red" *ngIf="hasErrors('telephoneArray','trunkline',item.type,i)">* {{item.message}}</mat-error>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </ng-container>
                            <div *ngIf="!editContact" class="d-flex justify-content-center">
                                <button type="button" (click)="addNewRow('telephone') " class="btn btn-info btn-sm d-flex align-items-center" >
                                    <span class="material-icons me-2">add_circle_outline</span>Add more
                                </button>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
            
        </mat-card>
        <br>
    </div>
</div>

<mat-menu #menu="matMenu" class="form-group">
    <form [formGroup]="agencyLogoForm">
        <input mat-menu-item type="file" class="form-control" id="logoSrc" placeholder="Upload Logo" formControlName="logoSrc" (change)="uploadLogo($event)">
    </form>
</mat-menu>
