<app-navbar [currentPage]="'Management'"></app-navbar>

<div class="content">
    <!-- <div class="row" *ngIf="auth.user | async as user; else showRestricted"> -->
        <!-- [ngClass]="collapse == true ?'collapse-true':'collapse-false'" -->
        <div class="row parent-collapse"  *ngIf="userLoading == true || (user); else showRestricted">
        <!-- <div> -->
            <mat-sidenav-container class="full-heigth" autosize="true">
                <mat-sidenav mode="side" class="full-heigth" [@collapsed]="collapse == true ? 'close' : 'open' "  opened>
                    <div mat-menu-item (click)="toggleSideNav()" style="background-color: #2e3338; cursor: pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="color:#FCD116 !important;margin: 0 !important;">menu</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse">
                                    <p class="wrapper items-center" style="color:#FCD116;font-size: 12px;">REGULATORY MANAGEMENT</p>
                                </span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <!-- GOVERNMENT MENU -->
                    <div mat-menu-item [ngClass]="dashboardView? 'active':''" (click)="opendashboardView()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">dashboard</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Dashboard</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div *ngIf="isAgency || isAgencyAdmin" mat-menu-item [ngClass]="profile? 'active':''" (click)="openagencypageView()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">account_balance</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Agency Profile</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div *ngIf="isAgencyAdmin" mat-menu-item [routerLink]="agency_structure" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">table_chart</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Organizational Structure</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div *ngIf="isAgencyAdmin" mat-menu-item [ngClass]="inboxView? 'active':''" [routerLink]="account_management_link" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">manage_accounts</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Management</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-accordion *ngIf="userSubtype != 'Government - IT/Admin'" class="accordion">
                        <mat-expansion-panel [expanded]='inboxExpander' hideToggle >
                            <mat-expansion-panel-header style="margin:0;padding:0;" (click)="toggleExpander('inbox')">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad with-icon">
                                        <mat-icon style="margin-right:0;">inbox</mat-icon>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description class="pad with-icon" style="margin:0;padding:0;">
                                    <span class="align-center" *ngIf="!collapse" >
                                        <p class="wrapper items-center" style="margin:0;padding:0">Inbox <mat-icon class="ms-3">{{inboxExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{inboxExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div mat-accordion class="accordion">
                                <mat-expansion-panel expanded='false' hideToggle >
                                    <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('myworklist')">
                                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                            <div class="pad with-icon">
                                                <mat-icon style="margin-right:0;">dns</mat-icon>
                                            </div>
                                        </mat-panel-title>
                                        <mat-panel-description class="with-icon">
                                            <span *ngIf="!collapse">
                                                <p class="wrapper items-center">My Worklist <mat-icon class="ms-3"> {{myworklistExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                            </span>
                                            <span *ngIf="collapse"><mat-icon>{{myworklistExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                        
                                    <div mat-menu-item-focus [ngClass]="sub_inbox_existing? 'active':''" (click)="openWorklistExisting2()" style="border-bottom:1px solid #394046;cursor:pointer;">
                                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                            <div class="pad">
                                                <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Existing</p></span>
                                            </div>
                                        </mat-panel-title>
                                    </div>
                
                                    <div mat-menu-item [ngClass]="sub_inbox_proposed? 'active':''" (click)="openWorklistProposed2()" style="border-bottom:1px solid #394046;cursor:pointer;">
                                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                            <div class="pad">
                                                <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Proposed</p></span>
                                            </div>
                                        </mat-panel-title>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <div mat-accordion *ngIf="userSubtype != 'Government - IT/Admin'" class="accordion">
                        <mat-expansion-panel expanded='false' hideToggle >
                            <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('regulatorystockEpander')">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad with-icon">
                                        <mat-icon style="margin-right:0;">library_add</mat-icon>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description class="with-icon">
                                    <span *ngIf="!collapse">
                                        <p class="wrapper items-center">Regulatory Stock <mat-icon class="ms-3"> {{regulatorystockEpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{regulatorystockEpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            
                            <div mat-menu-item routerLink="/pbris/upload-beta/existing" style="border-bottom:1px solid #394046;cursor:pointer;">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad">
                                        <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Upload Existing</p></span>
                                    </div>
                                </mat-panel-title>
                            </div>
    
                            <div mat-menu-item routerLink="/pbris/upload/proposed" style="border-bottom:1px solid #394046;cursor:pointer;">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad">
                                        <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">Upload Proposed</p></span>
                                    </div>
                                </mat-panel-title>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <div mat-menu-item *ngIf ="isAdmin" [ngClass]="comment_list? 'active':''" (click)="commentList()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">comment</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Regulation Comments</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <!-- ARTA PBRIS ADMINISTRATOR -->
                    <div mat-menu-item *ngIf="userType === 'arta'" class="mt-3">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon" style="font-size: 14px; background-color: #2d3339; cursor: pointer;">
                                <mat-icon style="margin: 0 !important;">settings</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse">
                                    <p class="wrapper items-center" style="font-size: 12px; color:#FFFFFF; margin:0; padding:0">ADMIN MANAGEMENT</p>
                                </span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf="isAdmin" routerLink="/agency/" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">account_balance</mat-icon>
                                <span class="ml" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Agency Controls</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf ="isAdmin" [ngClass]="accountprofileView? 'active':''" (click)="openaccountprofileView()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">manage_accounts</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Profile</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf ="isAdmin" [ngClass]="accountverificationView? 'active':''" (click)="openaccountverificationView()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">admin_panel_settings</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Account Management</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf ="isAdmin" [ngClass]="systemsettingsView? 'active':''" (click)="opensystemsettingsView()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">settings</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Reference Control</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf="userType === 'arta'" [ngClass]="notification_view? 'active':''" (click)="openNotifications()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon>notifications</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Notifications</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-menu-item *ngIf="userType === 'arta'" [ngClass]="feedback_list? 'active':''" (click)="feedbackList()" style="border-bottom:1px solid #394046;cursor:pointer;">
                        <mat-panel-title class="align-center" style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon style="margin-right:0;">textsms</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Feedback List</p></span>
                            </div>
                        </mat-panel-title>
                    </div>

                    <div mat-accordion class="accordion" *ngIf="userType === 'arta'">
                        <mat-expansion-panel expanded='false' hideToggle >
                            <mat-expansion-panel-header style="border-bottom:1px solid #394046;border-radius:0;" (click)="toggleExpander('ticket')">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad with-icon">
                                        <mat-icon style="margin-right:0;">featured_play_list</mat-icon>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description class="with-icon">
                                    <span *ngIf="!collapse" >
                                        <p class="wrapper items-center">Tickets <mat-icon class="ms-3"> {{ticketExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon></p>
                                    </span>
                                    <span *ngIf="collapse"> <mat-icon>{{ticketExpander  ? 'keyboard_arrow_down' : 'keyboard_arrow_left' }}</mat-icon> </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div mat-menu-item [ngClass]="sel_ticket? 'active':''" (click)="openTickets2()" style="border-bottom:1px solid #394046;border-radius:0;;cursor:pointer;">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad">
                                        <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">List Tickets</p></span>
                                    </div>
                                </mat-panel-title>
                            </div>

                            <div mat-menu-item [ngClass]="create_ticket? 'active':''" (click)="createTickets2()" style="border-bottom:1px solid #394046;border-radius:0;;cursor:pointer;">
                                <mat-panel-title class="align-center" style="margin:0;padding:0;">
                                    <div class="pad">
                                        <span class="ms-4 align-center" *ngIf="!collapse"><p class="wrapper items-center">File a Ticket</p></span>
                                    </div>
                                </mat-panel-title>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <!-- Hidden Menus -->
                    <!-- <div mat-menu-item [ngClass]="regulation_proposed_notification_view? 'active':''" (click)="openRegulationProposedNotificationView()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>post_add</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Proposed Regulation </p></span>
                        </div>
                    </div> -->
        
                    <!-- <div mat-menu-item [ngClass]="regulation_view? 'active':''" (click)="openRegulationView()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>view_headline</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Inbox Regulation View</p></span>
                        </div>
                    </div> -->
        
                    <!-- <div mat-menu-item [ngClass]="regulatory_stock_view? 'active':''" (click)="openRegulatoryStock()" style="cursor: pointer;">
                        <div class="pad with-icon">
                            <mat-icon>note_add</mat-icon>
                            <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Regulatory Stock</p></span>
                        </div>
                    </div> -->

                    <!-- <div mat-menu-item [ngClass]="feedback_form? 'active':''" (click)="feedbackForm()" style="cursor: pointer;">
                        <mat-panel-title style="margin:0;padding:0;">
                            <div class="pad with-icon">
                                <mat-icon>rate_review</mat-icon>
                                <span class="ml align-center" *ngIf="!collapse"><p class="wrapper items-center" style="margin:0; padding:0">Feedback Form</p></span>
                            </div>
                        </mat-panel-title>
                    </div> -->
                    
                </mat-sidenav>
            
                <!-- Content View -->
                <mat-sidenav-content class="padded" [@collapsed]="collapse == true ? 'auto1' : 'auto2' " >
                    <!-- Government View -->
                    <app-pbris-regulatory-dashboard *ngIf="dashboardView"></app-pbris-regulatory-dashboard>
                    <app-organization-profile *ngIf="profile"></app-organization-profile>
                    <app-inbox [regulationInboxCollectionName]="existing_regulation_collection_name" [regulationInboxWorklistCollectionName]="existing_regulation_worklist_collection_name" [activateChild]="activateChild" [activateObject]="activateObject" *ngIf="sub_inbox_existing"></app-inbox>                   
                    <app-inbox [regulationInboxCollectionName]="proposed_regulation_collection_name" [regulationInboxWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="sub_inbox_proposed "></app-inbox>
                    <app-pbris-comment-view *ngIf="comment_list"></app-pbris-comment-view>

                    <!-- ARTA IT/Admin View -->
                    <app-crud-users *ngIf="accountverificationView"></app-crud-users>
                    <app-reference-control *ngIf="systemsettingsView"></app-reference-control>
                    <app-pbris-account *ngIf="accountprofileView"></app-pbris-account>
                    <app-notifications *ngIf="notification_view"></app-notifications>
                    <app-ticket-list *ngIf="sel_ticket "></app-ticket-list>
                    <app-ticketing-filing *ngIf="create_ticket"></app-ticketing-filing>
                    <app-pbris-user-feedback-view *ngIf="feedback_list"></app-pbris-user-feedback-view>
                    
                    <!--Hidden Content -->
                    <app-verification-regulation hidden></app-verification-regulation>
                    <app-regulatory-stock hidden *ngIf="regulatory_stock_view"></app-regulatory-stock>
                    <app-regulation-proposed-notifications hidden [regulationProposedNotificationCollectionName]="proposed_regulation_notification_name" [regulationWorklistCollectionName]="proposed_regulation_worklist_collection_name" *ngIf="regulation_proposed_notification_view"></app-regulation-proposed-notifications>
                    <app-pbris-user-feedback-create hidden *ngIf="feedback_form"></app-pbris-user-feedback-create>                 
                </mat-sidenav-content>
            </mat-sidenav-container>
        <!-- </div> -->
        </div>
    <!-- </div> -->

    <ng-template #showRestricted>
        <h1>This is a Restricted Page</h1>
    </ng-template>
</div>


