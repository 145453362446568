import { OrganizationStaffDialogComponent } from './organization-staff-dialog/organization-staff-dialog.component';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { allMustBe } from 'src/app/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-organization-staff',
  templateUrl: './organization-staff.component.html',
  styleUrls: ['./organization-staff.component.scss']
})

export class OrganizationStaffComponent implements OnInit {
  loading = false;

  agency_head:any= [];
  chairperson:any=[];
  vice_chairperson:any= [];
  members:any= [];

  agency_head_acronym :any=[];

  @Input('agency_doc') doc_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;
  @Input('cartDetails') cartDetails:any;

  headOfAgencyTitle:string = ''
  editHeadOfAgencyTitle: boolean = false;
  headOfAgencyLoading = false;
  agencyHoAForm = this.fb.group({
    title: new UntypedFormControl(''),
    agencyHoAArray: this.fb.array([ ])
  })

  chairPersonTitle:string = ''
  editChairPersonTitle: boolean = false;
  chairPersonLoading = false;
  agencyCPForm = this.fb.group({
    title: new UntypedFormControl(''),
    agencyCPArray: this.fb.array([ ])
  })

  viceChairPersonTitle:string = ''
  editViceChairPersonTitle: boolean = false;
  viceChairPersonLoading = false;
  agencyVCPForm = this.fb.group({
    title: new UntypedFormControl(''),
    agencyVCPArray: this.fb.array([ ])
  })

  membersTitle:string = ''
  editMembersTitle: boolean = false;
  membersLoading = false;
  agencyMemForm = this.fb.group({
    title: new UntypedFormControl(''),
    agencyMemArray: this.fb.array([ ])
  })

  constructor( 
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private fb:UntypedFormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.log('staffs titles: ',this.cartDetails)
    this.loadInfo(this.doc_id);
  }

  loadInfo(id:any){
    if(this.cartDetails.headOfAgencyTitle)
      this.headOfAgencyTitle = this.cartDetails.headOfAgencyTitle
    else this.headOfAgencyTitle = 'Agency Head'
    this.agencyHoAForm.patchValue({title:this.headOfAgencyTitle})

    if(this.cartDetails.chairPersonTitle)
      this.chairPersonTitle = this.cartDetails.chairPersonTitle
    else this.chairPersonTitle = 'Chairpersons'
    this.agencyCPForm.patchValue({title:this.cartDetails.chairPersonTitle})
    
    if(this.cartDetails.viceChairPersonTitle)
      this.viceChairPersonTitle = this.cartDetails.viceChairPersonTitle
    else this.viceChairPersonTitle = 'Vice-Chairpersons'
    this.agencyVCPForm.patchValue({title:this.cartDetails.viceChairPersonTitle})
    
    if(this.cartDetails.membersTitle)
      this.membersTitle = this.cartDetails.membersTitle
    else this.membersTitle = 'Members'
    this.agencyMemForm.patchValue({title:this.cartDetails.membersTitle})

    this.afs.collection('Agency').doc(id).collection('Head of Agency', 
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(first())
    .subscribe(
    (data: any) => {
      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Head of Agency',item)
        this.agencyHoAArray.push(
          this.fb.group({
            name: new UntypedFormControl(item.name, Validators.required),
            designation: new UntypedFormControl(item.designation, Validators.required),
            email: new UntypedFormControl(item.email, [Validators.required, Validators.email]),
            id: new UntypedFormControl(item.id, Validators.required),
          })
        )
      });
    });

    this.afs.collection('Agency').doc(id).collection('Chairperson', 
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(first())
    .subscribe(
    (data: any) => {
      this.chairperson = [];
      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Chairperson',item)
        this.agencyCPArray.push(
          this.fb.group({
            name: new UntypedFormControl(item.name, Validators.required),
            designation: new UntypedFormControl(item.designation, Validators.required),
            email: new UntypedFormControl(item.email, [Validators.required, Validators.email]),
            id: new UntypedFormControl(item.id, Validators.required),
          })
        );
      });
    });

    this.afs.collection('Agency').doc(id).collection('Vice Chairperson', 
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(first())
    .subscribe(
    (data: any) => {
      this.vice_chairperson = [];
      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Vice Chairperson',item)
        this.agencyVCPArray.push(
          this.fb.group({
            name: new UntypedFormControl(item.name, Validators.required),
            designation: new UntypedFormControl(item.designation, Validators.required),
            email: new UntypedFormControl(item.email, [Validators.required, Validators.email]),
            id: new UntypedFormControl(item.id, Validators.required),
          })
        );
      });
    });

    this.afs.collection('Agency').doc(id).collection('Members', 
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(first())
    .subscribe(
    (data: any) => {
      this.members = [];
      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Members',item)
        this.agencyMemArray.push(
          this.fb.group({
            name: new UntypedFormControl(item.name, Validators.required),
            designation: new UntypedFormControl(item.designation, Validators.required),
            email: new UntypedFormControl(item.email, [Validators.required, Validators.email]),
            id: new UntypedFormControl(item.id, Validators.required),
          })
        );
      });
    });

  }

  getAcronym(str:any){

    try{
      console.log("Acronym",str)
      let matches = str.match(/\b(\w)/g);
      if(matches){
        let acronym = matches.join('');
        return acronym;
      }else{
        return ''
      }
      
    }catch(e){
      return ''
    }
  }

  get agencyHoAArray(){
    return this.agencyHoAForm.controls['agencyHoAArray'] as UntypedFormArray;
  }

  get agencyCPArray(){
    return this.agencyCPForm.controls['agencyCPArray'] as UntypedFormArray;
  }

  get agencyVCPArray(){
    return this.agencyVCPForm.controls['agencyVCPArray'] as UntypedFormArray;
  }

  get agencyMemArray(){
    return this.agencyMemForm.controls['agencyMemArray'] as UntypedFormArray;
  }

  getFormsArray(listName: string):UntypedFormGroup[]{
    if(listName === 'hOA')
      return this.agencyHoAArray.controls as UntypedFormGroup[]
    else if(listName === 'cP')
      return this.agencyCPArray.controls as UntypedFormGroup[]
    else if(listName === 'vCP')
      return this.agencyVCPArray.controls as UntypedFormGroup[]
    else if(listName === 'memb')
      return this.agencyMemArray.controls as UntypedFormGroup[]
    else
      return []
  }

  openDia(action:any,editName:any,index:number){
    if(this.isAdmin || this.isAgencyMember){
      let item:any = {};
      if(action !== 'create'){
        if(editName === 'hOA'){
          item = ((this.agencyHoAArray.controls as UntypedFormGroup[])[index] as UntypedFormGroup).value
        }
        else if(editName === 'cP'){
          item = ((this.agencyCPArray.controls as UntypedFormGroup[])[index] as UntypedFormGroup).value
        }
        else if(editName === 'vCP'){
          item = ((this.agencyVCPArray.controls as UntypedFormGroup[])[index] as UntypedFormGroup).value
        }
        else if(editName === 'memb'){
          item = ((this.agencyMemArray.controls as UntypedFormGroup[])[index] as UntypedFormGroup).value
        }
      } 
      item.collection = editName
      item.action = action
      item.doc_id = this.doc_id
      const dialogRef = this.dialog.open(OrganizationStaffDialogComponent, {
        data: item
      });

      dialogRef.afterClosed().subscribe((result:any)=>{
        //local change or firebase reload?
        if(result.success === true){
          if(result.action === 'create'){
            this.dialogCreate(result.collection,result.name,result.designation,result.email,result.id)
          }
          else if(result.action === 'update'){
            this.dialogUpdate(result.collection,result.name,result.designation,result.email,result.id)
          }
          else if(result.action === 'delete'){
            this.dialogDelete(result.collection, result.id)
          }
        }
        //local change
      })
     
    }
  }

  dialogCreate(groupName:any,name:string,designation:string,email:string,id:any){
    const item = this.fb.group({
      name: new UntypedFormControl(name, Validators.required),
      designation: new UntypedFormControl(designation, Validators.required),
      email: new UntypedFormControl(email, [Validators.required, Validators.email]),
      id: new UntypedFormControl(id, Validators.required),
    });
    if(groupName === 'hOA'){
      this.agencyHoAArray.push(item)
    }
    else if(groupName === 'cP'){
      this.agencyCPArray.push(item)
    }
    else if(groupName === 'vCP'){
      this.agencyVCPArray.push(item)
    }
    else if(groupName === 'memb'){
      this.agencyMemArray.push(item)
    }
  }

  async dialogUpdate(groupName:any,name:string,designation:string,email:string,id:any){
    if(groupName === 'hOA'){
      const ctr:any = await this.agencyHoAArray.value.map((res:any) => res.id).indexOf(id) as Number
      ((this.agencyHoAArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        name:name,
        designation:designation,
        email:email,
      })
    }
    else if(groupName === 'cP'){
      const ctr:any = await this.agencyCPArray.value.map((res:any) => res.id).indexOf(id) as Number
      ((this.agencyCPArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        name:name,
        designation:designation,
        email:email,
      })
    }
    else if(groupName === 'vCP'){
      const ctr:any = await this.agencyVCPArray.value.map((res:any) => res.id).indexOf(id) as Number
      ((this.agencyVCPArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        name:name,
        designation:designation,
        email:email,
      })
    }
    else if(groupName === 'memb'){
      const ctr:any = await this.agencyMemArray.value.map((res:any) => res.id).indexOf(id) as Number
      ((this.agencyMemArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        name:name,
        designation:designation,
        email:email,
      })
    }
  }

  async dialogDelete(groupName:any,id:any){
    if(groupName === 'hOA'){
      const ctr:any = await this.agencyHoAArray.value.map((res:any) => res.id).indexOf(id) as Number
      this.agencyHoAArray.removeAt(ctr)
    }
    else if(groupName === 'cP'){
      const ctr:any = await this.agencyCPArray.value.map((res:any) => res.id).indexOf(id) as Number
      this.agencyCPArray.removeAt(ctr)
    }
    else if(groupName === 'vCP'){
      const ctr:any = await this.agencyVCPArray.value.map((res:any) => res.id).indexOf(id) as Number
      this.agencyVCPArray.removeAt(ctr)
    }
    else if(groupName === 'memb'){
      const ctr:any = await this.agencyMemArray.value.map((res:any) => res.id).indexOf(id) as Number
      this.agencyMemArray.removeAt(ctr)
    }
  }

  
  //Shows forms/inputs
  toggleEdit(controlCode:any){
    if(this.isAdmin || this.isAgencyMember){
      switch(controlCode){
        case 'headOfAgencyTitle':{
          this.editHeadOfAgencyTitle = !this.editHeadOfAgencyTitle
          this.agencyHoAForm.patchValue({
            title: this.cartDetails.headOfAgencyTitle
          })
          this.agencyHoAForm.controls['title'].markAsUntouched()
          this.agencyHoAForm.controls['title'].markAsPristine()
          break;
        }
        case 'chairPersonTitle':{
          this.editChairPersonTitle = !this.editChairPersonTitle
          this.agencyCPForm.patchValue({
            title: this.cartDetails.chairPersonTitle
          })
          this.agencyCPForm.controls['title'].markAsUntouched()
          this.agencyCPForm.controls['title'].markAsPristine()
          break;
        }
        case 'viceChairPersonTitle':{
          this.editViceChairPersonTitle = !this.editViceChairPersonTitle
          this.agencyVCPForm.patchValue({
            title: this.cartDetails.viceChairPersonTitle
          })
          this.agencyVCPForm.controls['title'].markAsUntouched()
          this.agencyVCPForm.controls['title'].markAsPristine()
          break;
        }
        case 'membersTitle':{
          this.editMembersTitle = !this.editMembersTitle
          this.agencyMemForm.patchValue({
            title: this.cartDetails.membersTitle
          })
          this.agencyMemForm.controls['title'].markAsUntouched()
          this.agencyMemForm.controls['title'].markAsPristine()
          break;
        }
      }
    }
  }
  //

  //Submit
  update(controlCode:any){
    // this.loading = true// for button spinner
    //console.log({controlCode})
    if(this.isAdmin || this.isAgencyMember){

      let control = null
      let checkValue = null
      switch(controlCode){
        case 'headOfAgencyTitle':{
          // this.validateFields('headOfAgencyTitle')
          control = this.agencyHoAForm.controls['title']
          checkValue = this.headOfAgencyTitle
          this.headOfAgencyLoading = true
          break;
        }
        case 'chairPersonTitle':{
          // this.validateFields('chairPersonTitle')
          control = this.agencyCPForm.controls['title']
          checkValue = this.chairPersonTitle
          this.chairPersonLoading = true
          break;
        }
        case 'viceChairPersonTitle':{
          // this.validateFields('viceChairPersonTitle')
          control = this.agencyVCPForm.controls['title']
          checkValue = this.viceChairPersonTitle
          this.viceChairPersonLoading = true
          break;
        }
        case 'membersTitle':{
          // this.validateFields('membersTitle')
          control = this.agencyMemForm.controls['title']
          checkValue = this.membersTitle
          this.membersLoading = true
          break;
        }
      }

      //console.log('cotrol: ',control?.value)
      //console.log({checkValue})
      //console.log('check: ',control?.value != checkValue)
      
      if(control && checkValue && control.value != checkValue){
        this.afs.collection('Agency').doc(this.doc_id).update({
          ["cartDetails.titles."+controlCode]:control.value
        })
        .then(()=>{
          alert("Update Successful")
          switch(controlCode){
            case 'headOfAgencyTitle':{
              this.headOfAgencyTitle = this.agencyHoAForm.value.title
              this.cartDetails.headOfAgencyTitle = this.agencyHoAForm.value.title
              break;
            }
            case 'chairPersonTitle':{
              this.chairPersonTitle = this.agencyCPForm.value.title
              this.cartDetails.chairPersonTitle = this.agencyCPForm.value.title
              break;
            }
            case 'viceChairPersonTitle':{
              this.viceChairPersonTitle = this.agencyVCPForm.value.title
              this.cartDetails.viceChairPersonTitle = this.agencyVCPForm.value.title
              break;
            }
            case 'membersTitle':{
              this.membersTitle = this.agencyMemForm.value.title
              this.cartDetails.membersTitle = this.agencyMemForm.value.title
              break;
            }
          }

          this.toggleEdit(controlCode)
          this.endLoading(controlCode)
          // this.loading = false;
        })
        .catch((err)=>{
          alert("Update Failed")
          this.loading = false;
          console.error(err)
        })
      }
      else{
        // alert('')
        this.endLoading(controlCode)
        // this.loading = false
      }
    }
  }
  //

  endLoading(controlCode:string){
    switch(controlCode){
      case 'headOfAgencyTitle':{
        this.headOfAgencyLoading = false
        break;
      }
      case 'chairPersonTitle':{
        this.chairPersonLoading = false
        break;
      }
      case 'viceChairPersonTitle':{
        this.viceChairPersonLoading = false
        break;
      }
      case 'membersTitle':{
        this.membersLoading = false
        break;
      }
    }
  }

  validateFields(controlCode:any){
    if(this.isAdmin || this.isAgencyMember){
      switch(controlCode){
        case 'headOfAgencyTitle':{
          if(!this.agencyHoAForm.controls['title'].value){
            this.agencyHoAForm.patchValue({title:'Agency Head'})
          }
          break;
        }
        case 'chairPersonTitle':{
          if(!this.agencyCPForm.controls['title'].value){
            this.agencyCPForm.patchValue({title:'Chairpersons'})
          }
          break;
        }
        case 'viceChairPersonTitle':{
          if(!this.agencyVCPForm.controls['title'].value){
            this.agencyVCPForm.patchValue({title:'Vice-Chairpersons'})
          }
          break;
        }
        case 'membersTitle':{
          if(!this.agencyMemForm.controls['title'].value){
            this.agencyMemForm.patchValue({title:'Members'})
          }
          break;
        }
      }
      // if(editName === 'title'){
      //   if(!this.agencyTitleForm.valid){
      //     this.agencyTitleForm.markAllAsTouched();
      //     for(const control in this.agencyTitleForm.controls){
      //       if (this.agencyTitleForm.controls.hasOwnProperty(control)) {
      //         this.agencyTitleForm.controls[control].markAllAsTouched();
      //         this.agencyTitleForm.controls[control].markAsDirty();
      //         this.loading = false;// for button spinner
      //       }
      //     }
      //   }
      // }
    }
  }

}


