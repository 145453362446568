<!-- <app-wel-navbar></app-wel-navbar> -->
<app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header>
<app-navbar *ngIf="currentSystem == pbris"></app-navbar>

<!-- Auxiliary Navigation -->
<mat-toolbar class="auxiliary-nav">
    <ol class="breadcrumb my-1 ms-4">
        <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page">
            ADMIN MANAGEMENT<i class="fas fa-chevron-right mx-2"></i>AGENCY CONTROLS
        </li>
    </ol>
    <span class="auxiliary-nav-spacer"></span>
    <div class="auxiliary-nav-button-row">
        <a *ngIf="currentSystem == pbris" mat-icon-button class="btn btn-outline-dark" (click)="toRegulatoryManagement()">
            <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulatory Management">arrow_back_ios</mat-icon>
        </a>
    </div>
</mat-toolbar>

<div class="content">
    <div class="container-fluid p-3">
        <div>
            <mat-card>
                <mat-card-title class="col-md-12">
                    <h6 class="fw-bold report-title theme-default-text"><i class="fas fa-list me-3"></i>LIST OF AGENCIES
                        <span *ngIf="isAdmin">
                            <button type="button" class="btn btn-primary btn-sm float-end mx-2" (click)="openAddAgency()">
                                <i class="fas fa-plus-circle me-2"></i>Add Agency
                            </button>
                        </span>
                    </h6>
                </mat-card-title>
            </mat-card>
            
            <div class="form-container">
                <div class="d-flex justify-content-center align-self-center">
                    <div class="spinner-grow" role="status" *ngIf="loader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                
                <mat-card class="mt-3 mb-5">
                    <table class="table table-hover table-responsive w-100" *ngIf="!loader">
                        <thead>
                            <tr>
                                <th><h6 class="fw-bold theme-arta-text">AGENCY ABBREVIATION</h6></th>
                                <th><h6 class="fw-bold theme-arta-text">NAME</h6></th>
                                <th><h6 class="fw-bold theme-arta-text">ADDRESS</h6></th>
                                <th><h6 class="fw-bold theme-arta-text">CONTACT</h6></th>
                                <th><h6 class="fw-bold theme-arta-text">ACTION</h6></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let entry of agencies">
                                <td style="width:15px;">
                                    <a routerLink="/artemis/view/agency/{{entry.id}}">
                                        <div class="clickableTh">{{entry.abbreviation}}</div>
                                    </a>
                                </td>
                                <td>{{ entry.name}}</td>
                                <th>{{ entry.address | slice: 0:50 }}</th>
                                <th style="width:15px;">{{ entry.contact }}</th>
                                <th style="width:5px;">
                                    <button mat-icon-button
                                    [matMenuTriggerFor]="agencyControl"
                                    #menuTrigger 
                                    [matMenuTriggerData]="{entry: entry}">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                </th>
                            </tr>
                        </tbody>
                        <tbody *ngIf="agencies.length < 1 && !loader">
                            <tr>
                                <td colspan="4">No agencies</td>
                            </tr>
                        </tbody>

                    </table>
                </mat-card>

            </div>
        </div>
    </div>
</div>

<mat-menu #agencyControl="matMenu">
    <ng-template matMenuContent let-entry="entry">
        <button mat-menu-item (click)="goToAgencyMembers(entry.id)"><span style="color:000;border-bottom:1px solid #595959;">Member List</span></button>
        <button mat-menu-item (click)="goToAgencyStructure(entry.id)"><span style="color:000;">Agency Structure</span></button>
    </ng-template>
</mat-menu>