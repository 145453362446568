<!-- <ng-container *ngIf="!hideModal">
  <app-wel-navbar></app-wel-navbar>
</ng-container> -->

<ng-container *ngIf="!hideModal">
  <ng-container [ngSwitch]="currentSystem">
    <app-artemis-navigation-header *ngSwitchCase="ARTEMIS"></app-artemis-navigation-header>
    <app-navbar *ngSwitchCase="PBRIS"></app-navbar>
    <app-wel-navbar *ngSwitchDefault></app-wel-navbar>
  </ng-container>
</ng-container>

<mat-vertical-stepper [linear]="true">
  <!-- choose agency HIDDEN-->
  <mat-step *ngIf="!hasOwnAgency" [stepControl]="registrationAgencyForm" errorMessage="Agency is Required">
    <form [formGroup]="registrationAgencyForm">
      <ng-template matStepLabel>Agency</ng-template>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Agency</mat-label>
              <input type="text" placeholder="Choose one" aria-label="Agency" matInput [matAutocomplete]="agencyInput"
                formControlName="agencyInput">
              <mat-error *ngFor="let item of checkForm.government.agency">
                <div class="error" *ngIf="hasError('agency','agencyInput', item.type)">
                  {{item.message}}
                </div>
              </mat-error>
              <mat-autocomplete autoActiveFirstOption #autoC [displayWith]="getAgencyOptionName.bind(this)"
                #agencyInput="matAutocomplete">
                <ng-container>
                  <mat-option *ngFor="let agency of agencyChoices | async" [value]="agency.id">
                    <strong>{{agency.name}}</strong><span *ngIf="agency.motherAgency" style="font-size: 12px;"> | Mother
                      Agency: {{agency.motherAgency}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mother Agency</mat-label>
              <input type="text" aria-label="Mother-Agency" readonly matInput formControlName="motherAgency">
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col" style="position:relative;float:right">
          <button class="btn btn-primary" (click)="toValidate('agency')" matStepperNext>Next</button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- tnc -->
  <mat-step [stepControl]="tncForm" errorMessage="Terms & Conditions is Required">
    <form [formGroup]="tncForm">
      <ng-template matStepLabel>TERMS & CONDITIONS</ng-template>
      <div class="form-group terms-conditions-container">
        <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->

        <div class="termsandconditions" [hidden]="terms_and_conditions_JSON.length == 0 ? true : false">
          <div *ngFor="let section of terms_and_conditions_JSON">
            <p style="font-weight: bolder">{{ section.title }}</p>
            <div [innerHtml]="section.description"></div>
            <br />
          </div>
        </div>

        <!--CHECKBOX-->
        <!--Seperate checkbox and label in a row using column-->
        <div class="row">
          <div class="col-1">
            <div class="form-check mx-auto" [hidden]="terms_and_conditions_JSON.length == 0 ? true : false">
              <input class="form-check-input" type="checkbox" formControlName="accept" ng-required="true" />
            </div>

          </div>
          <div class="col">
            <label class="form-check-label fst-italic">
              I consent to the terms and conditions in the use of the {{currentSystem}}
            </label>
          </div>
        </div>

      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <button *ngIf="!hasOwnAgency" class="btn btn-warning" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>Back</button>
          <button class="btn btn-primary" (click)="toValidate('tnc')" matStepperNext
            [disabled]="!tncForm.value.accept">Next <i class="fas fa-arrow-circle-right ms-2"></i></button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- private policy -->
  <mat-step [stepControl]="privatePolicyForm" errorMessage="Data Privacy is Required">
    <form [formGroup]="privatePolicyForm">
      <ng-template matStepLabel>DATA PRIVACY</ng-template>
      <div class="form-group terms-conditions-container">
        <!--<app-terms-and-conditions-PBRIS></app-terms-and-conditions-PBRIS>-->
        <div class="termsandconditions" [hidden]="privacy_policy_JSON.length == 0 ? true : false">
          <div *ngFor="let section of privacy_policy_JSON">
            <p style="font-weight: bolder">{{ section.title }}</p>
            <div [innerHtml]="section.description"></div>
            <br />
          </div>
        </div>

        <!--CHECKBOX-->
        <!--Seperate checkbox and label in a row using column-->
        <div class="row">
          <div class="col-1">
            <div class="form-check mx-auto" [hidden]="privacy_policy_JSON.length == 0 ? true : false">
              <input class="form-check-input" type="checkbox" formControlName="accept" ng-required="true" />
            </div>

          </div>
          <div class="col">
            <label class="form-check-label fst-italic">
              I consent to the processing of my personal information
            </label>
          </div>
        </div>
      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <button class="btn btn-warning" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>Back</button>
          <button class="btn btn-primary" (click)="toValidate('privatePolicy')" matStepperNext
            [disabled]="!privatePolicyForm.value.accept">Next<i class="fas fa-arrow-circle-right ms-2"></i></button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- personal -->
  <mat-step [stepControl]="personalForm" errorMessage="User Details Required">
    <form [formGroup]="personalForm">
      <ng-template matStepLabel>User Details</ng-template>

      <div class="form-group">
        <div class="row mt-2">
          <div class="col">
            <label class="form-input-header">First Name <sup style="color:red">*</sup></label>
            <ng-container *ngFor="let item of checkForm.personal.first_name">
              <mat-error style="color:red" *ngIf="hasError('personal','first_name',item.type)">*
                {{item.message}}
              </mat-error>
            </ng-container>
            <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                <input matInput placeholder="First Name" type="text" class="form-input" formControlName="first_name">
              </mat-form-field>
            </div>
          </div>
          <div class="col">
            <label class="form-input-header">Last Name <sup style="color:red">*</sup></label>
            <ng-container *ngFor="let item of checkForm.personal.last_name">
              <mat-error style="color:red" *ngIf="hasError('personal','last_name',item.type)">*
                {{item.message}}
              </mat-error>
            </ng-container>
            <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                <input matInput placeholder="Last Name" type="text" class="form-input" formControlName="last_name">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-input-header">Sex assigned at birth</label>
            <div class="d-flex flex-column">
              <select class="form-select" formControlName="sex">
                <option selected disabled readonly value="">Select</option>
                <ng-container *ngFor="let item of genderOptions">
                  <option [value]="item">{{item}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-column"></div>
          </div>
        </div>
      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <button class="btn btn-warning" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>Back</button>
          <button class="btn btn-primary" (click)="toValidate('personal')" matStepperNext>Next<i class="fas fa-arrow-circle-right ms-2"></i></button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- credential -->
  <mat-step [stepControl]="credentialsForm" errorMessage="Account Credentials Required">
    <form [formGroup]="credentialsForm">
      <ng-template matStepLabel>Account Credentials</ng-template>

      <div class="form-group">

        <div class="row mt-2">
          <div class="col">
            <label class="form-input-header">Email Address<sup style="color:red">*</sup></label>
            <ng-container *ngFor="let item of checkForm.credentials.email">
              <mat-error style="color:red" *ngIf="hasError('credentials','email',item.type)">*
                {{item.message}}
              </mat-error>
            </ng-container>
            <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                <input matInput placeholder="Email" type="text" class="form-input" formControlName="email">
              </mat-form-field>
            </div>
          </div>
          <div class="col">
            <label class="form-input-header">Mobile Number</label>
            <ng-container *ngFor="let item of checkForm.credentials.mobile_number">
              <mat-error style="color:red" *ngIf="hasError('credentials','mobile_number',item.type)">*
                {{item.message}}
              </mat-error>
            </ng-container>
            <div class="d-flex flex-row">
              <mat-form-field appearance="outline" style="width:5em">
                <input matInput placeholder="+63" type="text" class="form-input" readonly value="+63">
              </mat-form-field>
              <mat-form-field appearance="outline" style="width:100%">
                <input matInput appPhoneMask placeholder="eg. 912..." type="text" maxlength="12" class="form-input"
                  formControlName="mobile_number">
              </mat-form-field>
            </div>
            <!-- <div class="d-flex flex-row">
              <div width="2em" class="d-flex flex-row">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="+63" type="text" class="form-input" readonly value="+63">
                </mat-form-field>
              </div>
              <div class="d-flex flex-column">
                <mat-form-field appearance="outline">
                  <input matInput appPhoneMask placeholder="eg. 912..." type="text" maxlength="12" class="form-input" formControlName="mobile_number">
                </mat-form-field>
              </div>
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-input-header">Account Role <sup style="color:red">*</sup></label>
            <ng-container *ngFor="let item of checkForm.credentials.account_subtype">
              <mat-error style="color:red" *ngIf="hasError('credentials','account_subtype',item.type)">*
                {{item.message}}
              </mat-error>
            </ng-container>
            <div class="d-flex flex-column">
              <select class="form-select" formControlName="account_subtype">
                <option selected disabled readonly value="">Select</option>
                <ng-container *ngFor="let item of subTypeOptions">
                  <!-- <option *ngIf="isAgencyAdmin && (item.includes('ARTA') || item.includes('IT/Admin')) ? false  : true" [value]="item">{{item}}</option> -->
                  <option *ngIf="isAgencyAdmin && (item.includes('ARTA')) ? false  : true" [value]="item">{{item}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col">

            <label class="form-input-header">Account Type</label>
            <div class="d-flex flex-column">
              <select class="form-select" formControlName="role">
                <option selected disabled readonly value="">Select</option>
                <ng-container
                  *ngFor="let item of filterRow(credentialsForm.value.account_subtype ? credentialsForm.value.account_subtype : '' )">
                  <option [value]="item">{{item}}</option>
                </ng-container>
              </select>
            </div>

          </div>
        </div>

        <div class="row" *ngIf="isArtaAdmin" style="margin-top: 2em;">
          <div class="col">
            <label class="form-input-header">Artemis Access</label>
            <mat-slide-toggle formControlName="access_artemis"
              [ngClass]="credentialsForm.value.access_artemis == true ? 'Enabled' : 'Disabled'">{{credentialsForm.value.access_artemis
              == true ? 'Enabled' : 'Disabled'}}</mat-slide-toggle>
          </div>
          <div class="col">
            <label class="form-input-header">Pbris Access</label>
            <mat-slide-toggle formControlName="access_pbris"
              [ngClass]="credentialsForm.value.access_pbris == true ? 'Enabled' : 'Disabled'">{{credentialsForm.value.access_pbris
              == true ? 'Enabled' : 'Disabled'}}</mat-slide-toggle>
          </div>
        </div>

      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <button class="btn btn-warning" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>Back</button>
          <button class="btn btn-primary" (click)="toValidate('credentials')" matStepperNext>Next<i class="fas fa-arrow-circle-right ms-2"></i></button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- government -->
  <mat-step [stepControl]="governmentForm" errorMessage="Government Credentials Required">
    <form [formGroup]="governmentForm">
      <ng-template matStepLabel>Government Credentials</ng-template>

      <div class="form-group">
        <!-- <div class="row">
          <div class="col">
            <label class="form-input-header">Agency <sup style="color:red">*</sup></label>
            <ng-container *ngFor="let item of checkForm.government.agency">
                <mat-error style="color:red" *ngIf="hasError('government','agency',item.type)">*
                  {{item.message}}
                </mat-error>
            </ng-container>
            <div class="d-flex flex-column">
              <select class="form-select" formControlName="agency">
                <option selected disabled readonly value="">Select Agency</option>
                <ng-container *ngFor="let item of agencyOptions">
                    <option [value]="item.id">{{item.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div> -->
        <div class="row mt-2">
          <!-- <div class="col">
            <label class="form-input-header">Sub Agency</label>
            <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                <input matInput placeholder="Sub Agency" type="text" class="form-input" formControlName="subagency">
              </mat-form-field>
            </div>
          </div> -->
          <div class="col">
            <label class="form-input-header">Official Designation <sup style="color:red">*</sup></label>
            <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                <input matInput placeholder="Official Designation" type="text" class="form-input"
                  formControlName="official_designation">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="currentSystem == PBRIS">
          <div class="col">
            <label class="form-input-header">Department/Bureau/Division/Unit/Etc.</label>
            <!-- Root dropdown -->
            <div>
              <select formControlName="subagency" id="rootSubAgency" class="form-select" (change)="onSelect(0, $event)">
                <option value="" disabled selected>Select</option>
                <option *ngFor="let root of rootSubAgencies" [value]="root.id">
                  {{ root.subagency }}
                </option>
              </select>
            </div>

            <!-- Dynamic child dropdowns -->
            <div *ngFor="let dropdown of childDropdowns; let i = index">
              <select formControlName="subagency" *ngIf="dropdown && dropdown.length > 0" class="form-select mt-2"
                (change)="onSelect(i + 1, $event)">
                <option value="" disabled selected>Select a sub-agency</option>
                <option *ngFor="let child of dropdown" [value]="child.id">
                  {{ child.subagency }}
                </option>
              </select>
            </div>

          </div>
        </div>
      </div>
    </form>
    <div class="button-div">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <button class="btn btn-warning" matStepperPrevious><i class="fas fa-arrow-circle-left me-2"></i>Back</button>
          <button class="btn btn-primary" (click)="toValidate('government')" matStepperNext>Next<i class="fas fa-arrow-circle-right ms-2"></i></button>
        </div>
      </div>
    </div>
  </mat-step>

  <!-- uploads -->
  <!-- <mat-step [stepControl]="uploadFilesForm" errorMessage="Uploads Required">
    <div>
      <ng-template matStepLabel>Upload Required Documents</ng-template>

      <div class="form-group">
        <div class="form-group file-uploader-wrapper">
          <div appFileUploader class="dropzone relative-div" (filesChangeEmiter)="dropzone($event, 'gov_id')">
            <button *ngIf="uploadFilesForm.value.gov_id.file" type="button" class="absolute-div absolute-div-x-button btn btn-outline-danger" title="Clear Upload" (click)="clearFile('gov_id')">
              Clear File<i class="fas fa-backspace"></i>
            </button>
            <button type="button" class="btn btn-link dropzone-btn" (click)="gov_id.click()">
              <label for="govid"><strong>Government Issued ID <span style="color:red">*</span></strong></label>
              <div *ngIf="display_government_id">
                {{ display_government_id }}
              </div>
              <ng-container *ngFor="let controlName of govIdFormControlsArr">
                <ng-container *ngFor="let item of checkForm.uploadFile.gov_id">
                  <div class="error" *ngIf="hasError('gov_id',controlName,item.type)">
                    {{item.message}}
                  </div>
                </ng-container>
              </ng-container>
              <span><strong>Drop file here to upload or click to choose file</strong></span>
              <span>(jpg, jpeg, png, pdf). file cannot exceed {{uploadLimits.gov_id.size}}{{uploadLimits.gov_id.unit}}.</span>
              <div class="progress" style="height: 20px; width: 100%;" *ngIf="gov_uploadProgress | async">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                  [style.width]="(gov_uploadProgress | async) + '%'" [attr.aria-valuenow]="gov_uploadProgress | async"
                  aria-valuemin="0" aria-valuemax="100">
                  {{uploadFilesForm.value.gov_id?.file?.total}}{{uploadFilesForm.value.gov_id?.file?.unit}} | {{gov_uploadProgress  | async}} %
                </div>
              </div>
            </button>
            <input #gov_id id="gov_id" type="file" hidden multiple (change)="buttonUpload($event,'gov_id')" [accept]="acceptedFileType"/>
          </div>
        </div>

        <div class="form-group file-uploader-wrapper">
          <div appFileUploader class="dropzone relative-div" (filesChangeEmiter)="dropzone($event, 'auth_letter')">
            <button *ngIf="uploadFilesForm.value.auth_letter.file" type="button" class="absolute-div absolute-div-x-button btn btn-outline-danger" title="Clear Upload" (click)="clearFile('auth_letter')">
              Clear File<i class="fas fa-backspace"></i>
            </button>
            <button type="button" class="btn btn-link dropzone-btn" (click)="auth_letter.click()">
              <label for="govid"><strong>Authorization Letter <span style="color:red">*</span></strong></label>
              <div *ngIf="display_authorization_letter">
                {{ display_authorization_letter }}
              </div>
              <ng-container *ngFor="let controlName of authLetterFormControlsArr">
                <ng-container *ngFor="let item of checkForm.uploadFile.auth_letter">
                  <div class="error" *ngIf="hasError('auth_letter',controlName,item.type)">
                    {{item.message}}
                  </div>
                </ng-container>
              </ng-container>
              <span><strong>Drop file here to upload or click to choose file</strong></span>
              <span>(jpg, jpeg, png, pdf). file cannot exceed {{uploadLimits.auth_letter.size}}{{uploadLimits.auth_letter.unit}}.</span>
              <div class="progress" style="height: 20px; width: 100%;" *ngIf="auth_uploadProgress | async">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                  [style.width]="(auth_uploadProgress | async) + '%'" [attr.aria-valuenow]="auth_uploadProgress | async"
                  aria-valuemin="0" aria-valuemax="100">
                  {{uploadFilesForm.value.auth_letter?.file?.total}}{{uploadFilesForm.value.auth_letter?.file?.unit}} | {{auth_uploadProgress | async}} %
                </div>
              </div>
            </button>
            <input #auth_letter id="auth_letter" type="file" hidden multiple (change)="buttonUpload($event,'auth_letter')" [accept]="acceptedFileType"/>
          </div>
        </div>
      </div>
    </div>
    <div class="button-div">
        <div class="row">
            <div class="col d-flex justify-content-between">
              <button class="btn btn-warning" matStepperPrevious>Back</button>
              <button class="btn btn-primary" (click)="toValidate('uploadFile')" matStepperNext>Next</button>
            </div>
        </div>
    </div>
  </mat-step> -->

  <!-- summary -->
  <mat-step>
    <div>
      <ng-template matStepLabel>Summary</ng-template>
    </div>
    <div class="button-div">
      <div class="row">
        <label><i class="fas fa-check-circle me-3" style="color: #04ff00;"></i>User Details</label>
      </div>
      <div class="row">
        <label><i class="fas fa-check-circle me-3" style="color: #04ff00;"></i>Account Credentials</label>
      </div>
      <div class="row">
        <label><i class="fas fa-check-circle me-3" style="color: #04ff00;"></i>Government Credentials</label>
      </div>
      <!-- <div class="row"><label>Uploaded Documents</label></div> -->
      <div class="row mt-4">
        <div class="col d-flex justify-content-between">
          <button class="btn btn-warning" matStepperPrevious [disabled]="loading.register">
            <!-- <div class="spinner-border text-light" role="status" *ngIf="loading.register"><span class="visually-hidden">Loading...</span></div> -->
            <i class="fas fa-arrow-circle-left me-2"></i>Back
          </button>
          <button class="btn btn-danger d-flex justify-content-center align-items-center" (click)="register()" [disabled]="loading.register">
            <div class="spinner-border text-light" role="status" *ngIf="loading.register" style="margin-right: 1em;">
              <span class="visually-hidden">Loading...</span>
            </div>
            Register<i class="fas fa-share-square ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </mat-step>
</mat-vertical-stepper>

<!-- <div *ngIf="!hideModal" style="position: fixed; bottom: 0;">
  <button class="btn btn-danger" (click)="closeModal">Cancel</button>
</div> -->