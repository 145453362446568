import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinStringSeparator'
})
export class JoinStringSeparatorPipe implements PipeTransform {

  transform(value: string[], separator: string): String {
    let transformArr = [];

    for(var x of value) {
      if(x) {
        transformArr.push(x);
      }
    }
    
    return transformArr.join(separator);
  }

}
