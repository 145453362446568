<app-navbar [currentPage]="'Management'"></app-navbar>

<!--Page Title -->
<mat-toolbar class="auxiliary-nav">
  <span class="ms-3">
    <span class="active fw-bold" style="color:#595959;font-size:12px" aria-current="page" *ngIf="currentFormPage == 'preface'">UPLOAD PROPOSED REGULATION</span>
    <span class="active fw-bold" style="color:#595959;font-size:12px" aria-current="page" *ngIf="currentFormPage != 'preface'">UPLOAD PROPOSED REGULATION<i class="fas fa-chevron-right mx-2"></i>PRE - RULE STAGE</span>
  </span>
  <span class="auxiliary-nav-spacer"></span>
  <div class="auxiliary-nav-button-row">
      <a mat-icon-button routerLink="/pbris/regulatory-management">
        <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulatory Management">arrow_back_ios</mat-icon>
      </a>
  </div>
</mat-toolbar>

<div class="p-4 bg-light">
  <ng-container>
    <div class="container-fluid">

      <div class="card-text my-4" *ngIf="currentFormPage == 'preface'">
        <p>Pursuant to Section 5 of RA 11032, all proposed regulations of government agencies under Section 3 of the same Act shall undergo regulatory 
          impact assessment to establish if the proposed regulation does not add undue regulatory burden and cost to these agencies and the applicants or requesting parties.
        </p>
        <p>To facilitate and implement the RIA process, rules on exemptions and proportionality, the rulemaking process will follow four 
          stages and one optional procedure.
        </p>
      </div>

      <mat-card class="mb-5" *ngIf="isPhase1">
        <mat-card-body>

          <!-- STAGE & DESCRIPTION -->
          <form *ngIf="currentFormPage == 'preface'">
  
            <div class="card-header card-header-custom">
              <div class="row">
                <div class="col-md-2 text-center">
                  <h5><b>STAGE</b></h5>
                </div>
                <div class="col-md-10 text-center">
                  <h5><b>DESCRIPTION</b></h5>
                </div>
              </div>
            </div>
  
            <div class="card-body rights-container">
              <div class="row alt-white">
                <div class="col-md-2"><b>PRE-RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    Government agencies must notify ARTA of every formulation, modification, or repeal of regulations with its corresponding rationale, objectives, expected impacts, and
                    targets dates through the Annual Regulatory Plan or the submission of the Regulatory Notification Form.
                  </p>
                  <p>
                    The regulatory agency intends to draft a regulation and is determining viable non-regulatory options as well as through preliminary impact assessment. 
                    Should the assessment support the implementation of a regulation, the agency submits its preliminary impact statement (PIS) to ARTA.
                  </p>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2">ARTA Preliminary Review</div>
                <div class="col-md-10">
                  <p>
                    ARTA is reviewing the PIS submitted by the agency and evaluating whether it has adequately explained and justified the proposed regulation. 
                    If ARTA deems the proposed regulation to have a significant impact on the economy, businesses, individuals or households, or the environment, ARTA will
                    require the regulatory agency to conduct a full RIA process of the proposed regulation (Proposed Rule Stage). Otherwise, the regulation proceeds to the Interim Rule Stage.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>PROPOSED RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The regulation has been assessed as major and is undergoing regulatory impact assessment. The drafted regulatory impact
                    statement (RIS) shall be submitted to the PBRIS for public consultation. Upon completion of the consultation, the regulatory agency finalizes the RIS and submits it to ARTA.
                  </p>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2">ARTA Proposed Rule Review</div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its regulatory impact statement has been submitted to ARTA and is undergoing proposed rule
                    review. ARTA may endorse or return a RIS based on its quality. Regardless of the assessment, all regulations will proceed to the Interim Rule Stage.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>INTERIM RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its impact statement has been assessed by ARTA and is returned to the agency. The regulatory agency shall inform 
                    ARTA of its next course of action:
                  </p>
                  <ul>
                    <li>
                      <b>For an Endorsed RIS:</b> Regulatory agencies are required to submit the implementation form which shall detail dates of promulgation, filing with ONAR,
                      publication in the Official Gazette/Newspaper of General Circulation, and the effectivity of the regulation.
                    </li>
                    <li>
                      <b>For a Returned RIA:</b> RIA will act as a trigger for the proponent agency to revise and improve the conducted RIA and drafted RIS. 
                      The agency should consider ARTA's findings and recommendation and should re-submit the revised RIS for ARTA review and endorsement.
                    </li>
                    <li>
                      <b>For minor regulations:</b> a Monitoring & Evaluation Plan must be submitted to ARTA with the Notification Form.
                    </li>
                  </ul>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2"><b>FINAL RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its regulatory impact statement is for promulgation, publishing and implementation.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>SUBMISSION TO CONGRESS*</b></div>
                <div class="col-md-10">
                  <p>
                    ARTA, if it deems necessary, may forward the results of the RIA undertaken by the Agency and the results of the ARTA review to Congress for appropriate action.
                  </p>
                </div>
              </div>
            </div>
            
            <div class="card-footer text-center mt-2">
              <button (click)="toCancel()" class="me-2 btn btn-dark">Cancel</button>
              <button class="btn btn-primary btn-primary" (click)="gotoForm('regulationdetails')">Proceed <i class="fas fa-arrow-circle-right ms-1"></i></button>
            </div>
          </form>

          <!-- QA & FORMS -->
          <div *ngIf="currentFormPage == 'regulationdetails'">
            <div class="card-header card-header-custom mt-2">
              <div class="row">
                  <div class="ms-3">
                  <h6 class="fw-bold">REGULATORY PLANNING</h6>
                  </div>
              </div>
            </div>
      
            <div class="card-text p-3">
              <p>All government agencies (national government agencies and offices, GOCCs, government instrumentalities, and all LGUs) covered under Section 3 of the EODB 
                  Act must notify ARTA of every formulation, modification, or repeal of regulations with its corresponding rationale, objectives, expected impacts, and target dates.
              </p>
              <p>
                For this purpose, it is recommended that all agencies should submit to ARTA an Annual Regulatory Plan (ARP) as an advance notification. For instances wherein 
                an agency intends to formulate/modify a regulation not included in the Annual Regulatory Plan, it should submit an Regulatory Notification Form (RNF). 
              </p>
            </div>

            <!-- PLANNED THIS YEAR -->
            <div class="row p-3 bg-white">
              <div class="form-group">
                <mat-label class="form-input-header" for="title">Are there any regulatory initiatives planned by your Agency for 2025? <span class="asterisk">*</span></mat-label>
                <div class="mt-4">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="plan_this_year" value="yes" [(ngModel)]="plan_this_year" />
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                  </div>
      
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="plan_this_year" value="no" [(ngModel)]="plan_this_year" />
                    <label class="form-check-label" for="inlineRadio2">No</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- PLANNED THIS YEAR - YES-->
            <div class="row">
              <div *ngIf="plan_this_year === 'yes'">
                <div class="p-3 bg-gray">
                  <div class="form-group">
                    <mat-label class="form-input-header" for="title">Is this part of your Agency's ARP? <span class="asterisk">*</span></mat-label>
                    <div class="mt-4">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="included_in_arp" value="yes" [(ngModel)]="included_in_arp" />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
          
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="included_in_arp" value="no" [(ngModel)]="included_in_arp" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div *ngIf="included_in_arp === 'no'">
                    <hr>
                    <app-upload-rnf-with-pia></app-upload-rnf-with-pia>
                  </div>

                  <div *ngIf="included_in_arp === 'yes'">
                    <hr>
                    <app-upload-rnf></app-upload-rnf>
                  </div>
                </div>
              </div>

            </div>
              
            <!-- PLANNED THIS YEAR - NO -->
            <div class="row p-3">
              <div *ngIf="plan_this_year ==='no'">
              <hr>
                <div appearance="fill">
                  <div class="bg-gray">
                    <nav aria-label="breadcrumb" class="bg-gray">
                      <ol class="breadcrumb py-3 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3 mb-0" aria-current="page">ANNUAL REGULATORY PLAN SUMMARY</li>
                      </ol>
                    </nav>
                  </div>

                  <div class="col-md-12 mt-5">
                    <h6 class="fw-bold theme-default-text">SUMMARY OF REGULATORY ACTIONS PLANNED FOR 2025:</h6>
                  </div>

                  <form [formGroup]="noARPForm">
                    <div class="form-check form-check-inline mt-4">
                      <input checked class="form-check-input" type="radio" name="no_arp_title" value="No Regulatory Actions Planned for 2025" formControlName="no_arp_title"/>
                      <label class="form-check-label" for="no_arp_title">
                        <h6 class="fw-bold">No Regulatory Actions Planned for 2025</h6>
                      </label>
                    </div>
                  
                    <div class="col-md-12 ps-3 mt-4">
                      <p>Provide narrative details if available:</p>
                      <editor formControlName="no_arp_subject"  [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                        }">
                      </editor>
                    </div>
                  
                    <div class="mt-5">
                      <div class="card-footer text-center mt-2">
                        <button class="btn btn-danger float-end" (click)="submitNoARP()" id="bt">
                          <i class="fas fa-share-square me-1"></i>Submit <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                        </button>
                      </div>
                    </div>
                  </form>


                </div>
              </div>
            </div>

          </div>
          
        </mat-card-body>
      </mat-card>
      
    </div>
  </ng-container>

  <!-- Loader -->
  <ng-template #notYetLoaded>
    <div class="d-flex justify-content-center align-items-center loader-wrapper py-5 my-5">
      <div class="loader">
        <div class="box box-1">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-2">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-3">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-4">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
