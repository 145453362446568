<!-- REGULATORY NOTIFICATION FORM (RNF) -->
<form [formGroup]="proposedregulationform">
    <div style="border-top:1px solid #FCD116;" hidden>
        <div class="form-group mt-4">
            <mat-label class="form-input-header" for="title">Do you intend to undertake a Preliminary Impact Assessment (PIA) prior to the implementation of this regulation? <span class="asterisk">*</span></mat-label> <!-- Is your regulation included/identified in your Agency's Annual Regulatory Plan? -->
            <div class="my-4">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="part_of_reg_plan" id="inlineRadio1" formControlName="part_of_reg_plan" [value]="true" />
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input disabled class="form-check-input" type="radio" name="part_of_reg_plan" id="inlineRadio2"/>
                    <!-- return these attributes when reenabled
                        formControlName="part_of_reg_plan" [value]="true"
                    -->
                    <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
            </div>
        </div>
    </div>

    <!-- RNF ONLY HIDDEN -->
    <div *ngIf="proposedregulationform.controls.part_of_reg_plan.value === false" hidden></div>

    <div class="row alt-white">
        <mat-accordion class="example-headers-align">
            <!-- REGULATORY NOTIFICATION FORM (RNF) -->
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title class="form-input-header my-3">
                        <mat-icon class="me-3">looks_one</mat-icon>REGULATORY NOTIFICATION FORM (RNF)
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div appearance="fill"><!--*ngIf="proposedregulationform.controls.part_of_reg_plan.value === true"-->
                    <!-- A. PROPONENT AGENCY DETAILS -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">A. PROPONENT AGENCY DETAILS</li>
                            </ol>
                        </nav>
                        <div class="row my-4">
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="acc_officer">Accountable Officer</mat-label>
                                <input matInput placeholder="Input" formControlName="acc_officer">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'acc_officer')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="acc_officer_designation">Designation</mat-label>
                                <input matInput placeholder="Input" formControlName="acc_officer_designation">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'acc_officer_designation')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="acc_officer_phone">Phone Number</mat-label>
                                <input matInput placeholder="Input" formControlName="acc_officer_phone">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'acc_officer_phone')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="acc_officer_email">Email Address</mat-label>
                                <input matInput placeholder="Input" formControlName="acc_officer_email">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'acc_officer_email')">Required</mat-hint>
                            </mat-form-field>
                        </div>
                        
                        <div class="row my-4">
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="contact_officer">Contact Officer</mat-label>
                                <input matInput placeholder="Input" formControlName="contact_officer">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'contact_officer')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="contact_officer_designation">Designation</mat-label>
                                <input matInput placeholder="Input" formControlName="contact_officer_designation">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'contact_officer_designation')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="contact_officer_phone">Phone Number</mat-label>
                                <input matInput placeholder="Input" formControlName="contact_officer_phone">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'contact_officer_phone')">Required</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-inline col-md-3">
                                <mat-label class="form-input-header" for="contact_officer_email">Email Address</mat-label>
                                <input matInput placeholder="Input" formControlName="contact_officer_email">
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'contact_officer_email')">Required</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- B. REGULATION DETAILS -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">B. REGULATION DETAILS</li>
                            </ol>
                        </nav>

                        <!-- Title -->
                        <div class="row mt-4">
                            <mat-form-field appearance="outline" class="form-group col-md-6">
                                <mat-label class="form-input-header" for="title">Full Title</mat-label>
                                <textarea matInput placeholder="Input" formControlName="title"></textarea>
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'title')">Required</mat-hint>
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.title">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'title',item.type)">Required</mat-error>
                                </ng-container> -->
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="form-group col-md-6">
                                <mat-label class="form-input-header" for="subject">Short Title</mat-label>
                                <textarea matInput placeholder="Input" formControlName="subject"></textarea>
                                <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'subject')">Required</mat-hint>
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.subject">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'subject',item.type)">Required</mat-error>
                                </ng-container> -->
                            </mat-form-field>
                        </div>

                        <!-- Regulatory Life Cycle Stage -->
                        <div class="row my-5">
                            <label class="form-input-header">Regulatory Life Cycle Stage <span class="asterisk">*</span></label>
                            <mat-radio-group formControlName="reg_life_cycle_stage">
                                <mat-radio-button checked value="Development of Regulation" class="mx-3">Development of Regulation</mat-radio-button>
                                <mat-radio-button value="Implementation" class="mx-3">Implementation</mat-radio-button>
                                <mat-radio-button value="Monitoring, Evaluation, & Ex-Post Review" class="mx-3">Monitoring, Evaluation, & Ex-Post Review</mat-radio-button>
                            </mat-radio-group>
                            <mat-hint style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_life_cycle_stage')">Required</mat-hint>
                        </div>

                        <!-- Regulation Instrument & Issuing Agency -->
                        <div class="row mt-4">
                            <div class="col-md-6">
                                <label class="form-input-header">Regulation Instrument <span class="asterisk">*</span></label>
                                <select class="form-control" formControlName="reg_instrument" (change)="instrumentOnChange($event)">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of instruments" [value]="x['id']">{{ x["name"] }}</option>
                                </select>
                                <mat-hint style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_instrument')">Required</mat-hint>
                                <!--<ng-container *ngFor="let item of checkProposedRegulationForm.reg_instrument">
                                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'reg_instrument',item.type)">* {{item.message}}</mat-error>
                                </ng-container>-->
                            </div>

                            <div class="col-md-6">
                                <label class="form-input-header" for="reg_agency">Issuing Agency <span class="asterisk">*</span></label>
                                <mat-select class="form-control" *ngIf="!multipleIssuingAgency" formControlName="reg_agency">
                                    <mat-option *ngFor="let x of agencies" [value]="x['id']">
                                        {{ x["name"] }}
                                    </mat-option>
                                </mat-select>
                                <mat-select class="form-control" *ngIf="multipleIssuingAgency" formControlName="reg_agency" multiple>
                                    <mat-option *ngFor="let x of agencies" [value]="x['id']">
                                        {{ x["name"] }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_agency')">Required</mat-hint>
                                <!--<ng-container *ngFor="let item of checkProposedRegulationForm.reg_agency">
                                    <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'reg_agency',item.type)">* {{item.message}}</mat-error>
                                </ng-container>-->
                            </div>
                        </div>

                        <!-- Regulatory Action -->
                        <div class="row my-5">
                            <div class="form-group">
                                <mat-label class="form-input-header" for="title">Classification of Action <span class="asterisk">*</span></mat-label>
                                <div class="mt-3" formGroupName="reg_action_classification">
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="reg_action_new" formControlName="reg_action_new" value="New Regulation"/>
                                        <label class="form-check-label" for="reg_action_new">New Regulation</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="reg_action_modification" formControlName="reg_action_modification" value="Amendment"/>
                                        <label class="form-check-label" for="reg_action_modification">Amendment</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="reg_action_repeal" formControlName="reg_action_repeal" value="Repeal"/>
                                        <label class="form-check-label" for="reg_action_repeal">Repeal</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="reg_action_ex_post" formControlName="reg_action_ex_post" value="Ex-Post RIS/PIR/SCR"/>
                                        <label class="form-check-label" for="reg_action_ex_post">Ex-Post RIS/PIR/SCR</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="reg_action_completed" formControlName="reg_action_completed" value="Completed"/>
                                        <label class="form-check-label" for="reg_action_completed">Completed</label>
                                    </div>
                                </div>
                            </div>
                            <mat-hint class="my-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_action_classification')">Required</mat-hint>
                            <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.reg_action_classification">
                                <mat-error class="mt-1 mb-4" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_action_classification',item.type)">* {{item.message}}</mat-error>
                            </ng-container>  -->
                        </div>
                        
                        <div class="row mt-4" *ngIf="reg_action_classification_val.controls.reg_action_modification.value === true">
                            <mat-form-field appearance="outline" class="col-md-6">
                                <mat-label class="form-input-header">Regulation to Amend</mat-label>
                                <textarea matInput placeholder="Regulation to Amend" formControlName="reg_action_modification_value"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-6">
                                <mat-label class="form-input-header">Section to Amend</mat-label>
                                <textarea matInput placeholder="Section to Amend" formControlName="reg_action_modification_section"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="row mt-4" *ngIf=" reg_action_classification_val.controls.reg_action_repeal.value === true">
                            <mat-form-field appearance="outline" class="col-md-6">
                                <mat-label class="form-input-header">Regulation to Repeal</mat-label>
                                <textarea matInput placeholder="Regulation to Repeal" formControlName="reg_action_repeal_value"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- C. STAKEHOLDERS AND/OR SECTORS AFFECTED -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">C. STAKEHOLDERS AND/OR SECTORS AFFECTED</li>
                            </ol>
                        </nav>

                        <div formGroupName="tags">
                            <div class="row mt-4">
                              <mat-label class="form-input-header">Classification <span class="asterisk">*</span></mat-label>
                              <div class="form-check">
                                <mat-radio-group formControlName="reg_classification" aria-label="Select an option">
                                  <mat-radio-button checked value="Business" class="mx-3">Business-Related</mat-radio-button>
                                  <mat-radio-button value="Non-Business" class="mx-3">Non-Business</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.tags">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'tags',item.type)">* {{item.message}}</mat-error>
                              </ng-container>  -->
                            </div>
                  
                            <!-- Business -->
                            <div *ngIf="proposedregulationform?.get('tags')?.get('reg_classification')?.value == 'Business'">
                                <div class="row my-5">
                                  <div class="form-group col-md-6">
                                    <label class="form-input-header" for="subject">Sector <span class="asterisk">*</span></label>
                                    <select placeholder="Select" class="form-control" formControlName="reg_sector" (change)="sectorOnChange($event)">
                                      <option *ngFor="let x of sectors" [value]="x['id']">{{ x["name"] }}</option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_sector','tags')">Required</mat-hint>
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_sector">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_sector',item.type,'tags')">Required</mat-error>
                                    </ng-container> -->
                                  </div>
                  
                                  <div class="form-group col-md-6">
                                    <label class="form-input-header" for="subject">Division</label>
                                    <select class="form-control" placeholder="Select" formControlName="reg_division" (change)="selectOnChange1('reg_division')">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of divisions_select" [value]="x['id']">{{ x["name"] }}</option>
                                    </select>
                                    <!-- <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_division','tags')">Required</mat-hint> -->
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_division">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_division',item.type,'tags')">Required</mat-error>
                                    </ng-container>  -->
                                  </div>
                                </div>
                  
                                <div class="row my-4">
                                  <div class="col-md-4">
                                    <label class="form-input-header" for="subject">Stage of Business <span class="asterisk">*</span></label>
                                    <select class="form-control" formControlName="reg_business" (change)="sobOnChange($event)">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of sobs" [value]="x['id']">{{ x["name"] }}</option>
                                    </select>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_business','tags')">Required</mat-hint>
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_business">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_business',item.type,'tags')">Required</mat-error>
                                    </ng-container>  -->
                                  </div>
                  
                                  <div class="form-group col-md-4">
                                    <label class="form-input-header" for="subject">Select here</label>
                                    <select class="form-control" placeholder="Select" formControlName="reg_case" (change)="selectOnChange1('reg_case')">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of cases_select" [value]="x['id']">{{ x["name"] }}</option>
                                    </select>
                                    <!-- <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_case','tags')">Required</mat-hint> -->
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_case">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_case',item.type,'tags')">Required</mat-error>
                                    </ng-container>  -->
                                  </div>

                                    <div class="form-inline col-md-4">
                                        <label class="form-input-header" for="reg_case">Case Use</label>
                                        <input type="text" class="form-control" formControlName="reg_case_text" />
                                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_case_text','tags')">Required</mat-hint>
                                        <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_case">
                                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_case',item.type,'tags')">Required</mat-error>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>
        
                            <!-- Non-Business -->
                            <div  *ngIf="proposedregulationform?.get('tags')?.get('reg_classification')?.value == 'Non-Business'">
                                <div class="row my-5">
                                  <div class="col-md-6">
                                    <label class="form-input-header" for="subject">Stage of Life</label>
                                    <select class="form-control" formControlName="reg_stageoflife" name="reg_stageoflife" id="reg_stageoflife" (change)="solOnChange($event)">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of sols" [value]="x['id']">{{ x["stage"] }}</option>
                                    </select>
                                    <!-- <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_stageoflife','tags')">Required</mat-hint> -->
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_stageoflife">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_stageoflife',item.type,'tags')">Required</mat-error>
                                    </ng-container>  -->
                                  </div>
                  
                                  <div class="form-group col-md-6">
                                    <label class="form-input-header" for="subject">Life Event</label>
                                    <select class="form-control" placeholder="Select" formControlName="reg_lifeevent" (change)="selectOnChange1('reg_lifeevent')">
                                      <option value="" disabled selected>--</option>
                                      <option *ngFor="let x of lifeevents_select" [value]="x['id']">{{ x["event"] }}</option>
                                    </select>
                                    <!-- <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_lifeevent','tags')">Required</mat-hint> -->
                                    <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_lifeevent">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_lifeevent',item.type,'tags')">Required</mat-error>
                                    </ng-container>  -->
                                  </div>
                                </div>
                            </div>
                  
                            <!-- Jurisdiction -->
                            <div class="row my-5">
                              <div class="form-group col-md-6">
                                <label class="form-input-header" for="subject" >Jurisdiction <span class="asterisk">*</span></label>
                                <select class="form-control" placeholder="Select" formControlName="reg_juris" (change)="selectOnChange1('reg_juris')">
                                  <option *ngFor="let x of jurisdictions" [value]="x['id']">{{ x["name"] }}</option>
                                </select>
                                <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_juris','tags')">Required</mat-hint>
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_juris">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_juris',item.type,'tags')">Required</mat-error>
                                </ng-container> -->
                              </div>
                            </div>
                        </div>
                    </div>

                    <!-- D. CONSULTATIONS CONDUCTED -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">D. CONSULTATIONS CONDUCTED</li>
                            </ol>
                        </nav>

                        <div class="row mt-4 mb-5">
                            <mat-label class="form-input-header">Has this been subjected to stakeholder consultation and comments? <span class="asterisk">*</span></mat-label>
                            <div class="form-check">
                              <mat-radio-group formControlName="has_consultation_comments" aria-label="Select an option">
                                <mat-radio-button checked value="Yes" class="mx-3">Yes</mat-radio-button>
                                <mat-radio-button value="No" class="mx-3">No</mat-radio-button>
                                <mat-radio-button value="Ongoing" class="mx-3">Ongoing</mat-radio-button>
                              </mat-radio-group>
                            </div>
                        </div>

                        <!-- <div class="row mt-4">
                            <div class="form-group">
                                <mat-label class="form-input-header">Has this been subjected to stakeholder consultation and comments? <span class="asterisk">*</span></mat-label>
                                <div class="mt-3">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="has_consultation_comments" id="inlineRadioCons1" formControlName="has_consultation_comments" [value]="Yes"/>
                                        <label class="form-check-label" for="inlineRadioCons1">Yes</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="has_consultation_comments" id="inlineRadioCons2" formControlName="has_consultation_comments" [value]="No"/>
                                        <label class="form-check-label" for="inlineRadioCons2">No</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="has_consultation_comments" id="inlineRadioCons3" formControlName="has_consultation_comments" [value]="Ongoing"/>
                                        <label class="form-check-label" for="inlineRadioCons3">Ongoing</label>
                                    </div>
                                </div>
                                
                                <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'has_consultation_comments')">Required</mat-hint>
                                <ng-container *ngFor="let item of checkProposedRegulationForm.has_consultation_comments">
                                    <mat-error class="mt-1" style="color:red;font-size:10px;" *ngIf="hasErrors(proposedregulationform, 'has_consultation_comments',item.type)">* {{item.message}}</mat-error>
                                </ng-container> 
                            </div>
                        </div> -->
                    </div>

                    <!-- E. SUMMARY OF REGULATION -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">E. SUMMARY OF REGULATION</li>
                            </ol>
                        </nav>

                        <div class="row mt-2">
                            <mat-form-field appearance="outline" class="form-group col-md-12">
                                <mat-label class="form-input-header" for="summary_regulation">PROBLEM ADDRESSED BY REGULATION / SUMMARY OF CHANGES</mat-label>
                                <textarea matInput placeholder="Input" formControlName="summary_regulation"></textarea>
                                <!-- <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'summary_regulation')">Required</mat-hint> -->
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.title">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'title',item.type)">Required</mat-error>
                                </ng-container> -->
                            </mat-form-field>
                        </div>

                        <div class="row mt-2">
                            <mat-form-field appearance="outline" class="form-group col-md-12">
                                <mat-label class="form-input-header" for="objective_reg_action">OBJECTIVE OF REGULATORY ACTION</mat-label>
                                <textarea matInput placeholder="Input" formControlName="objective_reg_action"></textarea>
                                <!-- <mat-hint class="mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'objective_reg_action')">Required</mat-hint> -->
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.subject">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'subject',item.type)">Required</mat-error>
                                </ng-container> -->
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- F. CLASSIFICATION OF REGULATION -->
                    <div class="my-4">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">F. CLASSIFICATION OF REGULATION</li>
                            </ol>
                        </nav>

                        <!--Significance & Narrative -->
                        <div class="form-group mt-2">
                            <mat-label class="form-input-header">Significance <span class="asterisk">*</span></mat-label>
                            <mat-radio-group formControlName="reg_significance" class="example-radio-group">
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <mat-radio-button checked value="Major" class="example-radio-button fw-bold">Major</mat-radio-button>
                                </div>
                                <p class="col-md-10">
                                    Regulations that meet the ARTA’s Proportionality Rule will require a full regulatory impact assessment 
                                    and must undergo ARTA’s review.
                                </p>
                            </div>
                
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <mat-radio-button value="Minor" class="example-radio-button fw-bold">Minor</mat-radio-button>
                                </div>
                                <p class="col-md-10">
                                    Regulations that have no expected significant socio-economic impacts. They do not need to undergo 
                                    full regulatory impact assessment.
                                </p>
                            </div>
                
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <mat-radio-button value="Exempted" class="example-radio-button fw-bold">Exempted</mat-radio-button>
                                </div>
                                <p class="col-md-10"> Regulations that are identified to be outside the scope of the RIA Process.</p>
                            </div>
                
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <mat-radio-button value="Emergency" class="example-radio-button fw-bold">Emergency</mat-radio-button>
                                </div>
                                <p class="col-md-10">
                                    Exempted Regulations due to exceptional circumstances which require immediate response 
                                    such as national emergencies. The submission of proper documentation to ARTA for such can 
                                    be done post-promulgation/issuance.
                                </p>
                            </div>
                            </mat-radio-group>
                
                            <div class="row mt-2" *ngIf="proposedregulationform?.get('reg_significance')?.value == 'Exempted' || proposedregulationform?.get('reg_significance')?.value == 'Emergency'">
                                <mat-label class="form-input-header">Justification<span class="asterisk">*</span></mat-label>

                                <editor [disabled]="commentMode" formControlName="reg_significance_comments"
                                    [init]="{
                                    base_url: '/tinymce',
                                    suffix: '.min',                
                                    height: 250,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist outdent indent | removeformat | help'
                                    }"
                                ></editor>

                                <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_significance_comments')">Required</mat-hint>
                                <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.reg_significance_comments">
                                    <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_significance_comments',item.type)">Required</mat-error>
                                </ng-container> -->
                            </div>
                        </div>
                
                        <!-- Lenses Required -->
                        <!-- <div class="form-group mt-4">
                            <mat-label class="form-input-header">Lenses Required <span class="asterisk">*</span></mat-label>
                            <div class="form-check p-3" formGroupName="reg_lenses">
                                <div class="form-group row">
                                    <div class="col-md-4">
                                    <input class="form-check-input" type="checkbox" id="lense_competition" formControlName="lense_competition" value="Competition Assessment"/>
                                    <label for="lense_competition" class="form-check-label fw-bold">Competition Assessment</label>
                                    </div>
                                    <p class="col-md-8">
                                        Competition assessment may demonstrate potential negative impacts of a policy/regulation on competition i.e., (1)
                                        creation of barrier/s to market entry, (2) restriction on the ability of market players to compete, (3) promotion of
                                        anti-competitive behavior among market players, and (4) limitation of consumer information and choice.
                                    </p>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-4">
                                        <input class="form-check-input" type="checkbox" id="lense_environmental" formControlName="lense_environmental" value="Environment Assessment"/>
                                        <label for="lense_environmental" class="form-check-label fw-bold">Environment Assessment</label>
                                    </div>
                                    <p class="col-md-8">
                                        Regulations that will likely cause negative or irreversible impact to the environment should be carefully evaluated before
                                        they get approved and implemented. Regulations that have been assessed using an environmental lens may also help in
                                        preventing the implementation of unnecessary programs or projects arising from the proposed or existing regulations.
                                    </p>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-4">
                                        <input class="form-check-input" type="checkbox" id="lense_inclusive" formControlName="lense_inclusive" value="Gender and Social Inclusivity Assessment"/>
                                        <label for="lense_inclusive" class="form-check-label fw-bold">Gender and Social Inclusivity Assessment</label>
                                    </div>
                                    <p class="col-md-8">
                                        The GESIA recognizes that gender intersects or interacts with other categories of social exclusion, often solidified through
                                        discrimination against certain groups or subpopulations based on their identity as defined by age, (dis)ability, ethnicity,
                                        location (urban or rural), class, faith, or sector.
                                    </p>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-4">
                                        <input class="form-check-input" type="checkbox" id="lense_disaster" formControlName="lense_disaster" value="Disaster Risk Assessment"/>
                                        <label for="lense_disaster" class="form-check-label fw-bold">Disaster Risk Assessment</label>
                                    </div>
                                    <p class="col-md-8">
                                        The need to investigate how regulation will impact the achievement of disaster risk reduction (DRR) objectives is
                                        paramount since disasters and bad development policies, including bad regulations are closely interlinked.
                                    </p>
                                </div>
                            </div> -->
                
                            <!-- Narrative / Justification -->
                            <!-- <div class="row g-3 mt-2 mb-5">
                            <mat-label class="form-input-header">Narrative / Justification <span class="asterisk">*</span></mat-label>
                            <editor [disabled]="commentMode" formControlName="reg_lenses_comments"
                                [init]="{
                                base_url: '/tinymce',
                                suffix: '.min',                
                                height: 250,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                }"
                            ></editor>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_lenses_comments')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.reg_lenses_comments">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_lenses_comments',item.type)">Required</mat-error>
                            </ng-container> 
                            </div>

                        </div> -->

                    </div>

                    <!-- G. TIMELINE OF ACTIVITIES (ACCOMPLISHED OR TARGET) -->
                    <div class="my-4" *ngIf="proposedregulationform?.get('reg_significance')?.value == 'Major' || proposedregulationform?.get('reg_significance')?.value == 'Minor'">
                        <nav aria-label="breadcrumb" class="breadcrumb-custom-main">
                            <ol class="breadcrumb p-2">
                            <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">G. TIMELINE OF ACTIVITIES (ACCOMPLISHED OR TARGET)</li>
                            </ol>
                        </nav>

                        <div class="row mt-2" *ngIf="proposedregulationform?.get('reg_significance')?.value == 'Major' || proposedregulationform?.get('reg_significance')?.value == 'Minor'">
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Conduct of PIA<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="conduct_pia_date"/>
                            </div>
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Consultation of PIS<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="consultation_pis_date"/>
                            </div>
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Submission of PIS<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="submission_pis_date"/>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="proposedregulationform?.get('reg_significance')?.value == 'Major'">
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Conduct of RIA<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="conduct_ria_date"/>
                            </div>
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Consultation of RIS<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="consultation_ris_date"/>
                            </div>
                            <div class="col-md-4">
                                <mat-label class="form-input-header fw-bold" for="date_conducted">Submission of RIS<span class="asterisk">*</span></mat-label>
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="submission_ris_date"/>
                            </div>
                        </div>
                        
                    </div>

                    <div class="row">
                        <div class="mt-5" *ngIf="proposedregulationform.invalid">
                            <p class="float-end fst-italic fw-bold">
                            Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                            </p>
                        </div>
                    </div>
                </div>
            
                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">
                        <span class="asterisk" matTooltipPosition="above" matTooltip="Submit RNF for now.">Go to summary</span>
                    </button>
                </mat-action-row>
            </mat-expansion-panel>

            <!-- SUMMARY -->
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title class="form-input-header my-3">
                        <mat-icon class="me-3">description</mat-icon>SUMMARY
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div appearance="fill">
                    <div>
                        <table class="table mb-5">
                            <thead>
                            <tr class="alt-gray">
                                <th><strong>SECTION</strong></th>
                                <th><strong>STATUS</strong></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let section of proposedRegulationFile">
                                <ng-container *ngIf="(section.code != 'subTags') || (section.code == 'subTags' && proposedregulationform.value.has_sub_tags == true)">
                                <td>
                                    <a [routerLink]="" (click)="gotoForm(section.path)">{{section.title}}</a>
                                </td>
                                <td>
                                    {{proposedRegulationChecker(section.code) ? 'Valid' : 'Invalid'}}
                                </td>
                                </ng-container>
                            </tr>
                            </tbody>
                        </table>

                        <!-- Comment -->
                        <div class="row" *ngIf="commentMode">
                            <div class="card col-md-12" style="margin:80px 0 0;">

                            <!-- Comment Footer Bottons -->
                            <div class="row g-3">
                                <div class="card-footer text-center col-md-12 mt-2">
                                <button class="me-2 btn btn-dark" (click)="toCancel()">Cancel</button>
                                <button class="me-2 btn btn-primary" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                                <button *ngIf="commentButtonsEnabled" class="btn btn btn-primary m-3 float-end" (click)="submitCommentsAndReturn()" id="bt "
                                    [disabled]="(comment_details.invalid && comment_regtext.invalid && comment_pia.invalid && comment_self_assessment.invalid) || loading">
                                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                                    <i class="fas fa-share-square me-1"></i>Submit Comments and Return
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                <mat-action-row *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                        <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                        <button class="btn btn-info float-start" (click)="saveAsDraft()" disabled><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                        <button class="btn btn-danger float-end" (click)="submitRegulation()" id="bt">
                            <i class="fas fa-share-square me-1"></i>Submit <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                        </button>
                        <button class="me-2 btn btn-primary float-end" (click)="prevStep()"><i class="fas fa-arrow-circle-left me-1"></i>Previous</button>
                    </div>
                </mat-action-row>
            </mat-expansion-panel>
        
        </mat-accordion>
    </div>
</form>


  <!-- <div class="row g-3">
    <div class="card-footer text-center col-md-6 mt-2">
      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
    </div>
    <div class="card-footer text-center col-md-6 mt-2">
      <button class="btn btn-danger float-end" (click)="gotoForm('pia')">Proceed to PIA <i class="fas fa-arrow-circle-right ms-1"></i></button>
    </div>
  </div> -->