<!-- TAB navs lvl 2 -->
<ul class="nav nav-tabs mb-3" id="regulationData-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
  <li class="nav-item" role="presentation" *ngFor="let year of years">
    <button class="nav-link" [class.active]="selectedYear === year" (click)="filterByYear(year)" type="button"
      role="tab" [attr.aria-selected]="selectedYear === year">
      {{ year }}
    </button>
  </li>
</ul>
<!-- TAB navs lvl 2 -->

<!-- Tabs content lvl 2 -->
<div class="tab-content" id="regulationData-content">
  <div class="tab-pane fade show active" id="existingtab" role="tabpanel" aria-labelledby="existing">
    <div [formGroup]="searchForm" class="form-group d-flex flex-row align-items-center" style="margin:1em 0;">
      <label for="search" style="margin-right:1em;">Search:</label>
      <input formControlName="keyword" id="search" name="search" (keyup)="search($event)" class="form-control" />
      <button class="btn btn-outline-dark" style="margin-left:1em;" (click)="refreshList()" title="refresh"><i
          class="fas fa-redo"></i></button>
    </div>

    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
    <table mat-table class="table table-hover table-responsive" id="matTable"
      [dataSource]="agencyRegulationsDataSource">
      <ng-container matColumnDef="regulations">
        <th mat-header-cell *matHeaderCellDef style="padding:1em">
          <h4></h4>
        </th>
        <td mat-cell *matCellDef="let item" style="padding:2em">
          <div style="font-weight:bold">
            <h5>
              <i class="mx-2" [ngClass]="item.title || item.subject ? '' : 'red-text'">
                <strong class="fw-bold">{{item.title ? item.title : item.subject ? item.subject : 'No Title'}}</strong>
              </i>
            </h5>
          </div>
        </td>
        <ng-container
          *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
          <td mat-footer-cell *matFooterCellDef style="padding:2em" class="fw-bold d-flex justify-content-center">
            No Regulations Data Found . . .
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns;"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      <ng-container
        *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
        <tr mat-footer-row *matFooterRowDef="columns"></tr>
      </ng-container>
    </table>
    <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
  </div>
</div>
<!-- Tabs content lvl 2 -->