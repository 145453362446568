import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  uploadFile(fileData: File, filePath: string) {
    var formData: any = new FormData();
    formData.append("file", fileData);
    formData.append("filePath", filePath);

    return this.http.post<any>(environment.apiBaseURL + "/uploadFile", formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
