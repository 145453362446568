<div class="container-fluid p-0">
    <div class="row g-0">

        <!-- Carousel -->
        <div class="col-5 d-none d-lg-block">
          <app-pbris-carousel></app-pbris-carousel> 
        </div>

        <!-- Forgot Password Card -->
        <div class="col-sm form-column">
            <div class="form-container mx-3">
                <app-pbris-form-banner></app-pbris-form-banner>
                <form [formGroup]="form">
                    <div class="form-group mt-5">
                        <h5 class="fw-bold text-center theme-arta-text">FORGOT PASSWORD</h5>
                    </div>
                    <div class="form-group mt-5">
                        <div class="form-group">
                            <label for="username" class="fw-bold mb-1">Email Address</label>
                            <input type="email" class="form-control" formControlName="username" />
                            <!--  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" -->
                            <div class="invalid-feedback">
                                <!-- *ngIf="submitted && f.username.errors"  -->
                                <div>Username is required</div>
                                <!-- *ngIf="f.username.errors.required" -->
                            </div>
                        </div>
    
                    </div>
                    <div class="row my-2">
                        <div class="col-md-7 mt-2"> 
                            <p class="text-start test-justify">
                                We will send a confirmation message to your email.
                            </p>
                        </div>
                        <div class="d-grid my-2 col-5">
                            <button (click)="sendPasswordResetEmail()" class="btn btn-primary btn-rounded float-end">Send Link</button>
                            <button class="btn btn btn-danger btn-rounded mt-2" (click)="loginAsGuest()">Continue as Guest</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
</div>
