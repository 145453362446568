<!-- <app-wel-navbar></app-wel-navbar> -->
<!--<app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header>-->
<!-- <app-navbar *ngIf="currentSystem == pbris"></app-navbar> -->
<div class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-head p-3">
                <h3>Reference Control</h3>
            </div>
            <div class="card-body form-container ">
                <div class="d-flex justify-content-center align-self-center">
                    <div class="spinner-grow" role="status" *ngIf="loader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <h4>LIST OF VALUES:</h4>

                
                <div >
                    <!-- <div class="sticky-container sticky-pos1"> -->
                    <div>
                        <!-- Level 1 Nav Tabs -->
                        <ul class="nav nav-tabs mb-3" id="listofvalues-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="common" data-mdb-toggle="tab" data-mdb-target="#commontab" type="button" role="tab" aria-controls="commontab" aria-selected="true" >
                                COMMON</button>
                            </li>
                            <li *ngIf="pbrisType || isAdmin" class="nav-item" role="presentation">
                                <button class="nav-link" id="pbris" data-mdb-toggle="tab" type="button" data-mdb-target="#pbristab"  role="tab" aria-controls="pbristab" aria-selected="false">
                                    PBRIS</button>
                            </li>
                            <li *ngIf="artaType || isAdmin" class="nav-item" role="presentation">
                                <button class="nav-link" id="arta" data-mdb-toggle="tab" type="button" data-mdb-target="#artatab" role="tab" aria-controls="artatab" aria-selected="false" >
                                    ARTEMIS</button>
                            </li>
                        </ul>
                    </div>
                    
                    <!-- Level 1 content -->
                    <div class="tab-content" id="listofvalues-content">
                        <div class="tab-pane fade show active" id="commontab" role="tabpanel" aria-labelledby="common">
                            <!-- COMMON TAB -->
                            <!-- <div class="sticky-container sticky-pos2"> -->
                            <div>
                                <ul class="nav nav-tabs mb-3" id="common-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
                                    <li class="nav-item" role="presentation">
                                        <button (click)="hideSubCntrl(false)" class="nav-link active" id="sector" data-mdb-toggle="tab" type="button" data-mdb-target="#sectortab" role="tab" aria-controls="sectortab" aria-selected="true" >
                                            SECTOR</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button (click)="hideSubCntrl(false)" class="nav-link" id="orgcode" data-mdb-toggle="tab" type="button" data-mdb-target="#orgcodetab" role="tab" aria-controls="orgcodetab" aria-selected="false">
                                            ORGANIZATION CODE</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button (click)="hideSubCntrl(false)" class="nav-link" id="loccode" data-mdb-toggle="tab" type="button" data-mdb-target="#loccodetab" role="tab" aria-controls="loccodetab" aria-selected="false" >
                                            LOCATION CODE</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button (click)="hideSubCntrl(false)" class="nav-link" id="psiccode" data-mdb-toggle="tab" type="button" data-mdb-target="#psiccodetab" role="tab" aria-controls="psiccodetab" aria-selected="false" >
                                            PSIC CODE</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button (click)="hideSubCntrl(true)" class="nav-link" id="idtab" data-mdb-toggle="tab" type="button" data-mdb-target="#idtab" role="tab" aria-controls="identification" aria-selected="false" >
                                            IDENTIFICATION CARD</button>
                                    </li>
                                </ul>
                            </div>
    
                            <!-- Level 2 content -->
                            <div class="tab-content" id="common-content">
                                <!-- Level 3 nav tabs -->
                                <div  class="tab-pane fade show active" id="sectortab" role="tabpanel" aria-labelledby="sector">
                                    <!-- SECTOR -->
                                    <app-reference-sub-control *ngIf="!hidesubcontrol" currentRef="Sector" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of SECTOR -->
                                </div>
                                <div class="tab-pane fade show" id="orgcodetab" role="tabpanel" aria-labelledby="orgcode">
                                    <!-- ORGANIZATION CODE -->
                                    <app-reference-sub-control *ngIf="!hidesubcontrol" currentRef="Organization Code" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of ORGANIZATION CODE -->
                                </div>
                                <div class="tab-pane fade show" id="loccodetab" role="tabpanel" aria-labelledby="loccode">
                                    <!-- LOCATION CODE -->
                                    <app-reference-sub-control *ngIf="!hidesubcontrol" currentRef="Location Code" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of LOCATION CODE -->
                                </div>
                                <div class="tab-pane fade show" id="psiccodetab" role="tabpanel" aria-labelledby="psiccode">
                                    <!-- PSIC CODE -->
                                    <app-reference-sub-control *ngIf="!hidesubcontrol" currentRef="PSIC Code" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of PSIC CODE -->
                                </div>

                                <div class="content">
                                    <div class="container-fluid-4">
                                        <app-artemis-identification-list-maintenance *ngIf="hidesubcontrol" ></app-artemis-identification-list-maintenance>
                                    </div>
                                </div>
                            </div>
                            <!-- end of common tab -->
                        </div>
                        <div *ngIf="pbrisType" class="tab-pane fade" id="pbristab" role="tabpanel" aria-labelledby="pbris">
                            <!-- PBRIS TAB -->
                            <div >
                                <ul class="nav nav-tabs mb-3" id="pbris-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="sob" data-mdb-toggle="tab" type="button" data-mdb-target="#sobtab" role="tab" aria-controls="sobtab" aria-selected="true" >
                                            Stage of Business</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="division" data-mdb-toggle="tab" type="button" data-mdb-target="#divisiontab" role="tab" aria-controls="divisiontab" aria-selected="false">
                                            Division</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="jusrisdition" data-mdb-toggle="tab" type="button" data-mdb-target="#jusrisditiontab" role="tab" aria-controls="jusrisditiontab" aria-selected="false" >
                                            Jurisdiction</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="caseuse" data-mdb-toggle="tab" type="button" data-mdb-target="#caseusetab" role="tab" aria-controls="caseusetab" aria-selected="false" >
                                            Case Use</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="agenloc" data-mdb-toggle="tab" type="button" data-mdb-target="#agenloctab" role="tab" aria-controls="agenloctab" aria-selected="false" >
                                            Agency Location</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="docu" data-mdb-toggle="tab" type="button" data-mdb-target="#docutab" role="tab" aria-controls="docutab" aria-selected="false" >
                                            Document</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="intru" data-mdb-toggle="tab" type="button" data-mdb-target="#intrutab" role="tab" aria-controls="intrutab" aria-selected="false" >
                                            Instruments</button>
                                    </li>
                                </ul>
                            </div>
    
                            
                            <!-- Level 2 content -->
                            <div class="tab-content" id="pbris-content">
                                <!-- Level 3 nav tabs -->
                                <div class="tab-pane fade show active" id="sobtab" role="tabpanel" aria-labelledby="sob">
                                    <!-- Stage of Business -->
                                    <app-reference-sub-control currentRef="Stage of Business" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Stage of Business -->
                                </div>
                                <div class="tab-pane fade show" id="divisiontab" role="tabpanel" aria-labelledby="division">
                                    <!-- Division -->
                                    <app-reference-sub-control currentRef="Division" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Division -->
                                </div>
                                <div class="tab-pane fade show" id="jusrisditiontab" role="tabpanel" aria-labelledby="jusrisdition">
                                    <!-- Jurisdiction -->
                                    <app-reference-sub-control currentRef="Jurisdiction" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Jurisdiction -->
                                </div>
                                <div class="tab-pane fade show" id="caseusetab" role="tabpanel" aria-labelledby="caseuse">
                                    <!-- Case Use -->
                                    <app-reference-sub-control currentRef="Case Use" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Case Use -->
                                </div>
                                <div class="tab-pane fade show" id="agenloctab" role="tabpanel" aria-labelledby="agenloc">
                                    <!-- Agency Location -->
                                    <app-reference-sub-control currentRef="Agency Location" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Agency Location -->
                                </div>
                                <div class="tab-pane fade show" id="docutab" role="tabpanel" aria-labelledby="docu">
                                    <!-- Document -->
                                    <app-reference-sub-control currentRef="Document" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Agency Location -->
                                </div>
                                <div class="tab-pane fade show" id="intrutab" role="tabpanel" aria-labelledby="intru">
                                    <!-- Instruments -->
                                    <app-reference-sub-control currentRef="Instrument" userAccess="pbris" [isAdmin]="isAdmin"></app-reference-sub-control>
                                    <!-- end of Instruments -->
                                </div>
                            </div>
                            <!-- end of pbris tab -->
                        </div>
                        <div *ngIf="artaType" class="tab-pane fade" id="artatab" role="tabpanel" aria-labelledby="arta1">
                            <!-- ARTEMIS TAB -->
                            <div >
                                <ul class="nav nav-tabs mb-3" id="arta-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="motheragency" data-mdb-toggle="tab" type="button" data-mdb-target="#motheragencytab" role="tab" aria-controls="motheragencytab" aria-selected="true" >
                                            Mother Agencies</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link " id="agency" data-mdb-toggle="tab" type="button" data-mdb-target="#agencytab" role="tab" aria-controls="agencytab" aria-selected="false" >
                                            Agency</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="agencycat" data-mdb-toggle="tab" type="button" data-mdb-target="#agencycattab" role="tab" aria-controls="agencycattab" aria-selected="false">
                                            Agency Category</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="class" data-mdb-toggle="tab" type="button" data-mdb-target="#classtab" role="tab" aria-controls="classtab" aria-selected="false" >
                                            Classification</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="los" data-mdb-toggle="tab" type="button" data-mdb-target="#lostab" role="tab" aria-controls="lostab" aria-selected="false" >
                                            List of Services</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="servcat" data-mdb-toggle="tab" type="button" data-mdb-target="#servcattab" role="tab" aria-controls="servcattab" aria-selected="false" >
                                            Service Category</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="sol" data-mdb-toggle="tab" type="button" data-mdb-target="#soltab" role="tab" aria-controls="soltab" aria-selected="false" >
                                            Stage of Life</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="idtab" data-mdb-toggle="tab" type="button" data-mdb-target="#idtab" role="tab" aria-controls="ids" aria-selected="false" >
                                            Identification Cards</button>
                                    </li>
                                </ul>
                            </div>
    
                            
                            <!-- Level 2 content -->
                            <div class="tab-content" id="arta-content">
                                <!-- Level 3 nav tabs -->
                              
                                <div class="tab-pane fade show active" id="motheragencytab" role="tabpanel" aria-labelledby="motheragencytab">
                                    <!--   Mother Agencies -->
                                    <app-artemis-reference-control currentRef="Mother Agency" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control >
                                    <!-- end of   Mother Agencies -->
                                </div>

                                <div class="tab-pane fade show " id="agencytab" role="tabpanel" aria-labelledby="agency">
                                    <!-- Agency -->
                                    <app-artemis-reference-control currentRef="Agency" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of Agency -->
                                </div>
                                <div class="tab-pane fade show" id="agencycattab" role="tabpanel" aria-labelledby="agencycat">
                                    <!-- Agency Category -->
                                    <app-artemis-reference-control currentRef="Agency Category" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of Agency Category -->
                                </div>
                                <div class="tab-pane fade show" id="classtab" role="tabpanel" aria-labelledby="class">
                                    <!-- Classification -->
                                    <app-artemis-reference-control currentRef="Classification" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of Classification -->
                                </div>
                                <div class="tab-pane fade show" id="lostab" role="tabpanel" aria-labelledby="los">
                                    <!-- List of Services -->
                                    <app-artemis-reference-control currentRef="List of Service" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of List of Services -->
                                </div>
                                <div class="tab-pane fade show" id="servcattab" role="tabpanel" aria-labelledby="servcat">
                                    <!-- Service Category -->
                                    <app-artemis-reference-control currentRef="Service Category" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of Service Category -->
                                </div>
                                <div class="tab-pane fade show" id="soltab" role="tabpanel" aria-labelledby="sol">
                                    <!-- Stage of Life -->
                                    <app-artemis-reference-control currentRef="Stage of Life" userAccess="artemis" [isAdmin]="isAdmin"></app-artemis-reference-control>
                                    <!-- end of Stage of Life -->
                                </div>
                            </div>
                            <!-- end of artemis tab -->
                        </div>
                        <!-- end of tab content -->
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
<!-- 

Common // seen by both
    - SECTOR
    - Organization Code
    - Location Code
    - PSIC Code

PBRIS
    - Stage of Business
    - Division
    - Jurisdiction
    - Case Use
    - Agency Location
    - Document
    - Instruments

ARTEMIS
    - Agency
    - Agency Category
    - Classification
    - List of Services
    - Service Category
    - Stage of Life
-->