<ng-container>
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="header-content text-center">
                <img src="{{header_content.logo}}">
                <h4 class="modal-title w-100" id="myModalLabel" *ngIf="header_content.title">{{ header_content.title }}</h4>
            </div>
            <div class="body-content">
                <p *ngIf="body_content.message">{{ body_content.message }}</p>
            </div>
            <div class="text-center">
                <button *ngIf="buttons.confirm" (click)="confirm()" class="btn btn-danger m-1">{{buttons.confirm}}</button>
                <button *ngIf="buttons.cancel" (click)="cancel()" class="btn btn-dark m-1">{{buttons.cancel}}</button>
            </div>
        </div>
    </div>
</ng-container>
