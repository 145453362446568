<!-- YT VIDEOS -->
<mat-card>
    <mat-card-body>
        <div class="container p-3" style="background-color:#F4F4F4;">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="fw-bold theme-default-text mb-4">The Philippine Business Regulations Information System (PBRIS)</h6>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/CI6OnJN9V_8?si=gz_bwRyOT3iom5jx&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="col-md-6">
                    <h6 class="fw-bold theme-default-text mb-4">Stakeholder Engagement</h6>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/xOUVKwxKbHw?si=MVgphOYHYF0NawSW&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </mat-card-body>
</mat-card>

<!-- REGULATION SUMMARY -->
<mat-card class="mt-3" *ngIf="userSubtype != 'Government - IT/Admin'">
    <mat-card-body>
        <div class="p-4">
            <div class="row mb-1 ps-2">
                <h6 class="fw-bold report-title theme-default-text">Proposed Regulations</h6>
            </div>

            <div class="mb-2 row row-cols-4 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 g-4">
                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Agency Review</p>
                                    <span *ngIf="counts.AgencyReview || counts.AgencyReview === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ counts.AgencyReview }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg fill="#000000" height="3rem" width="3rem" id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                            <path class="cls-1"
                                                d="M6,10c0-.34729,.05847-.67847,.14203-1h-1.14203v-1h1.55634c.22052-.37823,.49811-.71362,.82593-1h-2.38226v-1h5c.73181,0,1.4093,.21185,2,.55634V2H3V13H7.38226c-.8399-.73376-1.38226-1.79968-1.38226-3Zm-1-6h5v1H5v-1Z" />
                                            <path class="cls-1"
                                                d="M9.98916,7.01086c-.02514,0-.05008,.00031-.07535,.00092-1.65636,.04083-2.96598,1.41669-2.92514,3.07303,.04021,1.6311,1.37528,2.92609,2.99769,2.92609,.02514,0,.05007-.00034,.07535-.00095,.61566-.01517,1.1826-.21597,1.65086-.54587l1.59321,1.52966,.68951-.72437-1.57895-1.51596c.36822-.51218,.58726-1.13727,.57051-1.8165-.04022-1.6311-1.37528-2.92606-2.99769-2.92606h0Zm.01084,4.98914c-1.10278,0-2-.89722-2-2,0-1.10284,.89722-2,2-2,1.10284,0,2,.89716,2,2,0,1.10278-.89716,2-2,2h0Z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Arta Review</p>
                                    <span *ngIf="counts.ARTAReview || counts.ARTAReview === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ counts.ARTAReview }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg width="3rem" height="3rem" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.795 11.272L7.795 16.272C6.79593 16.8964 5.5 16.1782 5.5 15L5.5 5.00002C5.5 3.82186 6.79593 3.1036 7.795 3.72802L15.795 8.72802C16.735 9.31552 16.735 10.6845 15.795 11.272Z"
                                                fill="#000000" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Agency Posting</p>
                                    <span *ngIf="counts.Posting || counts.Posting === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ counts.Posting }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg fill="#000000" width="3rem" height="3rem" viewBox="0 -1.5 35 35" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>upload1</title>
                                            <path
                                                d="M29.426 15.535c0 0 0.649-8.743-7.361-9.74-6.865-0.701-8.955 5.679-8.955 5.679s-2.067-1.988-4.872-0.364c-2.511 1.55-2.067 4.388-2.067 4.388s-5.576 1.084-5.576 6.768c0.124 5.677 6.054 5.734 6.054 5.734h9.351v-6h-3l5-5 5 5h-3v6h8.467c0 0 5.52 0.006 6.295-5.395 0.369-5.906-5.336-7.070-5.336-7.070z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">Total Proposed</p>
                                    <span *ngIf="counts.Total || counts.Total === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ counts.Total }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg width="3rem" height="3rem" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4 19V6.2C4 5.0799 4 4.51984 4.21799 4.09202C4.40973 3.71569 4.71569 3.40973 5.09202 3.21799C5.51984 3 6.0799 3 7.2 3H16.8C17.9201 3 18.4802 3 18.908 3.21799C19.2843 3.40973 19.5903 3.71569 19.782 4.09202C20 4.51984 20 5.0799 20 6.2V17H6C4.89543 17 4 17.8954 4 19ZM4 19C4 20.1046 4.89543 21 6 21H20M9 7H15M9 11H15M19 17V21"
                                                stroke="#000000" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row ps-2 mb-1">
                <h6 class="fw-bold report-title theme-default-text">Existing Regulations</h6>
            </div>
            <div class="mb-2 row row-cols-4 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 g-4">
                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Agency Review</p>
                                    <span *ngIf="countsExisting.AgencyReview || countsExisting.AgencyReview === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ countsExisting.AgencyReview }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg fill="#000000" height="3rem" width="3rem" id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                            <path class="cls-1"
                                                d="M6,10c0-.34729,.05847-.67847,.14203-1h-1.14203v-1h1.55634c.22052-.37823,.49811-.71362,.82593-1h-2.38226v-1h5c.73181,0,1.4093,.21185,2,.55634V2H3V13H7.38226c-.8399-.73376-1.38226-1.79968-1.38226-3Zm-1-6h5v1H5v-1Z" />
                                            <path class="cls-1"
                                                d="M9.98916,7.01086c-.02514,0-.05008,.00031-.07535,.00092-1.65636,.04083-2.96598,1.41669-2.92514,3.07303,.04021,1.6311,1.37528,2.92609,2.99769,2.92609,.02514,0,.05007-.00034,.07535-.00095,.61566-.01517,1.1826-.21597,1.65086-.54587l1.59321,1.52966,.68951-.72437-1.57895-1.51596c.36822-.51218,.58726-1.13727,.57051-1.8165-.04022-1.6311-1.37528-2.92606-2.99769-2.92606h0Zm.01084,4.98914c-1.10278,0-2-.89722-2-2,0-1.10284,.89722-2,2-2,1.10284,0,2,.89716,2,2,0,1.10278-.89716,2-2,2h0Z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Arta Review</p>
                                    <span *ngIf="countsExisting.ARTAReview || countsExisting.ARTAReview === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ countsExisting.ARTAReview }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg width="3rem" height="3rem" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.795 11.272L7.795 16.272C6.79593 16.8964 5.5 16.1782 5.5 15L5.5 5.00002C5.5 3.82186 6.79593 3.1036 7.795 3.72802L15.795 8.72802C16.735 9.31552 16.735 10.6845 15.795 11.272Z"
                                                fill="#000000" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">For Agency Posting</p>
                                    <span *ngIf="countsExisting.Posting || countsExisting.Posting === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ countsExisting.Posting }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg fill="#000000" width="3rem" height="3rem" viewBox="0 -1.5 35 35" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>upload1</title>
                                            <path
                                                d="M29.426 15.535c0 0 0.649-8.743-7.361-9.74-6.865-0.701-8.955 5.679-8.955 5.679s-2.067-1.988-4.872-0.364c-2.511 1.55-2.067 4.388-2.067 4.388s-5.576 1.084-5.576 6.768c0.124 5.677 6.054 5.734 6.054 5.734h9.351v-6h-3l5-5 5 5h-3v6h8.467c0 0 5.52 0.006 6.295-5.395 0.369-5.906-5.336-7.070-5.336-7.070z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card mb-4 w-auto">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <p class="card-title text-capitalize text-muted mb-0">Total Existing</p>
                                    <span *ngIf="countsExisting.Total || countsExisting.Total === 0; else loader" class="h2 font-weight-bold mb-0">
                                        {{ countsExisting.Total }}
                                    </span>
                                </div>

                                <div class="col-auto">
                                    <div>
                                        <svg width="3rem" height="3rem" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4 19V6.2C4 5.0799 4 4.51984 4.21799 4.09202C4.40973 3.71569 4.71569 3.40973 5.09202 3.21799C5.51984 3 6.0799 3 7.2 3H16.8C17.9201 3 18.4802 3 18.908 3.21799C19.2843 3.40973 19.5903 3.71569 19.782 4.09202C20 4.51984 20 5.0799 20 6.2V17H6C4.89543 17 4 17.8954 4 19ZM4 19C4 20.1046 4.89543 21 6 21H20M9 7H15M9 11H15M19 17V21"
                                                stroke="#000000" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #loader>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </ng-template>
    </mat-card-body>
</mat-card>