import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import Swal from 'sweetalert2';
import { GovServiceService } from './gov-service.service';

@Injectable({
	providedIn: 'root',
})
export class generateArtemisReportService{
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private gSService: GovServiceService,
  ){

  }

  checkReport(generationFor:string,bool:boolean,id:string,agencyId:string,reportUrl:any){
    Swal.fire({
      title:"Checking File Report Availability",
      allowOutsideClick:false,
      icon:'info',
      showConfirmButton:false,
      didOpen:()=>{
        Swal.showLoading()
      }
    })
    if(bool){
      const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReportV2/api/v1/checkDocumentExist";
      const reportData ={
        generationFor: generationFor,
        id: id,
        agency: {agencyCode: agencyId}
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any> (apiUrl,reportData,httpOptions)
      .toPromise()
      .then((apiResponse:any)=>{
        if(apiResponse){
          if(apiResponse.exist == true && reportUrl){ //serviceDoc?.verifierCheck?.reportURL
            // window.open(reportUrl)
            // Swal.close()
            // let returnData:any = {
            //   reportURLExist:true
            // }
            // return returnData
            return Swal.fire({
              title:"File Available",
              icon:'success',
              showConfirmButton:true,
              confirmButtonText:"Open File",
              showDenyButton:true,
              denyButtonText:"Generate New File",
              showCancelButton: true,
              allowOutsideClick: false,
              didOpen:()=>{
                Swal.hideLoading()
              }
            })
            .then((swalResult)=>{
              if(swalResult.isConfirmed){
                let returnData:any = {
                  openFile:true
                }
                return returnData
              }
              else if(swalResult.isDenied){
                console.log('generating report')
                let returnData:any = {
                  generateReport:true
                }
                return returnData
              }
              else return false
            })
          }else{
            // alert("No PDF file to check, Generate a report first")
            console.error("No PDF file to check, Generate a report first")
            // return false
            return Swal.fire({
              title:"File Unavailable, Proceed to Generate File Report?",
              icon:'warning',
              showCancelButton: true,
              confirmButtonText:"Generate",
              allowOutsideClick: false,
              didOpen:()=>{
                Swal.hideLoading()
              }
            })
            .then((swalResult)=>{
              if(swalResult.isConfirmed){
                console.log('generating report')
                let returnData:any = {
                  generateReport:true
                }
                return returnData
              }
              else return false
            })
          }
        }else{
          // alert("Checking Report failed")
          console.error("Checking Report failed")
          // return false
          return Swal.fire({
            title:"Error Occured During File Report Availabity Checking",
            icon:'error',
            showCancelButton: true,
            cancelButtonText: "Back",
            showConfirmButton:false,
            allowOutsideClick:false,
            didOpen:()=>{
              Swal.hideLoading()
            }
          })
          .then(()=>false)
        }
      })
      .catch((err)=>{
        console.error("Error checking document: ",err)
        // return false
        return Swal.fire({
          title:"Error Occured During File Report Availabity Checking",
          icon:'error',
          showCancelButton: true,
          cancelButtonText: "Back",
          showConfirmButton:false,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>false)
      })
    }
    else {
      return Swal.fire({
        title:"Unable to Check File Report Availability",
        icon:'error',
        showCancelButton: true,
        cancelButtonText: "Back",
        showConfirmButton:false,
        allowOutsideClick:false,
        didOpen:()=>{
          Swal.hideLoading()
        }
      })
      .then(()=>false)
    }
  }

  fetchDocumentByID(collection:string,id:string){
    return this.afs.collection(collection).doc(id).get().toPromise()
    .then((response)=>{
      if(response?.exists){
        let item: any = response?.data()
        item.id = response.id
        return item
      } else {
        console.error('Error: document missing')
        return false
      }
    })
    .catch((err)=>{
      console.error('Error: unabled to fetch document: ',err)
      return false
    })
  }

  generateReportWOData(generationFor:string,bool:boolean,id:string,agencyId:string,documentIDToSearch:string){
    Swal.fire({
      title:"Generating File Report",
      allowOutsideClick:false,
      icon:'info',
      showConfirmButton:false,
      didOpen:()=>{
        Swal.showLoading()
      }
    })
    let collection:any = null
    switch(generationFor){
      case 'ccAgencyReports':{
        collection = 'citizens-charter-phase-02'
        break;
      }
      case 'serviceReport': case 'verifierCheckServiceReport':{
        collection = 'Services Phase 02'
        break;
      }
      // case 'verifierCheckServiceReport':{
      //   collection = 'Worklist-Citizens Charter'
      //   break;
      // }
      default:{
        console.error("Missing Generation Code")
        return Swal.fire({
          title:"Unable to Generate File Report",
          icon:'error',
          showCloseButton: true,
          cancelButtonText: "Back",
          showConfirmButton:false,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>false)
      }
    }
    return this.fetchDocumentByID(collection,documentIDToSearch)
    .then((response)=>{
      if(response) {
        // console.log('passed data: ',response)
        let data = null
        switch(generationFor){
          case 'ccAgencyReports':{
            data = response
            break;
          }
          case 'serviceReport': case 'verifierCheckServiceReport':{
            data = this.gSService.preProcessServiceData(response)
            break;
          }
          default:{
            console.error("Missing Generation Code")
            return Swal.fire({
              title:"Unable to Generate File Report",
              icon:'error',
              showCloseButton: true,
              cancelButtonText: "Back",
              showConfirmButton:false,
              allowOutsideClick:false,
              didOpen:()=>{
                Swal.hideLoading()
              }
            })
            .then(()=>false)
          }
        }
        console.log('formatted data: ',data)
        // return false
        return this.generateReport(generationFor,bool,id,agencyId,data)
      }
      else {
        console.error('missing document: ')
        return Swal.fire({
          title:"Unable to Generate File",
          icon:'error',
          showCancelButton: true,
          cancelButtonText: "Back",
          showConfirmButton:false,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>false)
      }
    })
    .catch((err)=>{
      console.error('failed to fetch document: ',err)
      return Swal.fire({
        title:"Unable to Generate File",
        icon:'error',
        showCancelButton: true,
        cancelButtonText: "Back",
        showConfirmButton:false,
        allowOutsideClick:false,
        didOpen:()=>{
          Swal.hideLoading()
        }
      })
      .then(()=>false)
    })
  }

  generateReportWData(generationFor:string,bool:boolean,id:string,agencyId:string,data:any){
    Swal.fire({
      title:"Generating File Report",
      allowOutsideClick:false,
      icon:'info',
      showConfirmButton:false,
      didOpen:()=>{
        Swal.showLoading()
      }
    })
    return this.generateReport(generationFor,bool,id,agencyId,data)
  }
  
  generateReport(generationFor:string,bool:boolean,id:string,agencyId:string,data:any){
    console.log({generationFor})
    console.log({bool})
    console.log({id})
    console.log({agencyId})
    console.log({data})
    if(bool){
      const apiUrl = "https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReportV2/api/v1/generateDoc"
      let reportData:any = {
        generationFor: generationFor,
        id: id,
        agency: {agencyCode: agencyId},
      }

      switch(generationFor){
        case 'ccAgencyReports': {
          // TODO: map fetch data w/ required values for document generation
          reportData.fullEditionStr = data.fullEditionStr
          reportData.agency = data.agency
          reportData.cartDetails = data.cartDetails
          reportData.services = data.services
          reportData.hOA = data.hOA
          reportData.cP = data.cP
          reportData.vCP = data.vCP
          reportData.memb = data.memb
          break;
        };
        case 'serviceReport': case 'verifierCheckServiceReport': {
          reportData.service = data
          break;
        };
        default: {
          console.error("Missing Generation Code")
          return Swal.fire({
            title:"Unable to Generate File Report",
            icon:'error',
            showCloseButton: true,
            cancelButtonText: "Back",
            showConfirmButton:false,
            allowOutsideClick:false,
            didOpen:()=>{
              Swal.hideLoading()
            }
          })
          .then(()=>false)
        }
      }
    
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      return this.http.post<any> (apiUrl,reportData,httpOptions).toPromise()
      .then((apiResponse:any)=>{
        if(apiResponse && apiResponse.processDone && apiResponse.result){
          // this.signUrl(ccDoc)
          return this.toGetDownloadURL(generationFor, id, agencyId, reportData)
        }
        else{
          console.error('generating pdf failed')
          return Swal.fire({
            title:"Generating File Failed",
            icon:'error',
            showCancelButton: true,
            cancelButtonText: "Back",
            showConfirmButton:false,
            allowOutsideClick:false,
            didOpen:()=>{
              Swal.hideLoading()
            }
          })
          .then(()=>false)
        }
      })
      .catch((err)=>{
        console.error("Error generating file ",err)
        // return false
        return Swal.fire({
          title:"Error Occured While Generating File",
          icon:'error',
          showCancelButton: true,
          cancelButtonText: "Back",
          showConfirmButton:false,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>false)
      })
    }
    else{
      // alert("Invalid Charter or Agency")
      // return false
      return Swal.fire({
        title:"Unable to Generate File Report",
        icon:'error',
        showCancelButton: true,
        cancelButtonText: "Back",
        showConfirmButton:false,
        allowOutsideClick:false,
        didOpen:()=>{
          Swal.hideLoading()
        }
      })
      .then(()=>false)
    }
  }

  // finish this
  private async toGetDownloadURL(generationFor:string,id:string,agencyId:string,data:any){
    // console.log('toGetDownloadURL')
    // const publicBucket = 'artemis_services_public_documents'//functions.config().storagebucket.publicreportsurl;
    // const publicStorage = this.store.storage.app.storage(publicBucket)
    
    let bucketName:any = null//'artemis_services_documents'//functions.config().storagebucket.publicreportsurl;
    let storagePath:any = null
    let fileName:any = null
    switch(generationFor){
      case 'ccAgencyReports':{
        fileName = data.id+"_agency_cc.pdf",
        storagePath = "reports/agency/"+agencyId+"/citizenscharter/"+id+"/"
        bucketName = 'artemis_services_documents'
        break;
      }
      case 'serviceReport':{
        fileName = data.id+"_service.pdf",
        storagePath = "reports/agency/"+agencyId+"/service/"+id+"/"
        bucketName = 'artemis_services_documents'
        break;
      }
      case 'verifierCheckServiceReport':{
        fileName = data.id+"_service.pdf",
        storagePath = "reports/agency/"+agencyId+"/worklist_check_service/"+id+"/"
        bucketName = 'artemis_services_documents'
        break;
      }
    }
    const storage = this.store.storage.app.storage(bucketName)
    const filePath = storagePath+fileName
    return storage.ref(filePath).getDownloadURL()
    .then((url:any)=>{
      //console.log('URL: ',url)
      if(url){
        this.updateFirebaseReportLink(generationFor,id,url);
        // alert("Document Generation Successful")
        window.open(url)
        return Swal.fire({
          title:"Successfully Generated File",
          icon:'success',
          showConfirmButton:true,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>url)
      }
      else{
        console.error('Failed to generate URL')
        return Swal.fire({
          title:"Failed to generate URL, Try Again",
          icon:'error',
          showCancelButton: true,
          cancelButtonText: "Back",
          showConfirmButton:false,
          allowOutsideClick:false,
          didOpen:()=>{
            Swal.hideLoading()
          }
        })
        .then(()=>false)
      }
    })
    .catch((err)=>{
      console.error('Failed to generate URL: ',err)
      // alert('Failed to generate URL, try again')
      return Swal.fire({
        title:"Failed to generate URL, Try Again",
        icon:'error',
        showCancelButton: true,
        cancelButtonText: "Back",
        showConfirmButton:false,
        allowOutsideClick:false,
        didOpen:()=>{
          Swal.hideLoading()
        }
      })
      .then(()=>false)
    })
  }

  updateFirebaseReportLink(generationFor:string,id:string,link:any){
    let collection:any = null
    switch(generationFor){
      case 'ccAgencyReports':{
        collection = 'citizens-charter-phase-02'
        break;
      }
      case 'serviceReport':{
        collection = 'Services Phase 02'
        break;
      }
      case 'verifierCheckServiceReport':{
        collection = 'Worklist-Citizens Charter'
        break;
      }
    }

    if(collection){
      this.afs.collection(collection).doc(id).update({reportURL:link})
      .then(()=>{
        console.log('Update Successful')
      })
      .catch(err=>{
        console.error("Error updating: ",err)
      })
    } else console.error("Error updating: No Update Values")
  }
}