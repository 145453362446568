import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pbris-forgot-password',
  templateUrl: './pbris-forgot-password.component.html',
  styleUrls: ['./pbris-forgot-password.component.scss']
})

export class PbrisForgotPasswordComponent implements OnInit {
  form = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
  });

  redirectUrlMap: any = {
    artemis: environment.verificationRedirectUrls.artemis,
    pbris: environment.verificationRedirectUrls.pbris,
  }
  constructor(private router: Router, public auth: AngularFireAuth, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  async sendPasswordResetEmail() {
    this.route.queryParams.subscribe((params: any) => {
      let passwordResetObj;
      if (params.requestFrom && this.redirectUrlMap[params.requestFrom]) {
        passwordResetObj = {
          url: this.redirectUrlMap[params.requestFrom]
        }
      }

      this.auth.sendPasswordResetEmail(this.form.value.username, passwordResetObj).then(() => {
        this.router.navigate(["/pbris/login"]).then(() => {
          alert("We have received your request. Kindly check your email and follow the instructions we have sent to reset your password.\n\n(If you have not received this email, please check your spam folder.)")
        })
      }).catch(error => {
        alert(error)
      });
    });
  }

  loginAsGuest() {
    this.router.navigate(['/pbris']);
  }

}
