import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { filter, firstValueFrom } from 'rxjs';
import { WorklistArtaViewGroupedStatusPBRISRegulation, WorklistStatusPBRISRegulation } from '../entities/worklist';

@Injectable({
  providedIn: 'root',
})
export class PbrisDashboardService {
  constructor(private afs: AngularFirestore) { }

  /**
   * Get counts for all statuses in one function, fetching only the "status" field.
   * @param collectionName Name of the collection to query.
   * @returns Promise<{ [key: string]: number }> An object with counts for each status and total count.
   */
  async getAllCounts(collectionName: string): Promise<{ [key: string]: number }> {

    const counts: { [key: string]: number } = {
      Draft: 0,
      Verification: 0,
      Revision: 0,
      AgencyReview: 0,
      ARTAReview: 0,
      Approval: 0,
      Posting: 0,
      Total: 0, // Count for total regulations
    };

    // Fetch only the documents and iterate through their data
    const snapshot = await firstValueFrom(
      this.afs.collection(collectionName, filter => filter.where('status', 'in', WorklistArtaViewGroupedStatusPBRISRegulation.ARTA)).get()
    );

    snapshot.docs.forEach(doc => {
      const data = doc.data() as { status?: WorklistStatusPBRISRegulation };

      const status = data.status; // Get the 'status' field
      counts.Total++; // Increment total count

      switch (status) {
        case WorklistStatusPBRISRegulation.DRAFT:
          counts.Draft++;
          break;

        case WorklistStatusPBRISRegulation.FOR_VERIFICATION:
          counts.Verification++;
          break;

        case WorklistStatusPBRISRegulation.FOR_APPROVAL:
        case WorklistStatusPBRISRegulation.FOR_REAPPROVAL:
          counts.Approval++;
          break;

        case WorklistStatusPBRISRegulation.FOR_RIA:
        case WorklistStatusPBRISRegulation.DRIS_FOR_REVISION:
        case WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION:
        case WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL:
        case WorklistStatusPBRISRegulation.SUBMITTED_CRIS:
        case WorklistStatusPBRISRegulation.DRAFT_CRIS:
        case WorklistStatusPBRISRegulation.DRAFT_DRIS:
          counts.AgencyReview++;
          break;

        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT:
          counts.ARTAReview++;
          break;

        case WorklistStatusPBRISRegulation.RESUBMITTED:
        case WorklistStatusPBRISRegulation.FOR_REVISION:
        case WorklistStatusPBRISRegulation.FOR_MODIFICATION:
          counts.Consolidation++;
          break;

        case WorklistStatusPBRISRegulation.FOR_POSTING:
          counts.Posting++;
          break;
      }
    });

    return counts;
  }
}
