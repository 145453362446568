import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { combineLatest, firstValueFrom } from 'rxjs';
import { CollectionName } from 'src/app/entities/constant';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { format } from 'date-fns';

import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-pbris-view-agency-arp',
  templateUrl: './pbris-view-agency-arp.component.html',
  styleUrls: ['./pbris-view-agency-arp.component.scss']
})
export class PbrisViewAgencyArpComponent implements OnInit {
  
  agencyRegulationsDataSource: MatTableDataSource<any>;
  regulationsMap: Map<string, any> = new Map();
  referenceMap: Map<string, any> = new Map();
  
  @Input('agency_doc') doc_id: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  columns = ['regulations']
  selectedYear: string = '';
  years: string[] = [];

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadRegulations()
  }

  loadRegulations() {
    let batchFetch: any = []
    // batchFetch.push(this.afs.collection(CollectionName.REGULATION_PROPOSED_PHASE_2, filter => this.filterFunc(CollectionName.REGULATION_PROPOSED_PHASE_2, filter, '==')).snapshotChanges())
    batchFetch.push(this.afs.collection(CollectionName.REGULATION_PROPOSED_PHASE_2).snapshotChanges())

    combineLatest(batchFetch)
      .subscribe({
        next: (res: any) => {
          const yearsSet = new Set<string>();
          Promise.all(
            res.map((res2: any) => {
              return Promise.all(
                res2.map(async (res3: any) => {
                  let item: any = res3.payload.doc.data()
                  item.id = res3.payload.doc.id

                  // Fetch the corresponding dateGenerated using documentId
                  const dateGeneratedQuery: any = await this.afs
                    .collection(CollectionName.WORKLIST_PROPOSED_REGULATIION, ref =>
                      ref.where('documentId', '==', item.id)
                    )
                    .get()
                    .toPromise();

                  if (!dateGeneratedQuery.empty) {
                    const dateGeneratedData = dateGeneratedQuery.docs[0].data(); // Assuming only one match
                    item.dateGenerated = format(dateGeneratedData.dateGenerated.toDate(), 'MM/dd/yyyy');

                    const year = dateGeneratedData.dateGenerated.toDate().getFullYear().toString();
                    item.year = year; // Store the year for easy access
                    yearsSet.add(year);
                  } else {
                    item.dateGenerated = null; // If no dateGenerated is found
                  }

                  if (!this.regulationsMap.has(item.id)) this.regulationsMap.set(item.id, item)


                  console.log('regulations: ', item)
                  return item
                })
              )
            })
          )
            .then(() => {
              this.refreshTable();
              this.years = Array.from(yearsSet).sort(); // Extract and sort unique years
              this.selectedYear = this.years[0] || ''; // Default to first year or empty if none
              this.filterByYear(this.selectedYear); // Initially filter by the first available year
            })
        },
        error: (err) => console.error('Error: ', err)
      })
  }


  filterFunc(controlCode: any, filter: Query, additionalOptions?: any) {
    switch (controlCode) {
      case CollectionName.REGULATION_PROPOSED_PHASE_2: {
        filter = filter.where('part_of_reg_plan', '==', true)
        break;
      }
    }
    return filter
  }

  search(event: Event) {
    let searchValue = (event.target as HTMLInputElement).value
    this.agencyRegulationsDataSource.filter = searchValue.trim().toLowerCase()
  }

  resetSandP() {
    this.agencyRegulationsDataSource.paginator = this.paginator
    this.agencyRegulationsDataSource.sort = this.sort
    this.settingFilters()
  }

  settingFilters() {
    this.agencyRegulationsDataSource.filterPredicate = (option: any, searchValue: string): boolean => {
      if (searchValue) {
        return (option.title)?.trim().toLowerCase().includes(searchValue)
          || (option.subject)?.trim().toLowerCase().includes(searchValue)
      } else return false
    }
  }

  refreshList() {
    this.searchForm.reset()
    this.refreshTable()
  }

  refreshTable() {
    console.log('refreshTable')
    this.agencyRegulationsDataSource = new MatTableDataSource<any>(Array.from(this.regulationsMap.values()))
    console.log(this.regulationsMap);
    this.resetSandP()
  }

  goToRegulation(id: any) {
    // this.router.navigate(['/artemis/view/detail',id])
    this.router.navigate(['/pbris/regulations/view/existing', id])
  }

  filterByYear(year: string) {
    console.log("filtered by year");
    this.selectedYear = year;

    // Set the custom filter predicate for filtering by year
    this.agencyRegulationsDataSource.filterPredicate = (data: any, filter: string) => {
      return data.year === filter;
    };

    // Set the filter value to the selected year
    this.agencyRegulationsDataSource.filter = year;
  }
}
