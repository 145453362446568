import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfRenderService } from '../../../../../pdf-render.service';
import { Input, Output, EventEmitter } from '@angular/core';

import { take, tap, map, first } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GovernmentAccountSubtype,
  WorklistActionFlagPBRISRegulation,
  WorklistAgencyActionGroupedStatusPBRISRegulation,
  WorklistAgencyActionMappedStatusPBRISRegulation,
  WorklistArtaActionMappedStatusPBRISRegulation,
  WorklistStatusPBRISRegulation
} from 'src/app/entities/worklist';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, forkJoin, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { RegulationViewConfirmationModalComponent } from '../regulation-view-confirmation-modal/regulation-view-confirmation-modal.component';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { Timestamp } from 'firebase/firestore';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PBRISEmailer } from 'src/app/service/pbris-emailer.service';
import { combineLatest } from 'rxjs';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ExistingCommentModalComponent } from 'src/app/pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/existing-comment-modal/existing-comment-modal.component';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';
import { da } from 'date-fns/locale';
import { RegulationCommentsComponent } from '../regulation-comments/regulation-comments.component';

@Component({
  selector: 'app-regulation-view-existing',
  templateUrl: './regulation-view-existing.component.html',
  styleUrls: ['./regulation-view-existing.component.scss'],
})
export class RegulationViewExistingComponent implements OnInit {
  /**
   * levels of access
   */

  // id = this.route.snapshot.params.id
  isAdmin: boolean = false;
  isOwner: boolean = false;
  isEncoder: boolean = false;

  // status: string = "non-specific";
  // regulation: any = "";
  file: any;

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem('user_type');

  /*Output and Input for Inbox View */
  @Output() toInboxComponent = new EventEmitter<boolean>();
  @Input() paramFromInboxList = '';
  @Input() docuFromInboxList = '';

  // regulations-existing-phase-02 or regulations-proposed-phase-02
  @Input() regulationInboxCollectionName: string = '';
  // Worklist-Existing Regulations or Worklist-Proposed Regulations
  @Input() regulationInboxWorklistCollectionName: string = '';

  /** */
  isSidebarVisible: boolean = true;

  /** page elements */
  reg_long_name: any;
  reg_short_name: any;
  reg_date: any;

  reg_section_name: any;
  reg_subject: any;

  current_regulation: any;
  current_instrument: any;

  //document Preview Details
  reg_documentTitle: any;
  reg_dateGenerated: any;
  reg_dueDate: any;
  reg_fromAgency: any;
  reg_fromOfficer: any;
  reg_status: any;
  reg_text: any;
  reg_display_title: string = '';
  reg_display_text: string = '';
  reg_docTite: string = '';
  reg_docId: string = '';
  reg_significance: any = '';
  reg_comment_details: any = '';
  reg_comment_regtext: any = '';
  selected_section: any;
  selected_subsection: any;
  rulestage: any;

  // Policy Details:

  // agency details
  reg_agency: any = [];
  reg_agency_name: any;
  agency_id: any;

  //others
  reg_parent_instrument_id: any;
  reg_instrument_name: any;

  // get firebase_folder of regulation info
  private firebase_folder: string = '';

  enum_regulation_status = WorklistStatusPBRISRegulation;

  phase2View: boolean = false;
  textRegView: boolean = true;
  piaView: boolean = false;
  commentsView: boolean = false;

  enum_action_flag = WorklistActionFlagPBRISRegulation;

  actionButtonAllowed: boolean = false;
  actionMade: any;
  actionFlag: any;

  //user info
  currenUser: any;

  loadedData: any = {

  }

  private worklistAllowedActionButtonFilters = new Map<string, string[]>([
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_ENC,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_VER,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_DIR,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_ADMIN,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_ADMIN,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_PO,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_DC,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_DIR,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_PO,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_DC,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_DIR,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_DDG,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_DG,

  ]);
  actionFilter: any = []

  commentButtonsEnabled: boolean = false;

  reg_text_annex_file: any;

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private pdf_render: PdfRenderService,
    private router: Router,
    public dialog: MatDialog,
    public auth: AngularFireAuth,
    private store: AngularFireStorage,
    public worklistService: WorklistService,
    private nE: NotificationsEmailer,
    private pEmail: PBRISEmailer,
  ) { }

  ngOnInit(): void {
    this.setTheme();
    this.isEncoder = sessionStorage.getItem('account_subtype') === GovernmentAccountSubtype.AGENCY_ENC;
    if (sessionStorage.getItem('arta_admin') == 'true') {
      this.isAdmin = true;
    }
    // this.loadStatus(this.status)
    this.loadInfo(this.paramFromInboxList);
    this.loadWorkListInformation(this.docuFromInboxList);

    this.auth.user.subscribe(data => {
      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
        .subscribe({
          next: (res) => {
            let user: any = res.payload.data()
            this.currenUser = user
            console.log('user: ', user)
          }
        })
    });
  }

  // loadStatus(getStatus: string) {
  //   this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
  // }
  setActionFilter(status: any) {
    this.actionFilter = this.worklistAllowedActionButtonFilters.get(
      sessionStorage.getItem('account_subtype') || ''
    );
    this.actionButtonAllowed = (this.actionFilter?.includes(status));

    var lowest_level_users = [
      GovernmentAccountSubtype.AGENCY_ENC.valueOf()
    ];
    this.commentButtonsEnabled = this.actionButtonAllowed && (lowest_level_users.indexOf(sessionStorage.getItem('account_subtype') || '') < 0);
  }

  showOnInboxViewport() {
    this.toInboxComponent.emit();
  }

  async setTheme() {
    switch (sessionStorage.getItem('user_type')) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  editRegulation() {
    this.router.navigate(
      ['/pbris/upload-beta/existing/' + this.paramFromInboxList],
      { queryParams: { worklistId: this.docuFromInboxList } }
    );
  }

  goToComments(type: string, sectionId?: number, subsectionId?: number) {
    let queryParams: any = { worklistId: this.docuFromInboxList, type: type };

    if (sectionId != null) {
      queryParams.sectionId = sectionId;
    }

    if (subsectionId != null) {
      queryParams.subsectionId = subsectionId;
    }

    this.router.navigate(
      ['/pbris/upload-beta/existing/comment/' + this.paramFromInboxList],
      { queryParams: queryParams }
    );
  }

  toggleSidebar() {
    if (this.isSidebarVisible) {
      this.isSidebarVisible = false;
    } else {
      this.isSidebarVisible = true;
    }
  }

  async loadWorkListInformation(id: any) {
    console.log('regulationInboxWorklistCollectionName', this.regulationInboxWorklistCollectionName)
    const regRef = this.afs
      .collection(this.regulationInboxWorklistCollectionName)
      .doc(id);
    regRef
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          let worklist_info_json = data.payload.data();

          if (worklist_info_json.documentTitle) {
            this.reg_documentTitle = worklist_info_json.documentTitle;
          }

          if (worklist_info_json.status) {
            this.reg_status = worklist_info_json.status;
            console.log('status', worklist_info_json.status)
            this.setActionFilter(this.reg_status)
            this.getRuleMakingStage(this.reg_status)
          }

          if (worklist_info_json.fromAgency) {
            this.reg_fromAgency = worklist_info_json.fromAgency;
            console.log('agency', worklist_info_json.fromAgency)
          }

          if (worklist_info_json.fromOfficer) {
            this.reg_fromOfficer = worklist_info_json.fromOfficer;
          }

          if (worklist_info_json.dateGenerated) {
            this.reg_dateGenerated = worklist_info_json.dateGenerated;
          }

          if (worklist_info_json.dueDate) {
            this.reg_dueDate = worklist_info_json.dueDate;
          }

          if (worklist_info_json.documentId) {
            this.reg_docId = worklist_info_json.documentId;
            this.loadInfo(this.reg_docId)
            // this.loadExistingRegulation(this.reg_docId)
          }

          if (worklist_info_json.commentDetails) {
            this.reg_comment_details = worklist_info_json.commentDetails;
          }

          if (worklist_info_json.commentRegText) {
            this.reg_comment_regtext = worklist_info_json.commentRegText;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  getRuleMakingStage(status: any) {
    this.afs.collection('Regulations_Classification').ref
      .where('status', '==', status).get()
      .then(info => {
        if (info.size > 0) {
          let tempArr: any = []
          info.forEach(data => {
            let item: any = data.data()
            item.id = data.id
            tempArr.push(item)
          })
          this.rulestage = tempArr[0]
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  // loadExistingRegulation(id:any){
  //   this.afs.collection('regulations-existing-phase-02').doc(id).snapshotChanges()
  //   .subscribe(info=>{
  //     if(info.payload.exists){
  //       let regulation:any = info.payload.data()
  //       regulation.id = info.payload.id
  //       this.current_regulation = regulation
  //       // console.log("regulation",regulation)
  //       // if(regulation.reg_significance){
  //       //   this.reg_significance = regulation.reg_significance
  //       // }
  //       if (regulation.reg_significance) {
  //         this.reg_significance = {
  //           significance: regulation.reg_significance,
  //           comments: regulation.reg_significance_comments,
  //         };
  //       }

  //       if(regulation.reg_instrument){
  //         this.loadInstrument(regulation.reg_instrument)
  //       }
  //     }
  //   })
  // }

  // loadInstrument(id:any){
  //   this.afs.collection('Instrument').doc(id).snapshotChanges()
  //   .subscribe(info=>{
  //     if(info.payload.exists){
  //       let instrument:any = info.payload.data()
  //       instrument.id = info.payload.id
  //       this.current_instrument = instrument
  //       // console.log("instrument",instrument)
  //     }
  //   })
  // }

  async loadInfo(id: any) {
    // this.afs.doc(`regulations-phase-02/${id}`).get().subscribe(
    const pageRef = this.afs
      .collection(this.regulationInboxCollectionName)
      .doc(id);
    // const agencyRef = this.afs.collection(`Agency`);
    /**
     * Fields
     */

    pageRef
      .snapshotChanges()
      .pipe(
        tap(async (data: any) => {
          // this.regulation = data.data();
          let page_info_json = data.payload.data();
          page_info_json.id = data.payload.id;
          console.log('existing regulation: ', page_info_json)
          if (page_info_json.title) {
            this.reg_long_name = page_info_json.title;
          }

          if (page_info_json.doc_date) {
            this.reg_date = page_info_json.doc_date;
          }

          if (page_info_json.section_name) {
            this.reg_section_name = page_info_json.section_name;
          }

          if (page_info_json.subject) {
            this.reg_subject = page_info_json.subject;
          }

          // if(page_info_json.classification){
          //   if(page_info_json == 'Business'){
          //     if (page_info_json.reg_sector) {
          //       const sector:any = await this.dataLoader('Sector','Sector',page_info_json.reg_sector)
          //       if(sector) page_info_json.reg_tag_sector = sector.name
          //       else console.error('no sector retrieved')
          //     }
          //     if(page_info_json.reg_business){
          //       const sob:any = await this.dataLoader('StageOfBusiness','Stage of Business',page_info_json.reg_business)
          //       if(sob) page_info_json.reg_tag_stage_of_business = sob.name
          //       else console.error('no sob retrieved')
          //     }
          //   }
          //   else if(page_info_json == 'Non-Business'){
          //     if (page_info_json.reg_stageoflife) {
          //       const sol:any = await this.dataLoader('StageOfLife','Stage of Life',page_info_json.reg_stageoflife)
          //       if(sol) page_info_json.reg_tag_stage_of_life = sol.name
          //       else console.error('no sol retrieved')
          //     }
          //     if (page_info_json.reg_lifeevent) {
          //       const le:any = await this.dataLoader('LifeEvent','Life Event',page_info_json.reg_lifeevent)
          //       if(le) page_info_json.reg_tag_life_event = le.name
          //       else console.error('no life event retrieved')
          //     }
          //   }
          // }

          // if (page_info_json.reg_division) {
          //   const division:any = await this.dataLoader('Division','Division',page_info_json.reg_division)
          //   if(division) page_info_json.reg_tag_division = division.name
          //   else console.error('no division retrieved')
          // }

          // if (page_info_json.reg_juris) {
          //   const jurisdiction:any = await this.dataLoader('Jurisdiction','Jurisdiction',page_info_json.reg_juris)
          //   if(jurisdiction) page_info_json.reg_tag_jurisdiction = jurisdiction.name
          //   else console.error('no jurisdiction retrieved')
          // }

          // if (page_info_json.reg_case) {
          //   const caseUse:any = await this.dataLoader('CaseUse','Case Use',page_info_json.reg_case)
          //   if(caseUse) page_info_json.reg_tag_case_use = caseUse.name
          //   else console.error('no caseUse retrieved')
          // }

          if (page_info_json.reg_instrument) {
            this.reg_parent_instrument_id = page_info_json.reg_instrument;
            // const instrumentRef = this.afs
            //   .collection(`Instrument`)
            //   .doc(this.reg_parent_instrument_id);
            // instrumentRef.snapshotChanges().subscribe((data: any) => {
            //   let instrumentRef_info_json = data.payload.data();
            //   this.reg_instrument_name = instrumentRef_info_json.name;
            // });


            const instrument: any = await this.dataLoader('Instrument', 'Instrument', page_info_json.reg_instrument)
            if (instrument) {
              this.current_instrument = instrument
              this.reg_instrument_name = instrument.name
            } else console.error('no instrument retrieved')
          }

          // if (page_info_json.reg_agency && page_info_json.reg_agency.length > 0 ) {
          //   this.reg_agency = [];
          //   // Because reg_agency can be single input or multiple input, handle as if it were a strong or an object (array)
          //   if(typeof page_info_json.reg_agency === 'string') {
          //     const agencyRef = this.afs.collection(`Agency`).doc(page_info_json.reg_agency);
          //     agencyRef.snapshotChanges().subscribe((data: any) => {
          //       let agencyRef_info_json = data.payload.data();
          //       agencyRef_info_json.reg_agency_name = agencyRef_info_json.name;
          //       this.reg_agency.push(agencyRef_info_json);
          //     });
          //   }
          //   if(typeof page_info_json.reg_agency === 'object') {
          //     for (let agency of page_info_json.reg_agency) {
          //       const agencyRef = this.afs.collection(`Agency`).doc(agency);
          //       agencyRef.snapshotChanges().subscribe((data: any) => {
          //         let agencyRef_info_json = data.payload.data();
          //         agencyRef_info_json.reg_agency_name = agencyRef_info_json.name;
          //         this.reg_agency.push(agencyRef_info_json);
          //       });
          //     }
          //     // console.log(' Agency Here: ' + this.reg_agency);
          //   }
          // }

          if (page_info_json.reg_agency) {
            if (Array.isArray(page_info_json.reg_agency)) {
              const agency_names: any = await this.multiDataLoader('Agency', 'Agency', page_info_json.reg_agency)
              if (agency_names && agency_names.length > 0) this.reg_agency = agency_names
              else {
                console.warn('Error: no agency name')
                this.reg_agency = []
              }
            }
            else {
              let temp_agency_list: any = []
              temp_agency_list.push(page_info_json.reg_agency)
              const agency_names: any = await this.multiDataLoader('Agency', 'Agency', temp_agency_list)
              if (agency_names && agency_names.length > 0) this.reg_agency = agency_names
              else {
                console.warn('Error: no agency name')
                this.reg_agency = []
              }
            }
          }

          if (page_info_json.regulation_text) {
            console.log('regulation_text: ', page_info_json.regulation_text)
            this.reg_text = page_info_json.regulation_text;
            if (this.reg_text[0]) {
              this.selected_section = this.reg_text[0];
              if (this.selected_section.subsections && this.selected_section.subsections[0]) {
                this.selected_subsection = this.selected_section.subsections[0];
              }
            }
          }

          if (page_info_json.firebase_folder) {
            this.firebase_folder = page_info_json.firebase_folder;
          }

          if (page_info_json.reg_significance) {
            this.reg_significance = {
              significance: page_info_json.reg_significance,
              comments: page_info_json.reg_significance_comments,
            };
          }

          if (page_info_json.has_annex_files) {

            let reg_text_annex_file: any = {
              has_annex_files: page_info_json.has_annex_files,
            }

            if (page_info_json.regulation_text_annex_file_folder && page_info_json.regulation_text_annex_file_name) {
              reg_text_annex_file.regulation_text_annex_file_folder = page_info_json.regulation_text_annex_file_folder
              reg_text_annex_file.regulation_text_annex_file_name = page_info_json.regulation_text_annex_file_name
              let tokenized = reg_text_annex_file.regulation_text_annex_file_name.split(".")
              if (tokenized && tokenized.length > 0 && tokenized[tokenized.length - 1] == 'zip') {
                reg_text_annex_file.annex_file_type = 'zip';
                this.store.ref(`${reg_text_annex_file.regulation_text_annex_file_name}/${reg_text_annex_file.regulation_text_annex_file_name}`).getDownloadURL()
                  .subscribe((url) => {
                    reg_text_annex_file.annex_file_download_link = url
                    this.reg_text_annex_file = reg_text_annex_file
                  })
              } else {
                const path = `${reg_text_annex_file.regulation_text_annex_file_folder}/${reg_text_annex_file.regulation_text_annex_file_name}`;

                firstValueFrom(this.pdf_render.getFileUrl(path)).then((data: any) => {
                  reg_text_annex_file.annex_file = data;
                  reg_text_annex_file.annex_file_page = 1;
                  this.reg_text_annex_file = reg_text_annex_file
                });
              }

              // this.regulationsData[code].data.reg_text_annex_file = reg_text_annex_file
            }
            else {
              this.reg_text_annex_file = reg_text_annex_file
            }
          }

          // if(page_info_json.reg_agency){
          //   this.reg_agency_id = page_info_json.reg_agency;
          //   const agencyRef =this.afs.collection(`Agency`).doc(this.reg_agency_id);
          //   agencyRef.snapshotChanges().subscribe((data: any) => {
          //     let agency_info_json = data.payload.data();
          //     this.reg_agency_name = agency_info_json.name;
          //   });
          // }
          this.current_regulation = page_info_json
        }),
        take(1)
      )
      .subscribe();
  }

  async multiDataLoader(code: string, collection: string, idArr: any): Promise<any> {
    // console.log('multiDataLoader ids: ',idArr)
    if (code && collection && idArr && idArr.length > 0) {
      if (this.loadedData[code]) {
        return this.multiDataLoader2(code, collection, idArr)
      }

      else {
        this.loadedData[code] = []
        return this.multiDataLoader(code, collection, idArr)
      }
    } else return false
  }

  async multiDataLoader2(code: string, collection: string, idArr: Array<string>) {
    return Promise.all(
      idArr.map(async (id: string) => this.asyncMapLoader(code, collection, idArr, id))
    )
      .then((data) => {
        if (data && data.length > 0) return data.map((agency: any) => agency.agencyDetails && agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name ? agency.name : '-- Not found')
        else return ['-- Not found']
      })
      .catch((err) => {
        console.error('Error: ', err)
        return false
      })
  }

  asyncMapLoader(code: string, collection: string, idArr: Array<any>, id: string) {
    const sureList = this.loadedData[code].filter((agency: any) => idArr.includes(agency.id))
    if (sureList.length > 0) return sureList[0]
    else return this.dataLoader2(code, collection, id)
  }

  async dataLoader(code: string, collection: string, id: any): Promise<any> {
    // code is used for accessing loadedData object
    // check if code, collection, and id are valid
    if (code && collection && id) {

      // check if loadedData.Object has a value
      if (this.loadedData[code]) {

        // check if there loadedData.Object array contains an element
        if (this.loadedData[code].length > 0) {

          // look for id wihtin the array and return the element
          const ctr = this.loadedData[code].indexOf(id)
          if (ctr >= 0) {
            return this.loadedData[code][ctr]
          }

          // if no element with that id get it from firebase
          else return await this.dataLoader2(code, collection, id)
        }

        // if loadedData.Object array doesnt contain an element get value from firebase
        else return await this.dataLoader2(code, collection, id)
      }

      // if loadedData.Object has no value, initialized an array there
      else {
        this.loadedData[code] = []
        return await this.dataLoader(code, collection, id)
      }
    } else return false
  }

  async dataLoader2(code: string, collection: string, id: any): Promise<any> {

    // get data from firebase
    const item = await this.loadData(collection, id)

    // if data exist add it to loaded.Object array
    if (item) {
      if (this.loadedData[code].map((val: any) => val.id).indexOf(id) == -1) this.loadedData[code].push(item)
      return item
    }

    // if data doesnt exist return false
    else return false
  }

  loadData(collection: any, value: any) {
    // get data from firebase if no data was retrieved return false
    return new Promise((res, rej) => {
      this.afs.collection(collection).doc(value).snapshotChanges()
        .subscribe({
          next: (data) => {
            if (data.payload.exists) {
              let item: any = data.payload.data()
              item.id = data.payload.id

              if (item.ref) delete item['ref']
              if (item._createdBy) delete item['_createdBy']
              if (item._updatedBy) delete item['_updatedBy']
              return res(item)
            } else return res(false)
          },
          error: (err) => {
            console.error('loadData Error: ', err)
            return res(false)
          }
        })
    })
  }

  // loadAgencyDetails() {}

  //figuring out to connect back to Worklist-Existing Regulations

  onChangeRegText(e: any, sectionFlag: number) {
    // 0 = section
    // 1 = subsection
    switch (sectionFlag) {
      case 0:
        this.selected_section = e?.selectedOptions?.selected[0].value;
        this.selected_subsection = this.selected_section.subsections[0];
        break;
      case 1:
        this.selected_subsection = e?.selectedOptions?.selected[0]?.value;
        break;
    }
  }

  submit(actionFlag: number) {
    if (actionFlag == this.enum_action_flag.POST_REGULATION) {
      var flags = {
        // The submit modal for regulations can still be one component because the RIA workflow is the only significant difference between Existing and Proposed
        regulationType: 'EXISTING'
      };
      const dialogRef = this.dialog.open(
        RegulationViewConfirmationModalComponent,
        {
          disableClose: true,
          height: '480px',
          width: '640px',
          data: {
            flags: flags,
            currentStatus: this.reg_status,
          },
        }
      );

      dialogRef.afterClosed().subscribe(
        async (result) => {
          if (result && result.submitFlag) {
            this.submissionConfirmed(result.submitFlag, result.actionFlag)
          }
          else console.log('cancelled!');
        },
        (error) => {
          console.log('cancelled!');
        }
      )
    }
    else {
      this.submissionConfirmed(true, actionFlag)
    }
  }

  getRegulationStatus(documentId: any) {
    this.afs.collection("Worklist-Proposed Regulations").ref
      .where('documentId', '==', documentId).get()
      .then(info => {
        if (info.size > 0) {
          let tempArr: any = []
          info.forEach(data => {
            let item: any = data.data()
            item.id = data.id
            tempArr.push(item)
          })
          return tempArr[0]
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  async submissionConfirmed(submitFlag: any, actionFlag: any) {
    let nextStatus = '';
    this.actionFlag = actionFlag
    switch (actionFlag) {
      case WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT:
        nextStatus = WorklistStatusPBRISRegulation.FOR_VERIFICATION;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_APPROVAL;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW;
        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.RESUBMITTED;
        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_REAPPROVAL;
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA:
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_REVISION;
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_MODIFICATION;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING:
        nextStatus = WorklistStatusPBRISRegulation.FOR_POSTING;
        break;
      case WorklistActionFlagPBRISRegulation.POST_REGULATION:
        // TODO: Update Service Status to POSTED for List of Service retrieval
        nextStatus = WorklistStatusPBRISRegulation.POSTED;
        break;
    }

    this.actionMade = nextStatus
    // this.getDetailsForEmailer()

    if (nextStatus !== '') {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      if (nextStatus == WorklistStatusPBRISRegulation.POSTED) {
        const updateRegulation = this.afs.firestore.collection(this.regulationInboxCollectionName).doc(this.paramFromInboxList);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        alert('the regulations has been successfully posted')
        batch.update(
          updateRegulation,
          { is_posted: true, date_posted: today }
        )
      }

      let updateObj: Record<string, any> = await this.worklistService.updateWorklistStatusObj(nextStatus);

      // Clear comments once sent upwards (or sent from PO to DC)
      if (nextStatus != WorklistStatusPBRISRegulation.FOR_REVISION && nextStatus != WorklistStatusPBRISRegulation.FOR_MODIFICATION && nextStatus != WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC) {
        updateObj.commentDetails = '';
        updateObj.commentRegText = '';
      }

      if (nextStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW) {
        updateObj.dateSubmitted = Timestamp.now().toDate()
      }

      if (nextStatus == WorklistStatusPBRISRegulation.FOR_VERIFICATION) {
        const documentID = this.docuFromInboxList;

        let resubmittedBy = {
          userAgency: this.currenUser.government.agency,
          userEmail: this.currenUser.credentials.email,
          userFullName: this.currenUser.personal.first_name + ' ' + this.currenUser.personal.first_name,
          userId: this.currenUser.credentials.uid
        }

        const updateComments = await this.afs.firestore.collection('Comments-Existing_Reg')
          .where('documentID', '==', documentID)
          .orderBy('timestampAdded', 'desc')
          .limit(1)
          .get();

        updateComments.forEach(doc => {
          const batch = this.afs.firestore.batch();
          const docRef = this.afs.firestore.collection('Comments-Existing_Reg').doc(doc.id);
          batch.update(docRef, { timestampResolved: new Date(), resubmittedBy: resubmittedBy });
          batch.commit().then(() => {
            console.log('Comment update successful');
          })
            .catch(error => {
              console.error('Error in comment update:', error);
            });
        });
      }

      const updateWorklist = this.afs.firestore.collection(this.regulationInboxWorklistCollectionName).doc(this.docuFromInboxList);
      batch.update(
        updateWorklist,
        updateObj
      );

      //email notifications
      const userAgency = this.currenUser.government.agency;
      const userEmail = [this.currenUser.credentials.email];
      var data: any = {};
      data.regulationTitle = this.reg_documentTitle;
      this.pEmail.emailNotificationbyAction(userAgency, userEmail, data, actionFlag, this.reg_status);

      await batch.commit()
        .then(() => {
          this.getDetailsForEmailer()
          this.showOnInboxViewport();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  resetFlag() {
    this.phase2View = false;
    // this.pdfView= false;
    this.textRegView = false;
    // this.crisView= false;
    this.piaView = false;
    this.commentsView = false;
    // this.notificationsView= false;
  }

  onTextRegView() {
    this.resetFlag();
    this.textRegView = true
  }

  onPiaView() {
    this.resetFlag();
    this.piaView = true;
  }

  onCommentsView() {
    this.resetFlag();
    this.commentsView = true;
  }

  onPhase2View() {
    this.resetFlag();
    this.phase2View = true;
  }

  pageShift(action: any, section: any, index?: any) {
    if (section == 'reg_text_annex') {
      if (action == "next") {
        if (this.reg_text_annex_file.annex_file_page < this.reg_text_annex_file.annex_file_total_page)
          this.reg_text_annex_file.annex_file_page++;
      }
      if (action == "back") {
        if (this.reg_text_annex_file.annex_file_page > 1)
          this.reg_text_annex_file.annex_file_page--;
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy, section: any) {
    if (section === 'reg_text_annex') {
      this.reg_text_annex_file.annex_file_total_page = pdf.numPages;
    }
  }

  downloadFile(downloadLink: any, fileName: string, section: string) {
    window.open(downloadLink, '_blank')
  }

  async getDetailsForEmailer() {
    console.log('agency id', this.reg_fromAgency)
    const agency: any = await this.getAgency(this.reg_fromAgency)
    console.log('agency', agency)
    // console.log('title:',this.reg_display_title)
    if (agency) {
      const previousStatus = this.actionFlag
      const afterStatus = this.actionMade
      console.log('previousStatus', previousStatus)
      console.log('actionMade', afterStatus)
      // encoder -> verifier
      if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT && afterStatus == WorklistStatusPBRISRegulation.FOR_VERIFICATION) {
        console.log('encoder: 112, verifier:115')
        let encoderData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 112, encoderData)
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 115, verifierData)
      }

      //returned by validator for revision
      else if ((afterStatus == WorklistStatusPBRISRegulation.FOR_REVISION
        && (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA || previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_MODIFICATION))
      ) {
        console.log('encoder: 113')
        let encoderData = {}
        await this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 113, encoderData)

        // returned from arta/agency head to agency for revision
        if (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR || previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA) {
          console.log('verifier: 118')
          let verifierData = {}
          this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 118, verifierData)

          if (afterStatus == WorklistStatusPBRISRegulation.FOR_MODIFICATION) {
            let userData = {
              regulationTitle: this.reg_long_name,
              agencyTitle: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
            }
            console.log('arta: 84')
            this.getCurrentUser(84, userData)
          }
        }
      }

      // verifier to agency head approval
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR && afterStatus == WorklistStatusPBRISRegulation.FOR_APPROVAL) {
        console.log('head: 116')
        let headData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Agency Head', 116, headData)
      }

      // submit to arta
      else if (
        (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR && afterStatus == WorklistStatusPBRISRegulation.RESUBMITTED)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_REAPPROVAL)
      ) {
        console.log('encoder: 114, verifier: 117, po: 81')
        let encoderData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 114, encoderData)
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 117, verifierData)
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Program Officer', 81, artaData)
      }

      // cleared by arta and up for posting
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING && afterStatus == WorklistStatusPBRISRegulation.FOR_POSTING) {
        console.log('verifier: 119')
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 119, verifierData)
      }

      //email users subscribed when published
      else if (afterStatus == WorklistStatusPBRISRegulation.POSTED) {
        console.log('users: 7')
        // this.getUsersSubscribed(regulationId)
      }

      // bro po to bro dc
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC) {
        console.log('dc: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Division Chief', 81, artaData)
      }

      // bro dc to bro dir
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR) {
        console.log('dir: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Director', 81, artaData)
      }

      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG) {
        console.log('ddg: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - DDG', 81, artaData)
      }

      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG) {
        console.log('dg: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - DG', 81, artaData)
      }
    } else console.warn('cannot fine agency')
  }

  getCurrentUser(templateCode: any, data: any) {
    this.auth.user.subscribe({
      next: (info) => {
        let uid = info?.uid
        if (uid) {
          this.afs.collection('Users').doc(uid).snapshotChanges()
            .pipe(first())
            .subscribe({
              next: (res) => {
                let user: any = res.payload.data()
                console.log('user: ', user)
                if (user.credentials.email) this.toSendNotifEmail([user.credentials.email], templateCode, data)
              }
            })
        }
      }
    })
  }

  getUsersUnderArta(accountSubtype: any, templateCode: any, data: any) {
    console.log('getUsersUnderArta', accountSubtype, templateCode, data)
    this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', accountSubtype)).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: (res) => {
          if (res.length > 0) {
            let tempUserEmailArr: any[] = []
            res.map(item => {
              let user: any = item.payload.doc.data()
              user.id = item.payload.doc.id
              // check user subscription
              // console.log('user: ',user)
              console.log('settings', user.setting)
              if (user.settings != undefined && user.settings != null && user.settings != '') {
                if ((templateCode == 81 && user.settings.admin && user.settings.admin.worklist && user.settings.admin.worklist.create == true)
                  || (templateCode != 81 && user.settings.admin && user.settings.admin.worklist && user.settings.admin.worklist.review == true)) {
                  if (!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
                } else console.warn('user ' + user.id + ' not subscribed to worklist')
              } else console.warn('user ' + user.id + ' not subscribed to worklist')
            })
            console.log('tempUserEmailArr', tempUserEmailArr)
            if (tempUserEmailArr.length > 0) this.toSendNotifEmail(tempUserEmailArr, templateCode, data)
          } else console.warn('no arta users with account subtype')
        }
      })
  }

  getUsersUnderAgency(agencyIds: any[], accountSubtype: any, templateCode: any, data: any) {
    this.afs.collection('Users',
      filter => filter.where('government.agency', 'in', agencyIds)
        .where('credentials.account_subtype', '==', accountSubtype)
    ).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: async (res) => {
          if (res.length > 0) {
            let tempUserEmailArr: any[] = []
            res.map(item => {
              let user: any = item.payload.doc.data()
              user.id = item.payload.doc.id
              // console.log('user: ',user)
              // console.log('settings',user.setting)
              if (!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
            })
            console.log('tempUserEmailArr', tempUserEmailArr)
            if (tempUserEmailArr.length > 0) this.toSendNotifEmail(tempUserEmailArr, templateCode, data)
          }
        }
      })
  }

  // getAgency(agencyName:any){
  //   return new Promise((res, rej)=>{
  //     let batches = []
  //     batches.push(this.afs.collection('Agency', filter=> filter.where('agencyDetails.agencyName','==',agencyName)).snapshotChanges())
  //     batches.push(this.afs.collection('Agency', filter=> filter.where('name','==',agencyName)).snapshotChanges())

  //     combineLatest(batches)
  //     .pipe(first())
  //     .subscribe({
  //       next:(result)=>{
  //         result.forEach((content:any)=>{
  //           content.forEach((item:any)=>{
  //             let agency:any = item.payload.doc.data()
  //             agency.id = item.payload.doc.id
  //             return res(agency)
  //           })
  //         })
  //         return res(false)
  //       },
  //       error:(err)=>{
  //         console.error('getAgency error: ',err)
  //         return res(false)
  //       }
  //     })
  //   })
  // }

  getAgency(agencyCode: any) {
    return new Promise((res, rej) => {
      let batches = []
      let batchCode: any = []
      batches.push(this.afs.collection('Agency').doc(agencyCode).snapshotChanges())
      batchCode.push('id')

      batches.push(this.afs.collection('Agency', filter => filter.where('agencyDetails.agencyName', '==', agencyCode)).snapshotChanges())
      batchCode.push('name')

      batches.push(this.afs.collection('Agency', filter => filter.where('name', '==', agencyCode)).snapshotChanges())
      batchCode.push('name')

      combineLatest(batches)
        .pipe(first())
        .subscribe({
          next: (result) => {
            let ctr = 0
            let agency: any;
            result.every((content: any, ctr: number) => {
              if (batchCode[ctr] == 'id') {
                if (content.payload.exists) {
                  agency = content.payload.data()
                  agency.id = content.payload.id
                  return false
                }
              }
              else if (batchCode[ctr] == 'name') {
                content.every((item: any) => {
                  if (item.payload.doc.exists) {
                    agency = item.payload.doc.data()
                    agency.id = item.payload.doc.id
                    return false
                  }
                  return true
                })
                if (agency) return false
              }
              return true
            })
            if (agency) return res(agency)
            return res(false)
          },
          error: (err) => {
            console.error('getAgency error: ', err)
            return res(false)
          }
        })
    })
  }

  getUsersSubscribed(regulationId: any, data: any) {
    this.afs.collection('PBRIS Pinned Regulation', filter => filter.where('', '==', '')).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: (result) => {
          // let tempArr:any = []
          // result.map((result2)=>{
          //   let item:any = result2.payload.doc.data()
          //   item.id = result2.payload.doc.id
          //   if(item.settings && user.settings.admin.worklist.review)
          //   // if(!tempArr.map((result3:any)=>result3.id).includes(item.id)) tempArr.push(item)
          // })
        }
      })
  }

  toSendNotifEmail(currentUsers: any[], templateCode: number, data: any) {
    console.log('sending email: ', currentUsers, templateCode)
    if (templateCode > 0) {
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('RegulationViewExistingComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('RegulationViewExistingComponent emailer error: ',err)
      //     }
      // })
    } else console.error('RegulationViewExistingComponent emailer error: no email template for account type')
  }

  openDialog() {
    const dialogRef = this.dialog.open(ExistingCommentModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
