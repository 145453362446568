import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs';

@Component({
  selector: 'app-regulation-comments',
  templateUrl: './regulation-comments.component.html',
  styleUrls: ['./regulation-comments.component.scss']
})
export class RegulationCommentsComponent implements OnInit {

  @Input() reg_docId: any;
  @Input() docuFromInboxList: any;
  comments: any;

  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {


    this.getComments(this.docuFromInboxList).then(comments => {
      this.comments = comments;
      console.log(this.comments); // Logs the resolved comments array
    });

    console.log(this.reg_docId)
    console.log(this.docuFromInboxList)
  }

    async getComments(docuFromInboxList: string) {
      return new Promise((res, rej) => {
        this.afs.collection('Comments-Existing_Reg', filter => filter.where('documentID', '==', docuFromInboxList)).snapshotChanges()
          .pipe(first())
          .subscribe({
            next: (result) => {
              let tempArr: any[] = [];
              result.map(data => {
                let res: any = data.payload.doc.data()
                if (!tempArr.includes(res)) tempArr.push(res)
              })
              tempArr.sort((a, b) => b.timestampAdded.seconds - a.timestampAdded.seconds);
              return res(tempArr)
            },
            error: (err) => {
              console.error('getComments', err)
              return res(false)
            }
          })
      })
    }
}