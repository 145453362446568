<!-- 2025 -->
<ul class="nav nav-tabs mb-3" id="regulationData-head" role="tablist" style="border-bottom: 2px solid #eceef1;">
  <li class="nav-item" role="presentation" *ngFor="let year of years">
    <button class="nav-link" [class.active]="selectedYear === year" (click)="filterByYear(year)" type="button"
      role="tab" [attr.aria-selected]="selectedYear === year">
      {{ year }}
    </button>
  </li>
</ul>

<mat-card>
  <mat-card-title>
    <h6 class="text-center fw-bold report-title theme-default-text mb-0">REGULATORY NOTIFICATION FORM</h6>
  </mat-card-title>
</mat-card>
  
<!-- 2025 - CONTENT -->
<mat-card class="mt-2">
  <div class="tab-content" id="regulationData-content">
    <div class="tab-pane fade show active" id="existingtab" role="tabpanel" aria-labelledby="existing">
      <div [formGroup]="searchForm" class="form-group d-flex flex-row align-items-center" style="margin:1em 0;">
        <label for="search" class="me-2 fw-bold">Search:</label>
        <input formControlName="keyword" id="search" name="search" (keyup)="search($event)" class="form-control" />
        <button class="btn btn-outline-dark ms-2" (click)="refreshList()" title="refresh"><i class="fas fa-redo"></i></button>
      </div>
  
      <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
      <table mat-table class="table table-hover table-responsive" id="matTable" [dataSource]="agencyRegulationsDataSource">
        <ng-container matColumnDef="regulations">
          <th mat-header-cell *matHeaderCellDef class="p-2">
            <h6 class="text-center fw-bold report-title theme-default-text"></h6>
          </th>
          <td mat-cell *matCellDef="let item" style="padding:2em">
            <div>
              <h6 class="fw-bold report-title theme-arta-text" [ngClass]="item.title || item.subject ? '' : 'red-text'">
                {{item.title ? item.title : item.subject ? item.subject : 'No Title'}}
              </h6>
            </div>
          </td>
          <ng-container
            *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
            <td mat-footer-cell *matFooterCellDef style="padding:2em" class="fw-bold d-flex justify-content-center">
              No posted Regulatory Notification Form yet.
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns;"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        <ng-container
          *ngIf="agencyRegulationsDataSource && agencyRegulationsDataSource.data && agencyRegulationsDataSource.data.length == 0">
          <tr mat-footer-row *matFooterRowDef="columns"></tr>
        </ng-container>
      </table>
      <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</mat-card>