import firebase from 'firebase/compat/app';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {  WorklistStatusPBRISRegulation } from 'src/app/entities/worklist';
import { startWith, debounceTime, flatMap, map, take, tap, first } from 'rxjs/operators';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { RegulationViewExistingComponent } from '../regulation-view-existing.component';
import { now } from 'moment';
import { Timestamp } from 'firebase/firestore';
// import { firestore } from 'firebase-admin';
// import { serverTimestamp, Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-dropdown-comment',
  templateUrl: './dropdown-comment.component.html',
  styleUrls: ['./dropdown-comment.component.scss']
})
export class DropdownCommentComponent implements OnInit {

  // comment_details = new FormControl('', Validators.required);
  comment_regtext = new UntypedFormControl('', Validators.required);
  // comment_self_assessment = new FormControl('', Validators.required);
  @Input() docuFromInboxList: any;
  @Input() currenUser: any;

  constructor(private afs: AngularFirestore,
    public worklistService: WorklistService,
    private route: ActivatedRoute,
    private router: Router,
    private regulationViewExisting: RegulationViewExistingComponent
  ) {
  }

  ngOnInit(): void {
  }

  async addComment() {
    let commentOwner = {
      userAgency: this.currenUser.government.agency,
      userEmail: this.currenUser.credentials.email,
      userFullName: this.currenUser.personal.first_name + ' ' + this.currenUser.personal.first_name,
      userId: this.currenUser.credentials.uid
    }
    let comments = {
      commentText: this.comment_regtext.value,
      documentXPath: '',
    }

    let comment = {
      comments: [comments],
      documentID: this.docuFromInboxList,
      timestampAdded: Timestamp.now().toDate(),
      commentOwner: commentOwner
    }

    const newComment = await this.afs.collection('Comments-Existing_Reg').add(comment);
    console.log('added comment', comment);
  }

  async submitCommentsAndReturn() {
    console.log(this.docuFromInboxList);
    console.log(this.docuFromInboxList);
    await this.addComment();
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let worklist_ref: any;

    if (this.docuFromInboxList) {
      worklist_ref = this.afs.firestore.collection(`Worklist-Existing Regulations`).doc(this.docuFromInboxList);

      let nextStatus = WorklistStatusPBRISRegulation.FOR_REVISION;
      let updateObj: Record<string, any> = await this.worklistService.updateWorklistStatusObj(nextStatus);
      updateObj.commentDetails = this.comment_regtext.value;
      updateObj.commentRegText = this.comment_regtext.value;

      batch.update(
        worklist_ref,
        updateObj
      );
    }
    
    console.log('load this: ' + this.docuFromInboxList);
    this.regulationViewExisting.loadWorkListInformation(this.docuFromInboxList);
    this.regulationViewExisting.onCommentsView();

    await batch.commit().then(() => {
      alert("Comments updated.");
      // this.getDetailsForEmailer(worklist_ref)
      this.forReturnToRegulatoryManagement(this.docuFromInboxList, this.docuFromInboxList)
      this.router.navigate(['/pbris/regulatory-management']);
      // this.loading = false
    }).catch((error: { message: any; }) => {
      // this.loading = false
      alert(error.message)
    });
  }

  forReturnToRegulatoryManagement(worklistId: any, regulatiionId: any) {
    sessionStorage.setItem('regulatoryActive', 'phase 2 existing')
    sessionStorage.setItem('worklistId', worklistId)
    sessionStorage.setItem('regulationId', regulatiionId)
  }
}
