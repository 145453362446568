<!-- <mat-card>
  <mat-card-title>
      <h5 class="fw-bold report-title theme-arta-text mb-0" style="font-size:14px;">
        <i class="fas fa-user-plus me-3"></i>Register an Agency member
      </h5>
  </mat-card-title>
</mat-card> -->

<mat-dialog-content class="mat-typography mb-4">
  <app-agency-register-member [hideModal]="true" [agency]="data.agency" [user]="data.user" (closeModalEmitter)="close()" (closeButtonLoadEmitter)="buttonLoad($event)"></app-agency-register-member>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-dialog-close class="btn btn-dark" [disabled]="loading">Cancel</button>
  <!-- <button (click)="register()" class="btn btn-primary">Register</button> -->
</mat-dialog-actions>