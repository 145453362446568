import { Component } from '@angular/core';

@Component({
  selector: 'app-pbris-view',
  templateUrl: './pbris-view.component.html',
  styleUrls: ['./pbris-view.component.scss']
})
export class PbrisViewComponent {

}
