<div>
    <ng-container [ngSwitch]="currentSystem">
        <app-artemis-navigation-header *ngSwitchCase="artemis"></app-artemis-navigation-header>
        <app-navbar *ngSwitchCase="pbris"></app-navbar>
        <app-artemis-navigation-header *ngSwitchDefault [currentPage]="''"></app-artemis-navigation-header>
    </ng-container>
</div>

<!-- Auxiliary Navigation -->
<mat-toolbar class="auxiliary-nav">
    <a *ngIf="isAdmin" mat-icon-button class="btn btn-outline-dark" routerLink="/agency/">
        <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to List of Agencies (ARTA ADMIN)">keyboard_backspace</mat-icon>
    </a>
    <ol class="breadcrumb my-1 ms-4">
        <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page">
            ORGANIZATIONAL STRUCTURE
        </li>
    </ol>
    <span class="auxiliary-nav-spacer"></span>
    <div class="auxiliary-nav-button-row">
        <a *ngIf="currentSystem == pbris" mat-icon-button class="btn btn-outline-dark" (click)="toRegulatoryManagement()">
            <mat-icon class="fw-bold" matTooltipPosition="above" matTooltip="Back to Regulatory Management">arrow_back_ios</mat-icon>
        </a>
    </div>
</mat-toolbar>

<div class="content">
    <div class="container-fluid mt-3 mb-5 px-5">
        <mat-card>
            <div class="container mt-4">
                <ul class="org-chart my-2">
                    <div *ngIf="subAgency.length <= 0">
                        <div style="background-color: #f4f4f4;">
                            <div class="text-center"
                                style="color: #6c757d; height: 5em; display: flex; align-items: center; justify-content: center;">
                                <p class="card-text" style="margin-bottom: 0;">
                                   Create your Organizational Structure here.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <ng-container *ngFor="let node of subAgency">
                        <li class="node node-root my-2">
                            <div class="input-group">
                                <div class="form-floating flex-grow-1">
                                    <input type="text" class="form-control text-box" [(ngModel)]="node.subagency" placeholder="Office/Department/Etc" />
                                    <label>Office/Department/Etc.</label>
                                </div>
                                <button class="btn btn-primary" type="button" (click)="addSubAgency(node.id)">
                                    <i class="fa fa-arrow-circle-left me-2"></i>Add Division/Unit/Etc.
                                </button>
                                <button class="btn btn-danger" type="button" (click)="removeSubAgency(node.id)" matTooltipPosition="above" matTooltip="remove">
                                    <i class="fas fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <ul *ngIf="node.structure.length > 0">
                                <ng-container
                                    *ngTemplateOutlet="recursiveTemplate; context: { $implicit: node.structure }">
                                </ng-container>
                            </ul>
                        </li>
                    </ng-container>
                </ul>

                <ng-template #recursiveTemplate let-structure>
                    <ul class="my-2">
                        <ng-container *ngFor="let child of structure">
                            <li class="node my-2">
                                <div class="input-group">
                                    <div class="form-floating flex-grow-1">
                                        <input type="text" class="form-control text-box" [(ngModel)]="child.subagency" placeholder="Division/Unit/Etc." />
                                        <label>Division/Unit/Etc.</label>
                                    </div>
                                    <button class="btn btn-primary" type="button" (click)="addSubAgency(child.id)">
                                        <i class="fa fa-arrow-circle-left me-2"></i>Add Division/Etc.</button>
                                    <button class="btn btn-danger" type="button" (click)="removeSubAgency(child.id)" matTooltipPosition="above" matTooltip="remove">
                                        <i class="fas fa-times-circle" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <ul *ngIf="child.structure.length > 0">
                                    <ng-container
                                        *ngTemplateOutlet="recursiveTemplate; context: { $implicit: child.structure }">
                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                </ng-template>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="mt-5">
                    <button class="btn btn-primary" type="button" (click)="addSubAgency(null)">
                        <i class="fas fa-plus-circle me-2"></i>Add Office/Department etc.
                    </button>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <button class="btn btn-dark" type="button" (click)="toRegulatoryManagement()">
                        Cancel
                    </button>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-danger float-end" type="button" (click)="uploadSubAgency()">
                        <i class="fa fa-save me-2"></i>Save
                    </button>
                </div>
            </div>
        
        </mat-card>

        
    </div>
</div>