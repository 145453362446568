<div *ngIf="comments; else noComments">
    <div *ngFor="let comment of comments" class="my-2 p-2 card">
        <p><small class="text-muted">Comment by {{ comment.commentOwner.userEmail }} on {{
                comment.timestampAdded?.toDate() | date:'short'
                }}</small>
            <br>
            <small class="text-primary" *ngIf="comment.timestampResolved">Resubmitted on {{
                comment.timestampResolved?.toDate() |
                date:'short' }} by {{comment.resubmittedBy.userFullName}}</small>
        </p>
        <div class="mx-3">
            <p><strong>{{ comment.comments[0].commentText }}</strong></p>
        </div>
    </div>
</div>

<!-- Fallback if there are no comments -->
<ng-template #noComments>
    <p>No comments available.</p>
</ng-template>