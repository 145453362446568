import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ARTEMIS, PBRIS } from 'src/app/entities/constant';
import { accountRole } from 'src/app/agency-control/agency-register-member/agency-register-member.component';

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})

  export class OrganizationProfileComponent implements OnInit {
    currentSystem:any = sessionStorage.getItem('currentSystem');
    pbris = PBRIS
    artemis = ARTEMIS
  
    loader: boolean = true;
    loading: boolean = false;// for button spinner
  
    //Current Agency
    doc_id :any = this.route.snapshot.params.id? this.route.snapshot.params.id : sessionStorage.getItem('agency_id')
    //
    
   
    // Agency Information
    agencies:any=[];
    agency_name:any;
    agency_abbreviation:any;
    agency_address:any;
    agency_address_line:any;
    agency_barangay:any;
    agency_city:any;
    agency_province:any;
    agency_postal:any;
    agency_website:any;
    protocol:any;
    agency_category:any;
    agency_categories:any = []
    mother_agency:any;
    motheragencies:any=[]
    cartDetails: any = {
      headOfAgencyTitle: '',
      chairPersonTitle: '',
      viceChairPersonTitle: '',
      membersTitle: '',
    }
    //
  
  
    //User limitations
    isAgencyMember:boolean = false;
    isAdmin:boolean = false;
    isArtaMember: any =false;
    headervisible: boolean =true;
    //
  
  
    style_theme: any = 'theme-default'; //current theme
  
  
    //show form handler
    showEditTitle:boolean = false;
    showEditAddress:boolean = false;
    showEditWebsite:boolean = false;
    //
  
  
    //HTML forms
    editTitle: boolean = true;
    agencyTitleForm = this.fb.group({
      agencyName: new UntypedFormControl('',[Validators.required]),
      agencyAbbreviation: new UntypedFormControl('',[Validators.required]),
      agencyCategory: new UntypedFormGroup({
        name: new UntypedFormControl('',[Validators.required]),
        abbreviation: new UntypedFormControl('',[Validators.required]),
        id: new UntypedFormControl('',[Validators.required]),
      }),
      motherAgency: new UntypedFormGroup({
        motheragency: new UntypedFormControl('',[Validators.required]),
        id: new UntypedFormControl('',[Validators.required])
      })
    })
  
    editWebsite: boolean =true;
    agencyWebsiteForm= this.fb.group({
      website: new UntypedFormControl('',[
        Validators.required,
        // Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
        Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\#[-a-zA-Z\d_]*)?$/),
      ]),
      // protocol: new FormControl('',[Validators.required]),
    })
  
    editAddress: boolean = true
    agencyAddressForm = this.fb.group({
      addressLine: new UntypedFormControl('',[Validators.required]),
      barangay: new UntypedFormControl('',[Validators.required]),
      city: new UntypedFormControl('',[Validators.required]),
      province: new UntypedFormControl('',[Validators.required]),
      postal: new UntypedFormControl('',[Validators.required]),
    })
    //
  
  
    //input error message to types
    checkAgencyTitleForm = {
      agencyName:[
        { type: "required", message: "Agency Name is required" },
      ],
      agencyAbbreviation:[
        { type: "required", message: "Agency Abbreviation is required" },
      ],
      id:[
        { type: "required", message: "Agency Category is required"},
      ],
      motherAgencyID:[
        {type:"required",message: "Mother Agency is required"}
      ]
    }
  
    checkAgencyWebsiteForm = {
      website:[
        { type: "required", message: "Website URL is required" },
        { type: "pattern", message: "Website URL incorrect format" },
      ],
      protocol:[
        { type: "required", message: "Website Protocol is required"}
      ]
    }
  
    checkAgencyAddressForm= {
      addressLine:[
        { type: "required", message: "Address Line: House no / Building no. - Street name / Avenue name - Subdivision is required" },
      ],
      barangay:[
        { type: "required", message: "Barangay is required" },
      ],
      city:[
        { type: "required", message: "City / Minicipality is required" },
      ],
      province:[
        { type: "required", message: "Province - Region is required" },
      ],
      postal:[
        { type: "required", message: "Postal code / Zip code is required" },
      ],
    }
    //
  
    constructor(
      private route: ActivatedRoute,
      private afs: AngularFirestore,
      private router: Router,
      private fb:UntypedFormBuilder,
      private auth: AngularFireAuth,) { }
  
    ngOnInit(): void {
      this.loadAgencyCategory()
      this.loadMotherAgencies()
      this.checkUser()
    }
  
    //page data loaders
    loadMotherAgencies(){
      this.afs.collection('Mother Agency',filter=> filter.orderBy('motheragency')).snapshotChanges()
      .subscribe(info=>{
          let tempArr:any = []
          info.forEach(data=>{
            let motheragency: any = data.payload.doc.data()
            motheragency.id = data.payload.doc.id
            tempArr.push(motheragency)
          })
          this.motheragencies = tempArr
      })
    }
  
    //page data loaders
    loadAgencyCategory(){
      this.afs.collection('Agency Category',filter=>filter.orderBy('name')).snapshotChanges()
      .pipe(first())
      .subscribe(info=>{
        let tempArr:any = []
        info.forEach(data=>{
          let agencyCategory:any = data.payload.doc.data()
          agencyCategory.id = data.payload.doc.id
          tempArr.push(agencyCategory)
        })
        this.agency_categories = tempArr
      })
    }
  
    isAgencyITAdmin: boolean =false
  
    async checkUser(){
      if(await this.auth.user){
        if(sessionStorage.getItem("user_type") === "arta") {
          this.isArtaMember =true
          this.isAdmin = sessionStorage.getItem("arta_admin") === "true" ? true : false;
          this.loadPageInformation(this.doc_id)
        }
        else if ( sessionStorage.getItem("user_type") === "agency"){
          this.auth.onAuthStateChanged(async (user:any)=>{
            if(user.uid){
              this.afs.collection('Users').doc(user.uid).snapshotChanges()
              .pipe(first())
              .subscribe(data=>{
                const item:any = data.payload.data()
                if(this.doc_id === item.government.agency ){ // agency code
                  
                  this.isAgencyMember =  true
                  if(item.credentials?.role === accountRole.Agency_IT_Admin){
                    this.isAgencyITAdmin = true
                  }
                  if(this.route.snapshot.params.id){
                    this.headervisible =true
                  }else{
                    this.headervisible =false
                  }
                }
                this.loadPageInformation(this.doc_id)
              })
            }
            else{
              this.loadPageInformation(this.doc_id)
            }
          })
        }
        else{
          this.loadPageInformation(this.doc_id)
        }
      }else{
        this.loadPageInformation(this.doc_id)
      }
    }
  
    async loadPageInformation(id: any){
      this.afs.collection('Agency').doc(id).snapshotChanges()
      .pipe(first())
      .subscribe(
        data =>{
          this.loader = false;
          let item:any  = data.payload.data()
  
          this.agency_name = item?.agencyDetails?.agencyName ? item.agencyDetails.agencyName : item?.name ? item.name : ""
          this.agency_abbreviation = item?.agencyDetails?.agencyName ? item.agencyDetails.agencyAbbreviation : item?.abbreviation ? item.abbreviation : ""
          this.agency_category = item?.agencyDetails?.agencyCategory ? item.agencyDetails.agencyCategory : {
            id:"",
            abbreviation: "",
            name: ""
          };
          this.mother_agency = item?.agencyDetails?.motherAgency ? item.agencyDetails.motherAgency : {
            id: "",
            motheragency: ""
          };
  
          // if(item.agencyDetails != null){
          //   this.agency_name = item.agencyDetails.agencyName ? item.agencyDetails.agencyName : item.name ? item.name : "";
          //   this.agency_abbreviation = item.agencyDetails.agencyName ? item.agencyDetails.agencyAbbreviation : item.abbreviation ? item.abbreviation : ""
          //   this.agency_category = item.agencyDetails.agencyCategory ? item.agencyDetails.agencyCategory : "" ;
          //   this.mother_agency = item.agencyDetails.motherAgency ? item.agencyDetails.motherAgency : "";
          // }
          // else{
          //   this.agency_name = item.name ? item.name : ""
          //   this.agency_abbreviation = ""
          //   this.agency_category = {
          //     id:"",
          //     abbreviation: "",
          //     name: ""
          //   }
          //   this.mother_agency = {
          //     id: "",
          //     motheragency: ""
          //   }
          // }
  
          this.cartDetails.headOfAgencyTitle = item?.cartDetails?.titles?.headOfAgencyTitle ? item.cartDetails.titles.headOfAgencyTitle : ''
          this.cartDetails.chairPersonTitle = item?.cartDetails?.titles?.chairPersonTitle ? item.cartDetails.titles.chairPersonTitle : ''
          this.cartDetails.viceChairPersonTitle = item?.cartDetails?.titles?.viceChairPersonTitle ? item.cartDetails.titles.viceChairPersonTitle : ''
          this.cartDetails.membersTitle = item?.cartDetails?.titles?.membersTitle ? item.cartDetails.titles.membersTitle : ''
  
          this.agencyTitleForm.patchValue({
            agencyName: this.agency_name,
            agencyAbbreviation: this.agency_abbreviation,
            agencyCategory:this.agency_category,
            motherAgency:this.mother_agency
          })
  
          if(item.addressDetails != null){
            this.agency_address_line = item.addressDetails.addressLine ? item.addressDetails.addressLine : "" ;
            this.agency_barangay = item.addressDetails.barangay ? item.addressDetails.barangay : "" ;
            this.agency_city = item.addressDetails.city ? item.addressDetails.city : "" ;
            this.agency_province = item.addressDetails.province ? item.addressDetails.province : "" ;
            this.agency_postal = item.addressDetails.postal ? item.addressDetails.postal : "" ;
  
            this.agency_address = this.agency_address_line 
            + " " + this.agency_barangay
            + " " + this.agency_city
            + " " + this.agency_province
            + "," + this.agency_postal;
          }
          else if(item.address != null){
            this.agency_address_line =  "" ;
            this.agency_barangay =  "" ;
            this.agency_city =  "" ;
            this.agency_province =  "" ;
            this.agency_postal =  "" ;
            this.agency_address = item.address
          }
          else{
            this.agency_address_line =  "" ;
            this.agency_barangay =  "" ;
            this.agency_city =  "" ;
            this.agency_province =  "" ;
            this.agency_postal =  "" ;
            this.agency_address = "";
          }
  
          this.agencyAddressForm.patchValue({
            addressLine: this.agency_address_line,
            barangay: this.agency_barangay,
            city: this.agency_city,
            province: this.agency_province,
            postal: this.agency_postal,
          })
  
          // let websiteS:any = "";
          // let protocol:any;
          if(item.agencyDetails != null && item.agencyDetails.website  != null){
  
            this.agency_website = item.agencyDetails.website ? item.agencyDetails.website : "";
            this.agencyWebsiteForm.patchValue({
              website: this.agency_website, 
              // protocol: protocol
            })
  
  
            // let slicedURL:any = item.agencyDetails.website.split('/')
            // if(slicedURL[0] == 'http:' || slicedURL[0] == 'https:')
            //   protocol = slicedURL[0]
            
            // for(const ctr in slicedURL){
            //   const num:number = parseInt(ctr)
            //   if(num == 0){
            //     if(slicedURL[num] !== 'http:' && slicedURL[num] !== 'https:'){
            //       websiteS = websiteS + slicedURL[num]
            //       if((num+1) < slicedURL.length)
            //         websiteS = websiteS+"/" 
            //     }
            //   }
            //   else if(num > 0 && slicedURL[num] != ""){
            //     websiteS = websiteS + slicedURL[num]
            //     if((num+1) < slicedURL.length)
            //       websiteS = websiteS+"/"
            //   }
            // }
          }
          // else{
          //   websiteS = "";
          //   // protocol = "";
          // }
  
          // this.agencyWebsiteForm.patchValue({
          //   website: websiteS, 
          //   // protocol: protocol
          // })d
          //console.log('agency: ',item)
          //console.log('all titles: ',this.cartDetails)
        })
    }
  
    universalOption(controlName:any){
      // if(controlName == 'website'){
      //   return ['http:','https:']
      // }
      // return []
    }
  
    universalSelected(controlName:any,value:any){
      // if(controlName == 'website'){
      //   if (this.agencyWebsiteForm.value.protocol == value) return true
      // }
      if(controlName == 'agencyCat'){
        if(this.agency_category.id == value) return true
      }else if(controlName = 'motherAgency'){
        if(this.mother_agency.id == value) return true
      }
      return false
    }
  
    universalUpdater(controlName:any, value:any){
      // if(controlName == 'website'){
      //   this.agencyWebsiteForm.patchValue({
      //     protocol: value
      //   })
      // }
      if(controlName == 'agencyCat'){
        let agencyCategory = this.agency_categories.filter((result:any)=>(result.id===value)?true:false)
        let name = ""
        let abbreviation = ""
        let id = ""
        if(agencyCategory.length>0){
          name = agencyCategory[0].name
          abbreviation = agencyCategory[0].abbreviation
          id = agencyCategory[0].id
        }
        this.agencyTitleForm.controls.agencyCategory.patchValue({
          name:name,
          abbreviation:abbreviation,
          id:id
        })
      }else if(controlName == 'motherAgency'){
        let agencyMother = this.motheragencies.filter((result:any)=>(result.id === value)?true:false)
        let motheragency =""
        let id  = ""
        if(agencyMother.length > 0){
          motheragency = agencyMother[0].motheragency
          id = agencyMother[0].id
        }
        this.agencyTitleForm.controls.motherAgency.patchValue({
          motheragency: motheragency,
          id:id
        })
      }
    }
  
    getAgencyFullURL(){
      return this.agency_website;
    }
    //
  
    
  
    //Submit
    update(editName:any){
      this.loading = true// for button spinner
      if(this.isAdmin || this.isAgencyMember){
        if(editName === 'title'){
          this.validateFields('title')
          if(this.agencyTitleForm.valid){
            let title:any = this.agencyTitleForm.value
            //console.log('mother agency value',title.motherAgency)
            
            this.afs.collection('Agency').doc(this.doc_id).update({
              "agencyDetails.agencyAbbreviation": title.agencyAbbreviation,
              "agencyDetails.agencyName": title.agencyName,
              "agencyDetails.agencyCategory": title.agencyCategory,
              "agencyDetails.motherAgency":title.motherAgency,
              abbreviation: title.agencyAbbreviation,
              name: title.agencyName,
            })
            .then(()=>{
              alert("Title Update Successful")
              this.agency_name = title.agencyName;
              this.agency_abbreviation = title.agencyAbbreviation
              this.agency_category = title.agencyCategory
              this.mother_agency = title.motherAgency
              this.toggleEdit('title');
              this.loading = false;// for button spinner
            })
            .catch(err=>{
              this.loading = false;// for button spinner
              alert("Title Update Failed")
            })
          }
        }
        else if(editName === 'address'){
          this.validateFields('address')
          if(this.agencyAddressForm.valid){
            const address:any = this.agencyAddressForm.value
            const newAddress =address.addressLine+" "+address.barangay+" "+ address.city+" "+address.province+", "+address.postal
            this.afs.collection('Agency').doc(this.doc_id).update({
              addressDetails: address,
  
              address: newAddress
            })
            .then(()=>{
              alert("Address Update Successful")
              this.agency_address_line = address.addressLine ;
              this.agency_barangay = address.barangay ;
              this.agency_city = address.city ;
              this.agency_province = address.province ;
              this.agency_postal = address.postal ;
              this.agency_address = newAddress;
              this.toggleEdit('address');
              this.loading = false;// for button spinner
            })
            .catch(err=>{
              this.loading = false;// for button spinner
              alert("Address Update Failed")
            })
          }
        }
        else if(editName === 'website'){
          this.validateFields('website')
          if(this.agencyWebsiteForm.valid){
            let link:any = this.agencyWebsiteForm.value
            // const url = link.protocol + "//" + link.website
            const url = link.website
            this.afs.collection('Agency').doc(this.doc_id).update({
              "agencyDetails.website": url,
            })
            .then(()=>{
              alert("Website URL Update Successful")
              this.agency_website = url ;
              this.toggleEdit('website');
              this.loading = false;// for button spinner
            })
            .catch(err=>{
              this.loading = false;// for button spinner
              alert("Website URL Update Failed")
            })
          }
        }
      }
    }
    //
  
  
    //Shows forms/inputs
    toggleEdit(editName:any){
      if(this.isAdmin || this.isAgencyMember){
        if(editName === 'title'){
          this.editTitle= !this.editTitle
          this.agencyTitleForm.patchValue({
            agencyName: this.agency_name,
            agencyAbbreviation: this.agency_abbreviation,
            agencyCategory: this.agency_category,
          })
          this.agencyTitleForm.markAsUntouched()
          this.agencyTitleForm.markAsPristine()
        }
        else if(editName === 'address'){
          this.editAddress = !this.editAddress
          this.agencyAddressForm.patchValue({
            addressLine: this.agency_address_line,
            barangay: this.agency_barangay,
            city: this.agency_city,
            province: this.agency_province,
            postal: this.agency_postal,
          })
          this.agencyAddressForm.markAsUntouched()
          this.agencyAddressForm.markAsPristine()
        }
        else if(editName === 'website'){
          this.editWebsite = !this.editWebsite
          // let websiteS:any = "";
          // let protocol:any;
          // if(this.agency_website){
          //   let slicedURL:any = this.agency_website.split('/')
          //   if(slicedURL[0] == 'http:' || slicedURL[0] == 'https:')
          //     protocol = slicedURL[0]
            
          //   for(const ctr in slicedURL){
          //     const num:number = parseInt(ctr)
          //     if(num == 0){
          //       if(slicedURL[num] !== 'http:' && slicedURL[num] !== 'https:'){
          //         websiteS = websiteS + slicedURL[num]
          //         if((num+1) < slicedURL.length)
          //           websiteS = websiteS+"/" 
          //       }
          //     }
          //     else if(num > 0 && slicedURL[num] != ""){
          //       websiteS = websiteS + slicedURL[num]
          //       if((num+1) < slicedURL.length)
          //         websiteS = websiteS+"/"
          //     }
          //   }
          // }
          // else{
          //   websiteS = null;
          //   protocol = null;
          // }
  
          this.agencyWebsiteForm.patchValue({
            website: this.agency_website, 
            // protocol: protocol
          })
          this.agencyWebsiteForm.markAsUntouched()
          this.agencyWebsiteForm.markAsPristine()
        }
      }
    }
    //
  
  
    // Error handlers & checker for inputs
    hasErrors(editName:any, controlName:any, validType:any){
      if(editName === 'title'){
        return ((this.agencyTitleForm.get(controlName) as UntypedFormControl).hasError(validType) 
        && ((this.agencyTitleForm.get(controlName) as UntypedFormControl).dirty 
        || (this.agencyTitleForm.get(controlName) as UntypedFormControl).touched));
      }
      else if(editName === 'address'){
        return ((this.agencyAddressForm.get(controlName) as UntypedFormControl).hasError(validType) 
        && ((this.agencyAddressForm.get(controlName) as UntypedFormControl).dirty 
        || (this.agencyAddressForm.get(controlName) as UntypedFormControl).touched));
      }
      else if(editName === 'website'){
        return ((this.agencyWebsiteForm.get(controlName) as UntypedFormControl).hasError(validType) 
        && ((this.agencyWebsiteForm.get(controlName) as UntypedFormControl).dirty 
        || (this.agencyWebsiteForm.get(controlName) as UntypedFormControl).touched));
      }
      else if(editName === 'agencyCatID'){
        return ((this.agencyTitleForm.controls.agencyCategory as UntypedFormGroup).get(controlName) as UntypedFormControl).hasError(validType)
        && (((this.agencyTitleForm.controls.agencyCategory as UntypedFormGroup).get(controlName) as UntypedFormControl).dirty
        || (((this.agencyTitleForm.controls.agencyCategory as UntypedFormGroup).get(controlName) as UntypedFormControl).touched))
      }
      else return null
    }
  
    validateFields(editName:any){
      if(this.isAdmin || this.isAgencyMember){
        if(editName === 'title'){
          if(!this.agencyTitleForm.valid){
            this.agencyTitleForm.markAllAsTouched();
            for(const control in this.agencyTitleForm.controls){
              if (this.agencyTitleForm.controls.hasOwnProperty(control)) {
                this.agencyTitleForm.controls[control].markAllAsTouched();
                this.agencyTitleForm.controls[control].markAsDirty();
                this.loading = false;// for button spinner
              }
            }
          }
        }
        else if(editName === 'address'){
          if(!this.agencyAddressForm.valid){
            this.agencyAddressForm.markAllAsTouched();
            for(const control in this.agencyAddressForm.controls){
              if (this.agencyAddressForm.controls.hasOwnProperty(control)) {
                this.agencyAddressForm.controls[control].markAllAsTouched();
                this.agencyAddressForm.controls[control].markAsDirty();
                this.loading = false;// for button spinner
              }
            }
          }
        }
        else if(editName === 'website'){
          if(!this.agencyWebsiteForm.valid){
            this.agencyWebsiteForm.markAllAsTouched();
            for(const control in this.agencyWebsiteForm.controls){
              if (this.agencyWebsiteForm.controls.hasOwnProperty(control)) {
                this.agencyWebsiteForm.controls[control].markAllAsTouched();
                this.agencyWebsiteForm.controls[control].markAsDirty();
                this.loading = false;// for button spinner
              }
            }
          }
        }
      }
    }
    //
  }
  