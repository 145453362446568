import { AngularFireStorage } from '@angular/fire/compat/storage';
import { first } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Observable } from 'rxjs'
import { isArray } from 'ngx-bootstrap/chronos';
import e from 'cors';
import { atLeastOne } from 'src/app/validators';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})

export class OrganizationDetailsComponent implements OnInit {
  
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger; 

  profileLoader:boolean = false;
  contactLoader:boolean = false;
  loading: boolean = false; //for button spinner
  agency_id:any;

  has_agency_info: boolean = false;
  agency_mission:any = "";
  agency_vision:any = "";
  agency_mandate:any = "";
  agency_pledges:any = "";

  //Contact Information
  agency_email:any;
  agency_mobile:any;
  agency_tels:any;

  agency_logo:any;

  imgSrc : string;
  agencyImage: any;
  logoPath = "agency/";

  defaultImgSrc = "/assets/default_upload_logo.png"

  uploadToFireStorageProgress: Observable<number | undefined>
 
  @Input('agency_doc') doc_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;

  editProfile:boolean= true;
  editMission:boolean= true;
  editVision:boolean= true;
  editMandate:boolean= true;
  editPledges:boolean= true;
  agencyProfileForm =  this.fb.group({
    mission: new UntypedFormControl('',), // Validators.required
    vision: new UntypedFormControl('',), // Validators.required
    mandate: new UntypedFormControl('',), // Validators.required
    pledges: new UntypedFormControl('',), // Validators.required
  })

  missionForm = this.fb.group({
    mission: new UntypedFormControl('',), // Validators.required
  })

  
  visionForm =  this.fb.group({
    vision: new UntypedFormControl('',), // Validators.required
  })

  
  mandateForm =  this.fb.group({
    mandate: new UntypedFormControl('',), // Validators.required
  })

  
  pledgesForm =  this.fb.group({
    pledges: new UntypedFormControl('',), // Validators.required
  })



  editContact:boolean = true;
  contactDetailsForm =  this.fb.group({
    phoneNumber: new UntypedFormControl('', [this.checkPhoneNumber()]),
    emailArray: this.fb.array([],{ validators: [atLeastOne(Validators.required)]}),
    telephoneArray: this.fb.array([],{ validators: [atLeastOne(Validators.required)]})
  })
  
  agencyLogoForm = this.fb.group({
    logoURL: new UntypedFormControl(''),
    logoSrc: new UntypedFormControl(''),
    logoFile: new UntypedFormControl('')
  })

  checkProfileForm = {
    mission:[
      { type: "required", message: "Mission is required" },
    ],
    vision:[
      { type: "required", message: "Vision is required" },
    ],
    mandate:[
      { type: "required", message: "Mandate is required" },
    ],
    pledges:[
      { type: "required", message: "Service Pledges required" },
    ],
  };

  checkContactsForm = {
    phoneNumber:[
      { type: "required", message: "Phone Number is required" },
      // { type: "pattern", message: "Phone number must only contain numbers" },
      { type: 'minlength', message: 'Phone Number must contain atleast 10 digits'},
    ],
    telephoneNumber:[
      { type: "required", message: "Telephone Number is required" },
      // { type: "pattern", message: "Telephone number must only contain numbers" },
      { type: 'minlength', message: 'Telephone Number must atleast contain 10 digits'},
    ],
    trunkline:[
      { type: "required", message: "Trunkline number is required" },
    ],
    email:[
      { type: "required", message: "Email is required" },
      { type: "email", message: "Email incorrect format" },
    ],
  };

  constructor(
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
    private store: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.loadInfo(this.doc_id);
  }

  loadInfo(id:any){
    this.afs.collection('Agency').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(
      data =>{
        if(data){
          let item:any  = data.payload.data();
          item.id = data.payload.id;

          this.agency_id = item.id

          if(item.agencyDetails != null){
            this.agency_logo = item.agencyDetails.logoURL;
            if(this.agency_logo) this.defaultImgSrc = item.agencyDetails.logoURL
          }
          else
            this.agency_logo = ""

          this.agencyLogoForm.patchValue({
            logoURL: this.agency_logo
          })

          this.agency_mission = item?.agencyInfo?.mission ? item.agencyInfo.mission ? item.mission : item.mission : ''
          this.agency_vision = item?.agencyInfo?.vision ? item.agencyInfo.vision ? item.vision : item.vision : ''
          this.agency_mandate = item?.agencyInfo?.mandate ? item.agencyInfo.mandate ? item.mandate : item.mandate : ''
          this.agency_pledges = item?.agencyInfo?.pledges ? item.agencyInfo.pledges ? item.service_pledge : item.service_pledge : ''

          // if(item.agencyInfo != null){
          //   this.has_agency_info = true
          //   this.agency_mission= item.agencyInfo.mission ? item.agencyInfo.mission : "" ;
          //   this.agency_vision= item.agencyInfo.vision ? item.agencyInfo.vision : "" ;
          //   this.agency_mandate= item.agencyInfo.mandate ? item.agencyInfo.mandate : "";
          //   this.agency_pledges= item.agencyInfo.pledges ? item.agencyInfo.pledges : "" ;
          // }
          // else{
          //   this.agency_mission = item.mission ? item.mission : ""
          //   this.agency_vision = item.vision ? item.vision : ""
          //   this.agency_mandate = item.mandate ? item.mandate : ""
          //   this.agency_pledges = item.service_pledge ? item.service_pledge : ""
          // }

          // this.agencyProfileForm.patchValue({
          //   mission:  this.agency_mission,
          //   vision: this.agency_vision,
          //   mandate: this.agency_mandate,
          //   pledges: this.agency_pledges,
          // })
          this.missionForm.patchValue({
            mission: this.agency_mission
          })
          
          this.visionForm.patchValue({
            vision: this.agency_vision
          })

          this.mandateForm.patchValue({
            mandate: this.agency_mandate
          })
          
          this.pledgesForm.patchValue({
            pledges: this.agency_pledges
          })

          let temporaryTel:any;
          let temporaryEmail:any;

          if(item.contactDetails  != null){
            if(item.contactDetails.email)
              temporaryEmail= item.contactDetails.email ? item.contactDetails.email :  "" ;
            else if(item.contactDetails.emailArray)
              temporaryEmail= item.contactDetails.emailArray;
            else temporaryEmail = []
            
            this.agency_mobile= item.contactDetails.phoneNumber ? item.contactDetails.phoneNumber : "" ;

            if(item.contactDetails.telephoneNumber)
              temporaryTel = item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "" ;
            else if(item.contactDetails.telephoneArray)
              temporaryTel = item.contactDetails.telephoneArray
            else temporaryTel = []
          }
          else{
            temporaryEmail = item.email ? item.email : ""
            this.agency_mobile = item.contact ? item.contact : "" 
            temporaryTel = []
          }

          temporaryEmail = this.checkEmailValue(temporaryEmail)
          this.agency_email = temporaryEmail
          // console.log('tempraryEmail',tempraryEmail)
          this.patchEmailArray(temporaryEmail)

          let pNum = this.slicingMobileFormat(this.agency_mobile ? this.agency_mobile : "")
          this.patchMobileValue(pNum)

          let tNum:any = this.telNumToFormat(temporaryTel ? temporaryTel : []);
          this.agency_tels = tNum
          this.patchTelephoneArray(tNum)
          
        }
      })
  }

  patchMobileValue(value: any){
    this.contactDetailsForm.patchValue({
      phoneNumber: value,
    })
  }

  slicingMobileFormat(value:any){
    if(value.length > 6)
      value = [value.slice(0,6),'-',value.slice(6)].join('')
    if(value.length > 3)
      value = [value.slice(0,3),'-',value.slice(3)].join('')
    return value
  }

  checkEmailValue(emailItem:any){
    // console.log('emailItem',emailItem)
    if(emailItem && !isArray(emailItem)){
      return [{email:emailItem}]
    }
    else if(emailItem && isArray(emailItem)){
      return emailItem
    }
    return []
  }

  telNumToFormat(telItem:any){
    // let tNumArr:any[] = []
    let tNum:any[] = [];

    if(telItem && !isArray(telItem)){
      // console.log('agency tel is not array')
      let tNumVal = this.slicingTelFormat(telItem)
      let data = {
        telephoneNumber: tNumVal,
        hasTrunkline: false,
        trunkline: ''
      }
      tNum.push(data)
      return tNum;
    }
    else if(telItem && isArray(telItem)){
      // console.log('agency tel is array')
      if(telItem.length > 0){
        telItem.map((item:any)=>{
          item.telephoneNumber = this.slicingTelFormat(item.telephoneNumber)
          return item;
        })
        return telItem
      }
    }
    return []
  }

  slicingTelFormat(telNum:any){
    if(telNum > 0 && telNum.length < 4){
      telNum = "("+[telNum.slice(0,telNum.length)]+")"
      // console.log('telItem 0',telNum)
      return telNum
    }
    else if(telNum > 0 && telNum.length >= 3){
      telNum = ["("+[telNum.slice(0,3)]+")"+telNum.slice(3)].join('')
      // console.log('telItem 1',telNum)

      if(telNum.length > 5){
        telNum = [telNum.slice(0,5)+" "+telNum.slice(5)].join('')
        // console.log('telItem 2',telNum)

        if(telNum.length > 10){
          telNum = [telNum.slice(0,10)+"-"+telNum.slice(10)].join('')
          // console.log('telItem 3',telNum)
        }
      }
    }
    return telNum
  }

  patchEmailArray(emailArr:any[]){
    this.emailArray.clear()
    emailArr.forEach((item:any)=>{
      this.emailArray.push(
        this.fb.group({
          email: new UntypedFormControl(item.email?item.email:'',[Validators.required,Validators.email]),
        })
      )
    })
  }

  patchTelephoneArray(telArr:any[]){
    this.telephoneArray.clear()
    telArr.forEach((item)=>{
      if(item.hasTrunkline == true){
        this.telephoneArray.push(
          this.fb.group({
            telephoneNumber: new UntypedFormControl(item.telephoneNumber?item.telephoneNumber:'',
            [
              Validators.required,
              //this.checkTelephoneNumber()
            ]),
            hasTrunkline: new UntypedFormControl(item.hasTrunkline ? item.hasTrunkline : false),
            trunkline: new UntypedFormControl(item.trunkline ? item.trunkline : '',[Validators.required]),
          })
        )
      }else{
        this.telephoneArray.push(
          this.fb.group({
            telephoneNumber: new UntypedFormControl(item.telephoneNumber?item.telephoneNumber:'',
            [
              Validators.required,
              //this.checkTelephoneNumber()
            ]),
            hasTrunkline: new UntypedFormControl(item.hasTrunkline ? item.hasTrunkline : false),
            trunkline: new UntypedFormControl(item.trunkline ? item.trunkline : '',),
          })
        )
      }
    })
    // console.log('telArr',this.contactDetailsForm.value)
  }

  get telephoneArray(){
    return (this.contactDetailsForm.controls['telephoneArray'] as UntypedFormArray)
  }

  get emailArray(){
    return (this.contactDetailsForm.controls['emailArray'] as UntypedFormArray)
  }

  update(editName:any){
    this.loading = true
    if(this.isAdmin || this.isAgencyMember){
      if(editName === 'profile'){
        // this.profileLoader = true
        this.validateFields('profile')
        // if(this.agencyProfileForm.valid){
          let profile:any = this.agencyProfileForm.value
          
          this.afs.collection('Agency').doc(this.doc_id).update({
            agencyInfo: profile,

            mission: profile.mission,
            mandate: profile.mandate,
            vision: profile.vision,
            service_pledge: profile.pledges
          })
          .then(()=>{
            //console.log("Update profile Successful")
            this.agency_mission = profile.mission;
            this.agency_vision = profile.vision;
            this.agency_mandate = profile.mandate;
            this.agency_pledges = profile.pledges;
            this.toggleEdit('profile')
            this.loading = false;// for button spinner
            alert("Agency Profile Update Successful")
            // this.profileLoader = false
          })
          .catch(err=>{
            this.loading = false;// for button spinner
            console.error("Update profile Failed ",err)
            alert("Agency Profile Update Failed")
            // this.profileLoader = false
          })
        // }
      }
      else if(editName === 'mission'){
        this.loading = true
        // this.validateFields('mission')
        // if(this.missionForm.valid)
        this.updateFirebase(editName, this.missionForm.value.mission)
        
      }
      else if(editName === 'vision'){
        // this.validateFields('vision')
        // if(this.visionForm.valid) 
        this.loading = true
        this.updateFirebase(editName, this.visionForm.value.vision)
        
      }
      else if(editName === 'mandate'){
        // this.validateFields('mandate')
        // if(this.mandateForm.valid)
        this.loading = true
        this.updateFirebase(editName, this.mandateForm.value.mandate)
        
      }
      else if(editName === 'pledges'){
        // this.validateFields('pledges')
        // if(this.pledgesForm.valid)
        this.loading = true
        this.updateFirebase(editName, this.pledgesForm.value.pledges)
       
      }
      else if(editName === 'contact'){
        this.loading = true
        this.validateFields('contact')
        if(this.contactDetailsForm.valid){
          let contact:any = this.contactDetailsForm.value
          contact.phoneNumber = contact.phoneNumber.replace(/[^0-9]/g,'')
          // contact.telephoneNumber = contact.telephoneNumber.replace(/[^0-9]/g,'');
          contact.telephoneArray = contact.telephoneArray.map((item:any)=>{
             // item.telephoneNumber = item.telephoneNumber.replace(/[^0-9]/g,'');
              if(item.hasTrunkline == false) item.trunkline = '';
              return item
            })
          this.afs.collection('Agency').doc(this.doc_id).update({
            contactDetails: contact,

            contact: contact.phoneNumber,
            email: contact.emailArray,
          })
          .then(()=>{
            //console.log("Update contact Successful")
            this.agency_email = contact.emailArray;
            this.emailArray.clear()
            this.patchEmailArray(contact.emailArray)
            this.agency_mobile = contact.phoneNumber;
            // let pNum = this.slicingMobileFormat(this.agency_mobile ? this.agency_mobile : "")
            // this.patchMobileValue(pNum)
            // this.agency_tels = contact.telephoneNumber;
            let tNum = this.telNumToFormat(contact.telephoneArray ? contact.telephoneArray : [])
            this.agency_tels = tNum;
            this.telephoneArray.clear()
            this.patchTelephoneArray(tNum)
            this.toggleEdit('contact');
            this.loading = false;// for button spinner
            alert("Contacts Update Successful")
          })
          .catch(err=>{
            this.loading = false;// for button spinner
            console.error("Update contact Failed ",err)
            alert("Contacts Update Successful")
          })
        } else {
          let errors = this.formErrors(this.contactDetailsForm.controls)
          console.warn('invalid contact form',errors)
          this.loading = false;// for button spinner
        }
      }
    }
  }

  updateFirebase(code:any, data:any){
    
    let profileData = {}
    if(code == 'mission') profileData = { 'agencyInfo.mission': data, mission: data } 
    if(code == 'vision') profileData = { 'agencyInfo.vision': data, vision: data } 
    if(code == 'mandate') profileData = { 'agencyInfo.mandate': data, mandate: data } 
    if(code == 'pledges') profileData = { 'agencyInfo.pledges': data, service_pledge: data }

    if(Object.keys(profileData).length > 0){
      this.afs.collection('Agency').doc(this.doc_id).update(profileData)
      .then(()=>{
        //console.log('Update '+code+' successful')
        if(code == 'mission') { this.agency_mission = data;}
        if(code == 'vision') { this.agency_vision = data;}
        if(code == 'mandate') { this.agency_mandate = data;}
        if(code == 'pledges') { this.agency_pledges = data;}
        this.toggleEdit(code)
        alert("Agency Profile Update Successful")
        this.loading = false;// for button spinner
      })
      .catch((err)=>{
        this.loading = false;// for button spinner
        console.error("Update "+code+" Failed ",err)
        alert("Agency Profile on "+code+" Update Failed")
      })
    } else console.warn('Invalid update code')
  }

  async uploadLogo(event:any){
    //console.log("uploading")
    if(event.target.files && event.target.files.length > 0){
      const [file] = event.target.files;
      if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'){
        const newUrl = await this.uploadToFirestorage(this.doc_id,this.doc_id,event.target.files[0])
        if(newUrl){
          this.updateAgenecyLogo(newUrl);
        } 
        else{
          alert("Unable to upload logo")
          this.resetLogo()
        }
      }
      else{
        alert("Incorrect file type")
        this.resetLogo()
      }
    }
  }

  resetLogo(){
    this.agencyImage = null
    this.imgSrc = this.defaultImgSrc;
    (document.getElementById('logoSrc') as HTMLInputElement).value = "";
    this.agencyLogoForm.patchValue({
      logoURL:this.defaultImgSrc,
      logoSrc:'',
      logoFile:''
    })
  }

  updateAgenecyLogo(newUrl:any){
    this.afs.collection('Agency').doc(this.agency_id).update({
      "agencyDetails.logoURL":newUrl
    })
    .then(()=>{
      this.defaultImgSrc = newUrl;
      this.agencyLogoForm.patchValue({
        logoURL: newUrl
      })
    })
    .catch(()=>{
      alert("Unable to upload log")
    })
  }

  uploadToFirestorage(agencyId:any,logoName:any, file:File){
    let filePath  = this.logoPath+"/"+agencyId+"/logo/uploadedLogo";
    // console.log("filepath",filePath)

    // return this.store.upload(filePath,file)
    // .then(res=>{
    //   return res.ref.getDownloadURL()
    // })
    // .catch(rej=>{
    //   console.log("upload reject",rej)
    //   return null
    // })
    let uploadTask = this.store.upload(filePath,file)
    
    this.uploadToFireStorageProgress = uploadTask.percentageChanges()

    return uploadTask
    .then(res=>{
      alert("Logo Uploaded")
      return res.ref.getDownloadURL()
    })
    .catch(rej=>{
      console.log("upload reject",rej)
      return null
    })
  }

  // Validators
  checkPhoneNumber():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.contactDetailsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            minlength: false,
            // pattern: false,
          }
          if(val.length > 0){
            if( val.length != 12){
              tempbool = true;
              errors.minlength = true;
            }
            // console.log(val.match(/(\d{0,9})/g))
            // if(){ 
            //   tempbool = true;
            //   errors.pattern = true;
            // }
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  
  checkTelephoneNumber():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.contactDetailsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        let tempbool: boolean = false
        let errors = {
          minlength: false,
          required:false,
        }
        if(val){
          if(val.length > 0){
            if( val.length < 14){
              tempbool = true;
              errors.minlength = true;
            }
          }
        } else{
          tempbool = true
          errors.required = true
        }
        return tempbool ? errors : null
      }
      return null
    }
  }

  checkTrunklineNumber(): ValidatorFn {
    return (control: AbstractControl): {[key: string]:any} | null =>{
      if(this.contactDetailsForm != undefined){
        let tempbool:boolean = false
        let errors = {
          required:false
        }
        if(control.parent != null && control.parent != undefined){
          const hasTrunkline:any = (control.parent as UntypedFormGroup).controls['hasTrunkline'].value
          const trunkline:any = control.value
          if(hasTrunkline == true){ 
            if(trunkline != undefined){
              if(trunkline.length == 0){
                tempbool = true
                errors.required = true
              }
            }
            else{
              tempbool = true;
              errors.required = true
            }
          }
        }
        return tempbool ? errors : null
      }
      return null
    }
  }
  // !Validators

  // forms array functions
  getFormsArray(listName: string): UntypedFormGroup[]{
    if(listName == 'email')
      return this.emailArray.controls as UntypedFormGroup[]
    if(listName == 'telephone')
      return this.telephoneArray.controls as UntypedFormGroup[]
    return []
  }

  addNewRow(listName: string){
    if(listName === 'telephone')
      this.telephoneArray.push(this.fb.group({
        telephoneNumber: new UntypedFormControl('',
        [
          Validators.required,
          //this.checkTelephoneNumber()
        ]),
        hasTrunkline: new UntypedFormControl(false),
        trunkline: new UntypedFormControl('',[]),
      }))
    else if(listName === 'email')
      this.emailArray.push(this.fb.group({
        email: new UntypedFormControl('',[Validators.required,Validators.email])
      }))
  }

  removeCartFormRow(listName:string, index:any){
    if(index >= 0){
      if(listName === 'telephone') (this.telephoneArray.removeAt(index))
      else if(listName === 'email') (this.emailArray.removeAt(index))
    }
    else console.warn('no index value to remove')
  }

  checkHasTrunkline(ctr:number):boolean{
    // console.log('checkHasTrunkline',(((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).controls['hasTrunkline'] as FormControl).value)
    let curBool: boolean = (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls['hasTrunkline'] as UntypedFormControl).value
    return (curBool == true) ? false : true
  }

  checkHasNoTrunkline(ctr:number):boolean{
    // console.log('checkHasTrunkline',(((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).controls['hasTrunkline'] as FormControl).value)
    let curBool: boolean = (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls['hasTrunkline'] as UntypedFormControl).value
    return (curBool == true) ? true : false
  }

  hasTrunklineChange(event:Event, ctr:number){
    // console.log('hasTrunklineChange',(event.target as HTMLInputElement).value)
    let checkBoxVal:boolean = ((event.target as HTMLInputElement).checked)
    if(checkBoxVal == false){
      ((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup)
      .patchValue({
        trunkline: '',
      });
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).setValidators([]);
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).markAsPristine();
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).markAsUntouched();
    }
    else if(checkBoxVal == true){
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).setValidators([Validators.required]);
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).markAsPristine();
      (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).markAsUntouched();
    }
    (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get('trunkline') as UntypedFormControl).updateValueAndValidity();
    // console.log(((this.telephoneArray.controls as FormGroup[])[ctr] as FormGroup).get('trunkline'))
  }
  
  getNumberOfElements(listName:any){
    if(listName === 'telephone') return this.telephoneArray.controls.length
    else if(listName === 'email') return this.emailArray.controls.length
    else return 0
  }
  // !forms array functions

  // error handlers
  toggleEdit(editName:any){
    if(this.isAdmin || this.isAgencyMember){
      if(editName === 'profile'){
        this.editProfile= !this.editProfile
        this.agencyProfileForm.patchValue({
          mission: this.agency_mission,
          vision: this.agency_vision,
          mandate: this.agency_mandate,
          pledges: this.agency_pledges,
        })
        this.agencyProfileForm.markAsUntouched()
        this.agencyProfileForm.markAsPristine()
      }
      else if(editName === 'mission'){
        this.editMission = !this.editMission
        this.missionForm.patchValue({
          mission:this.agency_mission
        })
        this.missionForm.markAsUntouched()
        this.missionForm.markAsPristine()
      }
      else if(editName === 'vision'){
        this.editVision = !this.editVision
        this.visionForm.patchValue({
          vision:this.agency_vision
        })
        this.visionForm.markAsUntouched()
        this.visionForm.markAsPristine()
      }
      else if(editName === 'mandate'){
        this.editMandate = !this.editMandate
        this.mandateForm.patchValue({
          mandate:this.agency_mandate
        })
        this.mandateForm.markAsUntouched()
        this.mandateForm.markAsPristine()
      }
      else if(editName === 'pledges'){
        this.editPledges = !this.editPledges
        this.pledgesForm.patchValue({
          pledges:this.agency_pledges
        })
        this.pledgesForm.markAsUntouched()
        this.pledgesForm.markAsPristine()
      }
      else if(editName === 'contact'){
        this.editContact= !this.editContact
        let pNum = this.agency_mobile;
        if(pNum.length > 6)
          pNum = [pNum.slice(0,6),'-',pNum.slice(6)].join('')
        if(pNum.length > 3)
          pNum = [pNum.slice(0,3),'-',pNum.slice(3)].join('')
        this.telephoneArray.clear()
        this.emailArray.clear()
        this.contactDetailsForm.patchValue({
          phoneNumber: pNum,
        })
        this.patchEmailArray(this.agency_email)
        this.patchTelephoneArray(this.agency_tels)
        this.contactDetailsForm.markAsUntouched()
        this.contactDetailsForm.markAsPristine()
      }
    }
  }

  hasErrors(editName:any, controlName:any, validType:any, ctr?:any){
    if(editName === 'profile'){
      return ((this.agencyProfileForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.agencyProfileForm.get(controlName) as UntypedFormControl).dirty 
      || (this.agencyProfileForm.get(controlName) as UntypedFormControl).touched));
    }
    if(editName === 'mission'){
      return ((this.missionForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.missionForm.get(controlName) as UntypedFormControl).dirty 
      || (this.missionForm.get(controlName) as UntypedFormControl).touched));
    }
    if(editName === 'vision'){
      return ((this.visionForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.visionForm.get(controlName) as UntypedFormControl).dirty 
      || (this.visionForm.get(controlName) as UntypedFormControl).touched));
    }
    if(editName === 'mandate'){
      return ((this.mandateForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.mandateForm.get(controlName) as UntypedFormControl).dirty 
      || (this.mandateForm.get(controlName) as UntypedFormControl).touched));
    }
    if(editName === 'pledges'){
      return ((this.pledgesForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.pledgesForm.get(controlName) as UntypedFormControl).dirty 
      || (this.pledgesForm.get(controlName) as UntypedFormControl).touched));
    }
    else if(editName === 'contact'){
      return ((this.contactDetailsForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.contactDetailsForm.get(controlName) as UntypedFormControl).dirty 
      || (this.contactDetailsForm.get(controlName) as UntypedFormControl).touched));
    }
    if(editName === 'telephoneArray'){
      return (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).hasError(validType)
      && ((((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).dirty
      || (((this.telephoneArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched)
    }
    if(editName === 'emailArray'){
      return (((this.emailArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).hasError(validType)
      && ((((this.emailArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).dirty
      || (((this.emailArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched)
    }
    else return null
  }

  formErrors(controls:any){
    let tempArr:any= []
    for (const control in controls){
      if(controls[control].invalid){
        tempArr.push(control)
      }
    }
    return tempArr
  }

  validateFields(editName:any){
    if(this.isAdmin || this.isAgencyMember){
      if(editName === 'profile'){
        if(!this.agencyProfileForm.valid){
          this.agencyProfileForm.markAllAsTouched();
          for(const control in this.agencyProfileForm.controls){
            if (this.agencyProfileForm.controls.hasOwnProperty(control)) {
              this.agencyProfileForm.controls[control].markAllAsTouched();
              this.agencyProfileForm.controls[control].markAsDirty();
              
            }
          }
        }
      }
      else if(editName === 'contact'){
        // if(!this.contactDetailsForm.valid){
          this.contactDetailsForm.markAllAsTouched();
          for(const control in this.contactDetailsForm.controls){
            if (this.contactDetailsForm.controls.hasOwnProperty(control)) {
              // this.contactDetailsForm.controls[control].markAllAsTouched();
              // this.contactDetailsForm.controls[control].markAsDirty();

              if(this.contactDetailsForm.controls[control] instanceof UntypedFormControl){
                // console.log(control+': control')
                this.contactDetailsForm.controls[control].markAllAsTouched();
                this.contactDetailsForm.controls[control].markAsDirty();
              }

              else if(this.contactDetailsForm.controls[control] instanceof UntypedFormGroup){
                // console.log(control+': group')
                this.contactDetailsForm.controls[control].markAllAsTouched();
                this.contactDetailsForm.controls[control].markAsDirty();
              }

              else if(this.contactDetailsForm.controls[control] instanceof UntypedFormArray){
                // console.log(control+': array')
                for(const formArrCtr in ((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[])){
                  // console.log(formArrCtr+': group');
                  ((((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[]))[formArrCtr] as UntypedFormGroup).markAllAsTouched();
                  ((((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[]))[formArrCtr] as UntypedFormGroup).markAsDirty();
                  for(const formGroupCtr in (((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[])[formArrCtr] as UntypedFormGroup).controls){
                    // console.log(formGroupCtr+': control','valid: '+((((this.contactDetailsForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls[formGroupCtr]).valid);
                    // console.log((((this.contactDetailsForm.controls[control] as FormArray).controls as FormGroup[])[formArrCtr] as FormGroup).controls)
                    if((((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[])[formArrCtr] as UntypedFormGroup).controls.hasOwnProperty(formGroupCtr)){
                      (((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[])[formArrCtr] as UntypedFormGroup).controls[formGroupCtr].markAsTouched();
                      (((this.contactDetailsForm.controls[control] as UntypedFormArray).controls as UntypedFormGroup[])[formArrCtr] as UntypedFormGroup).controls[formGroupCtr].markAsDirty();
                      
                    }
                  }
                }
              }
            }
          }
        // }
      }
    }
  }
  // !error handlers
}


