import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-organization-sector',
  templateUrl: './organization-sector.component.html',
  styleUrls: ['./organization-sector.component.scss']
})
export class OrganizationSectorComponent implements OnInit {
  availableSectors: string[] = [];

  constructor(private afs: AngularFirestore) {

  }
  sectors: { name: string; legalBasis?: string; role?: string }[] = [];

  ngOnInit(): void {
    this.loadSectors();
  }

  loadSectors() {
    this.afs
      .collection('Sector')
      .get()
      .subscribe(querySnapshot => {
        console.log('Number of sectors:', querySnapshot.docs.length);
        this.availableSectors = querySnapshot.docs.map(doc => (doc.data() as { name: string }).name);
      });
  }

  addNewSector(event: Event) {
    const target = event.target as HTMLInputElement;
    const selectedSector = target.value.trim();

    if (selectedSector) {
      this.sectors.push({ name: selectedSector });
    }

    console.log('Updated Sectors:', this.sectors);
    target.value = ''; // Reset input after selection
  }

  deleteSector(index: number) {
    this.sectors.splice(index, 1);
    console.log('Updated Sectors:', this.sectors);
  }
}
